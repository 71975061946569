const MAP_LEGEND_1 = 1;
const MAP_LEGEND_2 = 2;
const MAP_LEGEND_3 = 3;
const MAP_LEGEND_4 = 4;
const MAP_LEGEND_5 = 5;

const viewboard1 = {
  id: 'viewboard1',
  mapLegendNumber: MAP_LEGEND_1,
  contentId: null,
};

const viewboard2 = {
  id: 'viewboard2',
  mapLegendNumber: MAP_LEGEND_2,
  contentId: null,
};

const viewboard3 = {
  id: 'viewboard3',
  mapLegendNumber: MAP_LEGEND_3,
  contentId: null,
};

const viewboard4 = {
  id: 'viewboard4',
  mapLegendNumber: MAP_LEGEND_4,
  contentId: null,
};

const viewboard5 = {
  id: 'viewboard5',
  mapLegendNumber: MAP_LEGEND_5,
  contentId: null,
};

export const LOCATION_VIEWBOARDS = {
  vegas: [viewboard1],
  airport: [viewboard1, viewboard2],
  house: [viewboard1],
  movie_theater: [viewboard1],
  tech_store: [viewboard1],
  conference_room: [viewboard1],
  restaurant: [viewboard1],
  some_moon: [viewboard1],
  fluentworlds_academy: [viewboard1, viewboard2, viewboard3],
  gov_office: [viewboard1, viewboard2, viewboard3, viewboard4],
  viewu: [viewboard1],
  artworld: [viewboard1, viewboard2, viewboard3, viewboard4],
  nyc_park: [viewboard1],
  clothing_store: [viewboard1, viewboard2],
  office: [viewboard1],
  dr_office: [viewboard1],
  school: [viewboard1],
  stadium: [viewboard1, viewboard2, viewboard3, viewboard4],
  street: [viewboard1],
  gas_station: [viewboard1],
  nightvegas: [viewboard1],
  bank: [viewboard1],
  pumpkin_patch: [viewboard1],
  grocery_store: [viewboard1],
  rose_garden: [viewboard1],
  space_dock: [viewboard1, viewboard2],
  svu: [viewboard1],
  classroom: [viewboard1, viewboard2, viewboard3, viewboard4, viewboard5],
  mexico: [viewboard1],
  bridge: [viewboard1],
  soccer_stadium: [viewboard1],
  japan: [viewboard1],
  generic_redcarpet: [
    viewboard1,
    viewboard2,
    viewboard3,
    viewboard4,
    viewboard5,
  ],
  chichen_itza: [viewboard1],
  cabo: [viewboard1],
  mexico_market: [viewboard1],
  merida_plaza: [viewboard1],
  farm: [viewboard1],
  science_lab: [viewboard1],
  hotel_lobby: [viewboard1, viewboard2],
  cyberpunk_city: [viewboard1, viewboard2, viewboard3],
  night_cabo: [viewboard1],
  night_chichen_itza: [viewboard1],
  night_merida_plaza: [viewboard1],
  house_full: [viewboard1, viewboard2],
  lycee_concordia: [viewboard1, viewboard2],
  cyberpunk_city2: [viewboard1, viewboard2],
  naregitim: [viewboard1],
  qnb_bank: [viewboard1],
};
