import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const TextOverlay = (props) => {
  const getPositionStyle = (x, y, z) => {
    return {
      left: `${100 * x}%`,
      bottom: `${100 * y}%`,
      zIndex: z,
    };
  };

  const getCenteredStyle = (width, height) => {
    return {
      transform: [
        {
          translateX: -(width / 2),
        },
        {
          translateY: height / 2,
        },
      ],
    };
  };
  const {x, y, z, text, style, icon} = props;

  const [dimensions, setDimensions] = useState({
    width: 50,
    height: 50,
  });

  return (
    <View
      onLayout={(event) => {
        const {height, width} = event.nativeEvent.layout;
        setDimensions({width, height});
      }}
      style={[
        getCenteredStyle(dimensions.width, dimensions.height),
        styles.overlay,
        getPositionStyle(x, y, z),
      ]}>
      {icon ? <Icon name={icon} style={[styles.icon]} /> : null}
      <Text selectable={false} style={[styles[style]]}>
        {text}
      </Text>
    </View>
  );
};

TextOverlay.defaultProps = {
  style: 'name',
  icon: false,
};

TextOverlay.propTypes = {
  id: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  z: PropTypes.number.isRequired,
  style: PropTypes.oneOf(['name', 'chat', 'viewboard']),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

// TODO: Figure out alternative for `white-space` CSS property for RN
const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 20,
    color: '#ff6600',
  },
  name: {
    color: '#ff6600',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 20,
    fontSize: 20,
    textShadowOffset: {
      width: 1,
      height: 1,
    },
    textShadowRadius: 1,
    textShadowColor: '#ffffff',
  },
  chat: {
    color: '#88cc88',
    fontSize: 24,
  },
  viewboard: {
    color: '#88cccc',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 20,
    fontSize: 20,
  },
});

export default TextOverlay;
