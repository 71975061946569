import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {View, Text, StyleSheet} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Recorder from 'screens/Meeting/components/recorder/Recorder';

const RECORDING_ON_ICON_COLOR = 'red';
const RECORDING_OFF_ICON_COLOR = 'grey';

const RecordMeeting = () => {
  const {translate} = useContext(I18nContext);
  const recording = useSelector(
    (state) => state.meeting.recording.recordingLocally,
  );
  const iconColor = {
    color: recording ? RECORDING_ON_ICON_COLOR : RECORDING_OFF_ICON_COLOR,
  };
  return (
    <View>
      <View style={styles.item}>
        <View style={styles.display}>
          <Icon
            type="MaterialCommunityIcons"
            style={[styles.icon, iconColor]}
            name="record-rec"
          />
          <Text style={styles.text}>{translate('recordMeeting.record')}</Text>
        </View>
        <View style={styles.display}>
          <Recorder />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  item: {
    flex: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    marginTop: 15,
  },
  display: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  text: {
    fontSize: 18,
  },
  icon: {
    fontSize: 28,
    marginRight: 5,
  },
});

export default RecordMeeting;
