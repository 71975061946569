import * as types from './viewboardActionTypes';

export const screenOptions = {dashboard: 'dashboard', share: 'share'};
export const contentTypeOptions = {
  image: 'image',
  video: 'video',
  collection: 'collection',
};

export const initialState = {
  screen: screenOptions.dashboard,
  isEditMode: false,
  isViewboardContentFullScreen: false,
  selectedContentId: null,
  selectedViewboardId: null,
  content: [],
  viewboards: [],
  activeViewboards: [],
};

const viewboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_TO_DASHBOARD: {
      return {...state, screen: screenOptions.dashboard};
    }

    case types.SWITCH_TO_SHARE_ON_VIEWBOARD: {
      const isEditMode = action.payload;

      return {...state, screen: screenOptions.share, isEditMode: isEditMode};
    }

    case types.SELECT_VIEWBOARD_CONTENT: {
      return {...state, selectedContentId: action.payload};
    }

    case types.SELECT_VIEWBOARD: {
      return {...state, selectedViewboardId: action.payload};
    }

    case types.ADD_ACTIVE_VIEWBOARD: {
      const {viewboardId, contentId} = action.payload;
      const activeViewboard = state.viewboards.find(
        (viewboard) => viewboard.id === viewboardId,
      );

      return {
        ...state,
        activeViewboards: [
          ...state.activeViewboards,
          {...activeViewboard, contentId: contentId},
        ],
      };
    }

    case types.EDIT_ACTIVE_VIEWBOARD: {
      const {viewboardId, contentId} = action.payload;

      const activeViewboardToEditIndex = state.activeViewboards.findIndex(
        (activeViewboard) => activeViewboard.id === viewboardId,
      );
      state.activeViewboards[activeViewboardToEditIndex].contentId = contentId;

      return {
        ...state,
        activeViewboards: [...state.activeViewboards],
      };
    }

    case types.REMOVE_ACTIVE_VIEWBOARD: {
      const viewboardId = action.payload;

      return {
        ...state,
        activeViewboards: state.activeViewboards.filter(
          (viewboard) => viewboard.id !== viewboardId,
        ),
      };
    }

    case types.REGISTER_VIEWBOARDS: {
      const viewboards = action.payload;
      return {...state, viewboards: [...viewboards]};
    }

    case types.CLEAR_VIEWBOARDS: {
      return {...state, viewboards: [], activeViewboards: []};
    }

    case types.SET_IS_VIEWBOARD_CONTENT_FULLSCREEN: {
      return {...state, isViewboardContentFullScreen: action.payload};
    }

    case types.SET_VIEWBOARD_CONTENT: {
      return {...state, content: action.payload};
    }

    default:
      return state;
  }
};

export default viewboardReducer;
