import * as types from '../actions/actionTypes';

const FIRST_PERSON_PERSPECTIVE = 'FIRST';
const THIRD_PERSON_PERSPECTIVE = 'THIRD';
const OVER_SHOULDER_PERSPECTIVE = 'SHOULDER';

export const PERSPECTIVE_MODES = {
  first: FIRST_PERSON_PERSPECTIVE,
  third: THIRD_PERSON_PERSPECTIVE,
  shoulder: OVER_SHOULDER_PERSPECTIVE,
};

const ENGLISH = 'en';
const SPANISH = 'es';
const GERMAN = 'de';
const FRENCH = 'fr';
const CHINESE = 'zh';
const RUSSIAN = 'ru';

export const SUPPORTED_TRANSLATION_SERVICE_LANGUAGES = [
  {language: ENGLISH, show: true},
  {language: SPANISH, show: true},
  {language: GERMAN, show: true},
  {language: FRENCH, show: true},
  {language: CHINESE, show: true},
  {language: RUSSIAN, show: true},
];

export const initialState = {
  incomingVideoEnabled: true,
  lowPowerMode: true,
  positionalVoiceFallOff: false,
  liveTranslation: false,
  targetLanguage: ENGLISH,
  perspectiveMode: PERSPECTIVE_MODES.third,
};

const updateState = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INCOMING_VIDEO_ENABLED: {
      const {enabled} = action.payload;
      return {...state, incomingVideoEnabled: enabled};
    }

    case types.ENABLE_LOW_POWER_MODE: {
      return {...state, lowPowerMode: true};
    }

    case types.DISABLE_LOW_POWER_MODE: {
      return {...state, lowPowerMode: false};
    }

    case types.ENABLE_POSITIONAL_VOICE_FALL_OFF: {
      return {...state, positionalVoiceFallOff: true};
    }

    case types.DISABLE_POSITIONAL_VOICE_FALL_OFF: {
      return {...state, positionalVoiceFallOff: false};
    }

    case types.SET_PERSPECTIVE_MODE: {
      return {...state, perspectiveMode: action.payload};
    }

    case types.ENABLE_LIVE_TRANSLATION: {
      return {...state, liveTranslation: true};
    }

    case types.DISABLE_LIVE_TRANSLATION: {
      return {...state, liveTranslation: false};
    }

    case types.SET_LIVE_TRANSLATION_LANGUAGE: {
      return {...state, targetLanguage: action.payload};
    }

    default:
      return state;
  }
};

export default updateState;
