export const DISABLED_MEETING_CONTROL_COLOR = '#d93025';
export const MEETING_STATUS_BAR_BACKGROUND_COLOR = '#00000040';
export const SIDE_PANEL_ATTENDANCE_COLOR = '#3b5998';
export const SIDE_PANEL_MESSAGES_COLOR = '#5BC236';
export const SIDE_PANEL_NAVIGATION_COLOR = '#32cbfd';
export const SIDE_PANEL_VIEWBOARDS_COLOR = '#F45862';
export const SIDE_PANEL_SETTINGS_COLOR = '#2d2d2e';
export const VIEWBOARDS_HEADING_STYLE = {
  fontSize: 16,
  fontWeight: 'bold',
  marginTop: 15,
  marginBottom: 15,
  marginLeft: 15,
  marginRight: 10,
};
export const VIEWBOARD_MAP_IMAGE_CONTAINER = {
  alignSelf: 'center',
  justifyContent: 'flex-end',
  marginTop: 15,
};
export const VIEWBOARD_ITEM_BUTTON_CONTAINER = {
  borderRadius: 50,
  borderWidth: 1.5,
  borderColor: 'rgba(0, 0, 0, 0.5)',
  marginLeft: 10,
  marginRight: 10,
};
export const VIEWBOARD_SCROLL_ITEMS_CONTAINER = {
  marginTop: 5,
  marginBottom: 5,
  justifyContent: 'flex-start',
};
export const VIEWBOARD_SCROLL_VIEW_ITEM = {
  marginTop: 5,
  marginBottom: 5,
  justifyContent: 'flex-start',
};
export const VIEWBOARD_SCROLL_VIEW_CONTENT_CONTAINER = {
  flexGrow: 1,
  justifyContent: 'space-between',
};
export const MICROPHONE_VOLUME_INDICATOR = {
  width: 200,
  height: 16,
};
