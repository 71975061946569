import React, {useContext} from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import AllButton from './AllButton';
import {
  recallAllPeers,
  muteRoomAudio,
  muteRoomVideo,
  muteRoomChat,
} from 'store/meeting/actions/attendance';
import {logEvent} from 'utils/firebase/analytics';
import {
  MUTE_ALL_AUDIO,
  UNMUTE_ALL_AUDIO,
  MUTE_ALL_VIDEO,
  UNMUTE_ALL_VIDEO,
  MUTE_ALL_CHAT,
  UNMUTE_ALL_CHAT,
  RECALL_ALL,
} from 'utils/firebase/analytics.config';
import {
  checkPermission,
  VOICE_MUTE_OTHER,
  VIDEO_MUTE_OTHER,
  CHAT_MUTE_OTHER,
  LOCATION_RECALL,
} from 'utils/permissions';
import {I18nContext} from 'i18n/context/I18nContext';

const ControlAll = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const {muted} = useSelector((state) => state.communication.room);
  const {permissions} = useSelector((state) => state.communication.me);

  const recall = async () => {
    dispatch(recallAllPeers());

    await logEvent(RECALL_ALL);
  };

  const muteAudio = async () => {
    dispatch(muteRoomAudio(true));

    await logEvent(MUTE_ALL_AUDIO);
  };

  const unmuteAudio = async () => {
    dispatch(muteRoomAudio(false));

    await logEvent(UNMUTE_ALL_AUDIO);
  };

  const muteVideo = async () => {
    dispatch(muteRoomVideo(true));

    await logEvent(MUTE_ALL_VIDEO);
  };

  const unmuteVideo = async () => {
    dispatch(muteRoomVideo(false));

    await logEvent(UNMUTE_ALL_VIDEO);
  };

  const muteChat = async () => {
    dispatch(muteRoomChat(true));

    await logEvent(MUTE_ALL_CHAT);
  };

  const unmuteChat = async () => {
    dispatch(muteRoomChat(false));

    await logEvent(UNMUTE_ALL_CHAT);
  };

  const shouldRender =
    checkPermission(LOCATION_RECALL, permissions) ||
    checkPermission(VOICE_MUTE_OTHER, permissions) ||
    checkPermission(VIDEO_MUTE_OTHER, permissions) ||
    checkPermission(CHAT_MUTE_OTHER, permissions);

  return shouldRender ? (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text style={styles.headerTitle}>{translate('controlAll.block')}</Text>
      </View>
      {checkPermission(LOCATION_RECALL, permissions) ? (
        <View style={styles.controlRow}>
          <AllButton
            text={translate('controlAll.recallEveryone')}
            icon="arrow-down-circle"
            onPress={recall}
          />
        </View>
      ) : null}
      {checkPermission(VOICE_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          {muted.audio ? (
            <AllButton
              text={translate('controlAll.allow')}
              icon="microphone"
              showCancel
              onPress={unmuteAudio}
              focused={muted.audio}
            />
          ) : (
            <AllButton
              text={translate('controlAll.block')}
              icon="microphone"
              onPress={muteAudio}
              focused={!muted.audio}
            />
          )}
        </View>
      ) : null}
      {checkPermission(VIDEO_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          {muted.video ? (
            <AllButton
              text={translate('controlAll.allow')}
              icon="video"
              showCancel
              onPress={unmuteVideo}
              focused={muted.video}
            />
          ) : (
            <AllButton
              text={translate('controlAll.block')}
              icon="video"
              onPress={muteVideo}
              focused={!muted.video}
            />
          )}
        </View>
      ) : null}
      {checkPermission(CHAT_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          {muted.chat ? (
            <AllButton
              text={translate('controlAll.allow')}
              icon="chat"
              showCancel
              onPress={unmuteChat}
              focused={muted.chat}
            />
          ) : (
            <AllButton
              text={translate('controlAll.block')}
              icon="chat"
              onPress={muteChat}
              focused={!muted.chat}
            />
          )}
        </View>
      ) : null}
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  controlRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: 4,
  },
  root: {
    padding: 10,
    backgroundColor: '#add8e6',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    fontWeight: '600',
  },
});

export default ControlAll;
