import * as types from 'store/world/actionTypes';
import World from 'components/World';

export const clearWorld = () => {
  return {
    type: types.CLEAR_WORLD,
  };
};

export const setLocation = (location) => {
  return {
    type: types.SET_WORLD_LOCATION,
    payload: {location},
  };
};

export const setCustomizeLocation = (location) => {
  return {
    type: 'SET_CUSTOMIZE_LOCATION',
    payload: {location},
  };
};

export const setAppearance = (appearance) => {
  return {
    type: 'SET_APPEARANCE',
    payload: {appearance},
  };
};

export const setLowPower = (lowPower) => {
  return {
    type: 'SET_LOW_POWER',
    payload: {lowPower},
  };
};

export const setJoined = () => {
  return {
    type: 'SET_JOINED',
    payload: {joined: true},
  };
};

export const setTeleportToPeer = (peerId) => {
  return {
    type: 'SET_TELEPORT_TO_PEER',
    payload: {peerId},
  };
};

export const requestRecall = (peerId, targetLocation) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {location} = state.world;
    if (targetLocation !== location) {
      await dispatch(setLocation(targetLocation));
      await dispatch(setTeleportToPeer(peerId));
    } else {
      World.runCommand(`teleport_user ${peerId}`);
    }
  };
};

export const enableFloatingNames = () => {
  return async (dispatch) => {
    return changeFloatingNames(dispatch, true);
  };
};

export const disableFloatingNames = () => {
  return async (dispatch) => {
    return changeFloatingNames(dispatch, false);
  };
};

const changeFloatingNames = async (dispatch, enable) => {
  await World.runCommand(`show_names ${enable}`);
  await dispatch(clearTextOverlays());
  return await dispatch(setFloatingNames(enable));
};

export const setFloatingNames = (showFloatingNames) => {
  return {
    type: types.SET_FLOATING_NAMES,
    payload: {showFloatingNames},
  };
};

export const updateTextOverlay = (id, text, style, x, y, z, target) => {
  return {
    type: types.UPDATE_TEXT_OVERLAY,
    payload: {
      id,
      text,
      style,
      x,
      y,
      z,
      target,
    },
  };
};

export const removeTextOverlay = (id) => {
  return {
    type: types.REMOVE_TEXT_OVERLAY,
    payload: {
      id,
    },
  };
};

export const clearTextOverlays = () => {
  return {
    type: types.CLEAR_TEXT_OVERLAYS,
  };
};

export const setIsMessagePanelOpen = (isMessagePanelOpen) => {
  return {
    type: types.SET_IS_MESSAGE_PANEL_OPEN,
    payload: isMessagePanelOpen,
  };
};

export const setIsMessageNotification = (isMessageNotification) => {
  return {
    type: types.SET_IS_MESSAGE_NOTIFICATION,
    payload: isMessageNotification,
  };
};

export const setLabelDisplay = (labelId) => {
  return {
    type: types.SET_LABEL_DISPLAY,
    payload: labelId,
  };
};

export const clearLabelDisplay = () => {
  return {
    type: types.CLEAR_LABEL_DISPLAY,
    payload: null,
  };
};

export const clearLevelLoading = () => {
  return {
    type: types.CLEAR_LEVEL_LOADING,
    payload: null,
  };
};
