import * as types from '../actions/actionTypes';

const initialState = {
  peers: {},
};

const getPeerFromState = (state, peerId) => {
  let peer = state.peers[peerId];
  if (!peer) {
    peer = {id: peerId};
  }
  return peer;
};

const updateState = (state = initialState, action) => {
  switch (action.type) {
    case types.DISABLE_PEER_AUDIO: {
      const {peerId} = action.payload;
      const peer = getPeerFromState(state, peerId);

      const newPeer = {...peer, disableAudio: true};

      const peers = {...state.peers, [newPeer.id]: newPeer};

      return {...state, peers};
    }

    case types.ENABLE_PEER_AUDIO: {
      const {peerId} = action.payload;
      const peer = getPeerFromState(state, peerId);

      const newPeer = {...peer, disableAudio: false};

      const peers = {...state.peers, [newPeer.id]: newPeer};

      return {...state, peers};
    }

    case types.DISABLE_PEER_VIDEO: {
      const {peerId} = action.payload;
      const peer = getPeerFromState(state, peerId);

      const newPeer = {...peer, disableVideo: true};

      const peers = {...state.peers, [newPeer.id]: newPeer};

      return {...state, peers};
    }

    case types.ENABLE_PEER_VIDEO: {
      const {peerId} = action.payload;
      const peer = getPeerFromState(state, peerId);

      const newPeer = {...peer, disableVideo: false};

      const peers = {...state.peers, [newPeer.id]: newPeer};

      return {...state, peers};
    }

    default:
      return state;
  }
};

export default updateState;
