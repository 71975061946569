import React from 'react';
import PropTypes from 'prop-types';
import UniversalButton from 'components/UniversalButton';

const IconButton = (props) => {
  return <UniversalButton {...props} />;
};

IconButton.defaultProps = {};

IconButton.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  onPressIn: PropTypes.func,
  onPressOut: PropTypes.func,
  onLongPress: PropTypes.func,
  iconColor: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  textBadge: PropTypes.bool,
  iconBadge: PropTypes.bool,
  badgeForegroundColor: PropTypes.string,
  badgeBackgroundColor: PropTypes.string,
  badgeStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thumbnailStyles: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default IconButton;
