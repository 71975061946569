export const SET_APPEARANCE_BODY = 'SET_APPEARANCE_BODY';
export const SELECT_APPEARANCE_CATEGORY = 'SELECT_APPEARANCE_CATEGORY';
export const SET_APPEARANCE = 'SET_APPEARANCE';
export const SYNC_APPEARANCE = 'SYNC_APPEARANCE';
export const SET_AVATAR_LOADING = 'SET_AVATAR_LOADING';
export const SET_ASDKID = 'SET_ASDKID';
export const LOAD_APPEARANCE_DATA = 'LOAD_APPEARANCE_DATA';
export const FORCE_BROADCAST = 'FORCE_BROADCAST';
export const SET_EDITS_IN_PROGRESS = 'SET_EDITS_IN_PROGRESS';
export const SET_IS_FULL_BODY = 'SET_IS_FULL_BODY';
