import React, {useEffect, useContext} from 'react';
import {Switch, SafeAreaView, StyleSheet, Button, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector, useDispatch} from 'react-redux';

import {joinRoom} from 'store/communication/actions/controlActions';
import {goToMeeting} from 'store/app';
import VideoContainer from 'components/Communication/VideoContainer';
import AudioMonitor from 'components/Configs/AudioMonitor';
import {
  enableMic,
  disableMic,
  disableCamera,
  enableCamera,
  switchCamera,
} from 'store/media';
import UniversalButton from 'components/UniversalButton';
import SettingsDialogueOverlay from 'components/Communication/SettingsDialogueOverlay';
import {MICROPHONE_VOLUME_INDICATOR} from 'style/theme';
import {I18nContext} from 'i18n/context/I18nContext';

const DeviceConfig = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const {
    micEnabled,
    cameraEnabled,
    canSwitchCamera,
    camStream,
    micStream,
    mirrorCamera,
  } = useSelector((state) => state.media);

  useEffect(() => {
    if (!micEnabled) {
      dispatch(enableMic());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onJoin = () => {
    // starts a new instance of the RoomClient for this user which begins the connection to the comm server.
    dispatch(joinRoom());
    // changes the user's view to the meeting view
    dispatch(goToMeeting());
  };

  const changeCamera = async () => {
    dispatch(switchCamera());
  };

  const toggleMic = () => {
    if (micEnabled) {
      dispatch(disableMic());
    } else {
      dispatch(enableMic());
    }
  };

  const toggleCamera = async () => {
    if (cameraEnabled) {
      await dispatch(disableCamera());
    } else {
      await dispatch(enableCamera());
    }
  };

  const micIcon = micEnabled ? 'microphone-outline' : 'microphone-off';
  const cameraIcon = cameraEnabled ? 'video-outline' : 'video-off-outline';

  const micVolumeIconStyle = micEnabled
    ? styles.micEnabledIcon
    : styles.micDisabledIcon;

  const allowChangeCamera = cameraEnabled && canSwitchCamera;
  const cameraSwitchIconColor = allowChangeCamera ? '#000' : '#CCC';
  const cameraSwitchButtonColor = allowChangeCamera ? '#FFF' : '#FFF';

  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.videoContainer]}>
        {cameraEnabled ? (
          <VideoContainer
            style={styles.videoPane}
            stream={camStream}
            pointerEvents="box-none"
            waiting={false}
            mirror={mirrorCamera}
          />
        ) : (
          <View style={[styles.videoPane, styles.emptyVideo]}>
            <Icon style={[styles.emptyVideoIcon]} name={'video-off'} />
          </View>
        )}
      </View>
      <View style={styles.volumeTray}>
        <Icon style={micVolumeIconStyle} name="microphone-outline" />
        <AudioMonitor style={MICROPHONE_VOLUME_INDICATOR} stream={micStream} />
      </View>
      <View style={styles.controlTray}>
        <View style={styles.iconSwitch}>
          <Icon style={[styles.icon]} name={micIcon} />
          <Switch onValueChange={() => toggleMic()} value={micEnabled} />
        </View>
        <View style={styles.iconSwitch}>
          <Icon style={[styles.icon]} name={cameraIcon} />
          <Switch onValueChange={() => toggleCamera()} value={cameraEnabled} />
        </View>
        <UniversalButton
          name="video-switch"
          onPress={() => changeCamera()}
          color={cameraSwitchButtonColor}
          iconColor={cameraSwitchIconColor}
          style={styles.switchButton}
        />
      </View>
      <Button title={translate('deviceConfig.join')} onPress={onJoin} />
      <SettingsDialogueOverlay />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  icon: {
    color: '#000',
    fontSize: 40,
    textAlign: 'left',
    height: 40,
  },
  videoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoPane: {
    width: 300,
    height: 300,
  },
  emptyVideo: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#CCC',
    borderWidth: 3,
    borderRadius: 16,
  },
  emptyVideoIcon: {
    color: '#CCC',
    fontSize: 96,
    textAlign: 'center',
    height: 96,
  },
  controlTray: {
    width: 300,
    height: 96,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  iconSwitch: {
    width: 110,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 24,
    paddingLeft: 8,
    paddingRight: 8,
  },
  switchButton: {
    borderWidth: 1,
    borderColor: '#000',
  },
  micEnabledIcon: {
    color: '#000',
    fontSize: 40,
    textAlign: 'left',
    height: 40,
  },
  micDisabledIcon: {
    color: '#CCC',
    fontSize: 40,
    textAlign: 'left',
    height: 40,
  },
  volumeTray: {
    width: 300,
    height: 48,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default DeviceConfig;
