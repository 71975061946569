import {createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';

import rootReducer from './rootReducer';

const configureStore = () => {
  const middleware =
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(thunk)
      : applyMiddleware(thunk, createLogger());

  return createStore(rootReducer, compose(middleware));
};

export default configureStore;
