import getRemoteConfigValue from 'utils/firebase/remoteConfig';

export function getProtooUrl({
  token,
  forceH264,
  forceVP9,
  communicationServer,
}) {
  const defaultCommunicationServerUrl = getRemoteConfigValue(
    'communication_server_url',
  ).asString();

  const communicationServerUrl =
    typeof communicationServer === 'string' &&
    communicationServer !== '' &&
    communicationServer
      ? communicationServer
      : defaultCommunicationServerUrl;

  const communicationServerPort = getRemoteConfigValue(
    'communication_server_port',
  ).asString();

  let url = `wss://${communicationServerUrl}:${communicationServerPort}/?token=${token}`;

  if (forceH264) {
    url = `${url}&forceH264=true`;
  } else if (forceVP9) {
    url = `${url}&forceVP9=true`;
  }

  return url;
}
