import React, {useContext} from 'react';
import {
  SafeAreaView,
  View,
  StatusBar,
  TouchableOpacity,
  Text,
  Image,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';

import World from 'components/World';
import CustomizeTray from 'components/Customization/CustomizeTray';
import BodySelection from 'components/Customization/BodySelection';
import worldStyle from 'style/3d';
import containerStyle from 'style/screen';
import {I18nContext} from 'i18n/context/I18nContext';

// asset imports
import rotateIcon from 'images/CustomizationIcons/360.png';

const AvatarPreviewLandscape = ({
  resetSelection,
  customizeLocation,
  onLoadComplete,
  onContinue,
  loaded,
  avatarLoading,
  onLegacyFromWorld,
}) => {
  const {translate} = useContext(I18nContext);
  const {width} = useWindowDimensions();
  const tierTwoWidth = 0.4 * width;

  // stylesheets included in function so they can use the useWindowDimensions hook variables
  const styles = StyleSheet.create({
    worldStyle: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      width: '100%',
    },
    container: {
      flex: 1,
      alignItems: 'center',
      padding: 15,
    },
    // flexed under container
    mainCustomize: {
      flex: 1,
      flexDirection: 'row',
      width: '100%',
    },
    //end container flex
    // flexed under mainCustomize
    bodyContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    trayContainer: {
      flex: -1,
      height: '100%',
    },
    //end mainCustomize flex
    // flexed under joinContainer
    joinButton: {
      backgroundColor: '#fe6507',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
    },
    joinButtonLoading: {
      backgroundColor: 'rgba(254, 101, 7, .5)',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
    },
    // end flex for joinContainer
    // flexed under bodyContainer
    text: {
      flex: 1,
      color: '#fff',
      textAlign: 'center',
      fontSize: 24,
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 'auto',
    },
    bodySpacer: {
      flex: 5,
    },
    genders: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    rotateIconContainer: {flex: 1, width: 75},
    joinContainer: {
      flex: 1,
      justifyContent: 'center',
      width: 150,
    },
    // end bodyContainer flex
    // flexed under rotateIcon
    rotateIcon: {
      height: undefined,
      width: undefined,
      flex: 1,
      resizeMode: 'contain',
    },
    // end rotateIcon
    // flexed under trayContainer
    tray: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    randomizeContainer: {
      flex: -1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      borderTopColor: 'black',
      borderTopWidth: 1,
    },
    // end trayContainer flex
    // flexed under tray
    tierOne: {
      flex: -1,
      height: '100%',
      flexDirection: 'column',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
      marginLeft: 10,
    },
    tierTwo: {
      justifyContent: 'space-between',
      height: '100%',
      width: tierTwoWidth,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
    },
    tierSpacer: {
      width: tierTwoWidth,
      alignContent: 'center',
      justifyContent: 'center',
      padding: 20,
      textAlign: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
    },
    // end tray flex
    // scrollView contents style (flexed under TierOne)
    tierOneContents: {
      flex: 1,
    },
    // end scrollView Contents
    // flexed under tierTwo.
    tierTwoScrollContainer: {
      flex: 1,
    },
    // end tierTwo flex
    // tier two scrollview contents (flexed under tierTwoScrollContainer)
    tierTwoScrollContents: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    // end tier two scrollview contents
  });

  return (
    <View style={containerStyle.full} pointerEvents="box-none">
      <StatusBar
        backgroundColor="rgba(0, 0, 0, 0.25)"
        barStyle="light-content"
        translucent
      />
      <World
        location={customizeLocation}
        locationFilter={customizeLocation}
        onLoadComplete={onLoadComplete}
        style={worldStyle.world}
        onLegacyFromWorld={onLegacyFromWorld}
      />
      <SafeAreaView style={worldStyle.safeUI} pointerEvents="box-none">
        {!loaded ? null : (
          <View style={styles.container} pointerEvents="box-none">
            <View style={styles.mainCustomize} pointerEvents="box-none">
              <View style={styles.bodyContainer} pointerEvents="box-none">
                <Text style={styles.text} pointerEvents="none">
                  {translate('avatarPreview.joinMeeting')}
                </Text>
                <View style={styles.bodySpacer} pointerEvents="none" />
                <BodySelection
                  style={styles.genders}
                  onSelect={resetSelection}
                />
                <View style={styles.rotateIconContainer}>
                  <Image source={rotateIcon} style={styles.rotateIcon} />
                </View>
                <View style={styles.joinContainer}>
                  <TouchableOpacity
                    disabled={avatarLoading}
                    style={
                      avatarLoading
                        ? styles.joinButtonLoading
                        : styles.joinButton
                    }
                    onPress={() => onContinue()}>
                    <Text style={styles.joinButtonText}>
                      {translate('avatarPreview.join')}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={styles.trayContainer}>
                <CustomizeTray styles={styles} isInGame={false} />
              </View>
            </View>
          </View>
        )}
      </SafeAreaView>
    </View>
  );
};

export default AvatarPreviewLandscape;
