import React, {useEffect, useContext} from 'react';
import {
  SafeAreaView,
  Image,
  StatusBar,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import SplashScreen from 'react-native-splash-screen';
import {showMessage} from 'react-native-flash-message';

import {
  setIsMeetingDisrupted,
  clearMeetingProperties,
} from 'store/meeting/actions/properties';
import {joinRoom} from 'store/communication/actions/controlActions';
import {goToMeeting} from 'store/app';
import {I18nContext} from 'i18n/context/I18nContext';
import images from 'images';
import Meeting from 'screens/Meeting';
import DeviceConfig from 'screens/DeviceConfig';
import AvatarPreview from 'screens/AvatarPreview';
import StartMeetingButton from './StartMeetingButton';
import ErrorHandler from 'components/ErrorHandler';
import {logScreenView} from 'utils/firebase/analytics';

const Landing = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const dynamicLinkState = useSelector((state) => state.app.dynamicLinkState);
  const link = useSelector((state) => state.app.link);
  const isMeetingDisrupted = useSelector(
    (state) => state.meeting.properties.isMeetingDisrupted,
  );
  const lastMeetingId = useSelector(
    (state) => state.meeting.properties.lastMeetingId,
  );
  const token = useSelector((state) => state.meeting.properties.token);

  // hide the splash screen when there is no dynamic link on mobile
  useEffect(() => {
    if (dynamicLinkState === 'ready') {
      if (!link) {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          SplashScreen.hide();
        }
      }
    }
  }, [dynamicLinkState, link]);

  useEffect(() => {
    const meetingDisruptedMessage = translate('landing.meetingDialog', {
      lastMeetingId,
    });

    const rejoinMeeting = () => {
      dispatch(setIsMeetingDisrupted(false));
      dispatch(joinRoom());
      dispatch(goToMeeting());
    };

    if (token && isMeetingDisrupted) {
      showMessage({
        message: meetingDisruptedMessage,
        hideStatusBar: Platform.OS === 'android' ? true : false,
        type: 'info',
        icon: 'auto',
        autoHide: false,
        onPress: () => {
          rejoinMeeting();
        },
        onLongPress: () => {
          dispatch(clearMeetingProperties());
        },
      });
    }
  }, [dispatch, isMeetingDisrupted, lastMeetingId, token, translate]);
  const meeting = <Meeting />;
  const deviceConfig = <DeviceConfig />;
  const avatarPreview = <AvatarPreview />;
  const landing = (
    <SafeAreaView style={styles.container}>
      <Image
        source={images.logo_3dmeet_full}
        style={styles.logo}
        resizeMode={'contain'}
      />
      <View style={styles.startMeetingButtonContainer}>
        <StartMeetingButton />
      </View>
    </SafeAreaView>
  );

  const screen = useSelector((state) => state.app.screen);
  let currentScreen;
  switch (screen) {
    case 'meeting':
      currentScreen = meeting;
      break;
    case 'deviceConfig':
      currentScreen = deviceConfig;
      break;
    case 'avatarPreview':
      currentScreen = avatarPreview;
      break;
    default:
      currentScreen = landing;
      break;
  }

  useEffect(() => {
    const logScreenToFirebaseAnalytics = async () => {
      await logScreenView(screen);
    };

    logScreenToFirebaseAnalytics();
  }, [screen]);

  return <ErrorHandler>{currentScreen}</ErrorHandler>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
  startMeetingButtonContainer: {
    paddingTop: 30,
  },
  logo: {
    width: 400,
    height: 110,
  },
});

export default Landing;
