import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {
  View,
  TouchableHighlight,
  Text,
  StyleSheet,
  Platform,
} from 'react-native';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import {SIDE_PANEL_SETTINGS_COLOR} from 'style/theme';
import {logEvent} from 'utils/firebase/analytics';
import {REFRESH_ROOM_CONFIGS} from 'utils/firebase/analytics.config';

const RefreshMeetingConfigsButton = () => {
  const {translate} = useContext(I18nContext);
  const roomClient = useSelector(
    (state) => state.communication.control.roomClient,
  );

  const ROOM_CONFIGS_REFRESH_MESSAGE = translate(
    'refreshMeetingConfigsButton.confirmreRefreshedConfigs',
  );
  const ROOM_CONFIGS_REFRESH_ERROR_MESSAGE = translate(
    'refreshMeetingConfigsButton.errorRefreshMeeting',
  );

  const refreshMeetingConfigs = async () => {
    const roomConfigsUpdated = await roomClient.refreshRoomConfigs();

    showMessage({
      message: roomConfigsUpdated
        ? ROOM_CONFIGS_REFRESH_MESSAGE
        : ROOM_CONFIGS_REFRESH_ERROR_MESSAGE,
      type: roomConfigsUpdated ? 'success' : 'danger',
      icon: 'auto',
      duration: 3000,
      hideStatusBar: Platform.OS === 'android',
    });

    if (roomConfigsUpdated) {
      logEvent(REFRESH_ROOM_CONFIGS, {meeting_id: roomClient.meetingId});
    }
  };

  return (
    <View style={styles.centeredView}>
      <TouchableHighlight
        style={styles.refreshButton}
        onPress={refreshMeetingConfigs}>
        <Text style={styles.textStyle}>
          {translate('refreshMeetingConfigsButton.refreshConfigs')}
        </Text>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  refreshButton: {
    backgroundColor: SIDE_PANEL_SETTINGS_COLOR,
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    maxWidth: '96%',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default RefreshMeetingConfigsButton;
