export const truncateFirebaseAnalyticParameterValue = (
  analyticParameterValue,
) => {
  const MAX_ANALYTICS_VALUE_LENGTH = 100;
  const ELLIPSES = '...';
  const MAX_STRING_LENGTH_WITHOUT_ELLIPSES =
    MAX_ANALYTICS_VALUE_LENGTH - ELLIPSES.length;

  const truncatedAnalyticsParameterValue =
    analyticParameterValue.slice(
      0,
      Math.min(
        analyticParameterValue.length,
        MAX_STRING_LENGTH_WITHOUT_ELLIPSES,
      ),
    ) + ELLIPSES;

  return truncatedAnalyticsParameterValue;
};
