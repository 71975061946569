import React, {useContext, useState} from 'react';
import {useSelector, useDispatch, connect} from 'react-redux';
import {View, Text, StyleSheet, Platform, Switch} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Picker} from '@react-native-community/picker';

import {
  disableLiveTranslation,
  enableLiveTranslation,
  setLiveTranslation,
} from 'store/meeting/actions/settings';
import {SUPPORTED_TRANSLATION_SERVICE_LANGUAGES} from 'store/meeting/reducers/settings';
import {I18nContext} from 'i18n/context/I18nContext';
import {
  refreshMessages,
  clearMessageTranslations,
} from 'store/communication/actions/chatActions';

const TranslationSwitcher = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const targetLanguage = useSelector(
    (state) => state.meeting.settings.targetLanguage,
  );
  const liveTranslation = useSelector(
    (state) => state.meeting.settings.liveTranslation,
  );
  const [translationItems] = useState(() => {
    // Convert to expected Picker shape and sort alphabetically to make finding language easier
    return SUPPORTED_TRANSLATION_SERVICE_LANGUAGES.reduce((langArray, lang) => {
      if (lang.show) {
        langArray.push({
          label: translate(`translationSwitcher.${lang.language}`),
          value: lang.language,
        });
      }
      return langArray;
    }, []).sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  });
  const toggleLiveTranslation = async () => {
    if (liveTranslation) {
      dispatch(disableLiveTranslation());
    } else {
      dispatch(enableLiveTranslation());
      dispatch(refreshMessages());
    }
  };

  const liveTranslationIconColor = {
    color: liveTranslation ? 'green' : 'grey',
  };

  const updateLiveTranslationTargetLanguage = async (value) => {
    dispatch(setLiveTranslation(value));
    dispatch(refreshMessages());
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Icon
          type="MaterialCommunityIcons"
          style={[styles.liveTranslationIcon, liveTranslationIconColor]}
          name="comment-arrow-right-outline"
        />
        <Text style={styles.liveTranslationText}>
          {translate('liveTranslation.liveTranslation')}
        </Text>
        <Switch value={liveTranslation} onValueChange={toggleLiveTranslation} />
      </View>

      {liveTranslation ? (
        <View style={[styles.row, styles.pickerContainer]}>
          <Picker
            selectedValue={targetLanguage}
            style={styles.picker}
            onValueChange={(itemValue) => {
              updateLiveTranslationTargetLanguage(itemValue);
            }}>
            {translationItems.map((item, index) => {
              return (
                <Picker.Item
                  key={`lang-${index}`}
                  label={item.label}
                  value={item.value}
                />
              );
            })}
          </Picker>
          {Platform.OS === 'ios' ? (
            <View style={[styles.column]}>
              <View style={[styles.triangle, styles.arrowUp]} />
              <View style={[styles.triangle, styles.arrowDown]} />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#f8f8f8',
    borderBottomColor: '#cccccc',
    borderBottomWidth: 2,
    paddingTop: 5,
  },
  pickerContainer: {
    overflow: 'hidden',
    height: 43,
  },
  picker: {
    fontSize: 16,
    width: '100%',
    marginTop: Platform.OS === 'ios' ? -87 : 0,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  column: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    position: 'absolute',
    right: 10,
    height: 43,
  },
  liveTranslationIcon: {
    fontSize: 36,
  },
  liveTranslationText: {
    fontSize: 20,
    marginLeft: 3,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
  },
  arrowUp: {
    borderTopWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 15,
    borderLeftWidth: 10,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#aaaaaa',
    borderLeftColor: 'transparent',
  },
  arrowDown: {
    borderTopWidth: 15,
    borderRightWidth: 10,
    borderBottomWidth: 0,
    borderLeftWidth: 10,
    borderTopColor: '#aaaaaa',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    refreshMessages: () => dispatch(refreshMessages()),
    clearTranslation: () => dispatch(clearMessageTranslations()),
  };
};

export default connect(mapDispatchToProps, [])(TranslationSwitcher);
