import World from 'components/World';
import * as types from './viewboardActionTypes';
import {LOCATION_VIEWBOARDS} from 'config/viewboards.config';
import {logEvent} from 'utils/firebase/analytics';
import {SHARE_ON_VIEWBOARD} from 'utils/firebase/analytics.config';
import {
  getViewboardReducedMedia,
  getViewboardSanitizedContentId,
} from 'utils/helpers';

export const switchToDashboard = () => {
  return {
    type: types.SWITCH_TO_DASHBOARD,
  };
};

export const switchToShareOnViewboard = (
  {isEditMode} = {isEditMode: false},
) => {
  return {
    type: types.SWITCH_TO_SHARE_ON_VIEWBOARD,
    payload: isEditMode,
  };
};

export const selectViewboardContent = (contentId) => {
  return {
    type: types.SELECT_VIEWBOARD_CONTENT,
    payload: contentId,
  };
};

export const selectViewboard = (viewboardId) => {
  return {
    type: types.SELECT_VIEWBOARD,
    payload: viewboardId,
  };
};

export const clearViewboards = () => {
  return {
    type: types.CLEAR_VIEWBOARDS,
  };
};

export const setViewboardContent = (viewboardContent) => {
  return {
    type: types.SET_VIEWBOARD_CONTENT,
    payload: viewboardContent,
  };
};

export const setIsViewboardContentFullScreen = (
  isViewboardContentFullScreen,
) => {
  return {
    type: types.SET_IS_VIEWBOARD_CONTENT_FULLSCREEN,
    payload: isViewboardContentFullScreen,
  };
};

export const updateActiveViewboard = ({viewboardId, contentId}) => {
  return async (dispatch, getState) => {
    const state = getState();

    const isViewboardAlreadyActive = state.viewboard.activeViewboards.find(
      (viewboard) => viewboard.id === viewboardId,
    )
      ? true
      : false;

    if (isViewboardAlreadyActive) {
      return dispatch(editActiveViewboard({viewboardId, contentId}));
    } else {
      return dispatch(addActiveViewboard({viewboardId, contentId}));
    }
  };
};

export const addActiveViewboard = ({viewboardId, contentId}) => {
  return {
    type: types.ADD_ACTIVE_VIEWBOARD,
    payload: {viewboardId, contentId},
  };
};

export const editActiveViewboard = ({viewboardId, contentId}) => {
  return {
    type: types.EDIT_ACTIVE_VIEWBOARD,
    payload: {viewboardId, contentId},
  };
};

export const removeActiveViewboard = ({viewboardId}) => {
  return {
    type: types.REMOVE_ACTIVE_VIEWBOARD,
    payload: viewboardId,
  };
};

/*
  TODO: Use location Id instead when we switch viewboard info settings to be set up in room configs
*/
export const registerViewboards = (locationResourceID) => {
  let viewboards = LOCATION_VIEWBOARDS[locationResourceID];

  if (!viewboards) {
    viewboards = [];
  }

  return {
    type: types.REGISTER_VIEWBOARDS,
    payload: viewboards,
  };
};

export const shareViewboardContent = (location, viewboardId, contentId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    const content = state.viewboard.content.find((c) => c.id === contentId);

    const reducedMedia = getViewboardReducedMedia(content);
    const sanitizedContentId = getViewboardSanitizedContentId(content);

    roomClient.setViewboardContent(
      location,
      viewboardId,
      sanitizedContentId,
      0,
    );

    // TODO: Update to play_media_direct?
    World.runCommand(
      `play_channel_direct ${viewboardId} ${sanitizedContentId} ${reducedMedia}`,
    );

    logEvent(SHARE_ON_VIEWBOARD, {
      meeting_id: roomClient.meetingId,
      viewboard_id: viewboardId,
      location_id: location,
      content_type: content?.type,
    });
  };
};

export const navigateToViewboard = (viewboardId) => {
  World.runCommand(`navigate_view ${viewboardId}`);
};

export const clearViewboardContent = (location, viewboardId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    roomClient.clearViewboardContent(location, viewboardId);
    World.runCommand(`viewboard_clear ${viewboardId}`);
  };
};

export const playVideo = (viewboardId) => {
  World.runCommand(`viewboard_event ${viewboardId} PLAY`);
};

export const pauseVideo = (viewboardId) => {
  World.runCommand(`viewboard_event ${viewboardId} PAUSE`);
};

export const nextElementInCollection = (viewboardId) => {
  World.runCommand(`viewboard_event ${viewboardId} NEXT`);
};

export const previousElementInCollection = (viewboardId) => {
  World.runCommand(`viewboard_event ${viewboardId} PREV`);
};

export const setFullScreen = (viewboardId, isFullScreen) => {
  World.runCommand(`viewboard_fullscreen ${viewboardId} ${isFullScreen}`);
};

export const setMuteAllViewboards = (viewboardId, isMuted) => {
  World.runCommand(`mute_media ${viewboardId} ${isMuted}`);
};

export const startViewboardReceiver = (message) => {
  const messagePieces = message.payload.split('|');
  const viewboardId = messagePieces[0];

  World.runCommand(`viewboard_reciever ${viewboardId} true`);
};

export const stopViewboardReceiver = (message) => {
  const messagePieces = message.payload.split('|');
  const viewboardId = messagePieces[0];

  World.runCommand(`viewboard_reciever ${viewboardId} false`);
};
