import React from 'react';
import PropTypes from 'prop-types';
import {View, ActivityIndicator, StyleSheet} from 'react-native';

import VideoStream from './VideoStream';

const VideoContainer = React.memo(({stream, style, waiting, hide, mirror}) => {
  const ready = !waiting && stream && stream.active;

  const localStyle = {};
  if (hide) {
    localStyle.display = 'none';
  }

  // videoStyle cannot be a stylesheet, it fails on web. the video component can't take a stylesheet
  const videoStyle = {
    width: '100%',
    height: '100%',
  };

  return (
    <View style={[localStyle, style]} pointerEvents="auto">
      {ready ? (
        <VideoStream
          stream={stream}
          style={videoStyle}
          pointerEvents="auto"
          mirror={mirror}
        />
      ) : (
        <View style={styles.loading} pointerEvents="box-none">
          <ActivityIndicator
            size="large"
            color="#0000ff"
            pointerEvents="box-none"
          />
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  loading: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

VideoContainer.defaultProps = {
  stream: null,
  style: null,
  waiting: true,
  hide: false,
  mirror: false,
};

VideoContainer.propTypes = {
  stream: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    getVideoTracks: PropTypes.func.isRequired,
  }),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  waiting: PropTypes.bool,
  hide: PropTypes.bool,
  mirror: PropTypes.bool,
};

export default VideoContainer;
