import * as types from './actionTypes';
import * as Permissions from 'utils/communication/Permissions';

export const initialState = {
  micEnabled: false,
  cameraEnabled: false,
  screenShareEnabled: false,
  micPermissions: Permissions.UNKNOWN,
  cameraPermissions: Permissions.UNKNOWN,
  userRequestedBlockedPermission: false,
  canSwitchCamera: false,
  mirrorCamera: false,
  camStream: undefined,
  micStream: undefined,
  selectedCameraDeviceId: undefined,
  selectedMicrophoneDeviceId: undefined,
  availableDevices: undefined,
};

const updateState = (state = initialState, action) => {
  switch (action.type) {
    case types.DISABLE_MIC: {
      return {...state, micEnabled: action.payload};
    }

    case types.ENABLE_MIC: {
      return {...state, micEnabled: true};
    }

    case types.DISABLE_CAMERA: {
      return {...state, cameraEnabled: false};
    }

    case types.ENABLE_CAMERA: {
      return {...state, cameraEnabled: true};
    }

    case types.DISABLE_SCREEN_SHARE: {
      return {...state, screenShareEnabled: false};
    }

    case types.ENABLE_SCREEN_SHARE: {
      return {...state, screenShareEnabled: true};
    }

    case types.SET_MIC_PERMISSIONS: {
      const {micPermissions} = action.payload;
      return {...state, micPermissions};
    }

    case types.SET_CAMERA_PERMISSIONS: {
      const {cameraPermissions} = action.payload;
      return {...state, cameraPermissions};
    }

    case types.SET_REQUESTED_BLOCKED_PERMISSION: {
      const {requested} = action.payload;
      return {...state, userRequestedBlockedPermission: requested};
    }

    case types.SET_CAN_SWITCH_CAMERA: {
      const {canSwitchCamera} = action.payload;
      return {...state, canSwitchCamera};
    }

    case types.SET_MIRROR_CAMERA: {
      const {mirrorCamera} = action.payload;
      return {...state, mirrorCamera};
    }

    case types.SET_CAMERA_STREAM: {
      const {stream} = action.payload;
      return {...state, camStream: stream};
    }

    case types.SET_MICROPHONE_STREAM: {
      const {stream} = action.payload;
      return {...state, micStream: stream};
    }

    case types.SET_AVAILABLE_DEVICES: {
      return {...state, availableDevices: action.payload};
    }

    case types.SET_SELECTED_MICROPHONE_DEVICE_ID: {
      return {...state, selectedMicrophoneDeviceId: action.payload};
    }

    case types.SET_SELECTED_CAMERA_DEVICE_ID: {
      return {...state, selectedCameraDeviceId: action.payload};
    }

    default:
      return state;
  }
};

export default updateState;
