import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const styles = StyleSheet.create({
  button: {
    height: 42,
    backgroundColor: '#2196F3',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8,
  },
  text: {
    fontSize: 14,
    color: '#FFFFFF',
  },
  icon: {
    fontSize: 28,
    color: '#FFFFFF',
  },
});

const PeerNavButton = (props) => {
  const {icon, text, onPress} = props;
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Icon type="MaterialCommunityIcons" style={styles.icon} name={icon} />
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

PeerNavButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

export default PeerNavButton;
