import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {View, StyleSheet} from 'react-native';

import Viewboard from '../ViewboardShareFlow/ViewboardSelect/Viewboard';
import ViewboardContent from '../ViewboardShareFlow/ViewboardSelectContent/ViewboardContent';
import ActiveViewboardActions from './ActiveViewboardActions';
import ActiveViewboardControls from './ActiveViewboardControls';

const ActiveViewboard = ({viewboard}) => {
  const {id, contentId, displayName} = viewboard;
  const viewboardContent = useSelector((state) =>
    state.viewboard.content.find((content) => content.id === contentId),
  );

  return (
    <View style={styles.activeViewboardContainer}>
      <View style={styles.activeViewboardTitle}>
        <Viewboard
          displayName={displayName}
          shouldDisplayMapLegendIcon={false}
        />
        <View style={styles.activeViewboardControls}>
          <ActiveViewboardControls
            viewboardId={id}
            contentType={viewboardContent?.type}
          />
        </View>
      </View>
      <View style={styles.activeViewboardContent}>
        <ViewboardContent
          name={viewboardContent?.name}
          type={viewboardContent?.type}
        />
      </View>
      <View style={styles.activeViewboardActions}>
        <ActiveViewboardActions viewboardId={id} />
      </View>
    </View>
  );
};

Viewboard.propTypes = {
  viewboard: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    mapLegendNumber: PropTypes.number,
    contentId: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  activeViewboardContainer: {
    borderRadius: 20,
    borderWidth: 1.5,
    borderColor: 'rgba(0, 0, 0, 0.5)',
    marginLeft: 10,
    marginRight: 10,
  },
  activeViewboardTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  activeViewboardControls: {
    marginLeft: 10,
  },
  activeViewboardContent: {
    marginLeft: 10,
  },
  activeViewboardActions: {
    marginTop: 18,
    marginBottom: 12,
  },
});

export default ActiveViewboard;
