// Custom events
// Events used to calculate Level 1 Metrics / critical flows
export const JOIN_MEETING = 'join_meeting';
export const LEAVE_MEETING = 'leave_meeting';
export const SHARE_MEETING_INVITE = 'share_meeting_invite';
export const OPEN_IN_APP_BROWSER = 'open_in_app_browser';
// Events used to calculate secondary metrics / general engagement
export const TELEPORT = 'teleport';
export const DOWNLOAD = 'download';
export const TELEPORT_TO_PARTICIPANT = 'teleport_to_participant';
export const SEND_GLOBAL_CHAT_MESSAGE = 'send_global_chat_message';
export const ENABLE_SCREENSHARE_EVENT = 'enable_screenshare';
export const ENABLE_CAMERA_EVENT = 'enable_camera';

export const CHANGE_CUSTOMIZE_AVATAR_CATEGORY =
  'change_customize_avatar_category';
export const CHANGE_CUSTOMIZE_AVATAR_SUBCATEGORY =
  'change_customize_avatar_subcategory';
export const CHANGE_CUSTOMIZE_AVATAR_TEXTURE =
  'change_customize_avatar_texture';
export const CHANGE_CUSTOMIZE_AVATAR_COLOR = 'change_customize_avatar_color';
export const CHANGE_CUSTOMIZE_AVATAR_RANDOM = 'change_customize_avatar_random';
export const CHANGE_CUSTOMIZE_AVATAR_BODY = 'change_customize_avatar_body';
export const CHANGE_CUSTOMIZE_AVATAR_CUSTOM_HEAD =
  'change_customize_avatar_custom_head';

export const SAVE_CUSTOMIZE_AVATAR_SUBCATEGORY =
  'save_customize_avatar_subcategory';
export const SAVE_CUSTOMIZE_AVATAR_TEXTURE = 'save_customize_avatar_texture';
export const SAVE_CUSTOMIZE_AVATAR_COLOR = 'save_customize_avatar_color';
export const SAVE_CUSTOMIZE_AVATAR_BODY = 'save_customize_avatar_body';

export const MEETING_CONNECTION_FAILURE = 'meeting_connection_failure';
export const RECALL_ALL = 'recall_all';
export const RECALL_TARGETED = 'recall_targeted';
export const MUTE_ALL_AUDIO = 'mute_all_audio';
export const UNMUTE_ALL_AUDIO = 'unmute_all_audio';
export const MUTE_ALL_VIDEO = 'mute_all_video';
export const UNMUTE_ALL_VIDEO = 'unmute_all_video';
export const MUTE_ALL_CHAT = 'mute_all_chat';
export const UNMUTE_ALL_CHAT = 'unmute_all_chat';
export const MUTE_TARGETED_AUDIO = 'mute_targeted_audio';
export const UNMUTE_TARGETED_AUDIO = 'unmute_targeted_audio';
export const MUTE_TARGETED_VIDEO = 'mute_targeted_video';
export const UNMUTE_TARGETED_VIDEO = 'unmute_targeted_video';
export const MUTE_TARGETED_CHAT = 'mute_targeted_chat';
export const UNMUTE_TARGETED_CHAT = 'unmute_targeted_chat';
export const SET_USER_PERMISSIONS = 'set_user_permissions';
export const SET_SANITIZED_USER_PERMISSIONS = 'set_sanitized_user_permissions';
export const END_MEETING_FOR_ALL = 'end_meeting_for_all';
export const CLOSE_ROOM_FAILURE = 'close_room_failure';
export const RECORD_MEETING = 'record_meeting';
export const END_RECORD_MEETING = 'end_record_meeting';
export const TRANSLATION_SUCCESS = 'translation_success';
export const TRANSLATION_FAILURE = 'translation_failure';
// Viewboards
export const SHARE_ON_VIEWBOARD = 'share_on_viewboard';
export const FILESHARE_UPLOAD = 'fileshare_upload';
export const FILESHARE_UPLOAD_CANCEL = 'fileshare_upload_cancel';
export const FILESHARE_UPLOAD_ERROR = 'fileshare_upload_error';
export const ADD_INSTANCE_CONTENT = 'add_instance_content';
export const ADD_INSTANCE_CONTENT_ERROR = 'add_instance_content_error';
export const NAVIGATE_TO_VIEWBOARD = 'navigate_to_viewboard';
export const EDIT_VIEWBOARD = 'edit_viewboard';
export const REMOVE_VIEWBOARD = 'remove_viewboard';
export const FULLSCREEN_VIEWBOARD = 'fullscreen_viewboard';
export const NEXT_ELEMENT_IN_VIEWBOARD_COLLECTION =
  'next_element_in_viewboard_collection';
export const PREVIOUS_ELEMENT_IN_VIEWBOARD_COLLECTION =
  'previous_element_in_viewboard_collection';
// Settings
export const OPEN_DEVICE_SELECTION_MODAL = 'open_device_selection_modal';
export const TOGGLE_SPEAKERPHONE = 'toggle_speakerphone';
export const SELECT_MICROPHONE_DEVICE = 'select_microphone_device';
export const SELECT_CAMERA_DEVICE = 'select_camera_device';
export const REFRESH_ROOM_CONFIGS = 'refresh_room_configs';
export const TOGGLE_LOW_POWER_MODE = 'toggle_low_power_mode';
export const TOGGLE_FLOATING_NAMES = 'toggle_floating_names';
export const TOGGLE_DISABLE_VIDEO = 'toggle_disable_video';
export const SET_POSITIONAL_MODE = 'set_positional_mode';
export const SELECT_AVATAR_PERSPECTIVE = 'select_avatar_perspective';
export const HEADSET_DISCONNECT = 'headset_disconnect';
export const ENABLE_LIVE_TRANSLATION = 'enable_live_translation';
export const DISABLE_LIVE_TRANSLATION = 'disable_live_translation';
export const SET_LIVE_TRANSLATION_LANGUAGE = 'set_live_translation_language';
export const MANUAL_APPEARANCE_BROADCAST = 'manual_appearance_broadcast';
// App Usage / Debug
export const INVALID_TOKEN = 'invalid_token';
export const INVALID_DOWNLOAD = 'invalid_download';
export const LEVEL_DOWNLOAD = 'level_download';
export const LEVEL_LOAD = 'level_load';

// User properties
// TODO: this export is never used due to changes in the customization flow. We still want this data. How should I incorporate it?
export const CUSTOMIZE_AVATAR_SUBCATEGORY = 'customize_sub';
export const CUSTOMIZE_AVATAR_TEXTURE = 'customize_text';
export const CUSTOMIZE_AVATAR_COLOR = 'customize_color';
export const CHANGED_CUSTOMIZE_AVATAR_RANDOM = 'customize_random';
export const CUSTOMIZE_AVATAR_BODY = 'customize_avatar_body';

// Potential additions in future:
// - "type" of user (i.e. light, normal, power) based on feature usage / # of meetings in given time period?
// - potentially what integration user is from
