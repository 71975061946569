import React, {useContext, useRef} from 'react';
import {Platform, KeyboardAvoidingView, StyleSheet, Text} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {GiftedChat, InputToolbar, Send} from 'react-native-gifted-chat';
import emojiUtils from 'emoji-utils';
import Message from 'components/Communication/Message';
import {sendMessage} from 'store/communication/actions/chatActions';
import {I18nContext} from 'i18n/context/I18nContext';
import TranslationSwitcher from 'components/Communication/TranslationSwitcher';

// See https://github.com/FaridSafi/react-native-gifted-chat/issues/680
const ANDROID_KEYBOARD_OFFSET = 80;
const COMPOSER_HEIGHT = 70;
const INPUT_TOOLBAR_HEIGHT = 80;
const SUBMIT_KEY = 'Enter';

const styles = StyleSheet.create({
  button: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderColor: '#aaaaaa',
    borderWidth: 2,
    borderRadius: 3,
    flex: 0.5,
    backgroundColor: '#4bc236',
  },
  sendText: {
    color: '#ffffff',
    fontWeight: '600',
    fontSize: 17,
    backgroundColor: 'transparent',
    marginLeft: 10,
    marginRight: 10,
  },
});

const Messages = () => {
  const dispatch = useDispatch();
  let giftedChatInputRef = useRef();
  const {translate} = useContext(I18nContext);
  const chatMessages = useSelector((state) => state.communication.chat);
  const currentUser = useSelector((state) => state.communication.me);
  const isRoomChatMuted = useSelector(
    (state) => state.communication.room.muted.chat,
  );
  const isMeChatMuted = useSelector(
    (state) => state.communication.me.muted.chat,
  );
  const isChatMuted = isRoomChatMuted || isMeChatMuted;

  const renderMessage = (props) => {
    const {
      currentMessage: {text: currentText},
    } = props;

    let messageTextStyle;

    // Make "pure emoji" messages much bigger than plain text.
    if (currentText && emojiUtils.isPureEmojiString(currentText)) {
      messageTextStyle = {
        fontSize: 28,
        // Emoji get clipped if lineHeight isn't increased; make it consistent across platforms.
        lineHeight: Platform.OS === 'android' ? 34 : 30,
      };
    }

    return <Message {...props} messageTextStyle={messageTextStyle} />;
  };

  const renderInputToolbar = (props) => {
    return (
      <InputToolbar
        {...props}
        primaryStyle={styles.inputBar}
        textInputProps={{
          onKeyPress: (e) => {
            if (
              e.key === SUBMIT_KEY &&
              !e.shiftKey &&
              props.onSend &&
              props.text
            ) {
              props.onSend({text: props.text.trim()}, true);
            }
          },
          blurOnSubmit: false,
          autoFocus: true,
          ref: giftedChatInputRef,
          multiline: true,
        }}
      />
    );
  };

  const renderSend = (props) => {
    return (
      <Send
        {...props}
        containerStyle={[
          styles.container,
          {height: props.minInputToolbarHeight},
        ]}
        textStyle={styles.button}>
        <Text style={styles.sendText}>Send</Text>
      </Send>
    );
  };

  return (
    <>
      <TranslationSwitcher />
      <GiftedChat
        messages={chatMessages}
        onSend={(messages) => {
          dispatch(sendMessage(messages[0]));
          giftedChatInputRef.current?.focus();
        }}
        user={{
          _id: currentUser.id,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        }}
        renderMessage={renderMessage}
        placeholder={
          isChatMuted
            ? translate('messages.chatDisabled')
            : translate('messages.sendMsgAll')
        }
        renderAvatarOnTop
        renderInputToolbar={renderInputToolbar}
        disableComposer={isChatMuted}
        maxComposerHeight={COMPOSER_HEIGHT}
        minComposerHeight={COMPOSER_HEIGHT}
        minInputToolbarHeight={INPUT_TOOLBAR_HEIGHT}
        renderSend={renderSend}
      />
      {Platform.OS === 'android' && (
        <KeyboardAvoidingView
          behavior={'padding'}
          keyboardVerticalOffset={ANDROID_KEYBOARD_OFFSET}
        />
      )}
    </>
  );
};

export default Messages;
