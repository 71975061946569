import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';

import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import {M3D_SCENES_BASE_URL} from 'utils/World/config';

class PlatformWorld extends Component {
  static instance = undefined;
  componentDidMount() {
    PlatformWorld.instance = this;
    window.addEventListener('message', PlatformWorld.onWindowMessage);
    this.iframe = document.getElementById('world-iframe');
    this.iframe.style.border = 'none';
  }

  componentWillUnmount() {
    PlatformWorld.instance = undefined;
    window.removeEventListener('message', PlatformWorld.onWindowMessage);
  }

  static onWindowMessage(event) {
    const {instance} = PlatformWorld;
    if (!instance) {
      return;
    }

    try {
      // TODO: Check for source, if it is our local 3d world, relay
      // that message out to other users
      // HACK:
      if (event.data) {
        const hasAction = Object.prototype.hasOwnProperty.call(
          event.data,
          'action',
        );
        const hasRoute = Object.prototype.hasOwnProperty.call(
          event.data,
          'route',
        );

        if (hasAction || hasRoute) {
          instance.onMessageFromWorld(event.data);
        }
      }
    } catch (e) {
      console.error('onWindowMessage() | failed: %o', e);
    }
  }

  onMessageFromWorld = (message) => {
    const {onTransform, onLegacyMessage, onCommand} = this.props;

    const hasCommand = Object.prototype.hasOwnProperty.call(message, 'command');
    if (hasCommand) {
      onCommand(message.command);
    }

    const hasRoute = Object.prototype.hasOwnProperty.call(message, 'route');
    if (hasRoute) {
      // FIXME:: Implement 3d voice
      // if (message.route && message.route === 'j') {
      // this.positionalVoice.localPosition = new
      // Position(data.value.x, data.value.y,
      // data.value.z,
      // data.value.filter);
      // this.setAllParticipantsVolume();
      // }
      if (message.route === 'j') {
        onTransform(message);
      } else {
        onLegacyMessage(message);
      }
    }
  };

  static sendMessage = (message) => {
    const {instance} = PlatformWorld;
    if (!instance) {
      return;
    }

    const {iframe} = instance;
    if (!iframe) {
      return;
    }

    const {contentWindow} = iframe;
    if (!contentWindow) {
      return;
    }

    contentWindow.postMessage(message, '*');
  };

  render() {
    const webScenesVersion = getRemoteConfigValue('web_scenes_version');
    const {location} = this.props;

    return (
      <Iframe
        url={`${M3D_SCENES_BASE_URL}${webScenesVersion.asString()}/${location}/index.html`}
        width="100%"
        height="100%"
        id="world-iframe"
        className="myClassname"
        display="initial"
        position="absolute"
      />
    );
  }
}

PlatformWorld.defaultProps = {
  onTransform: () => {},
  onLegacyMessage: () => {},
  onCommand: () => {},
  onAppearanceComplete: () => {},
};

PlatformWorld.propTypes = {
  location: PropTypes.string.isRequired,
  onTransform: PropTypes.func,
  onLegacyMessage: PropTypes.func,
  onCommand: PropTypes.func,
  onAppearanceComplete: PropTypes.func,
};

export default PlatformWorld;
