import React, {useContext} from 'react';
import {View, Text, Button, Platform, StyleSheet} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import {openSettings, closeSettingsDialogue} from 'store/media';
import {I18nContext} from 'i18n/context/I18nContext';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#0008',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    maxWidth: 250,
    maxHeight: 200,
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 10,
    flex: 1,
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
});

const SettingsDialogueOverlay = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const {userRequestedBlockedPermission} = useSelector((state) => state.media);

  const openSettingsPopup = async () => {
    await dispatch(openSettings());
  };

  const closeSettingsPopup = async () => {
    dispatch(closeSettingsDialogue());
  };

  const mobileMessage = translate(
    'settingsDialogueOverlay.cameraMicEnablePrompt',
  );
  const webMessage = translate('settingsDialogueOverlay.sitePermissionPrompt');

  const mobileButton = translate('settingsDialogueOverlay.openSettings');
  const webButton = translate('settingsDialogueOverlay.showInstructions');

  return userRequestedBlockedPermission ? (
    <View style={styles.container}>
      <View style={styles.background}>
        <Text style={styles.text}>
          {Platform.OS === 'web' ? webMessage : mobileMessage}
        </Text>
        <Button
          title={Platform.OS === 'web' ? webButton : mobileButton}
          onPress={openSettingsPopup}
        />
        <Button title="Close" onPress={closeSettingsPopup} />
      </View>
    </View>
  ) : null;
};

export default SettingsDialogueOverlay;
