export const SET_MESSAGE_PANEL_OPEN = 'SET_MESSAGE_PANEL_OPEN';

// SETTINGS
export const SET_INCOMING_VIDEO_ENABLED = 'SET_INCOMING_VIDEO_ENABLED';
export const DISABLE_LOW_POWER_MODE = 'DISABLE_LOW_POWER_MODE';
export const ENABLE_LOW_POWER_MODE = 'ENABLE_LOW_POWER_MODE';
export const ENABLE_POSITIONAL_VOICE_FALL_OFF =
  'ENABLE_POSITIONAL_VOICE_FALL_OFF';
export const DISABLE_POSITIONAL_VOICE_FALL_OFF =
  'DISABLE_POSITIONAL_VOICE_FALL_OFF';
export const SET_PERSPECTIVE_MODE = 'SET_PERSPECTIVE_MODE';
export const ENABLE_LIVE_TRANSLATION = 'ENABLE_LIVE_TRANSLATION';
export const DISABLE_LIVE_TRANSLATION = 'DISABLE_LIVE_TRANSLATION';
export const SET_LIVE_TRANSLATION_LANGUAGE = 'SET_LIVE_TRANSLATION_LANGUAGE';

// PROPERTIES
export const SET_TOKEN = 'SET_TOKEN';
export const SET_IS_MEETING_DISRUPTED = 'SET_IS_MEETING_DISRUPTED';
export const SET_LAST_MEETING_ID = 'SET_LAST_MEETING_ID';
export const CLEAR_MEETING_PROPERTIES = 'CLEAR_MEETING_PROPERTIES';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_INVITE_URL = 'SET_INVITE_URL';
export const SET_IS_MEETING_VALID = 'SET_IS_MEETING_VALID';
export const SET_MEETING_MAX_NUMBER_PARTICIPANTS =
  'SET_MEETING_MAX_NUMBER_PARTICIPANTS';

// RECORDING
export const SET_RECORDING = 'SET_RECORDING';
export const SET_RECORDING_ELEMENTS = 'SET_RECORDING_ELEMENTS';
export const SET_IS_BEING_RECORDED = 'SET_IS_BEING_RECORDED';
