import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import {contentTypeOptions} from 'store/viewboard';
import {SIDE_PANEL_VIEWBOARDS_COLOR} from 'style/theme';

const getIconForType = (type) => {
  switch (type) {
    case contentTypeOptions.image:
      return 'image';
    case contentTypeOptions.video:
      return 'video';
    case contentTypeOptions.collection:
      return 'image-multiple';
    default:
      return 'image';
  }
};

const ViewboardContentTypeIcon = ({type, onPress, style}) => {
  const iconName = getIconForType(type);

  return (
    <IconButton
      name={iconName}
      onPress={onPress}
      color={SIDE_PANEL_VIEWBOARDS_COLOR}
      style={style}
    />
  );
};

ViewboardContentTypeIcon.defaultProps = {
  onPress: () => {},
  style: null,
};

ViewboardContentTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default ViewboardContentTypeIcon;
