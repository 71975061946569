import React, {useState, useEffect, createContext} from 'react';
import i18n from 'i18n-js';
import memoize from 'lodash.memoize';
import {I18nManager} from 'react-native';
import * as RNLocalize from 'react-native-localize';

import en from '../resources/en';
import es from '../resources/es';

export const I18nContext = createContext();

const fallback = {languageTag: 'en', isRTL: false};

const translationGetters = {
  en: () => en,
  es: () => es,
};

export const getLanguageCode = () => {
  return i18n.locale;
};

export const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key),
);

const initI18nConfig = async () => {
  const {languageTag, isRTL} =
    RNLocalize.findBestAvailableLanguage(Object.keys(translationGetters)) ||
    fallback;

  // clear translation cache
  translate.cache.clear();
  // update layout direction
  I18nManager.forceRTL(isRTL);

  // set i18n-js config
  i18n.translations = {[languageTag]: translationGetters[languageTag]()};
  i18n.locale = languageTag;
};

const I18nContextProvider = (props) => {
  const [isTranslationLoaded, setIsTranslationLoaded] = useState(false);
  const [forceUpdateState, setForceUpdateState] = useState(0);
  const forceUpdate = () => setForceUpdateState(forceUpdateState + 1);

  useEffect(() => {
    initI18nConfig().then(() => setIsTranslationLoaded(true)); // set initial config
    RNLocalize.addEventListener('change', initI18nConfig());

    return () => {
      RNLocalize.removeEventListener('change', initI18nConfig());
    };
  }, []);

  useEffect(() => {
    if (isTranslationLoaded) {
      forceUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTranslationLoaded]);

  const changeLanguage = (languageTag) => {
    // clear translation cache
    translate.cache.clear();
    // update layout direction
    I18nManager.forceRTL(false);

    // set i18n-js config
    i18n.translations = {[languageTag]: translationGetters[languageTag]()};
    i18n.locale = languageTag;
    forceUpdate();
  };

  return (
    <I18nContext.Provider
      value={{
        translate,
        changeLanguage,
      }}>
      {props.children}
    </I18nContext.Provider>
  );
};

export default I18nContextProvider;
