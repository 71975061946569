import React, {useContext, useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {showMessage} from 'react-native-flash-message';

import {setCategory, setAppearance, setAvatarLoading} from 'store/appearance';
import Appearances from 'utils/Appearances';
import Categories from './Categories';
import {ColorWheel} from './ColorWheel';
import {I18nContext} from 'i18n/context/I18nContext';
import IconButton from 'components/IconButton';
import HeadOptions from './HeadOptions';
import Loading from 'components/World/Loading';
import {HEAD_CATEGORY} from 'utils/Appearances/appearanceConsts';
import {
  CHANGE_CUSTOMIZE_AVATAR_CATEGORY,
  CHANGE_CUSTOMIZE_AVATAR_SUBCATEGORY,
  CHANGE_CUSTOMIZE_AVATAR_TEXTURE,
  CHANGE_CUSTOMIZE_AVATAR_COLOR,
  CHANGE_CUSTOMIZE_AVATAR_CUSTOM_HEAD,
} from 'utils/firebase/analytics.config';
import {logEvent} from 'utils/firebase/analytics';

const CustomizeTray = (props) => {
  const {height, width} = useWindowDimensions();
  const ratio = height / width;
  const {translate} = useContext(I18nContext);
  const {styles, isInGame} = props;
  const [subCategoryImage, setSubCategoryImage] = useState(undefined);
  const [showPresetHeads, setShowPresetHeads] = useState(false);

  const userSelectedBody = useSelector((state) => state.appearance.body);
  const userSelectedCategory = useSelector(
    (state) => state.appearance.category,
  );
  const appearanceLoading = useSelector(
    (state) => state.appearance.avatarLoading,
  );
  const userSelectedSubcategory = useSelector(
    (state) => state.appearance.selection,
  );
  const userSelectedMaterial = useSelector(
    (state) => state.appearance.material,
  );
  const userSelectedColor = useSelector((state) => state.appearance.color);
  const userSelectedLooks = useSelector((state) => state.appearance.looks);
  const isFullBody = useSelector((state) => state.appearance.isFullBody);
  const appearanceData = useSelector(
    (state) => state.appearance.appearanceData,
  );

  const dispatch = useDispatch();

  // get the info to generate the high level category buttons
  const mainCategories = Appearances.getCategories(
    appearanceData,
    userSelectedBody,
  );

  const mainCategoryButtons = mainCategories.map((category) => {
    return {
      data: category,
      text: category,
      image: Appearances.getCategoryImage(userSelectedBody, category),
      key: category,
      // set the color of the currently selected category to be "highlighted"
      color: category === userSelectedCategory ? '#fe6507' : undefined,
    };
  });

  // get the info to generate the subCategory buttons
  const subCategories = Appearances.getSubcategories(
    appearanceData,
    userSelectedBody,
    userSelectedCategory,
  );

  const subCategoryButtons = subCategories.map((subCategory) => {
    return {
      data: subCategory.id,
      text: subCategory.id,
      image: {uri: subCategory.imgUrl},
      key: subCategory.id,
    };
  });

  // get the info to generate the texture buttons
  const textures = Appearances.getTextures(
    appearanceData,
    userSelectedBody,
    userSelectedCategory,
    userSelectedSubcategory,
  );

  const textureButtons = textures.map((texture) => {
    return {
      data: texture.id,
      text: texture.id,
      image: {uri: texture.imgUrl},
      key: texture.id,
    };
  });

  const onSelectCategory = (newCategory) => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_CATEGORY, {category: newCategory});
    // if the currently selected category is the same as the newly selected category - unselect all options and reset the camera.
    if (userSelectedCategory === newCategory) {
      dispatch(setCategory({}));
      Appearances.restoreCameraPosition(ratio, isInGame);
      return;
    }
    // focuses camera on the newly chosen category if it's. There has got to be a better way to do this in the future.
    Appearances.focusCategory(newCategory, ratio, isInGame, userSelectedBody);

    // updates the store with the newly selected category
    dispatch(
      setCategory({
        category: newCategory,
        selection: undefined,
        material: undefined,
        color: undefined,
      }),
    );
    // update the local head state so it resets with all the other categories.
    setShowPresetHeads(false);
  };

  const onSelectSubCategory = (newSubCategory, generateHead) => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_SUBCATEGORY, {
      subcategory: newSubCategory,
    });
    // if the currently selected subcategory is the same as the newly selected category - do nothing.
    if (userSelectedSubcategory === newSubCategory) {
      return;
    }

    // update store to show that a subCategory has been picked
    dispatch(
      setCategory({
        category: userSelectedCategory,
        selection: newSubCategory,
        material: undefined,
        color: undefined,
      }),
    );

    // start with the current userSelectedLooks, then modify from there
    const newLooks = {...userSelectedLooks};

    // if the user just selected a full body outfit (aka. a dress) then remove the top/bottom subcategory from the looks
    if (userSelectedCategory === 'dress') {
      // remove the top/bottom so unity doesn't render both the dress and the top/bottom
      delete newLooks[userSelectedBody].categories.top;
      delete newLooks[userSelectedBody].categories.bottom;

      // check if the dress currently doesn't exist, if so then add it back in with some default starting values
      if (!('dress' in newLooks[userSelectedBody].categories)) {
        newLooks[userSelectedBody].categories.dress = {};
        // assign first available values from options for the BOTTOM
        // color (white as the default)
        newLooks[userSelectedBody].categories.dress.color = '#FFFFFF';
        // selection
        newLooks[userSelectedBody].categories.dress.selection =
          appearanceData.bodyTypes[
            userSelectedBody
          ].categories.bottom.types[0].id;
        // texture
        newLooks[userSelectedBody].categories.dress.texture =
          appearanceData.bodyTypes[
            userSelectedBody
          ].categories.bottom.types[0].textures[0].id;
      }
    }
    // if either the top or the bottom were selected, remove the dress
    else if (
      userSelectedCategory === 'top' ||
      userSelectedCategory === 'bottom'
    ) {
      // remove the dress so unity doesn't render both the dress and the top/bottom
      delete newLooks[userSelectedBody].categories.dress;
      // if at this point either top or bottom are not present in the looks object, add them in
      // (they may have been deleted from a dress selection earlier)
      // check if bottom doesn't exist first
      if (!('bottom' in newLooks[userSelectedBody].categories)) {
        newLooks[userSelectedBody].categories.bottom = {};
        // assign first available values from options for the BOTTOM
        // color (white as the default)
        newLooks[userSelectedBody].categories.bottom.color = '#FFFFFF';
        // selection
        newLooks[userSelectedBody].categories.bottom.selection =
          appearanceData.bodyTypes[
            userSelectedBody
          ].categories.bottom.types[0].id;
        // texture
        newLooks[userSelectedBody].categories.bottom.texture =
          appearanceData.bodyTypes[
            userSelectedBody
          ].categories.bottom.types[0].textures[0].id;
      }

      if (!('top' in newLooks[userSelectedBody].categories)) {
        newLooks[userSelectedBody].categories.top = {};
        // assign first available values for the TOP
        // color
        newLooks[userSelectedBody].categories.top.color = '#FFFFFF';
        // selection
        newLooks[userSelectedBody].categories.top.selection =
          appearanceData.bodyTypes[userSelectedBody].categories.top.types[0].id;
        // texture
        newLooks[userSelectedBody].categories.top.texture =
          appearanceData.bodyTypes[
            userSelectedBody
          ].categories.top.types[0].textures[0].id;
      }
    }

    // add the user's selected subcategory to the newLooks
    newLooks[userSelectedBody].categories[
      userSelectedCategory
    ].selection = newSubCategory;

    // default to the first texture from the appearance data for the selected subcategory
    if (userSelectedCategory !== 'head') {
      const types =
        appearanceData.bodyTypes[userSelectedBody].categories[
          userSelectedCategory
        ].types;

      const foundType = types.find((type) => type.id === newSubCategory);

      newLooks[userSelectedBody].categories[userSelectedCategory].texture =
        foundType.textures[0].id;
    }

    // set the flag for whether or not to generate the head from avatarSDK
    newLooks[userSelectedBody].categories[
      userSelectedCategory
    ].generateHead = generateHead;

    console.log('NEW LOOKS: ', newLooks);

    // save this new look to the store
    dispatch(setAppearance(newLooks));

    // set local state for non-head categories so we have the selected subCategory's image URL
    if (userSelectedCategory !== HEAD_CATEGORY) {
      const selectedSubData = subCategoryButtons.find((button) => {
        return button.data === newSubCategory;
      });
      setSubCategoryImage(selectedSubData.image.uri);
    }

    // prompt the user if it's a head change letting them know this may take a minute
    if (userSelectedCategory === HEAD_CATEGORY) {
      showMessage({
        message: translate('avatarPreview.loadingTime'),
        icon: 'auto',
        autoHide: true,
        duration: 3000,
        type: 'info',
        floating: true,
        style: {width: 250},
        titleStyle: {alignSelf: 'center'},
        hideOnPress: true,
        position: 'bottom',
      });
    }

    // set the loading view while unity processes the change
    dispatch(setAvatarLoading(true));
    // update the avatar in unity with the new look.
    Appearances.applyLook(
      newLooks[userSelectedBody],
      userSelectedBody,
      isFullBody,
    );
  };

  const onSelectTexture = (newTexture) => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_TEXTURE, {texture: newTexture});
    // if the currently selected texture is the same as the newly selected category - do nothing.
    if (userSelectedMaterial === newTexture) {
      return;
    }
    // update store to show that a texture has been picked
    dispatch(
      setCategory({
        category: userSelectedCategory,
        selection: userSelectedSubcategory,
        material: newTexture,
        color: undefined,
      }),
    );

    // updates the store look with the new texture
    const newLooks = {...userSelectedLooks};

    newLooks[userSelectedBody].categories[
      userSelectedCategory
    ].texture = newTexture;
    dispatch(setAppearance(newLooks));

    // set the loading views
    dispatch(setAvatarLoading(true));
    // update the avatar with the selected item - including the generateHead value from the current look in the store
    Appearances.applyLook(
      newLooks[userSelectedBody],
      userSelectedBody,
      isFullBody,
    );
  };

  const onColorChange = (newColor) => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_COLOR, {color: newColor});
    // if the currently selected color is the same as the newly selected color - do nothing.
    if (userSelectedColor === newColor) {
      return;
    }
    // update store to show that a color has been picked
    dispatch(
      setCategory({
        category: userSelectedCategory,
        selection: userSelectedSubcategory,
        material: userSelectedMaterial,
        color: newColor,
      }),
    );

    // updates the store look with the new color
    const newLooks = {...userSelectedLooks};

    newLooks[userSelectedBody].categories[
      userSelectedCategory
    ].color = newColor;

    dispatch(setAppearance(newLooks));

    // set the loading views
    dispatch(setAvatarLoading(true));
    // update the avatar with the selected color.
    Appearances.applyLook(
      newLooks[userSelectedBody],
      userSelectedBody,
      isFullBody,
    );
  };

  const onTextureBack = () => {
    // reset store variables so we see the previous subcategory view
    dispatch(
      setCategory({
        category: userSelectedCategory,
        selection: undefined,
        material: undefined,
      }),
    );
  };

  const saveCustomHead = (data, bool) => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_CUSTOM_HEAD);
    onSelectSubCategory(data, bool);
  };

  const tierSpacer = (
    <View style={styles.tierSpacer}>
      <Text style={styles.tierSpacerText}>
        {translate('avatarPreview.traySpacer')}
      </Text>
    </View>
  );

  const subCategoriesContainer = (
    <View style={styles.tierTwo}>
      {!showPresetHeads ? null : (
        <TouchableOpacity
          disabled={appearanceLoading}
          onPress={() => setShowPresetHeads(false)}
          style={style.categorySelected}>
          <View style={style.categorySelectedContents}>
            <IconButton
              name={'arrow-left-circle-outline'}
              onPress={() => setShowPresetHeads(false)}
              color={'#fe6507'}
            />
          </View>
        </TouchableOpacity>
      )}
      <Categories
        options={subCategoryButtons}
        selectOption={onSelectSubCategory}
        scrollViewStyle={styles.tierTwoScrollContainer}
        innerScrollViewStyle={styles.tierTwoScrollContents}
        buttonSizeStyle={style.buttonSizeStyle}
        disabled={appearanceLoading}
      />
    </View>
  );

  const texturesContainer = (
    <View style={styles.tierTwo}>
      <View style={style.categorySelected}>
        <TouchableOpacity style={style.categorySelectedContents}>
          <IconButton
            name={'arrow-left-circle-outline'}
            onPress={() => {
              onTextureBack();
              setShowPresetHeads(false);
            }}
            color={'#fe6507'}
            disabled={appearanceLoading}
          />
        </TouchableOpacity>
        <Categories
          options={[
            {
              data: 'displayedCategory',
              text: userSelectedSubcategory,
              image: {uri: subCategoryImage},
              key: 'displayedCategory',
            },
          ]}
          selectOption={() => null}
          scrollViewStyle={style.categorySelectedScrollOuter}
          innerScrollViewStyle={style.categorySelectedScrollInner}
          disabled={true}
        />
      </View>
      <Categories
        options={textureButtons}
        selectOption={onSelectTexture}
        scrollViewStyle={styles.tierTwoScrollContainer}
        innerScrollViewStyle={styles.tierTwoScrollContents}
        buttonSizeStyle={style.buttonSizeStyle}
        disabled={appearanceLoading}
      />
      <ColorWheel onChange={onColorChange} />
    </View>
  );

  const headContainer = (
    <HeadOptions
      styles={styles}
      setShowPresetHeads={setShowPresetHeads}
      savePicture={(data, bool) => {
        saveCustomHead(data, bool);
      }}
    />
  );

  const loadingContainer = (
    <View style={styles.tierSpacer}>
      <Loading loading={appearanceLoading} color={'transparent'} />
    </View>
  );

  let tier2Display;
  if (userSelectedCategory === HEAD_CATEGORY) {
    tier2Display = showPresetHeads ? subCategoriesContainer : headContainer;
  } else if (Array.isArray(textureButtons) && textureButtons.length > 0) {
    tier2Display = texturesContainer;
  } else if (
    Array.isArray(subCategoryButtons) &&
    subCategoryButtons.length > 0
  ) {
    tier2Display = subCategoriesContainer;
  } else {
    tier2Display = tierSpacer;
  }

  return (
    <View style={styles.tray}>
      {appearanceLoading ? loadingContainer : tier2Display}
      <View style={styles.tierOne}>
        <Categories
          options={mainCategoryButtons}
          selectOption={onSelectCategory}
          scrollViewStyle={styles.tierOneScrollContainer}
          innerScrollViewStyle={styles.tierOneScrollContents}
          showRandomize={true}
          isInGame={isInGame}
          disabled={appearanceLoading}
        />
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  buttonSizeStyle: {
    height: 60,
    width: 60,
    borderRadius: 60,
  },
  headButtons: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fe6507',
    padding: 10,
    margin: 10,
  },
  headButtonIcons: {
    width: undefined,
    height: undefined,
    flex: 1,
    resizeMode: 'contain',
  },
  headButtonText: {
    textAlign: 'center',
    color: '#fff',
  },
  categorySelected: {
    flex: -1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 5,
    padding: 5,
  },
  // flexed under categorySelected
  categorySelectedContents: {flex: 1},
  categorySelectedScrollOuter: {flex: 1},
  // end categorySelected
  categorySelectedScrollInner: {flex: -1, alignItems: 'flex-end'},
});

CustomizeTray.defaultProps = {
  style: undefined,
  vertical: undefined,
};

CustomizeTray.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  vertical: PropTypes.bool,
};

export default CustomizeTray;
