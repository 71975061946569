import React, {useContext} from 'react';
import {
  Text,
  StyleSheet,
  ScrollView,
  View,
  useWindowDimensions,
  TouchableOpacity,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import CustomizeTray from 'components/Customization/CustomizeTray';
import BodySelection from 'components/Customization/BodySelection';
import Appearances from 'utils/Appearances';
import {I18nContext} from 'i18n/context/I18nContext';
import {
  setAppearanceInStorage,
  setCategory,
  setEditsInProgress,
} from 'store/appearance';

const Customize = () => {
  const isInGame = true;

  const {height, width} = useWindowDimensions();
  const ratio = height / width;

  const {translate} = useContext(I18nContext);
  const dispatch = useDispatch();

  const userSelectedBody = useSelector((state) => state.appearance.body);
  const looks = useSelector((state) => state.appearance.looks);
  const avatarLoading = useSelector((state) => state.appearance.avatarLoading);
  const asdkId = useSelector((state) => state.appearance.asdkId);
  const editsInProgress = useSelector(
    (state) => state.appearance.editsInProgress,
  );

  const resetSelection = () => {
    dispatch(setCategory({}));
    Appearances.restoreCameraPosition(ratio, isInGame);
  };

  const startAppearance = () => {
    dispatch(setEditsInProgress(true));
    Appearances.UseCustomizeCamera(ratio, isInGame);
  };

  const saveAppearance = () => {
    // reset the customize UI
    dispatch(setCategory({}));
    // reset the store state for the UI
    dispatch(setEditsInProgress(false));
    // save the new appearance in local storage
    dispatch(setAppearanceInStorage(asdkId, userSelectedBody, looks));
    // sets the camera back behind the user
    Appearances.UsePlayerCamera();
    // broadcasts appearance to others
    Appearances.broadcastAppearance(userSelectedBody, looks);
  };

  const startCustomizeButton = (
    <TouchableOpacity
      disabled={avatarLoading}
      style={styles.saveAppearanceButton}
      onPress={startAppearance}>
      <Text style={styles.joinButtonText}>
        {translate('avatarPreview.startAppearance')}
      </Text>
    </TouchableOpacity>
  );

  const saveCustomizeButton = (
    <TouchableOpacity
      disabled={avatarLoading}
      style={styles.saveAppearanceButton}
      onPress={saveAppearance}>
      <Text style={styles.joinButtonText}>
        {translate('avatarPreview.saveAppearance')}
      </Text>
    </TouchableOpacity>
  );

  return (
    <ScrollView
      contentContainerStyle={styles.trayContainer}
      alwaysBounceHorizontal={false}
      alwaysBounceVertical={false}
      bounces={false}>
      <View style={styles.saveAppearanceContainer}>
        {editsInProgress ? saveCustomizeButton : startCustomizeButton}
      </View>
      {editsInProgress ? (
        <CustomizeTray styles={styles} isInGame={isInGame} />
      ) : null}
      {editsInProgress ? (
        <View style={styles.saveAppearanceContainer}>
          <Text style={styles.bodyHeader}>{translate('meeting.bodyType')}</Text>
          <BodySelection style={styles.genders} onSelect={resetSelection} />
        </View>
      ) : null}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
  trayContainer: {
    justifyContent: 'space-between',
    flex: 1,
    padding: 5,
  },
  // flexed under trayContainer
  saveAppearanceContainer: {
    flex: -1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tray: {
    flex: 6,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  bodyContainer: {flex: 1},
  // end trayContainer flex
  // flexed under saveAppearanceContainer
  saveAppearanceButton: {
    backgroundColor: '#fe6507',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: 150,
  },
  saveAppearanceButtonText: {
    textAlign: 'center',
  },
  // end saveAppearanceContainer
  // flexed under bodyContainer
  bodyHeader: {
    textAlign: 'center',
  },
  genders: {flexDirection: 'row'},
  // end bodyContainer
  // flexed under tray
  tierOne: {
    flex: -1,
    height: '100%',
    flexDirection: 'column',
    marginLeft: 10,
  },
  tierTwo: {
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    flex: 1,
  },
  tierSpacer: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    padding: 5,
    textAlign: 'center',
  },
  // end tray flex
  // flex under tierSpacer
  tierSpacerText: {
    textAlign: 'center',
  },
  //end tierSpacer flex
  // flexed under TierOne
  tierOneScrollContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  // end tier flex
  // flexed under tierScrollContainer
  tierOneScrollContents: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  // end tierScrollContainer
  // flexed under TierTwo
  tierTwoScrollContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  colorWheel: {
    width: '100%',
    height: 125,
    marginBottom: 10,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  // end tier flex
  // flexed under tierScrollContainer
  tierTwoScrollContents: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  // end tierScrollContainer
});

export default Customize;
