import React from 'react';
import PropTypes from 'prop-types';
import {View, TouchableOpacity, StyleSheet} from 'react-native';

import Viewboard from './Viewboard';
import {VIEWBOARD_ITEM_BUTTON_CONTAINER} from 'style/theme';

const ViewboardButton = ({
  onPress,
  displayName,
  mapLegendNumber,
  isViewboardButtonDisabled,
}) => {
  return (
    <TouchableOpacity onPress={onPress} disabled={isViewboardButtonDisabled}>
      <View
        style={[
          VIEWBOARD_ITEM_BUTTON_CONTAINER,
          isViewboardButtonDisabled ? styles.disabledViewboardButton : null,
        ]}>
        <Viewboard
          displayName={displayName}
          mapLegendNumber={mapLegendNumber}
        />
      </View>
    </TouchableOpacity>
  );
};

ViewboardButton.defaultProps = {
  isViewboardButtonDisabled: false,
};

ViewboardButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  mapLegendNumber: PropTypes.number.isRequired,
  isViewboardButtonDisabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  disabledViewboardButton: {
    backgroundColor: 'gray',
  },
});

export default ViewboardButton;
