import React from 'react';
import PropTypes from 'prop-types';
import AttendanceHeader from './AttendanceHeader';
import AttendanceContent from './AttendanceContent';

const AttendanceItem = (props) => {
  const {peerId, expandPeer, expanded, volume} = props;
  const expand = () => {
    if (!expanded) {
      expandPeer(peerId);
    } else {
      expandPeer(undefined);
    }
  };

  return (
    <>
      <AttendanceHeader
        peerId={peerId}
        expanded={expanded}
        expand={expand}
        volume={volume}
      />
      {expanded ? <AttendanceContent peerId={peerId} /> : null}
    </>
  );
};

AttendanceItem.propTypes = {
  peerId: PropTypes.string.isRequired,
  expandPeer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  volume: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
};

export default AttendanceItem;
