import React, {useEffect, useState, useContext} from 'react';
import {
  Text,
  Button,
  Share,
  Clipboard,
  StyleSheet,
  ScrollView,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import AttendanceItem from './Controls/AttendanceItem';
import ControlAll from './Controls/ControlAll';
import {logEvent} from 'utils/firebase/analytics';
import {SHARE_MEETING_INVITE} from 'utils/firebase/analytics.config';
import {setMeetingMaxNumberParticipants} from 'store/meeting/actions/properties';
import {I18nContext} from 'i18n/context/I18nContext';

const Attendance = (props) => {
  const dispatch = useDispatch();
  const inviteURL = useSelector((state) => state.meeting.properties.inviteURL);
  const meetingMaxNumberParticipants = useSelector(
    (state) => state.meeting.properties.meetingMaxNumberParticipants,
  );
  const {translate} = useContext(I18nContext);

  const DEFAULT_SHARE_MEETING_BUTTON_TITLE = translate('attendance.shareLink');
  const COPIED_SHARE_MEETING_BUTTON_TITLE = translate(
    'attendance.copiedClipboard',
  );

  const peers = useSelector((state) => state.communication.peers);
  const activeSpeakerId = useSelector(
    (state) => state.communication.room.activeSpeakerId,
  );
  const activeSpeakerVolume = useSelector(
    (state) => state.communication.room.activeSpeakerVolume,
  );
  const peerIds = Object.keys(peers);
  const [expandedPeer, setExpandedPeer] = useState(undefined);
  const peerIdArray = [];

  if (peerIds) {
    peerIds.forEach((peerId) => {
      peerIdArray.push(peerId);
    });
  }

  // Update meeting max number participants, used for analytic purposes
  useEffect(() => {
    const currentNumberMeetingParticipants = peerIdArray.length + 1;
    if (meetingMaxNumberParticipants < currentNumberMeetingParticipants) {
      dispatch(
        setMeetingMaxNumberParticipants(currentNumberMeetingParticipants),
      );
    }
  }, [dispatch, meetingMaxNumberParticipants, peerIdArray.length]);

  const hasPeers = peerIdArray.length > 0;

  const [shareMeetingButtonTitle, setShareMeetingButtonTitle] = useState(
    DEFAULT_SHARE_MEETING_BUTTON_TITLE,
  );

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: translate('attendance.joinMeeting', {inviteURL}),
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
          await logEvent(SHARE_MEETING_INVITE, {
            activity_type: result.activityType,
          });
        } else {
          // shared
          await logEvent(SHARE_MEETING_INVITE);
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Clipboard.setString(`${inviteURL}`);
      setShareMeetingButtonTitle(COPIED_SHARE_MEETING_BUTTON_TITLE);
      setTimeout(
        () => setShareMeetingButtonTitle(DEFAULT_SHARE_MEETING_BUTTON_TITLE),
        3000,
      );

      await logEvent(SHARE_MEETING_INVITE, {
        activity_type: 'copied to clipboard',
      });
    }
  };

  const expandPeer = (peerId) => {
    setExpandedPeer(peerId);
  };

  return (
    <ScrollView>
      {inviteURL && inviteURL !== '' ? (
        <Button onPress={onShare} title={shareMeetingButtonTitle} />
      ) : null}
      {hasPeers ? <ControlAll /> : null}
      {hasPeers ? (
        peerIdArray.map((peerId) => {
          return (
            <AttendanceItem
              peerId={peerId}
              expanded={peerId === expandedPeer}
              expandPeer={expandPeer}
              key={peerId}
              volume={peerId === activeSpeakerId ? activeSpeakerVolume : false}
            />
          );
        })
      ) : (
        <Text style={styles.text}>
          {translate('attendance.waitingForUsers')}
        </Text>
      )}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
});

export default Attendance;
