import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {View, Button, StyleSheet, Alert, Platform} from 'react-native';
import {useDispatch} from 'react-redux';

import {
  leaveRoom,
  endMeetingForAll,
} from 'store/communication/actions/controlActions';
import {goToLanding} from 'store/app';
import {I18nContext} from 'i18n/context/I18nContext';

const LeaveMeetingOverlay = ({
  isLeaveMeetingModalVisible,
  setIsLeaveMeetingModalVisible,
}) => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const onEndMeetingForAll = async () => {
    const endMeeting = async () => {
      await dispatch(endMeetingForAll());
      dispatch(leaveRoom());
      dispatch(goToLanding());
    };
    if (Platform.OS !== 'web') {
      Alert.alert(
        translate('leaveMeetingOverlay.confirm'),
        translate('leaveMeetingOverlay.endKickEveryone'),
        [
          {
            text: translate('shared.cancel'),
            style: 'cancel',
          },
          {
            text: translate('leaveMeetingOverlay.end'),
            onPress: async () => await endMeeting(),
            style: 'destructive',
          },
        ],
        {cancelable: false},
      );
    } else {
      await endMeeting();
    }
  };

  const onClose = async () => {
    dispatch(leaveRoom());
    dispatch(goToLanding());
  };

  const onCancel = async () => {
    setIsLeaveMeetingModalVisible(false);
  };

  return isLeaveMeetingModalVisible ? (
    <View style={styles.container}>
      <View style={styles.background}>
        <Button
          title={translate('leaveMeetingOverlay.endForEveryone')}
          onPress={onEndMeetingForAll}
        />
        <Button
          title={translate('leaveMeetingOverlay.leave')}
          onPress={onClose}
        />
        <Button title={translate('shared.cancel')} onPress={onCancel} />
      </View>
    </View>
  ) : null;
};

LeaveMeetingOverlay.propTypes = {
  isLeaveMeetingModalVisible: PropTypes.bool.isRequired,
  setIsLeaveMeetingModalVisible: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#0008',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    maxWidth: 250,
    maxHeight: 175,
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 10,
    flex: 1,
    justifyContent: 'space-between',
  },
});

export default LeaveMeetingOverlay;
