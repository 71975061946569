import getRemoteConfigValue from 'utils/firebase/remoteConfig';
const jwtDecode = require('jwt-decode');

const REQUIRED_TOKEN_FIELDS = ['aud', 'sub', 'iat', 'exp', 'iss'];
const IAT_TIME_DELTA_ALLOWANCE = 3600;

export const VerifyAndParseToken = (token) => {
  const integrationKeysRemoteConfigValue = getRemoteConfigValue(
    'integration_keys',
  );
  const INTEGRATION_KEYS = JSON.parse(
    integrationKeysRemoteConfigValue.asString(),
  );

  if (!token || typeof token === undefined || token === 'undefined') {
    return {error: 'TokenEmptyError'};
  }
  // Check for fields on decode, no point in verifying if expected fields aren't there (verifying is more expensive than decoding)
  let decodedToken;

  try {
    decodedToken = jwtDecode(token);
  } catch (error) {
    return {error: 'TokenMalformedError'};
  }

  for (const field of REQUIRED_TOKEN_FIELDS) {
    if (!decodedToken.hasOwnProperty(field)) {
      return {error: 'TokenFieldsMissingError'};
    }
  }

  const {iss: integration, iat} = decodedToken;
  if (
    iat > Math.floor(new Date().getTime() / 1000 + IAT_TIME_DELTA_ALLOWANCE)
  ) {
    return {error: 'IATInvalidError'};
  }

  if (!(integration in INTEGRATION_KEYS)) {
    return {error: 'IntegrationInvalidError'};
  }

  return {
    integration: decodedToken.iss,
    expiration: decodedToken.exp,
    peerId: decodedToken.sub,
    roomId: decodedToken.aud,
    meetingName: decodedToken.meetingName,
    permissions: decodedToken.permissions,
    communicationServer: decodedToken.communicationServer,
  };
};
