import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import Thumbnail from 'components/Thumbnail';
import images from 'images';
import {isURL} from 'utils/helpers';

const styles = StyleSheet.create({
  button: {
    height: 42,
    backgroundColor: '#2196F3',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8,
  },
  text: {
    fontSize: 14,
    color: '#FFFFFF',
  },
  icon: {
    fontSize: 28,
    color: '#FFFFFF',
  },
});

const PeerChaseButton = (props) => {
  const {location, alt, text, onPress} = props;
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <Thumbnail
        alt={alt}
        large
        source={isURL(location) ? {uri: location} : images[location]}
      />
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

PeerChaseButton.defaultProp = {
  location: null,
  alt: '',
};

PeerChaseButton.propTypes = {
  location: PropTypes.string,
  alt: PropTypes.string,
  text: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

export default PeerChaseButton;
