import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';

import {checkPermission, VIEWBOARD_CONTROL} from 'utils/permissions';
import {logEvent} from 'utils/firebase/analytics';
import {
  NAVIGATE_TO_VIEWBOARD,
  EDIT_VIEWBOARD,
  REMOVE_VIEWBOARD,
} from 'utils/firebase/analytics.config';
import {
  navigateToViewboard,
  clearViewboardContent,
  removeActiveViewboard,
  switchToShareOnViewboard,
  selectViewboard,
} from 'store/viewboard';
import IconButton from 'components/IconButton';
import {SIDE_PANEL_VIEWBOARDS_COLOR} from 'style/theme';

const ActiveViewboardActions = ({viewboardId}) => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.world.location);
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );

  const onNavigatePress = () => {
    navigateToViewboard(viewboardId);

    logEvent(NAVIGATE_TO_VIEWBOARD, {
      viewboard_id: viewboardId,
      location: location,
    });
  };

  const onEditPress = () => {
    dispatch(selectViewboard(viewboardId));
    dispatch(switchToShareOnViewboard({isEditMode: true}));

    logEvent(EDIT_VIEWBOARD, {
      viewboard_id: viewboardId,
      location: location,
    });
  };

  const onDeletePress = () => {
    dispatch(clearViewboardContent(location, viewboardId));
    dispatch(removeActiveViewboard({viewboardId}));

    logEvent(REMOVE_VIEWBOARD, {
      viewboard_id: viewboardId,
      location: location,
    });
  };

  return (
    <View style={styles.activeViewboardActionsContainer}>
      <IconButton
        name={'walk'}
        onPress={onNavigatePress}
        color={SIDE_PANEL_VIEWBOARDS_COLOR}
        style={styles.activeViewboardActionStyle}
      />
      {checkPermission(VIEWBOARD_CONTROL, permissions) ? (
        <IconButton
          name={'pencil'}
          onPress={onEditPress}
          color={SIDE_PANEL_VIEWBOARDS_COLOR}
          style={styles.activeViewboardActionStyle}
        />
      ) : null}
      {checkPermission(VIEWBOARD_CONTROL, permissions) ? (
        <IconButton
          name={'delete'}
          onPress={onDeletePress}
          color={SIDE_PANEL_VIEWBOARDS_COLOR}
          style={styles.activeViewboardActionStyle}
        />
      ) : null}
    </View>
  );
};

ActiveViewboardActions.propTypes = {
  viewboardId: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  activeViewboardActionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeViewboardActionStyle: {
    marginRight: 10,
    marginLeft: 10,
  },
});

export default ActiveViewboardActions;
