const isOpera =
  // eslint-disable-next-line no-undef
  (!!window.opr && !!opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window.safari ||
      // eslint-disable-next-line no-undef
      (typeof safari !== 'undefined' && safari.pushNotification),
  );

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
const isEdgeLegacy = !isIE && !!window.StyleMedia;

// Chrome 1 - 79
const isChrome =
  !!window.chrome &&
  (!!window.chrome.webstore ||
    !!window.chrome.runtime ||
    window.location.hostname === 'localhost');

// Edge (based on chromium) detection
const isEdge = isChrome && navigator.userAgent.indexOf('Edg') !== -1;

let platform = 'unknown';

if (isChrome) {
  platform = 'google-chrome';
} else if (isFirefox) {
  platform = 'firefox';
} else if (isSafari) {
  platform = 'apple-safari';
} else if (isIE) {
  platform = 'microsoft-internet-explorer';
} else if (isEdgeLegacy) {
  platform = 'microsoft-edge-legacy';
} else if (isEdge) {
  platform = 'microsoft-edge';
} else if (isOpera) {
  platform = 'opera';
}

export default {
  type: 'laptop',
  platform: platform,
};
