import React from 'react';
import {View, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';

import RemoteAudio from './RemoteAudio';

const RemoteAudios = () => {
  const consumers = useSelector((state) => state.communication.consumers);

  const audioConsumersArray = [];

  const consumerIds = Object.keys(consumers);
  consumerIds.forEach((consumerId) => {
    const consumer = consumers[consumerId];

    if (consumer.track && consumer.track.kind === 'audio') {
      audioConsumersArray.push(consumer);
    }
  });

  return (
    <View style={styles.container} pointerEvents="box-none">
      {audioConsumersArray.map((consumer) => {
        return consumer.track.readyState === 'ended' ? null : (
          <RemoteAudio key={consumer.id} consumerId={consumer.id} />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'none',
  },
});

export default RemoteAudios;
