import * as defaultAppConfigValues from 'config/app.config';
import assetVersion from 'config/assetVersion.json';

const remoteConfigDefault = {
  communication_server_url: defaultAppConfigValues.HOST,
  communication_server_port: defaultAppConfigValues.PORT,
  marcus_site_url: defaultAppConfigValues.MARCUS_SITE_URL,
  appearance_json_url: defaultAppConfigValues.APPEARANCE_JSON_URL,
  addressables_catalog_base_url:
    defaultAppConfigValues.ADDRESSABLES_CATALOG_BASE_URL,
  integration_keys: JSON.stringify(defaultAppConfigValues.INTEGRATION_KEYS),
  log_character_appearance: defaultAppConfigValues.LOG_CHARACTER_APPEARANCE,
  viewboard_map_image_uri: defaultAppConfigValues.VIEWBOARD_MAP_IMAGE_URI,
  viewboard_map_image_path: defaultAppConfigValues.VIEWBOARD_MAP_IMAGE_PATH,
  viewboard_share_instance_content_web_modal_margin_top:
    defaultAppConfigValues.VIEWBOARD_SHARE_INSTANCE_CONTENT_WEB_MODAL_MARGIN_TOP,
  web_scenes_version: defaultAppConfigValues.WEB_SCENES_VERSION,
  asset_version: assetVersion.assetVersion,
  bundled_levels: JSON.stringify(assetVersion.bundled),
  avatar_sdk_client_id: defaultAppConfigValues.AVATAR_SDK_CLIENT_ID,
  avatar_sdk_secret_key: defaultAppConfigValues.AVATAR_SDK_SECRET_KEY,
};

export default remoteConfigDefault;
