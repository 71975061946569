import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import axios from 'axios';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import md5 from 'js-md5';
import {useFilePicker} from 'use-file-picker';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import {SIDE_PANEL_VIEWBOARDS_COLOR} from 'style/theme';
import {logEvent} from 'utils/firebase/analytics';
import {
  FILESHARE_UPLOAD,
  FILESHARE_UPLOAD_ERROR,
} from 'utils/firebase/analytics.config';

// TODO: Get this from the comm server? or set via remote configs for now?
const ACCEPTED_MIME_TYPES = ['image/jpeg', 'image/png', 'video/mp4'];

const ViewboardUploadContentButton = () => {
  const {translate} = useContext(I18nContext);
  const roomClient = useSelector(
    (state) => state.communication.control.roomClient,
  );

  const FILESHARE_UPLOAD_ERROR_MESSAGE = translate(
    'viewboardUploadContentButtonWeb.errorUpload',
  );
  const FILESHARE_UPLOAD_SUCCESS_MESSAGE = translate(
    'viewboardUploadContentButtonWeb.uploadSuccess',
  );
  const ERROR_MESSAGE_OBJECT = Object.freeze({
    message: FILESHARE_UPLOAD_ERROR_MESSAGE,
    type: 'danger',
    icon: 'auto',
  });

  const [
    openFileSelector,
    {filesContent, plainFiles, clear, loading},
  ] = useFilePicker({
    multiple: false,
    readAs: 'ArrayBuffer',
    accept: ACCEPTED_MIME_TYPES,
  });

  const [isFileUploading, setIsFileUploading] = useState(false);
  useEffect(() => {
    const calculateMD5Hash = (file) => {
      return md5.hex(file);
    };

    const uploadFileToSignedUrl = async (signedUrl, hash, mimetype, file) => {
      const config = {
        headers: {
          'Content-MD5': hash,
          'Content-Type': mimetype,
        },
      };

      return await axios.put(signedUrl, file, config);
    };

    const fileshareUploadRequest = async (fileToUpload) => {
      const result = await roomClient.requestFileshareUpload(
        fileToUpload.name,
        fileToUpload.type,
        fileToUpload.size,
        fileToUpload.hash,
      );

      if (!result?.uploadUrl || !result?.hash?.b64) {
        showMessage(ERROR_MESSAGE_OBJECT);

        logEvent(FILESHARE_UPLOAD_ERROR, {
          meeting_id: roomClient.meetingId,
          error: 'Error occurred while requesting fileshare upload info',
        });
      } else {
        const uploadFileResult = await uploadFileToSignedUrl(
          result.uploadUrl,
          result.hash.b64,
          fileToUpload.type,
          fileToUpload.content,
        );

        if (uploadFileResult?.status === 200) {
          showMessage({
            message: FILESHARE_UPLOAD_SUCCESS_MESSAGE,
            description: fileToUpload.name,
            type: 'success',
            icon: 'auto',
            duration: 3000,
          });
          roomClient.refreshRoomConfigs();

          logEvent(FILESHARE_UPLOAD, {
            meeting_id: roomClient.meetingId,
            file_type: fileToUpload.type,
            file_size: fileToUpload.size,
          });
        } else {
          showMessage(ERROR_MESSAGE_OBJECT);

          logEvent(FILESHARE_UPLOAD_ERROR, {
            meeting_id: roomClient.meetingId,
            error: `uploadFileResult not 200, instead ${uploadFileResult?.status}`,
          });
        }
      }
    };

    const handleFilePick = async (fileContent, fileInfo) => {
      const fileHash = calculateMD5Hash(fileContent.content);

      const fileToUpload = {
        name: fileInfo.name,
        size: fileInfo.size,
        type: fileInfo.type,
        hash: fileHash,
        content: fileContent.content,
      };

      await fileshareUploadRequest(fileToUpload);

      clear();
      setIsFileUploading(false);
    };

    if (
      plainFiles.length >= 1 &&
      filesContent.length >= 1 &&
      !isFileUploading
    ) {
      setIsFileUploading(true);

      handleFilePick(filesContent[0], plainFiles[0]);
    }
  }, [
    filesContent,
    plainFiles,
    roomClient,
    isFileUploading,
    clear,
    ERROR_MESSAGE_OBJECT,
    FILESHARE_UPLOAD_SUCCESS_MESSAGE,
  ]);

  const onViewboardContentUpload = async () => {
    clear();
    openFileSelector();
  };

  return (
    <TouchableOpacity
      onPress={onViewboardContentUpload}
      disabled={isFileUploading || loading}>
      <View style={styles.buttonContainer}>
        {isFileUploading || loading ? (
          <ActivityIndicator
            style={styles.uploadActivityIndicator}
            size="large"
            color="white"
          />
        ) : (
          <Icon
            type="MaterialCommunityIcons"
            name="upload"
            style={styles.buttonIcon}
            color="white"
          />
        )}
        <Text style={styles.buttonText}>
          {translate('viewboardUploadContentButtonWeb.uploadContent')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    backgroundColor: SIDE_PANEL_VIEWBOARDS_COLOR,
    color: 'white',
    borderRadius: 50,
  },
  buttonIcon: {fontSize: 30, paddingRight: 6, paddingLeft: 8},
  buttonText: {
    fontSize: 18,
    marginRight: 10,
    paddingRight: 8,
    color: 'white',
    fontWeight: 'bold',
  },
  uploadActivityIndicator: {
    paddingRight: 4,
    paddingLeft: 4,
  },
});

export default ViewboardUploadContentButton;
