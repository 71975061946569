import React, {useState, useEffect, useContext} from 'react';
import {View, Text, StyleSheet, Platform} from 'react-native';
import {useSelector} from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Orientation from 'react-native-orientation-locker';
import {hasNotch} from 'react-native-device-info';

import {I18nContext} from 'i18n/context/I18nContext';

const RECORDING_ICON = 'record-rec';

const RecordingStatus = () => {
  const {translate} = useContext(I18nContext);
  const recording = useSelector(
    (state) => state.meeting.recording.isBeingRecorded,
  );

  const isMobilePlatform = Platform.OS !== 'web';

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  const isMobilePortrait =
    isMobilePlatform && currentOrientation === 'PORTRAIT';
  const isAndroidLandscape =
    Platform.OS === 'android' &&
    (currentOrientation === 'LANDSCAPE-LEFT' ||
      currentOrientation === 'LANDSCAPE-RIGHT');

  const recordingStatusTopPosition =
    isMobilePortrait && hasNotch()
      ? 40
      : isMobilePortrait || isAndroidLandscape
      ? 20
      : 5;

  return recording ? (
    <View
      style={[styles.container, {top: recordingStatusTopPosition}]}
      pointerEvents="none">
      <Icon
        type="MaterialCommunityIcons"
        style={styles.icon}
        name={RECORDING_ICON}
      />
      <Text style={[styles.text, styles.shadow]}>
        {translate('recordingStatus.meetingRecording')}
      </Text>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'space-between',
  },
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
  shadow: {
    textShadowOffset: {
      width: 1,
      height: 1,
    },
    textShadowRadius: 0,
    textShadowColor: '#000',
    color: '#fff',
  },
  icon: {
    fontSize: 40,
    color: '#f00',
    marginRight: 3,
  },
});

export default RecordingStatus;
