export const DISABLE_MIC = 'DISABLE_MIC';
export const ENABLE_MIC = 'ENABLE_MIC';
export const DISABLE_CAMERA = 'DISABLE_CAMERA';
export const ENABLE_CAMERA = 'ENABLE_CAMERA';
export const DISABLE_SCREEN_SHARE = 'DISABLE_SCREEN_SHARE';
export const ENABLE_SCREEN_SHARE = 'ENABLE_SCREEN_SHARE';
export const SET_CAN_SWITCH_CAMERA = 'SET_CAN_SWITCH_CAMERA';
export const SET_MIC_PERMISSIONS = 'SET_MIC_PERMISSIONS';
export const SET_CAMERA_PERMISSIONS = 'SET_CAMERA_PERMISSIONS';
export const SET_REQUESTED_BLOCKED_PERMISSION =
  'SET_REQUESTED_BLOCKED_PERMISSION';
export const SET_MIRROR_CAMERA = 'SET_MIRROR_CAMERA';
export const SET_CAMERA_STREAM = 'SET_CAMERA_STREAM';
export const SET_MICROPHONE_STREAM = 'SET_MICROPHONE_STREAM';
export const SET_AVAILABLE_DEVICES = 'SET_AVAILABLE_DEVICES';
export const SET_SELECTED_MICROPHONE_DEVICE_ID =
  'SET_SELECTED_MICROPHONE_DEVICE_ID';
export const SET_SELECTED_CAMERA_DEVICE_ID = 'SET_SELECTED_CAMERA_DEVICE_ID';
