import React from 'react';
import PropTypes from 'prop-types';
import {View, TouchableOpacity} from 'react-native';

import ViewboardContent from './ViewboardContent';
import {VIEWBOARD_ITEM_BUTTON_CONTAINER} from 'style/theme';

const ViewboardContentButton = ({onPress, name, type}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={VIEWBOARD_ITEM_BUTTON_CONTAINER}>
        <ViewboardContent name={name} type={type} />
      </View>
    </TouchableOpacity>
  );
};

ViewboardContentButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ViewboardContentButton;
