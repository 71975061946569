import React from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import images from 'images';
import Thumbnail from 'components/Thumbnail';
import {isURL} from 'utils/helpers';

const WorldListItem = ({
  worldInfo,
  teleport,
  isLevelAvailable,
  isLevelDownloading,
}) => {
  return (
    <View key={worldInfo.id} style={styles.item}>
      <TouchableOpacity
        onPress={() => teleport(worldInfo.id, worldInfo.name)}
        style={styles.button}>
        <View style={styles.image}>
          <Thumbnail
            large
            source={
              isURL(worldInfo.preview)
                ? {uri: worldInfo.preview}
                : images[worldInfo.preview]
            }
          />

          {isLevelDownloading && (
            <ActivityIndicator
              size="large"
              color="white"
              style={styles.loadingIconOverlay}
            />
          )}

          {isLevelAvailable && (
            <Icon style={styles.downloadIconOverlay} name="download" />
          )}
        </View>
        <Text style={styles.text}>{worldInfo.name}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  item: {
    margin: 5,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 100,
    borderRadius: 23,
    borderColor: '#000',
    borderWidth: 2,
  },
  image: {
    padding: 10,
  },
  text: {
    flex: 1,
    fontSize: 20,
  },
  downloadIconOverlay: {
    position: 'absolute',
    top: 8,
    left: 8,
    fontSize: 60,
    color: 'white',
  },
  loadingIconOverlay: {
    position: 'absolute',
    top: 20,
    left: 20,
  },
});

WorldListItem.propTypes = {
  worldInfo: PropTypes.object.isRequired,
  teleport: PropTypes.func.isRequired,
  isLevelAvailable: PropTypes.bool.isRequired,
  isLevelDownloading: PropTypes.bool.isRequired,
};

export default WorldListItem;
