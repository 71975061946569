import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet, Platform} from 'react-native';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import ViewboardContentTypeIcon from './ViewboardContentTypeIcon';

const ViewboardContent = ({name, type}) => {
  const {translate} = useContext(I18nContext);

  const notifyType = () => {
    switch (type) {
      case 'image':
        showMessage({
          message: translate('viewboardContent.contentImage'),
          hideStatusBar: Platform.OS === 'android' ? true : false,
          type: 'info',
          icon: 'auto',
        });
        break;
      case 'video':
        showMessage({
          message: translate('viewboardContent.contentVideo'),
          hideStatusBar: Platform.OS === 'android' ? true : false,
          type: 'info',
          icon: 'auto',
        });
        break;
      case 'collection':
        showMessage({
          message: translate('viewboardContent.contentSlideshow'),
          hideStatusBar: Platform.OS === 'android' ? true : false,
          type: 'info',
          icon: 'auto',
          duration: 4000,
        });
        break;
      default:
        return;
    }
  };

  return (
    <View style={styles.viewboardContentContainer}>
      <View style={styles.viewboardContentIconContainer}>
        <ViewboardContentTypeIcon type={type} onPress={notifyType} />
      </View>
      <Text
        style={styles.viewboardContentNameText}
        numberOfLines={2}
        ellipsizeMode="middle">
        {name}
      </Text>
    </View>
  );
};

ViewboardContent.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  viewboardContentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewboardContentIconContainer: {
    marginRight: 10,
  },
  viewboardContentNameText: {
    fontStyle: 'italic',
    flex: 1,
    paddingRight: 2,
  },
});

export default ViewboardContent;
