export const getResourceIDFromLocation = (location, locations) => {
  const locationData = locations?.find((loc) => loc.id === location);
  const resourceID = locationData ? locationData.resourceID : location;
  return resourceID;
};

export function isURL(stringToTest) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(stringToTest);
}

export function toTitleCase(stringToConvert) {
  const words = stringToConvert.toLowerCase().split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(' ');
}

// TODO: Use a "formatUnicorn" option instead for clarity (named replacement variables like 'https://www.example.com/{MEETING_ID}' rather than 'https://www.example.com/%s')
export function formatString(originalString, ...replacements) {
  if (!originalString || typeof originalString === 'undefined') {
    return '';
  }
  if (
    !replacements ||
    typeof replacements === 'undefined' ||
    replacements.length === 0
  ) {
    return originalString;
  }
  let formattedString = originalString;
  let matchIndex = 0;
  formattedString = formattedString.replace(
    /%(s|d|i)/g,
    (percent, charMatch) => {
      let replacement = percent + charMatch;
      if (matchIndex > replacements.length) {
        return replacement;
      }

      switch (charMatch) {
        case 's': {
          replacement = replacements[matchIndex++];
          break;
        }

        case 'd': {
          replacement = Number.parseFloat(replacements[matchIndex++]);
          break;
        }

        case 'i': {
          replacement = Number.parseInt(replacements[matchIndex++], 10);
          break;
        }

        default: {
          break;
        }
      }

      return replacement;
    },
  );

  return formattedString;
}

export function encodeForHTML(originalString) {
  originalString = originalString.replace(/&/g, '&amp;');
  originalString = originalString.replace(/</g, '&lt;');
  originalString = originalString.replace(/>/g, '&gt;');
  return originalString;
}

export const getViewboardReducedMedia = (content) => {
  const mediaDelimiter = '~';

  return content?.media.reduce((acc, cur) => {
    return (acc += `${cur.url}${mediaDelimiter}${cur.type} `);
  }, '');
};

export const getViewboardSanitizedContentId = (content) => {
  return content?.id.replace(/ /g, '_');
};

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = typeof val1 === 'object' && typeof val2 === 'object';
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}
