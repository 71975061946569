// world
export const CLEAR_WORLD = 'CLEAR_WORLD';
export const SET_WORLD_LOCATION = 'SET_WORLD_LOCATION';
export const SET_FLOATING_NAMES = 'SET_FLOATING_NAMES';
export const UPDATE_TEXT_OVERLAY = 'UPDATE_TEXT_OVERLAY';
export const REMOVE_TEXT_OVERLAY = 'REMOVE_TEXT_OVERLAY';
export const CLEAR_TEXT_OVERLAYS = 'CLEAR_TEXT_OVERLAYS';
export const SET_IS_MESSAGE_PANEL_OPEN = 'SET_IS_MESSAGE_PANEL_OPEN';
export const SET_IS_MESSAGE_NOTIFICATION = 'SET_IS_MESSAGE_NOTIFICATION';
export const SET_LABEL_DISPLAY = 'SET_LABEL_DISPLAY';
export const CLEAR_LABEL_DISPLAY = 'CLEAR_LABEL_DISPLAY';
export const CLEAR_LEVEL_LOADING = 'CLEAR_LEVEL_LOADING';
