import React, {useState, useEffect} from 'react';
import {
  View,
  StyleSheet,
  useWindowDimensions,
  Platform,
  FlatList,
} from 'react-native';
import {useSelector} from 'react-redux';
import DoubleClick from 'react-native-double-tap';
import {hasNotch} from 'react-native-device-info';
import Orientation from 'react-native-orientation-locker';

import RemoteVideo from './RemoteVideo';

const RemoteVideos = () => {
  const consumers = useSelector((state) => state.communication.consumers);
  const incomingVideoEnabled = useSelector(
    (state) => state.meeting.settings.incomingVideoEnabled,
  );

  const [videoConsumersArray, setVideoConsumerArray] = useState([]);
  useEffect(() => {
    let newVideoConsumersArray = videoConsumersArray;

    const consumerIds = Object.keys(consumers);
    consumerIds.forEach((consumerId) => {
      const consumer = consumers[consumerId];

      if (consumer.track && consumer.track.kind === 'video') {
        if (
          !newVideoConsumersArray.some(
            (videoConsumer) => videoConsumer.id === consumer.id,
          )
        ) {
          newVideoConsumersArray.push(consumer);
        }
      }
    });

    // Remove any consumers in local state that are no longer in consumers object
    newVideoConsumersArray = newVideoConsumersArray.filter((videoConsumer) =>
      consumers.hasOwnProperty(videoConsumer.id),
    );

    setVideoConsumerArray(newVideoConsumersArray);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumers]);

  const [isVideoFocused, setIsVideoFocused] = useState(false);
  const [isVideoSelectMode, setIsVideoSelectMode] = useState(false);
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;

  let videoContainerStyle = styles.videoContainer;
  if (isVideoFocused && Platform.OS === 'web') {
    videoContainerStyle = styles.focusedWebVideoContainer;
  } else if (isVideoFocused) {
    videoContainerStyle = {
      width: windowWidth,
      height: windowHeight,
      top: -40,
    };
  }

  const isAtLeastOneVideo = videoConsumersArray.length >= 1;
  const isMultipleVideos = videoConsumersArray.length >= 2;

  const [currentSelectedVideo, setCurrentSelectedVideo] = useState(
    isAtLeastOneVideo ? videoConsumersArray[0] : null,
  );

  useEffect(() => {
    if (
      videoConsumersArray.length === 1 &&
      (currentSelectedVideo === null ||
        !videoConsumersArray.includes(currentSelectedVideo))
    ) {
      setCurrentSelectedVideo(videoConsumersArray[0]);
    }
  }, [currentSelectedVideo, videoConsumersArray]);

  const toggleVideoFocused = (videoConsumer) => {
    setIsVideoFocused(!isVideoFocused);
    setCurrentSelectedVideo(videoConsumer);
  };

  const setSelectedVideo = (videoConsumer) => {
    setIsVideoSelectMode(!isVideoSelectMode);
    setCurrentSelectedVideo(videoConsumer);
  };

  const renderVideo = ({item}) => (
    <DoubleClick
      key={item.id}
      doubleTap={() => toggleVideoFocused(item)}
      singleTap={() => setSelectedVideo(item)}>
      <RemoteVideo
        remoteVideoConsumer={item}
        videoContainerStyle={videoContainerStyle}
      />
    </DoubleClick>
  );

  const renderMultipleVideosScrollView = () => (
    <FlatList
      data={videoConsumersArray}
      renderItem={renderVideo}
      keyExtractor={(videoConsumer) => videoConsumer.id}
      horizontal
    />
  );

  const renderMultipleVideoStackIndicator = () => {
    if (
      !isVideoFocused &&
      isMultipleVideos &&
      isAtLeastOneVideo &&
      !isVideoSelectMode &&
      incomingVideoEnabled
    ) {
      return (
        <>
          <View style={styles.videoStackRightContainer} />
          <View style={styles.videoStackBottomContainer} />
        </>
      );
    } else {
      return null;
    }
  };

  const isMultipleVideosMode =
    isVideoSelectMode &&
    isMultipleVideos &&
    !isVideoFocused &&
    incomingVideoEnabled;

  const isSingleVideoMode =
    (!isVideoSelectMode || isVideoFocused) &&
    isAtLeastOneVideo &&
    currentSelectedVideo &&
    incomingVideoEnabled;

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  const remoteVideoContainerTopPosition =
    currentOrientation !== 'PORTRAIT' ? 0 : 40;
  const remoteVideoContainerLeftPosition =
    currentOrientation === 'LANDSCAPE-LEFT' && hasNotch() ? 33 : 0;

  return (
    <View
      style={[
        styles.container,
        {
          top: remoteVideoContainerTopPosition,
          left: remoteVideoContainerLeftPosition,
        },
      ]}
      pointerEvents="box-none">
      {isMultipleVideosMode ? renderMultipleVideosScrollView() : null}
      {isSingleVideoMode ? (
        <DoubleClick
          doubleTap={() => toggleVideoFocused(currentSelectedVideo)}
          singleTap={() => {
            if (isMultipleVideos) {
              setIsVideoSelectMode(!isVideoSelectMode);
            }
          }}>
          <RemoteVideo
            key={currentSelectedVideo.id}
            remoteVideoConsumer={currentSelectedVideo}
            videoContainerStyle={videoContainerStyle}
          />
        </DoubleClick>
      ) : null}
      {renderMultipleVideoStackIndicator()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    position: 'absolute',
    backgroundColor: '#000000',
  },
  videoContainer: {
    width: 180,
    height: 180,
  },
  focusedWebVideoContainer: {
    width: '100vw',
    height: '100vh',
    top: -40,
  },
  videoStackRightContainer: {
    height: 180,
    width: 10,
    backgroundColor: '#FFFFFF50',
    top: 10,
  },
  videoStackBottomContainer: {
    height: 10,
    width: 170,
    backgroundColor: '#FFFFFF50',
    top: 10,
    left: -180,
  },
});

export default RemoteVideos;
