import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';

import TextOverlay from './TextOverlay';

const TextOverlayManager = (props) => {
  const {loading} = props;
  const overlays = useSelector((state) => state.world.overlays);
  const activeSpeakerId = useSelector(
    (state) => state.communication.room.activeSpeakerId,
  );
  const showFloatingNames = useSelector(
    (state) => state.world.showFloatingNames,
  );

  const getIcon = useCallback(
    (overlay) => {
      switch (overlay.style) {
        case 'name': {
          if (overlay.target === activeSpeakerId) {
            return 'microphone';
          }
          return false;
        }
        default: {
          return false;
        }
      }
    },
    [activeSpeakerId],
  );

  const overlayComps = Object.keys(overlays).map((key) => {
    const overlay = overlays[key];
    return (
      <TextOverlay
        key={key}
        id={key}
        x={overlay.x}
        y={overlay.y}
        z={overlay.z}
        style={overlay.style}
        text={overlay.text}
        icon={getIcon(overlay)}
      />
    );
  });
  // Maybe use 'box-none' for pointerEvents
  return showFloatingNames && !loading ? (
    <View style={styles.container} pointerEvents={'none'}>
      {overlayComps}
    </View>
  ) : null;
};

TextOverlayManager.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
  },
});

export default TextOverlayManager;
