import * as types from './actionTypes';

export const initialState = {
  screen: 'landing',
  dynamicLinkState: 'loading',
  link: undefined,
  isRemoteConfigLoaded: false,
  availableLevels: {},
  levelCacheDirectory: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GO_TO_LANDING: {
      return {...state, screen: 'landing'};
    }

    case types.GO_TO_MEETING: {
      return {...state, screen: 'meeting'};
    }

    case types.GO_TO_DEVICE_CONFIG: {
      return {...state, screen: 'deviceConfig'};
    }

    case types.GO_TO_AVATAR_PREVIEW: {
      return {...state, screen: 'avatarPreview'};
    }

    case types.SET_DYNAMIC_LINK: {
      const {link} = action.payload;
      const screen =
        state.screen === 'landing' && link ? 'avatarPreview' : state.screen;

      return {...state, dynamicLinkState: 'ready', link, screen};
    }

    case types.USE_DYNAMIC_LINK: {
      return {...state, dynamicLinkState: 'used'};
    }

    case types.SET_REMOTE_CONFIG_LOADED: {
      return {...state, isRemoteConfigLoaded: true};
    }

    case types.SET_ALL_AVAILABLE_LEVELS: {
      return {...state, availableLevels: action.payload};
    }

    case types.ADD_AVAILABLE_LEVEL: {
      return {
        ...state,
        availableLevels: {...state.availableLevels, ...action.payload},
      };
    }

    case types.UPDATE_AVAILABLE_LEVEL: {
      const {level, data} = action.payload;
      return {
        ...state,
        availableLevels: {...state.availableLevels, [level]: {...data}},
      };
    }

    case types.REMOVE_AVAILABLE_LEVEL: {
      const level = action.payload;
      let newLevels = state.availableLevels;
      delete newLevels[level];
      return {...state, availableLevels: {...newLevels}};
    }

    case types.SET_LEVEL_CACHE_DIRECTORY: {
      return {...state, levelCacheDirectory: action.payload};
    }

    default:
      return state;
  }
};

export default reducer;
