import React, {useState} from 'react';

import ViewboardSelectContent from './ViewboardSelectContent';
import ViewboardSelect from './ViewboardSelect';

export const stepOptions = {
  selectContent: 'selectContent',
  selectViewboard: 'selectViewboard',
};

const ViewboardShareFlow = () => {
  const [currentStep, setCurrentStep] = useState(stepOptions.selectContent);

  switch (currentStep) {
    case stepOptions.selectContent:
      return <ViewboardSelectContent setCurrentStep={setCurrentStep} />;
    case stepOptions.selectViewboard:
      return <ViewboardSelect setCurrentStep={setCurrentStep} />;
    default:
      return <ViewboardSelectContent setCurrentStep={setCurrentStep} />;
  }
};

export default ViewboardShareFlow;
