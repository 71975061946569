import React, {useState, useEffect, useContext} from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import {useSelector} from 'react-redux';
import {showMessage} from 'react-native-flash-message';

import World from 'components/World';
import IconButton from 'components/IconButton';
import {I18nContext} from 'i18n/context/I18nContext';

const FLYING_TIMEOUT_INTERVAL = 35;
let flyingUpTimeout = null;
let flyingDownTimeout = null;

const Emotes = () => {
  const {translate} = useContext(I18nContext);
  const [openEmotes, setOpenEmotes] = useState(false);
  const [isSitting, setIsSitting] = useState(false);
  const [isHandUp, setIsHandUp] = useState(false);
  const [isFlying, setIsFlying] = useState(false);
  const [isLaserOn, setIsLaserOn] = useState(false);

  const location = useSelector((state) => state.world.location);
  const [currentLocation, setCurrentLocation] = useState(location);
  useEffect(() => {
    const clearEmoteState = () => {
      setOpenEmotes(false);
      setIsSitting(false);
      setIsHandUp(false);
      setIsFlying(false);
      setIsLaserOn(false);

      World.runCommand('perform_emote sit false');
      World.runCommand('perform_emote handraise false');
      World.runCommand('perform_emote fly false');
      World.runCommand('perform_emote laser false');
    };

    if (currentLocation !== location) {
      clearEmoteState();
      setCurrentLocation(location);
    }
  }, [currentLocation, location]);

  const toggleEmotes = () => {
    setOpenEmotes(!openEmotes);
  };

  const toggleEmotesLongPress = () => {
    showMessage({
      message: translate('emotes.emoteDesc'),
      hideStatusBar: Platform.OS === 'android' ? true : false,
      type: 'info',
      icon: 'auto',
      duration: 4000,
    });
  };

  const toggleFlying = () => {
    setIsFlying(!isFlying);
    World.runCommand(`perform_emote fly ${!isFlying}`);
  };

  const toggleSitting = () => {
    setIsSitting(!isSitting);
    World.runCommand(`perform_emote sit ${!isSitting}`);
  };

  const toggleHandUp = () => {
    setIsHandUp(!isHandUp);
    World.runCommand(`perform_emote handraise ${!isHandUp}`);
  };

  const onJump = () => {
    World.runCommand('perform_emote jump');
  };

  const toggleLaser = () => {
    setIsLaserOn(!isLaserOn);
    World.runCommand(`perform_emote laser ${!isLaserOn}`);
  };

  const onFlyUp = () => {
    World.runCommand('perform_emote altitude true');
    flyingUpTimeout = setTimeout(onFlyUp, FLYING_TIMEOUT_INTERVAL);
  };

  const onStopFlyUp = () => {
    clearTimeout(flyingUpTimeout);
  };

  const onFlyDown = () => {
    World.runCommand('perform_emote altitude false');
    flyingDownTimeout = setTimeout(onFlyDown, FLYING_TIMEOUT_INTERVAL);
  };

  const onStopFlyDown = () => {
    clearTimeout(flyingDownTimeout);
  };

  const ON_COLOR = '#19B5FE';
  const OFF_COLOR = '#858282';

  const emotesColor = openEmotes ? ON_COLOR : OFF_COLOR;
  const sittingColor = isSitting ? ON_COLOR : OFF_COLOR;
  const handUpColor = isHandUp ? ON_COLOR : OFF_COLOR;
  const flyingColor = isFlying ? ON_COLOR : OFF_COLOR;
  const laserColor = isLaserOn ? ON_COLOR : OFF_COLOR;

  return (
    <View>
      {openEmotes ? (
        <>
          <IconButton
            name="airplane"
            color={flyingColor}
            onPress={toggleFlying}
          />
          <IconButton
            name="hand-pointing-up"
            color={laserColor}
            onPress={toggleLaser}
          />
          <IconButton
            name="seat"
            color={sittingColor}
            onPress={toggleSitting}
          />
          <IconButton
            name="human-greeting"
            color={handUpColor}
            onPress={toggleHandUp}
          />
          {!isFlying && (
            <IconButton
              name="chevron-double-up"
              style={styles.jumpButton}
              color={OFF_COLOR}
              onPress={onJump}
            />
          )}
        </>
      ) : null}
      {isFlying ? (
        <>
          <IconButton
            name="airplane-takeoff"
            style={styles.flyUpButton}
            color={ON_COLOR}
            onPressIn={onFlyUp}
            onPressOut={onStopFlyUp}
          />
          <IconButton
            name="airplane-landing"
            style={styles.flyDownButton}
            color={ON_COLOR}
            onPressIn={onFlyDown}
            onPressOut={onStopFlyDown}
          />
        </>
      ) : null}
      <IconButton
        onPress={toggleEmotes}
        onLongPress={toggleEmotesLongPress}
        name="walk"
        style={styles.tray}
        color={emotesColor}
      />
    </View>
  );
};

export default Emotes;

const styles = StyleSheet.create({
  jumpButton: {position: 'absolute', left: 60, bottom: 0},
  flyUpButton: {position: 'absolute', left: 60, bottom: 0},
  flyDownButton: {position: 'absolute', left: 120, bottom: 0},
});
