import {StyleSheet} from 'react-native';

const shareMeetingModalStyle = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    maxWidth: '90%',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
  },
  headerContainerTwo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    paddingVertical: 15,
    paddingLeft: 15,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#202124',
  },
  headerTextTwo: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#202124',
    textAlign: 'left',
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'lightgray',
  },
  shareText: {
    width: '100%',
    paddingHorizontal: 20,
    marginVertical: 15,
    color: '#202124AB',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '100%',
  },
  linkText: {
    width: '100%',
    paddingHorizontal: 20,
    marginBottom: 15,
    textAlign: 'left',
    fontSize: 14,
    color: '#202124',
  },
  copyInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 30,
    paddingBottom: 20,
  },
  copyInfoText: {
    color: '#3C4043',
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold',
  },
  shareButtonWrapper: {
    marginTop: 5,
    marginBottom: 20,
  },
  shareButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
    backgroundColor: '#1b73e4',
    color: 'white',
    borderRadius: 8,
  },
  shareButtonIcon: {fontSize: 30, paddingRight: 6, paddingLeft: 10},
  shareButtonText: {fontSize: 18, marginRight: 10, color: 'white'},
  closeIcon: {
    fontSize: 30,
    color: '#3C4043',
    position: 'absolute',
    right: 10,
    top: 10,
  },
  copyIcon: {
    fontSize: 28,
    marginRight: 15,
    color: '#3C4043',
  },
});

export default shareMeetingModalStyle;
