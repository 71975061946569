import React, {useState, useContext} from 'react';
import {
  Modal,
  Text,
  TouchableHighlight,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {
  selectCamera,
  setSelectedCameraDeviceId,
  setSelectedMicrophoneDeviceId,
  selectMicrophone,
  updateAvailableDevices,
} from 'store/media';
import AudioMonitor from 'components/Configs/AudioMonitor';
import VideoContainer from 'components/Communication/VideoContainer';
import DeviceSelectionPicker from './DeviceSelectionPicker';
import {logEvent} from 'utils/firebase/analytics';
import {OPEN_DEVICE_SELECTION_MODAL} from 'utils/firebase/analytics.config';
import {I18nContext} from 'i18n/context/I18nContext';

import {MICROPHONE_VOLUME_INDICATOR} from 'style/theme';
import {styles} from './deviceSelectionModalStyles';

const DeviceSelectionModal = () => {
  const {translate} = useContext(I18nContext);
  const dispatch = useDispatch();
  const availableDevices = useSelector((state) => state.media.availableDevices);
  const micEnabled = useSelector((state) => state.media.micEnabled);
  const micStream = useSelector((state) => state.media.micStream);
  const camStream = useSelector((state) => state.media.camStream);
  const mirrorCamera = useSelector((state) => state.media.mirrorCamera);
  const cameraEnabled = useSelector((state) => state.media.cameraEnabled);
  const selectedCameraDeviceId = useSelector(
    (state) => state.media.selectedCameraDeviceId,
  );
  const selectedMicrophoneDeviceId = useSelector(
    (state) => state.media.selectedMicrophoneDeviceId,
  );
  const [modalVisible, setModalVisible] = useState(false);

  const changeCamera = (deviceId) => {
    dispatch(setSelectedCameraDeviceId(deviceId));
    dispatch(selectCamera(deviceId));
    dispatch(updateAvailableDevices());
  };

  const changeMicrophone = (deviceId) => {
    dispatch(setSelectedMicrophoneDeviceId(deviceId));
    dispatch(selectMicrophone(deviceId));
    dispatch(updateAvailableDevices());
  };

  const openDeviceSelectionModal = () => {
    setModalVisible(true);

    logEvent(OPEN_DEVICE_SELECTION_MODAL);
  };

  const hasAvailableMicrophones =
    availableDevices?.microphones && availableDevices?.microphones?.length > 0;
  const hasAvailableCameras =
    availableDevices?.cameras && availableDevices?.cameras?.length > 0;

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        supportedOrientations={['portrait']}>
        <TouchableWithoutFeedback
          onPress={() => {
            setModalVisible(false);
          }}>
          <View style={styles.centeredView}>
            <TouchableWithoutFeedback>
              <View style={styles.modalView}>
                <Text style={styles.modalHeader}>
                  <Icon style={styles.modalIcon} name="speaker" />
                  {translate('deviceSelectionModalWeb.audio')}
                </Text>
                <Text style={styles.modalText}>
                  {translate('deviceSelectionModalWeb.microphone')}
                </Text>
                {hasAvailableMicrophones && (
                  <DeviceSelectionPicker
                    selectedValue={
                      selectedMicrophoneDeviceId
                        ? selectedMicrophoneDeviceId
                        : availableDevices?.microphones[0].deviceId
                    }
                    onValueChange={(deviceId) => changeMicrophone(deviceId)}
                    pickerItemObjects={availableDevices?.microphones}
                  />
                )}
                {hasAvailableMicrophones ? null : (
                  <Text style={styles.modalText}>
                    {translate('deviceSelectionModalWeb.noMics')}
                  </Text>
                )}
                {micEnabled ? (
                  <View style={styles.volumeTray}>
                    <Icon
                      style={styles.micEnabledIcon}
                      name="microphone-outline"
                    />
                    <AudioMonitor
                      style={MICROPHONE_VOLUME_INDICATOR}
                      stream={micStream}
                    />
                  </View>
                ) : (
                  <Text style={styles.modalText}>
                    {translate('deviceSelectionModalWeb.micOff')}
                  </Text>
                )}

                <Text style={[styles.modalHeader, styles.topMargin]}>
                  <Icon style={styles.modalIcon} name="video" />
                  {translate('deviceSelectionModalWeb.video')}
                </Text>
                <Text style={styles.modalText}>
                  {translate('deviceSelectionModalWeb.camera')}
                </Text>
                {hasAvailableCameras && (
                  <DeviceSelectionPicker
                    selectedValue={
                      selectedCameraDeviceId
                        ? selectedCameraDeviceId
                        : availableDevices?.cameras[0].deviceId
                    }
                    onValueChange={(deviceId) => changeCamera(deviceId)}
                    pickerItemObjects={availableDevices?.cameras}
                  />
                )}
                {hasAvailableCameras ? null : (
                  <Text style={styles.modalText}>
                    {translate('deviceSelectionModalWeb.noCameras')}
                  </Text>
                )}
                {cameraEnabled ? (
                  <VideoContainer
                    style={styles.videoPane}
                    stream={camStream}
                    pointerEvents="box-none"
                    waiting={false}
                    mirror={mirrorCamera}
                  />
                ) : (
                  <Text style={[styles.modalText, styles.topMargin]}>
                    {translate('deviceSelectionModalWeb.cameraOff')}
                  </Text>
                )}

                <TouchableHighlight
                  style={[styles.openButton, styles.topMargin]}
                  onPress={() => {
                    setModalVisible(!modalVisible);
                  }}>
                  <Text style={styles.textStyle}>
                    {translate('shared.close')}
                  </Text>
                </TouchableHighlight>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      <TouchableHighlight
        style={styles.openButton}
        onPress={openDeviceSelectionModal}>
        <Text style={styles.textStyle}>
          {translate('deviceSelectionModalWeb.audioVideo')}
        </Text>
      </TouchableHighlight>
    </View>
  );
};

export default DeviceSelectionModal;
