import React from 'react';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {StyleSheet} from 'react-native';

const NotificationIcon = (props) => {
  return (
    <Icon type="MaterialCommunityIcons" style={[styles.iconArea]} name="chat" />
  );
};

const styles = StyleSheet.create({
  iconArea: {
    fontSize: 26,
    color: '#ffffff',
    margin: 10,
  },
});

export default NotificationIcon;
