export const LANGUAGE_ENGLISH_DESC = 'English';
export const LANGUAGE_SPANISH_DESC = 'Español';
export const LANGUAGE_ENGLISH_CODE = 'en';
export const LANGUAGE_SPANISH_CODE = 'es';
export const LANGUAGE_FALLBACK_DESC = LANGUAGE_ENGLISH_DESC;
export const LANGUAGE_FALLBACK_CODE = LANGUAGE_ENGLISH_CODE;

const LANGUAGE_DICT = Object.freeze({
  LANGUAGE_ENGLISH_DESC: LANGUAGE_ENGLISH_CODE,
  LANGUAGE_SPANISH_DESC: LANGUAGE_SPANISH_CODE,
});

export const langDescToCode = (langDesc) => {
  return LANGUAGE_DICT[langDesc] ?? LANGUAGE_FALLBACK_CODE;
};

export const langCodeToDesc = (langCode) => {
  return (
    Object.keys(LANGUAGE_DICT).find((key) => LANGUAGE_DICT[key] === langCode) ??
    LANGUAGE_FALLBACK_DESC
  );
};
