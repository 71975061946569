import * as types from './actionTypes';

export const setToken = (token) => {
  return {
    type: types.SET_TOKEN,
    payload: token,
  };
};

export const setIsMeetingDisrupted = (isMeetingDisrupted) => {
  return {
    type: types.SET_IS_MEETING_DISRUPTED,
    payload: isMeetingDisrupted,
  };
};

export const setIsMeetingValid = (isMeetingValid) => {
  return {
    type: types.SET_IS_MEETING_VALID,
    payload: isMeetingValid,
  };
};

export const setMeetingMaxNumberParticipants = (
  meetingMaxNumberParticipants,
) => {
  return {
    type: types.SET_MEETING_MAX_NUMBER_PARTICIPANTS,
    payload: meetingMaxNumberParticipants,
  };
};

export const setLastMeetingId = (lastMeetingId) => {
  return {
    type: types.SET_LAST_MEETING_ID,
    payload: lastMeetingId,
  };
};

export const clearMeetingProperties = () => {
  return {
    type: types.CLEAR_MEETING_PROPERTIES,
  };
};

export const setLocations = (locations) => {
  return {
    type: types.SET_LOCATIONS,
    payload: {locations},
  };
};

export const setInviteURL = (url) => {
  return {
    type: types.SET_INVITE_URL,
    payload: {url},
  };
};
