import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {ScrollView, Platform, Alert} from 'react-native';

import {setLocation} from 'store/world/actions';
import {downloadLevel, CACHE_LEVEL_STATUS} from 'store/app/cacheActions';
import {getResourceIDFromLocation} from 'utils/helpers';
import {logEvent} from 'utils/firebase/analytics';
import {TELEPORT, DOWNLOAD} from 'utils/firebase/analytics.config';
import WorldListItem from 'screens/Meeting/components/SideBar/Navigation/WorldListItem';
import {I18nContext} from 'i18n/context/I18nContext';
import {setAvatarLoading} from 'store/appearance';

const Navigation = (props) => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const locations = useSelector((state) => state.meeting.properties.locations);
  const availableLevels = useSelector((state) => state.app.availableLevels);
  const [worlds, setWorlds] = useState(locations);

  useEffect(() => {
    setWorlds(locations);
  }, [locations]);

  const {closeSidebar} = props;

  const teleport = async (location, worldName) => {
    // set avatar state to loading so the UI behaves correctly
    dispatch(setAvatarLoading(true));
    const sanitizedLocation = getResourceIDFromLocation(location, worlds);

    if (Platform.OS === 'web' || sanitizedLocation in availableLevels) {
      dispatch(setLocation(location));
      closeSidebar();

      await logEvent(TELEPORT, {location: location});
    } else {
      Alert.alert(
        translate('navigation.downloadWorld', {worldName}),
        translate('navigation.downloadWorldPrompt', {worldName}),
        [
          {
            text: translate('shared.cancel'),
          },
          {
            text: translate('navigation.download'),
            onPress: async () => {
              dispatch(downloadLevel(location, closeSidebar, worldName));
              await logEvent(DOWNLOAD, {location: location});
            },
          },
        ],
      );
    }
  };

  return (
    <ScrollView>
      {worlds?.map((worldInfo) => {
        const isLevelAvailable =
          !(worldInfo.resourceID in availableLevels) && Platform.OS !== 'web';
        const isLevelDownloading =
          availableLevels[worldInfo.resourceID]?.status ===
          CACHE_LEVEL_STATUS.DOWNLOADING;

        return (
          <WorldListItem
            key={worldInfo.id}
            worldInfo={worldInfo}
            teleport={teleport}
            isLevelAvailable={isLevelAvailable}
            isLevelDownloading={isLevelDownloading}
          />
        );
      })}
    </ScrollView>
  );
};

Navigation.defaultProps = {
  closeSidebar: () => {},
};

Navigation.propTypes = {
  closeSidebar: PropTypes.func,
};

export default Navigation;
