import {Platform, NativeEventEmitter, NativeModules} from 'react-native';
import Orientation from 'react-native-orientation-locker';
import {isTablet} from 'react-native-device-info';
import RNCallKeep from 'react-native-callkeep';
import {v4 as uuidv4} from 'uuid';

import {clearWorld} from 'store/world/actions';
import {clearViewboards} from 'store/viewboard';
import {clearMessages} from 'store/communication/actions/chatActions';
import {
  setIsMeetingDisrupted,
  setLastMeetingId,
} from 'store/meeting/actions/properties';
import {goToLanding} from 'store/app';
import {updateAvailableDevices} from 'store/media';
import RoomClient from 'utils/communication/RoomClient';
import DeviceInfo from 'utils/communication/DeviceInfo';
import * as InCallWrapper from 'utils/communication/InCallWrapper';
import {VerifyAndParseToken} from 'utils/communication/Token';
import {logEvent} from 'utils/firebase/analytics';
import {
  JOIN_MEETING,
  LEAVE_MEETING,
  END_MEETING_FOR_ALL,
  HEADSET_DISCONNECT,
} from 'utils/firebase/analytics.config';

const {InCallManager} = NativeModules;
const incallManagerEmitter = new NativeEventEmitter(InCallManager);

export const joinRoom = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {token} = state.meeting.properties;

    // Verify token (reject/return to previous state if invalid)
    const verifiedToken = VerifyAndParseToken(token);
    if (verifiedToken.hasOwnProperty('error')) {
      console.error(verifiedToken.error);
    }

    // Set peerId and roomId from token
    const {peerId, roomId, communicationServer} = verifiedToken;

    // Display name should be received from server since it would come from the userInfoURL request (which needs to be signed)
    let displayName = peerId;
    const handler = Platform.OS === 'web' ? undefined : 'ReactNative';
    const useSimulcast = true;
    const useSharingSimulcast = true;
    const forceTcp = false;
    const produce = true;
    const consume = true;
    const forceH264 = false;
    const forceVP9 = false;
    const svc = undefined;
    const datachannel = true;

    let device = DeviceInfo;

    const callId = uuidv4();

    const roomClient = new RoomClient({
      token,
      callId,
      roomId,
      peerId,
      displayName,
      device,
      handlerName: handler,
      useSimulcast,
      useSharingSimulcast,
      forceTcp,
      produce,
      consume,
      forceH264,
      forceVP9,
      svc,
      datachannel,
      communicationServer,
    });

    if (Platform.OS === 'ios') {
      try {
        const options = {
          ios: {
            appName: '3Dmeet',
          },
        };

        RNCallKeep.setup(options);

        RNCallKeep.addEventListener('endCall', () => {
          dispatch(setIsMeetingDisrupted(true));
          dispatch(setLastMeetingId(roomId));
          dispatch(leaveRoom());
          dispatch(goToLanding());
        });

        // TODO: Use room display name as contact identifier
        RNCallKeep.startCall(callId, roomId, roomId, 'generic', true);
      } catch (error) {
        console.error(error);
      }
    }

    InCallWrapper.start({media: 'video', auto: true});
    InCallWrapper.setKeepScreenOn(true);
    InCallWrapper.setForceSpeakerphoneOn(true);
    incallManagerEmitter.addListener('WiredHeadset', (data) => {
      if (data && !data.isPlugged) {
        InCallWrapper.setForceSpeakerphoneOn(true);
        logEvent(HEADSET_DISCONNECT, {meeting_id: roomId});
      }

      dispatch(updateAvailableDevices());
    });

    dispatch(updateAvailableDevices());

    await logEvent(JOIN_MEETING, {meeting_id: roomId});
    roomClient.join();

    dispatch({
      type: 'JOIN_ROOM',
      payload: {roomClient},
    });

    // if current device is not a tablet nor android, unlock all orientations on join meeting
    if (!isTablet() && Platform !== 'android') {
      Orientation.unlockAllOrientations();
    }
  };
};

export const leaveRoom = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    if (Platform.OS === 'ios') {
      RNCallKeep.endCall(roomClient.callId);
      RNCallKeep.removeEventListener('endCall');
    }

    let isMeetingValid;
    if (state.meeting.properties.isMeetingValid) {
      isMeetingValid = 'true';
    } else {
      isMeetingValid = 'false';
    }

    await logEvent(LEAVE_MEETING, {
      meeting_id: roomClient.meetingId,
      is_meeting_valid: isMeetingValid,
      meeting_max_number_participants:
        state.meeting.properties.meetingMaxNumberParticipants,
    });
    await roomClient.close();

    InCallWrapper.setForceSpeakerphoneOn(false);
    InCallWrapper.stop();
    incallManagerEmitter.removeAllListeners('WiredHeadset');

    dispatch(clearMessages());
    dispatch(clearViewboards());
    dispatch(clearWorld());
    dispatch(clear());

    // if current device is not a tablet, lock to portrait on leave meeting
    if (!isTablet()) {
      Orientation.lockToPortrait();
    }
  };
};

export const endMeetingForAll = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    await logEvent(END_MEETING_FOR_ALL, {
      meeting_id: roomClient.meetingId,
    });
    await roomClient.endMeetingForAll();
  };
};

export const clear = () => {
  return {
    type: 'CLEAR_ROOM',
  };
};

export const sendWorldMessage = (message) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.sendWorldMessage(message);
  };
};

export const syncWorldPeerState = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.syncAllPeerState();
  };
};

export const sendAppearance = (appearance) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.setAppearance(appearance);
  };
};

export const sendLocation = (location) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.setLocation(location);
  };
};

export const syncPeerSate = (peerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.syncPeerState(peerId);
  };
};
