import React from 'react';
import PropTypes from 'prop-types';
import {View, ActivityIndicator, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
});

const Loading = (props) => {
  const {loading, color} = props;

  const backgroundColor = color ? color : '#fff';

  return loading ? (
    <View style={[styles.container, {backgroundColor}]}>
      <ActivityIndicator size="large" color="#0000ff" />
    </View>
  ) : null;
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  color: PropTypes.string,
};

export default Loading;
