import {isURL} from 'utils/helpers';
import * as types from '../actions/actionTypes';

export const initialState = {
  token: undefined,
  isMeetingDisrupted: false,
  isMeetingValid: false,
  meetingMaxNumberParticipants: 1,
  lastMeetingId: null,
  locations: [],
  inviteURL: undefined,
};

const properties = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN: {
      const token = action.payload;
      return {...state, token};
    }

    case types.SET_IS_MEETING_DISRUPTED: {
      const isMeetingDisrupted = action.payload;
      return {...state, isMeetingDisrupted};
    }

    case types.SET_IS_MEETING_VALID: {
      const isMeetingValid = action.payload;
      return {...state, isMeetingValid};
    }

    case types.SET_MEETING_MAX_NUMBER_PARTICIPANTS: {
      const meetingMaxNumberParticipants = action.payload;
      return {...state, meetingMaxNumberParticipants};
    }

    case types.SET_LAST_MEETING_ID: {
      const lastMeetingId = action.payload;
      return {...state, lastMeetingId};
    }

    case types.CLEAR_MEETING_PROPERTIES: {
      return initialState;
    }

    case types.SET_LOCATIONS: {
      const {locations} = action.payload;
      return {...state, locations};
    }

    case types.SET_INVITE_URL: {
      const {url} = action.payload;
      if (!isURL(url)) {
        return state;
      }

      return {...state, inviteURL: url};
    }

    default:
      return state;
  }
};

export default properties;
