import React, {useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {HexColorPicker} from 'react-colorful';
import {useSelector} from 'react-redux';

export const ColorWheel = ({onChange}) => {
  const userSelectedLooks = useSelector((state) => state.appearance.looks);
  const userSelectedBody = useSelector((state) => state.appearance.body);
  const userSelectedCategory = useSelector(
    (state) => state.appearance.category,
  );

  const [color, setColor] = useState(
    userSelectedLooks[userSelectedBody].categories[userSelectedCategory].color,
  );

  return (
    <View style={style.wheelContainer}>
      <TouchableOpacity
        style={style.wheel}
        onPress={() => {
          onChange(color);
        }}
        activeOpacity={1}>
        <HexColorPicker onChange={setColor} color={color} />
      </TouchableOpacity>
    </View>
  );
};

const style = StyleSheet.create({
  wheelContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  wheel: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
});
