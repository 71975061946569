const Spanish = {
  actions: {
    cameraError: 'Error de cámara - ',
    microphoneError: 'Error de micrófono - ',
  },
  app: {
    necessary: '<Es esto necesario?>',
    restart: 'Reanudar',
    unexpectedError: 'Ocurrió un error inesperado',
    reported:
      'Hemos informado de esto a nuestro equipo! Intente reiniciar la aplicación.',
  },
  attendance: {
    copiedClipboard: 'Copiado al clipboard!',
    shareLink: 'Compartir enlace 3DMeet',
    waitingForUsers: 'Esperando a otros usuarios',
    joinMeeting: 'Unase a mi reunión en 3Dmeet!\n{{inviteURL}}',
  },
  attendanceContent: {
    recall: 'Traer los participantes',
    teleport: 'Teletransportarse a',
  },
  avatarPreview: {
    join: 'Unirse a la reunión',
    joinMeeting: 'Personaliza Tu Personaje',
    traySpacer:
      'Seleccione una opción en la barra lateral para personalizar diferentes partes de su avatar',
    headSelfie: 'Toma Selfie',
    headPicture: 'Elegir Foto de Archivo',
    headPreset: 'Cabezas Prefabricadas',
    headSave: 'Salvar',
    headCapture: 'Capturar foto',
    loadingWebcam: 'Conexión a la Cámara',
    loadingTime:
      'Esta actualización puede tardar un poco. Gracias por su paciencia.\n\n[cerrar]',
    avatarUpdateProblem:
      'There was an issue updating your avatar. You have been given a default avatar appearance due to this issue. Please try your avatar update again.\n\n[cerrar]',
    imageFlashMessageTitle: 'Tenga en cuenta lo siguiente acerca de su imagen:',
    imageFlashMessageDescription:
      '1) La cara debe ser fácilmente reconocible en la foto (la foto no puede estar borrosa).\n2) La foto debe tener una buena iluminación uniforme, sin sombras oscuras o un brillo demasiado brillante.\n3) El rostro debe mantener una expresión facial neutra o leve sonrisa pero sin abrir la boca. Si los dientes son visibles en la foto de entrada, los resultados serán incorrectos.\n4) La cara debe mirar directamente a la cámara sin girar el cuello ni los ojos.\n5) Recomendamos quitarse las gafas, ya que pueden confundir el software de detección facial.\n\nCuando esté lista para continuar, haga clic a continuación..\n\n[cerrar para continuar]',
    updatesInProgress:
      'El juego está procesando actualizaciones tuyas. Dale un momento para ponerse al día antes de hacer más cambios.\n\n[cerrar]',
    nextBody: 'Próximo',
    previousBody: 'Previo',
    saveAppearance: 'Guardar Avatar',
    startAppearance: 'Cambiar Avatar',
  },
  cacheActions: {
    downloadWorldComplete: '{{worldName}} Descarga completa',
    downloadError1: 'Se produjo un error al descargar el nivel {{level}}.',
    downloadError2:
      'Se produjo un error al descargar el nivel {{level}}. Por favor, compruebe la conexión y vuelva a intentarlo.',
    downloadComplete: 'Descarga completa',
    downloadInvalid: 'La descarga no es válida en: {{levelDownloadUrl}}',
    teleportWorld: 'Te gustaría teletransportarte al mundo {{worldName}}?',
    teleport: 'Teletransportarse',
  },
  connectionError: {
    failed: 'La conexión falló',
  },
  connectionFailureOverlay: {
    poorConnection: 'Mala conexión detectada',
  },
  controlAll: {
    allow: 'Permitir',
    block: 'Bloquear',
    controlAll: 'Controlar todo',
    recallEveryone: 'Traerlos a todos',
  },
  controls: {
    micMuted: 'Tu micrófono se ha silenciado',
    videoDisabled: 'Tu video ha sido inhabilitado',
  },
  deviceConfig: {
    join: 'Unirse a la reunión',
  },
  deviceSelectionModal: {
    attSpeakerOff: 'Intentó apagar el altavoz',
    attSpeakerOn: 'Intentó encender el altavoz',
    audio: 'Audio',
    speaker: 'Altavoz',
    speakerOff: 'Apague el altavoz',
    speakerOn: 'Encienda el altavoz',
  },
  deviceSelectionModalWeb: {
    audioVideo: 'Audio y Video',
    cameraOff: 'La cámara está apagada',
    noCameras: 'No se pudo detectar ninguna cámara disponible',
    noMics: 'No se pudo detectar ningún micrófono disponible',
    micOff: 'El micrófono está apagado',
    audio: 'Audio',
    video: 'Video',
    microphone: 'Micrófono',
    camera: 'Cámara',
  },
  emotes: {
    emoteDesc:
      'Movimientos que puedes realizar, como levantar la mano, sentarte e incluso volar.',
  },
  errorHandler: {
    wrong: 'Algo salió mal',
    tryAgain: 'Intentar otra vez',
  },
  landing: {
    meetingDialog:
      'Reunión en curso: {{lastMeetingId}}. Presione para volver a unirse a la reunión, presione prolongadamente para borrar.',
  },
  languageSwitcher: {
    chooseLanguage: 'Elige el lenguaje',
  },
  leaveMeetingOverlay: {
    confirm: 'Está seguro?',
    endForEveryone: 'Finalizar la reunión para todos',
    end: 'Final',
    leave: 'Salir de la reunión',
    endKickEveryone:
      'Al finalizar la reunión para todos, la totalidad de los participantes seran sacados de la reunión actual.',
  },
  linkActions: {
    invalidToken: 'Token no valido',
    userNum: 'Usuario #{{num}}',
  },
  linkActionsWeb: {
    clickForSupport:
      'Enlace de reunión no válido: haga clic aquí para obtener ayuda.',
  },
  liveTranslation: {
    clearTranslations: 'Claro',
    hideOriginal: 'ocultar originales',
    showOriginal: 'mostrar original',
    liveTranslation: 'Traducción en Vivo',
    refreshTranslations: 'actualizar',
  },
  mediaManager: {
    noCam: 'Sin dispositivos de cámara',
    noMic: 'Sin dispositivos de micrófono',
  },
  meeting: {
    navigation: 'Navegación',
    people: 'Personas',
    settings: 'Ajustes',
    viewboards: 'Tableros',
    messages: 'Mensajes',
    avatar: 'Personaje',
    bodyType: 'Cuerpo',
  },
  messageBubble: {
    copyText: '📄 Copiar texto',
    deleteMsg: '🗑 Borrar mensaje',
  },
  messages: {
    chatDisabled: 'El chat está deshabilitado',
    sendMsgAll: 'Envíe un mensaje a todos',
  },
  navigation: {
    downloadWorld: 'Descargar {{worldName}}',
    downloadWorldPrompt: 'Te gustaría descargar el mundo {{worldName}}?',
    download: 'Descargar',
  },
  perspectiveSwitcher: {
    changePerspective: 'Cambiar perspectiva',
    first: 'Primera persona',
    overShoulder: 'Por encima del hombro',
    third: 'Tercera persona',
  },
  positionalModeSettings: {
    fallOff: 'Caída de la voz',
    positional: 'Modo posicional',
    bubbleArea: 'Mostrar área de burbuja',
    bubble: 'burbuja',
    world: 'mundo',
    global: 'global',
  },
  recorderWeb: {
    keepRecordingPrompt: 'Seguir grabando o no?',
    startRecording: 'Iniciar la grabación',
    stopRecording: 'Pare de grabar',
    record: 'Grabar',
    stop: 'detener',
  },
  recordingStatus: {
    meetingRecording: 'La reunión se está grabando',
  },
  recordMeeting: {
    record: 'Grabar',
  },
  refreshMeetingConfigsButton: {
    errorRefreshMeeting:
      'Se produjo un error al intentar actualizar la configuración de la reunión..',
    refreshConfigs: 'Actualizar las configuraciones de la reunión',
    confirmreRefreshedConfigs:
      'Se actualizaron las configuraciones de su reunión.',
  },
  broadcastAppearanceButton: {
    buttonText: 'Enviar apariencia',
    flashMessage:
      'Actualmente tiene personalizaciones de avatar en proceso. Danos un minuto para actualizar tu avatar antes de transmitir tu aparición.',
  },
  settingsDialogueOverlay: {
    openSettings: 'Configuración abierta',
    showInstructions: 'Mostrar instrucciones',
    cameraMicEnablePrompt:
      'Debe habilitar los permisos de micrófono y cámara en la configuración de la aplicación.',
    sitePermissionPrompt:
      'Debe dar permiso a este sitio para usar el micrófono y la cámara.',
  },
  shared: {
    cancel: 'Cancelar',
    close: 'Cerrar',
  },
  shareMeetingModal: {
    copyJoinInfo: 'Copiar información para unirse',
    joinMeeting: 'Unase a mi reunión de 3DMeet {{inviteURL}',
    share: 'Compartir',
    shareInvite: 'Compartir invitación',
    shareWithOthers:
      'Comparta esta información para unirse con otras personas que desee en la reunión',
    firstOneHere: 'Tu eres el primero aqui',
  },
  startMeetingButton: {
    buttonText: 'Iniciar reunión',
  },
  startMeetingButtonWeb: {
    buttonText: 'Iniciar reunión',
  },
  translationSwitcher: {
    default: 'lengua de llegada',
    en: 'Inglés',
    zh: 'Chino',
    fr: 'Francés',
    de: 'Alemán',
    ru: 'Ruso',
    es: 'Español',
  },
  viewboardAddInstanceContentButton: {
    shareYT: 'Compartir video de YouTube',
  },
  viewboardAddInstanceContentModal: {
    error: 'Se ha producido un error',
    enterLink: 'Ingrese el enlace aquí',
    enterYTLink: 'Ingrese el enlace de video de YouTube',
    errorContent:
      'Se produjo un error al intentar agregar el contenido de la instancia',
    invalidLink: 'Se proporcionó un vínculo no válido',
    videoAdded: 'Su video se agregó correctamente.',
    clear: 'Quitar',
    submit: 'Enviar',
  },
  viewboardContent: {
    contentSlideshow:
      'El contenido es una presentación de diapositivas, que puede estar compuesta por imágenes y videos',
    contentVideo: 'El contenido es un video',
    contentImage: 'El contenido es una imagen',
  },
  viewboardDashboard: {
    activeViewboard: 'Tableros activo',
    activeViewboards: 'Tableros activos',
    noActiveViewboard: 'Sin Tableros activos',
  },
  viewboardSelect: {
    selectViewboard: 'Seleccione el Tablero',
    viewboardDisplay: 'Tablero {{num}}',
  },
  viewboardSelectContent: {
    noContent: 'No hay contenido disponible',
    selectContent: 'Seleccionar contenido',
  },
  viewboardShareButton: {
    shareViewboard: 'Compartir en Tablero',
  },
  viewboardUploadContentButton: {
    errorUpload: 'Se produjo un error al intentar cargar su archivo.',
    uploadContent: 'Cargar contenido',
    uploadSuccess: 'Su archivo se cargó correctamente.',
    meeting: 'reunión',
  },
  viewboardUploadContentButtonWeb: {
    errorUpload: 'Se produjo un error al intentar cargar su archivo.',
    uploadContent: 'Cargar contenido',
    uploadSuccess: 'Su archivo se cargó correctamente.',
    meeting: 'reunión',
  },
  worldSettingsListItems: {
    disableVideos: 'Desactivar videos',
    floatingName: 'Nombres flotantes',
    lowPower: 'Modo de bajo consumo',
    commServerLabel: 'Servidor',
  },
};

export default Spanish;
