import React from 'react';
import {useSelector} from 'react-redux';

import {screenOptions} from 'store/viewboard';
import ViewboardDashboard from './ViewboardDashboard';
import ViewboardShareFlow from './ViewboardShareFlow';

const Viewboards = () => {
  const screen = useSelector((state) => state.viewboard.screen);

  switch (screen) {
    case screenOptions.dashboard:
      return <ViewboardDashboard />;
    case screenOptions.share:
      return <ViewboardShareFlow />;
    default:
      return <ViewboardDashboard />;
  }
};

export default Viewboards;
