import React, {useState, useContext} from 'react';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {I18nContext} from 'i18n/context/I18nContext';
import ViewboardAddInstanceContentModal from './ViewboardAddInstanceContentModal';
import {SIDE_PANEL_VIEWBOARDS_COLOR} from 'style/theme';

const ViewboardAddInstanceContentButton = () => {
  const {translate} = useContext(I18nContext);

  const [
    isViewboardAddInstanceContentModalVisible,
    setIsViewboardAddInstanceContentModalVisible,
  ] = useState(false);

  const openViewboardAddInstanceContentModal = () => {
    setIsViewboardAddInstanceContentModalVisible(true);
  };

  return (
    <>
      <TouchableOpacity onPress={openViewboardAddInstanceContentModal}>
        <View style={styles.buttonContainer}>
          <Icon
            type="MaterialCommunityIcons"
            name="link-plus"
            style={styles.buttonIcon}
            color="white"
          />
          <Text style={styles.buttonText}>
            {translate('viewboardAddInstanceContentButton.shareYT')}
          </Text>
        </View>
      </TouchableOpacity>
      <ViewboardAddInstanceContentModal
        isViewboardAddInstanceContentModalVisible={
          isViewboardAddInstanceContentModalVisible
        }
        setIsViewboardAddInstanceContentModalVisible={
          setIsViewboardAddInstanceContentModalVisible
        }
      />
    </>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    backgroundColor: SIDE_PANEL_VIEWBOARDS_COLOR,
    color: 'white',
    borderRadius: 50,
    maxWidth: '98%',
  },
  buttonIcon: {fontSize: 30, paddingRight: 6, paddingLeft: 8},
  buttonText: {
    fontSize: 14,
    marginRight: 10,
    paddingRight: 8,
    color: 'white',
    fontWeight: 'bold',
  },
});

export default ViewboardAddInstanceContentButton;
