import {StyleSheet, Platform, StatusBar} from 'react-native';

const styles = StyleSheet.create({
  world: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  safeUI: {
    flex: 1,
    paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
  },
});

export default styles;
