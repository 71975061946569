import {logEvent} from 'utils/firebase/analytics';
import {
  RECORD_MEETING,
  END_RECORD_MEETING,
} from 'utils/firebase/analytics.config';
import * as types from './actionTypes';

export const setRecording = (recording) => {
  return {
    type: types.SET_RECORDING,
    payload: recording,
  };
};

export const setIsBeingRecorded = (beingRecorded) => {
  return {
    type: types.SET_IS_BEING_RECORDED,
    payload: beingRecorded,
  };
};

export const requestRecording = (platform) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    // TODO: Add meeting ID and integration to meeting redux and pass in here (or move this log elsewhere)
    await logEvent(RECORD_MEETING, {
      meeting_id: roomClient.meetingId,
      platform: platform,
    });

    return await roomClient.requestRecording();
  };
};

export const notifyEndRecording = (platform, reason) => {
  return (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    logEvent(END_RECORD_MEETING, {
      meeting_id: roomClient.meetingId,
      platform,
      reason,
    });

    roomClient.notifyEndRecording();
  };
};

export const setRecordingElements = (
  audioContext,
  audioDestination,
  whiteNoiseSource,
  screenCaptureStream,
  recorder,
) => {
  return {
    type: types.SET_RECORDING_ELEMENTS,
    payload: {
      audioContext,
      audioDestination,
      screenCaptureStream,
      recorder,
      whiteNoiseSource,
    },
  };
};
