import * as types from 'store/communication/actions/actionTypes';
import {ROOM_STATE} from 'utils/communication/utils';
import {convertMessageToTransform} from 'utils/World/Transform';

export const initialState = {
  id: null,
  displayName: null,
  displayNameSet: false,
  device: null,
  canSendMic: false,
  canSendWebcam: false,
  canChangeWebcam: false,
  webcamInProgress: false,
  shareInProgress: false,
  audioOnly: false,
  audioOnlyInProgress: false,
  audioMuted: false,
  restartIceInProgress: false,
  muted: {
    audio: false,
    video: false,
    chat: false,
  },
  photoURL: 'https://ui-avatars.com/api/?size=120&length=1&name=U',
  permissions: [],
};

const me = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOM_STATE': {
      const roomState = action.payload.state;

      if (roomState === ROOM_STATE.closed) {
        return {
          ...state,
          webcamInProgress: false,
          shareInProgress: false,
          audioOnly: false,
          audioOnlyInProgress: false,
          audioMuted: false,
          restartIceInProgress: false,
        };
      } else {
        return state;
      }
    }

    case types.SET_ME: {
      const {
        peerId,
        displayName,
        displayNameSet,
        device,
        permissions,
      } = action.payload;

      return {
        ...state,
        id: peerId,
        displayName,
        displayNameSet,
        device,
        permissions,
      };
    }

    case types.SET_ME_INFO: {
      const {
        peerId,
        displayName,
        appearance,
        photoURL,
        permissions,
      } = action.payload;

      // Issues arise if we assign peerId to id, so we store it elsewhere so we can use that if needed
      const meState = {
        peerId,
      };
      if (
        displayName &&
        typeof displayName !== 'undefined' &&
        displayName !== ''
      ) {
        meState.displayName = displayName;
        meState.displayNameSet = true;
      }
      if (appearance && typeof appearance !== 'undefined') {
        meState.appearance = appearance;
      }
      if (photoURL && typeof photoURL !== 'undefined' && photoURL !== '') {
        meState.photoURL = photoURL;
      }
      if (permissions && typeof permissions !== 'undefined') {
        meState.permissions = permissions;
      }

      return {...state, ...meState};
    }

    case 'SET_MEDIA_CAPABILITIES': {
      const {canSendMic, canSendWebcam} = action.payload;

      return {...state, canSendMic, canSendWebcam};
    }

    case 'SET_CAN_CHANGE_WEBCAM': {
      const canChangeWebcam = action.payload;

      return {...state, canChangeWebcam};
    }

    case 'SET_WEBCAM_IN_PROGRESS': {
      const {flag} = action.payload;

      return {...state, webcamInProgress: flag};
    }

    case 'SET_SHARE_IN_PROGRESS': {
      const {flag} = action.payload;

      return {...state, shareInProgress: flag};
    }

    case 'SET_DISPLAY_NAME': {
      let {displayName} = action.payload;

      // Be ready for undefined displayName (so keep previous one).
      if (!displayName) {
        displayName = state.displayName;
      }

      return {...state, displayName, displayNameSet: true};
    }

    case 'SET_AUDIO_ONLY_STATE': {
      const {enabled} = action.payload;

      return {...state, audioOnly: enabled};
    }

    case 'SET_AUDIO_ONLY_IN_PROGRESS': {
      const {flag} = action.payload;

      return {...state, audioOnlyInProgress: flag};
    }

    case 'SET_AUDIO_MUTED_STATE': {
      const {enabled} = action.payload;

      return {...state, audioMuted: enabled};
    }

    case 'SET_RESTART_ICE_IN_PROGRESS': {
      const {flag} = action.payload;

      return {...state, restartIceInProgress: flag};
    }

    case types.SET_ME_CHAT_MUTED: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, chat: muted}};
    }

    case types.SET_ME_AUDIO_MUTED: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, audio: muted}};
    }

    case types.SET_ME_VIDEO_MUTED: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, video: muted}};
    }

    case types.SET_ME_TRANSFORM: {
      const {transform} = action.payload;
      const {payload} = transform;

      const convertedTransform = convertMessageToTransform(payload);
      return {...state, transform: convertedTransform};
    }

    default:
      return state;
  }
};

export default me;
