import React, {useState, useEffect, useContext} from 'react';
import {
  Animated,
  StyleSheet,
  View,
  Text,
  Keyboard,
  Platform,
  useWindowDimensions,
} from 'react-native';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {isTablet, hasNotch} from 'react-native-device-info';
import Orientation from 'react-native-orientation-locker';
import {showMessage} from 'react-native-flash-message';

import {
  setIsMessagePanelOpen,
  setIsMessageNotification,
} from 'store/world/actions';
import IconButton from 'components/IconButton';
import Appearances from 'utils/Appearances';
import {setCategory} from 'store/appearance';
import {I18nContext} from 'i18n/context/I18nContext';

const FULL_WIDTH = Platform.OS === 'web' || isTablet() ? 375 : 300;
const TAB_WIDTH = 56;
const CONTENT_WIDTH = FULL_WIDTH - TAB_WIDTH;
const MESSAGE_PANEL_INDEX = 1;
const CUSTOMIZATION_PANEL_INDEX = 0;

const SidePanel = (props) => {
  const dispatch = useDispatch();

  const {translate} = useContext(I18nContext);

  const isInGame = true;
  const {height, width} = useWindowDimensions();
  const ratio = height / width;

  const isMessagePanelOpen = useSelector(
    (state) => state.world.isMessagePanelOpen,
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [panelPosition] = useState(new Animated.Value(CONTENT_WIDTH));
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  const appearanceLoading = useSelector(
    (state) => state.appearance.avatarLoading,
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  let marginRight = 0;
  const NOTCH_LANDSCAPE_LEFT_MARGIN_RIGHT_OFFSET = -48;
  const NOTCH_LANDSCAPE_RIGHT_MARGIN_RIGHT_OFFSET = -15;
  if (hasNotch() && currentOrientation === 'LANDSCAPE-LEFT') {
    marginRight = NOTCH_LANDSCAPE_LEFT_MARGIN_RIGHT_OFFSET;
  } else if (hasNotch() && currentOrientation === 'LANDSCAPE-RIGHT') {
    marginRight = NOTCH_LANDSCAPE_RIGHT_MARGIN_RIGHT_OFFSET;
  }

  let contentContainerPaddingLeft = 0;
  const NOTCH_LANDSCAPE_RIGHT_PADDING_LEFT_OFFSET = 33;
  if (hasNotch() && currentOrientation === 'LANDSCAPE-RIGHT' && !isPanelOpen) {
    contentContainerPaddingLeft = NOTCH_LANDSCAPE_RIGHT_PADDING_LEFT_OFFSET;
  }

  const {style, children} = props;

  let selected;
  if (Array.isArray(children)) {
    if (selectedIndex < children.length) {
      selected = children[selectedIndex];
    } else {
      [selected] = children;
    }
  } else {
    selected = children;
  }

  const {title, icon, color} = selected.props;

  const openSidebar = () => {
    setIsPanelOpen(true);

    Animated.spring(panelPosition, {
      toValue: 0,
      duration: 1000,
      overshootClamping: true,
      useNativeDriver: true,
    }).start();
  };

  const closeSidebar = () => {
    // reset all the customize avatar settings if changes were made
    dispatch(setCategory({}));
    Appearances.UsePlayerCamera();
    setIsPanelOpen(false);
    dispatch(setIsMessagePanelOpen(false));

    Keyboard.dismiss();

    Animated.spring(panelPosition, {
      toValue: CONTENT_WIDTH,
      duration: 1000,
      overshootClamping: true,
      useNativeDriver: true,
    }).start();
  };

  selected = React.cloneElement(selected, {
    closeSidebar,
  });

  const onButtonPressed = (index) => {
    const isMessagePanelSelected = index === MESSAGE_PANEL_INDEX;
    const isCustomizationPanelSelected = index === CUSTOMIZATION_PANEL_INDEX;

    if (isPanelOpen && index === selectedIndex) {
      closeSidebar();
    } else {
      // if it's the customization panel then update the unity camera
      if (isCustomizationPanelSelected) {
        // if there are already customizations loading in unity then don't change the camera, inform the user.
        if (appearanceLoading) {
          showMessage({
            message: translate('avatarPreview.updatesInProgress'),
            icon: 'auto',
            autoHide: false,
            duration: 3000,
            type: 'info',
            floating: true,
            style: {width: 250},
            titleStyle: {alignSelf: 'center'},
            hideOnPress: true,
            position: 'center',
          });
        } else {
          Appearances.UseCustomizeCamera(ratio, isInGame);
        }
      } else {
        Appearances.UsePlayerCamera();
      }
      // check if it's the message panel
      if (isMessagePanelSelected) {
        dispatch(setIsMessageNotification(false));
      }
      dispatch(setIsMessagePanelOpen(isMessagePanelSelected));
      // update to the new selection
      setSelectedIndex(index);
      openSidebar();
    }
  };

  useEffect(() => {
    if (isMessagePanelOpen) {
      dispatch(setIsMessageNotification(false));
      setSelectedIndex(MESSAGE_PANEL_INDEX);
      openSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagePanelOpen]);

  const buttonList = [];
  if (Array.isArray(children)) {
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i];
      const buttonData = {
        index: i,
        icon: child.props.icon,
        color: child.props.color,
        textBadge: child.props.textBadge,
        iconBadge: child.props.iconBadge,
        badgeStyle: child.props.badgeStyle,
        badgeForegroundColor: child.props.badgeForegroundColor,
        badgeBackgroundColor: child.props.badgeBackgroundColor,
        badgeContent: child.props.badgeContent,
        key: `key${i}`,
      };
      buttonList.push(buttonData);
    }
  }

  return (
    <View style={styles.container} pointerEvents="box-none">
      <View
        style={[{marginRight}, styles.panel, style]}
        pointerEvents="box-none">
        <Animated.View
          style={[
            styles.animatedArea,
            {
              transform: [
                {
                  translateX: panelPosition,
                },
              ],
            },
          ]}
          pointerEvents="box-none">
          <View
            style={[styles.fillUpperNotch, {backgroundColor: color}]}
            pointerEvents="box-none"
          />
          <View style={styles.tabContainer} pointerEvents="box-none" buttons>
            {buttonList.map((info) => {
              return (
                <IconButton
                  name={info.icon}
                  onPress={() => {
                    onButtonPressed(info.index);
                  }}
                  color={info.color}
                  key={info.key}
                  style={styles.tabButton}
                  textBadge={info.textBadge}
                  iconBadge={info.iconBadge}
                  badgeForegroundColor={info.badgeForegroundColor}
                  badgeBackgroundColor={info.badgeBackgroundColor}
                  badgeStyle={info.badgeStyle}
                  badgeContent={info.badgeContent}
                />
              );
            })}
          </View>
          <View
            style={[
              styles.contentContainer,
              {paddingLeft: contentContainerPaddingLeft},
            ]}
            pointerEvents="box-none">
            <View style={[styles.header, {backgroundColor: color}]}>
              <IconButton
                name="arrow-right"
                onPress={() => onButtonPressed(selectedIndex)}
                color="#FFF3"
              />
              <Text style={styles.title}>{title}</Text>
              <Icon
                type="MaterialCommunityIcons"
                name={icon}
                style={styles.headerSelectionIcon}
              />
            </View>
            <View style={styles.content}>{selected}</View>
          </View>
        </Animated.View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    height: 56,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
  fillUpperNotch: {
    position: 'absolute',
    width: CONTENT_WIDTH,
    height: 400,
    top: -400,
    right: 0,
  },
  headerSelectionIcon: {
    color: 'white',
    fontSize: 32,
  },
  panel: {
    position: 'absolute',
    width: FULL_WIDTH,
    height: '100%',
    right: 0,
    top: 0,
    flex: 1,
  },
  content: {
    backgroundColor: 'white',
    flex: 1,
  },
  title: {
    color: 'white',
    fontSize: 22,
  },
  animatedArea: {
    position: 'absolute',
    width: FULL_WIDTH,
    height: '100%',
  },
  tabContainer: {
    position: 'absolute',
    left: 0,
    width: TAB_WIDTH,
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  contentContainer: {
    position: 'absolute',
    left: TAB_WIDTH,
    width: CONTENT_WIDTH,
    height: '100%',
  },
  tabButton: {
    marginTop: 10,
  },
});

SidePanel.defaultProps = {
  style: {},
  children: undefined,
};

SidePanel.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  children: PropTypes.node,
};

export default SidePanel;
