import React from 'react';
import PropTypes from 'prop-types';
import {View, Text, StyleSheet} from 'react-native';

import ViewboardMapLegendIcon from './ViewboardMapLegendIcon';

const Viewboard = ({
  displayName,
  mapLegendNumber,
  shouldDisplayMapLegendIcon,
}) => {
  const viewboardNameTextStyle = shouldDisplayMapLegendIcon ? {flex: 1} : null;

  return (
    <View style={styles.viewboardContainer}>
      <Text
        style={[styles.viewboardNameText, viewboardNameTextStyle]}
        numberOfLines={2}
        ellipsizeMode="middle">
        {displayName}
      </Text>
      {shouldDisplayMapLegendIcon && (
        <View style={styles.viewboardIconContainer}>
          <ViewboardMapLegendIcon mapLegendNumber={mapLegendNumber} />
        </View>
      )}
    </View>
  );
};

Viewboard.defaultProps = {
  shouldDisplayMapLegendIcon: true,
  mapLegendNumber: null,
};

Viewboard.propTypes = {
  displayName: PropTypes.string.isRequired,
  mapLegendNumber: PropTypes.number,
  shouldDisplayMapLegendIcon: PropTypes.bool,
};

const styles = StyleSheet.create({
  viewboardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 50,
  },
  viewboardIconContainer: {
    marginRight: 10,
  },
  viewboardNameText: {
    marginLeft: 10,
    fontSize: 18,
  },
});

export default Viewboard;
