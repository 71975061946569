import React, {useContext} from 'react';
import {StyleSheet, ScrollView, useWindowDimensions} from 'react-native';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import UniversalButton from 'components/UniversalButton';
import IconButton from 'components/IconButton';
import Appearances from 'utils/Appearances';
import {setAppearance, setCategory, setAvatarLoading} from 'store/appearance';
import {
  CHANGE_CUSTOMIZE_AVATAR_RANDOM,
  CHANGED_CUSTOMIZE_AVATAR_RANDOM,
} from 'utils/firebase/analytics.config';
import {logEvent, setUserProperties} from 'utils/firebase/analytics';

const styles = StyleSheet.create({
  button: {margin: 4},
  thumbnailStyles: {height: undefined, width: undefined, flex: 1},
});

const Categories = (props) => {
  const {
    options,
    selectOption,
    scrollViewStyle,
    innerScrollViewStyle,
    showRandomize,
    buttonSizeStyle,
    isInGame,
    disabled,
  } = props;

  const {height, width} = useWindowDimensions();
  const ratio = height / width;

  const {translate} = useContext(I18nContext);

  const userSelectedBody = useSelector((state) => state.appearance.body);
  const userSelectedLooks = useSelector((state) => state.appearance.looks);
  const isFullBody = useSelector((state) => state.appearance.isFullBody);
  const appearanceData = useSelector(
    (state) => state.appearance.appearanceData,
  );

  const dispatch = useDispatch();

  const onReroll = () => {
    // save events for analytics
    logEvent(CHANGE_CUSTOMIZE_AVATAR_RANDOM);
    setUserProperties({[CHANGED_CUSTOMIZE_AVATAR_RANDOM]: 'true'});

    // let the user know this may take a minute
    showMessage({
      message: translate('avatarPreview.loadingTime'),
      icon: 'auto',
      autoHide: true,
      duration: 3000,
      type: 'info',
      floating: true,
      style: {width: 250},
      titleStyle: {alignSelf: 'center'},
      hideOnPress: true,
      position: 'bottom',
    });

    // get a randomized appearance object categories
    const randomLook = Appearances.getRandomAppearance(
      appearanceData,
      userSelectedBody,
    );
    // updates the store look with the new look
    const newLooks = userSelectedLooks;

    newLooks[userSelectedBody].categories = randomLook;

    dispatch(setAppearance(newLooks));

    // set the loading views
    dispatch(setAvatarLoading(true));
    // apply the new look to the avatar
    Appearances.applyLook(
      {categories: randomLook},
      userSelectedBody,
      isFullBody,
    );
    // update store to reset the selections to the default
    dispatch(setCategory({}));
    // reset the camera
    Appearances.restoreCameraPosition(ratio, isInGame);
  };

  return (
    <ScrollView
      horizontal={false}
      showsVerticalScrollIndicator={true}
      centerContent
      fadingEdgeLength={75}
      style={scrollViewStyle}
      contentContainerStyle={innerScrollViewStyle}
      key={options.length}>
      {options.map((option) => {
        const {image, icon, color, key} = option;
        let {text} = option;
        if (image || icon) {
          text = undefined;
        }
        return (
          <UniversalButton
            name={icon}
            imageSource={image}
            onPress={() => selectOption(option.data)}
            color={color}
            key={key}
            text={text}
            style={[styles.button, buttonSizeStyle]}
            thumbnailStyles={styles.thumbnailStyles}
            disabled={disabled}
          />
        );
      })}
      {showRandomize ? (
        <IconButton
          style={styles.button}
          name="dice-multiple"
          onPress={onReroll}
          color="#fe6507"
          disabled={disabled}
        />
      ) : null}
    </ScrollView>
  );
};

Categories.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string,
      icon: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
      ]),
      color: PropTypes.string,
      data: PropTypes.any.isRequired,
    }),
  ).isRequired,
  selectOption: PropTypes.func.isRequired,
  scrollViewStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  innerScrollViewStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  showRandomize: PropTypes.bool,
  buttonSizeStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.string,
  ]),
  isInGameMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Categories;
