import * as types from 'store/communication/actions/actionTypes';
import {ROOM_STATE} from 'utils/communication/utils';
import {convertMessageToTransform} from 'utils/World/Transform';

export const initialState = {};

const EMOTES = Object.freeze([
  'raiseHand',
  'sitOnGround',
  'sitOnChair',
  'point',
  'fly',
]);

const peers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOM_STATE': {
      const roomState = action.payload.state;

      if (roomState === ROOM_STATE.closed) {
        return {};
      } else {
        return state;
      }
    }

    case types.ADD_PEER: {
      const {peer} = action.payload;

      return {...state, [peer.id]: peer};
    }

    case 'REMOVE_PEER': {
      const {peerId} = action.payload;
      const newState = {...state};

      delete newState[peerId];

      return newState;
    }

    case 'SET_PEER_DISPLAY_NAME': {
      const {displayName, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found');
      }

      const newPeer = {...peer, displayName};

      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_PHOTO_URL: {
      const {photoURL, peerId} = action.payload;

      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found');
      }

      const newPeer = {...peer, photoURL};

      return {...state, [newPeer.id]: newPeer};
    }

    case 'SET_PEER_LOCATION': {
      const {location, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found');
      }

      const newPeer = {...peer, location};

      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_TRANSFORM: {
      const {transform, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found');
      }

      const newPeer = {
        ...peer,
        transform: convertMessageToTransform(transform),
      };

      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_EMOTES: {
      const {animations, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found');
      }

      const animationPieces = animations.split('|');
      if (animationPieces.length < 2) {
        console.error('Animation message malformed');
        return state;
      }

      // Animation status (flags) are a boolean, space delimited string in a specific order
      const emoteAnimations = animationPieces[1].split(' ');
      const activeEmotes = emoteAnimations.reduce(
        (emoteAccumulator, emoteBool, index) => {
          // This string is set in C# where booleans are rendered to Pascal case but JS expects booleans in lower case
          //  We could cast it and use a boolean constructor but this is a bit more performant (string comparison vs string manipulation and constructor/parsing)
          // Possible emotes should never be beyond our EMOTE keys here but just in case, don't fail (just ignore)
          if (emoteBool === 'True' && index < EMOTES.length - 1) {
            emoteAccumulator.push(EMOTES[index]);
          }
          return emoteAccumulator;
        },
        [],
      );

      const newPeer = {
        ...peer,
        activeEmotes,
      };
      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_AUDIO_MUTED: {
      const {muted, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('No Peer found');
      }

      const newPeer = {...peer, muted: {...peer.muted, audio: muted}};

      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_VIDEO_MUTED: {
      const {muted, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('No Peer found');
      }

      const newPeer = {...peer, muted: {...peer.muted, video: muted}};

      return {...state, [newPeer.id]: newPeer};
    }

    case types.SET_PEER_CHAT_MUTED: {
      const {muted, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('No Peer found');
      }

      const newPeer = {...peer, muted: {...peer.muted, chat: muted}};

      return {...state, [newPeer.id]: newPeer};
    }

    case 'ADD_CONSUMER': {
      const {consumer, peerId} = action.payload;
      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found for new Consumer');
      }

      const newConsumers = [...peer.consumers, consumer.id];
      const newPeer = {...peer, consumers: newConsumers};

      return {...state, [newPeer.id]: newPeer};
    }

    case 'REMOVE_CONSUMER': {
      const {consumerId, peerId} = action.payload;
      const peer = state[peerId];

      // NOTE: This means that the Peer was closed before, so it's ok.
      if (!peer) {
        return state;
      }

      const idx = peer.consumers.indexOf(consumerId);

      if (idx === -1) {
        throw new Error('Consumer not found');
      }

      const newConsumers = peer.consumers.slice();

      newConsumers.splice(idx, 1);

      const newPeer = {...peer, consumers: newConsumers};

      return {...state, [newPeer.id]: newPeer};
    }

    case 'ADD_DATA_CONSUMER': {
      const {dataConsumer, peerId} = action.payload;

      // special case for bot DataConsumer.
      if (!peerId) {
        return state;
      }

      const peer = state[peerId];

      if (!peer) {
        throw new Error('no Peer found for new DataConsumer');
      }

      const newDataConsumers = [...peer.dataConsumers, dataConsumer.id];
      const newPeer = {...peer, dataConsumers: newDataConsumers};

      return {...state, [newPeer.id]: newPeer};
    }

    case 'REMOVE_DATA_CONSUMER': {
      const {dataConsumerId, peerId} = action.payload;

      // special case for bot DataConsumer.
      if (!peerId) {
        return state;
      }

      const peer = state[peerId];

      // NOTE: This means that the Peer was closed before, so it's ok.
      if (!peer) {
        return state;
      }

      const idx = peer.dataConsumers.indexOf(dataConsumerId);

      if (idx === -1) {
        throw new Error('DataConsumer not found');
      }

      const newDataConsumers = peer.dataConsumers.slice();

      newDataConsumers.splice(idx, 1);

      const newPeer = {...peer, dataConsumers: newDataConsumers};

      return {...state, [newPeer.id]: newPeer};
    }

    default: {
      return state;
    }
  }
};

export default peers;
