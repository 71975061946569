// TODO: Tie into server notifications once they are refined (M3D-478)
// import {Platform} from 'react-native';
// import {showMessage} from 'react-native-flash-message';

import * as types from 'store/communication/actions/actionTypes';
import {enableBubbleMode} from 'store/meeting/actions/settings';

export const setRoomUrl = (url) => {
  return {
    type: 'SET_ROOM_URL',
    payload: {url},
  };
};

export const setRoomState = (state) => {
  return {
    type: 'SET_ROOM_STATE',
    payload: {state},
  };
};

export const setRoomActiveSpeaker = (peerId, volume) => {
  return {
    type: 'SET_ROOM_ACTIVE_SPEAKER',
    payload: {peerId, volume},
  };
};

export const setRoomStatsPeerId = (peerId) => {
  return {
    type: 'SET_ROOM_STATS_PEER_ID',
    payload: {peerId},
  };
};

export const setRoomFaceDetection = (flag) => {
  return {
    type: 'SET_FACE_DETECTION',
    payload: flag,
  };
};

export const setMe = ({
  peerId,
  displayName,
  displayNameSet,
  device,
  permissions,
}) => {
  return {
    type: types.SET_ME,
    payload: {peerId, displayName, displayNameSet, device, permissions},
  };
};

export const setMeInfo = (info) => {
  return {
    type: types.SET_ME_INFO,
    payload: info,
  };
};

export const setMeChatMuted = (muted) => {
  return {
    type: types.SET_ME_CHAT_MUTED,
    payload: {muted},
  };
};

export const setMeAudioMuted = (muted) => {
  return {
    type: types.SET_ME_AUDIO_MUTED,
    payload: {muted},
  };
};

export const setMeVideoMuted = (muted) => {
  return {
    type: types.SET_ME_VIDEO_MUTED,
    payload: {muted},
  };
};

export const setMeTransform = (transform) => {
  return {
    type: types.SET_ME_TRANSFORM,
    payload: {transform},
  };
};

export const checkMeChatMuted = (peerId, muted) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUserId = state.communication.me.id;

    if (peerId === currentUserId) {
      if (!muted) {
        const {roomClient} = state.communication.control;
        await roomClient.enableChatDataProducer();
      }

      return dispatch(setMeChatMuted(muted));
    }
  };
};

export const checkMeAudioMuted = (peerId, muted) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUserId = state.communication.me.id;

    if (peerId === currentUserId) {
      return dispatch(setMeAudioMuted(muted));
    }
  };
};

export const checkMeVideoMuted = (peerId, muted) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentUserId = state.communication.me.id;

    if (peerId === currentUserId) {
      return dispatch(setMeVideoMuted(muted));
    }
  };
};

export const setMediaCapabilities = ({canSendMic, canSendWebcam}) => {
  return {
    type: 'SET_MEDIA_CAPABILITIES',
    payload: {canSendMic, canSendWebcam},
  };
};

export const setCanChangeWebcam = (flag) => {
  return {
    type: 'SET_CAN_CHANGE_WEBCAM',
    payload: flag,
  };
};

export const setDisplayName = (displayName) => {
  return {
    type: 'SET_DISPLAY_NAME',
    payload: {displayName},
  };
};

export const setAudioOnlyState = (enabled) => {
  return {
    type: 'SET_AUDIO_ONLY_STATE',
    payload: {enabled},
  };
};

export const setAudioOnlyInProgress = (flag) => {
  return {
    type: 'SET_AUDIO_ONLY_IN_PROGRESS',
    payload: {flag},
  };
};

export const setAudioMutedState = (enabled) => {
  return {
    type: 'SET_AUDIO_MUTED_STATE',
    payload: {enabled},
  };
};

export const setRoomAudioMutedState = (muted) => {
  return {
    type: types.SET_ROOM_AUDIO_MUTED_STATE,
    payload: {muted},
  };
};

export const setRoomVideoMutedState = (muted) => {
  return {
    type: types.SET_ROOM_VIDEO_MUTED_STATE,
    payload: {muted},
  };
};

export const setRoomChatMutedState = (muted) => {
  return {
    type: types.SET_ROOM_CHAT_MUTED_STATE,
    payload: {muted},
  };
};

export const setRoomVoicePositionalModeState = (mode) => {
  return async (dispatch, getState) => {
    if (mode === 'bubble') {
      dispatch(enableBubbleMode('voice', mode));
    }
    return dispatch({
      type: types.SET_ROOM_VOICE_POSITIONAL_MODE_STATE,
      payload: {mode},
    });
  };
};

export const setRoomVideoPositionalModeState = (mode) => {
  return async (dispatch, getState) => {
    if (mode === 'bubble') {
      dispatch(enableBubbleMode('video', mode));
    }
    return dispatch({
      type: types.SET_ROOM_VIDEO_POSITIONAL_MODE_STATE,
      payload: {mode},
    });
  };
};

export const setRestartIceInProgress = (flag) => {
  return {
    type: 'SET_RESTART_ICE_IN_PROGRESS',
    payload: {flag},
  };
};

export const addProducer = (producer) => {
  return {
    type: 'ADD_PRODUCER',
    payload: {producer},
  };
};

export const removeProducer = (producerId) => {
  return {
    type: 'REMOVE_PRODUCER',
    payload: {producerId},
  };
};

export const setProducerPaused = (producerId) => {
  return {
    type: 'SET_PRODUCER_PAUSED',
    payload: {producerId},
  };
};

export const setProducerResumed = (producerId) => {
  return {
    type: 'SET_PRODUCER_RESUMED',
    payload: {producerId},
  };
};

export const setProducerTrack = (producerId, track) => {
  return {
    type: 'SET_PRODUCER_TRACK',
    payload: {producerId, track},
  };
};

export const setProducerScore = (producerId, score) => {
  return {
    type: 'SET_PRODUCER_SCORE',
    payload: {producerId, score},
  };
};

export const addDataProducer = (dataProducer) => {
  return {
    type: 'ADD_DATA_PRODUCER',
    payload: {dataProducer},
  };
};

export const removeDataProducer = (dataProducerId) => {
  return {
    type: 'REMOVE_DATA_PRODUCER',
    payload: {dataProducerId},
  };
};

export const setWebcamInProgress = (flag) => {
  return {
    type: 'SET_WEBCAM_IN_PROGRESS',
    payload: {flag},
  };
};

export const setShareInProgress = (flag) => {
  return {
    type: 'SET_SHARE_IN_PROGRESS',
    payload: {flag},
  };
};

export const addPeer = (peer) => {
  return {
    type: types.ADD_PEER,
    payload: {peer},
  };
};

export const removePeer = (peerId) => {
  return {
    type: 'REMOVE_PEER',
    payload: {peerId},
  };
};

export const setPeerDisplayName = (displayName, peerId) => {
  return {
    type: 'SET_PEER_DISPLAY_NAME',
    payload: {displayName, peerId},
  };
};

export const setPeerPhotoURL = (photoURL, peerId) => {
  return {
    type: types.SET_PEER_PHOTO_URL,
    payload: {photoURL, peerId},
  };
};

export const addConsumer = (consumer, peerId) => {
  return {
    type: types.ADD_CONSUMER,
    payload: {consumer, peerId},
  };
};

export const removeConsumer = (consumerId, peerId) => {
  return {
    type: types.REMOVE_CONSUMER,
    payload: {consumerId, peerId},
  };
};

export const clearConsumers = () => {
  return {
    type: types.CLEAR_CONSUMERS,
  };
};

export const setConsumerPaused = (consumerId, originator) => {
  return {
    type: 'SET_CONSUMER_PAUSED',
    payload: {consumerId, originator},
  };
};

export const setConsumerResumed = (consumerId, originator) => {
  return {
    type: 'SET_CONSUMER_RESUMED',
    payload: {consumerId, originator},
  };
};

export const setConsumerCurrentLayers = (
  consumerId,
  spatialLayer,
  temporalLayer,
) => {
  return {
    type: 'SET_CONSUMER_CURRENT_LAYERS',
    payload: {consumerId, spatialLayer, temporalLayer},
  };
};

export const setConsumerPreferredLayers = (
  consumerId,
  spatialLayer,
  temporalLayer,
) => {
  return {
    type: 'SET_CONSUMER_PREFERRED_LAYERS',
    payload: {consumerId, spatialLayer, temporalLayer},
  };
};

export const setConsumerPriority = (consumerId, priority) => {
  return {
    type: 'SET_CONSUMER_PRIORITY',
    payload: {consumerId, priority},
  };
};

export const setConsumerTrack = (consumerId, track) => {
  return {
    type: 'SET_CONSUMER_TRACK',
    payload: {consumerId, track},
  };
};

export const setConsumerScore = (consumerId, score) => {
  return {
    type: 'SET_CONSUMER_SCORE',
    payload: {consumerId, score},
  };
};

export const addDataConsumer = (dataConsumer, peerId) => {
  return {
    type: 'ADD_DATA_CONSUMER',
    payload: {dataConsumer, peerId},
  };
};

export const removeDataConsumer = (dataConsumerId, peerId) => {
  return {
    type: 'REMOVE_DATA_CONSUMER',
    payload: {dataConsumerId, peerId},
  };
};

export const addNotification = (notification) => {
  // TODO: Tie into server notifications once they are refined (M3D-478)
  // showMessage({
  //   message: notification.text,
  //   duration: notification.timeout,
  //   icon: 'auto',
  //   hideStatusBar: Platform.OS === 'android' ? true : false,
  //   type: notification.type,
  // });

  return {
    type: 'ADD_NOTIFICATION',
    payload: {notification},
  };
};

export const removeNotification = (notificationId) => {
  return {
    type: 'REMOVE_NOTIFICATION',
    payload: {notificationId},
  };
};

export const removeAllNotifications = () => {
  return {
    type: 'REMOVE_ALL_NOTIFICATIONS',
  };
};

export const setConnectionFailure = (source, reason, message) => {
  return {
    type: 'SET_CONNECTION_FAILURE',
    payload: {source, reason, message},
  };
};

export const clearConnectionFailure = () => {
  return {
    type: 'CLEAR_CONNECTION_FAILURE',
  };
};

export const setPeerLocation = (peerId, location) => {
  return {
    type: 'SET_PEER_LOCATION',
    payload: {peerId, location},
  };
};

export const setPeerTransform = (peerId, transform) => {
  return {
    type: types.SET_PEER_TRANSFORM,
    payload: {peerId, transform},
  };
};

export const setPeerEmotes = (peerId, animations) => {
  return {
    type: types.SET_PEER_EMOTES,
    payload: {peerId, animations},
  };
};

export const setPeerAudioMuted = (peerId, muted) => {
  return {
    type: types.SET_PEER_AUDIO_MUTED,
    payload: {peerId, muted},
  };
};

export const setPeerVideoMuted = (peerId, muted) => {
  return {
    type: types.SET_PEER_VIDEO_MUTED,
    payload: {peerId, muted},
  };
};

export const setPeerChatMuted = (peerId, muted) => {
  return {
    type: types.SET_PEER_CHAT_MUTED,
    payload: {peerId, muted},
  };
};
