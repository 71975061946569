import React, {useEffect, useState, useContext} from 'react';
import {View, Text, Button, StyleSheet, ActivityIndicator} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {hideMessage, showMessage} from 'react-native-flash-message';

import {leaveRoom} from 'store/communication/actions/controlActions';
import {goToLanding} from 'store/app';
import {I18nContext} from 'i18n/context/I18nContext';
import {logEvent} from 'utils/firebase/analytics';
import {MEETING_CONNECTION_FAILURE} from 'utils/firebase/analytics.config';
import {ROOM_STATE} from 'utils/communication/utils';

const ConnectionFailureOverlay = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);

  const {source, reason, message} = useSelector(
    (state) => state.communication.room.failure,
  );
  const roomState = useSelector((state) => state.communication.room.state);
  const roomClient = useSelector(
    (state) => state.communication.control.roomClient,
  );

  const [shouldDisplay, setShouldDisplay] = useState(
    source && roomState === ROOM_STATE.closed,
  );

  const onClose = async () => {
    dispatch(leaveRoom());
    dispatch(goToLanding());

    await logEvent(MEETING_CONNECTION_FAILURE, {
      source: source,
      reason: reason,
      message: message,
    });
  };

  useEffect(() => {
    switch (roomState) {
      case ROOM_STATE.disconnected: {
        showMessage({
          message: translate('connectionFailureOverlay.poorConnection'),
          icon: 'auto',
          renderFlashMessageIcon: () => (
            <View>
              <ActivityIndicator color="white" />
            </View>
          ),
          autoHide: false,
          type: 'warning',
          floating: true,
          style: {width: 250},
          titleStyle: {alignSelf: 'center'},
          hideOnPress: false,
          position: 'top',
        });
        break;
      }
      case ROOM_STATE.closed: {
        hideMessage();
        break;
      }
      default: {
        break;
      }
    }
  }, [roomState, translate]);

  useEffect(() => {
    setShouldDisplay(source && roomState === ROOM_STATE.closed);
  }, [source, roomState, setShouldDisplay]);

  return shouldDisplay ? (
    <View style={styles.container}>
      <View style={styles.background}>
        <Text style={styles.text}>{`${source}`}</Text>
        {reason ? <Text style={styles.text}>{`${reason}`}</Text> : null}
        <Text
          style={
            styles.text
          }>{`${message}\n${roomClient?.getCommunicationServer()}`}</Text>
        <Button title={translate('shared.close')} onPress={onClose} />
      </View>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#0008',
    alignItems: 'center',
    justifyContent: 'center',
  },
  background: {
    maxWidth: 250,
    maxHeight: 200,
    backgroundColor: '#FFF',
    borderRadius: 20,
    padding: 10,
    flex: 1,
    justifyContent: 'space-between',
  },
  text: {
    fontSize: 18,
    textAlign: 'center',
  },
});

export default ConnectionFailureOverlay;
