import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';

import * as types from './actionTypes';
import fallbackData from 'utils/Appearances/appearance.json';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';

const ASYNC_STORAGE_APPEARANCE_KEY = '@appearance';

export const setBody = (body) => {
  return {
    type: types.SET_APPEARANCE_BODY,
    payload: {body},
  };
};

export const setCategory = ({category, selection, material, color}) => {
  return {
    type: types.SELECT_APPEARANCE_CATEGORY,
    payload: {category, selection, material, color},
  };
};

export const setAppearance = (appearance) => {
  return {
    type: types.SET_APPEARANCE,
    payload: appearance,
  };
};

export const syncAppearance = (appearance) => {
  return {
    type: types.SYNC_APPEARANCE,
    payload: appearance,
  };
};

export const setAppearanceInStorage = (asdkId, body, looks) => {
  return async () => {
    try {
      const appearance = {
        asdkId,
        body,
        looks,
      };

      const appearanceJSON = JSON.stringify(appearance);

      await AsyncStorage.setItem(ASYNC_STORAGE_APPEARANCE_KEY, appearanceJSON);
    } catch (error) {
      console.warning(
        'An error occurred saving appearance to async storage',
        error,
      );
    }
  };
};

export const loadAppearanceFromStorage = () => {
  return async (dispatch) => {
    try {
      const appearanceJSON = await AsyncStorage.getItem(
        ASYNC_STORAGE_APPEARANCE_KEY,
      );

      if (appearanceJSON !== null && typeof appearanceJSON === 'string') {
        const checkJson = appearanceJSON.match(/categories/);
        if (!Array.isArray(checkJson) || checkJson.length === 0) {
          // delete if it's not the new version of the looks data.
          await AsyncStorage.removeItem(ASYNC_STORAGE_APPEARANCE_KEY);
          return;
        }
        // save if it is the latest version of the looks data
        const appearance = JSON.parse(appearanceJSON);
        dispatch(syncAppearance(appearance));
      }
    } catch (error) {
      console.warn(
        'An error occurred syncing appearance from async storage',
        error,
      );
    }
  };
};

export const setAvatarLoading = (isLoading) => {
  return {
    type: types.SET_AVATAR_LOADING,
    payload: isLoading,
  };
};

export const setAsdkId = (asdkId) => {
  return {
    type: types.SET_ASDKID,
    payload: asdkId,
  };
};

export const loadAppearanceJsonData = () => {
  const appearanceJsonURL = getRemoteConfigValue(
    'appearance_json_url',
  ).asString();

  return async (dispatch) => {
    try {
      const response = await axios.get(appearanceJsonURL);

      if (response.data !== null) {
        const appearance = response.data;
        dispatch(setAppearanceJsonData(appearance));
      }
    } catch (error) {
      console.warn(
        'An error occurred getting appearance JSON data. Using fallback data.',
        error,
      );
      dispatch(setAppearanceJsonData(fallbackData));
    }
  };
};

export const setAppearanceJsonData = (appearanceData) => {
  return {
    type: types.LOAD_APPEARANCE_DATA,
    payload: appearanceData,
  };
};

export const forceBroadcast = (broadcast) => {
  return {
    type: types.FORCE_BROADCAST,
    payload: broadcast,
  };
};

export const setEditsInProgress = (bool) => {
  return {
    type: types.SET_EDITS_IN_PROGRESS,
    payload: bool,
  };
};

export const setIsFullBody = (bool) => {
  return {
    type: types.SET_IS_FULL_BODY,
    payload: bool,
  };
};
