import React from 'react';
import {Platform} from 'react-native';
import {showMessage} from 'react-native-flash-message';
import NotificationIcon from 'components/Communication/NotificationIcon';

import {
  RECEIVE_MESSAGE,
  SEND_MESSAGE,
  DELETE_MESSAGE,
  CLEAR_MESSAGES,
  REFRESH_MESSAGE_TRANSLATIONS,
  CLEAR_MESSAGE_TRANSLATIONS,
} from './actionTypes';
import {
  setIsMessagePanelOpen,
  setIsMessageNotification,
} from 'store/world/actions';
import {logEvent} from 'utils/firebase/analytics';
import {SEND_GLOBAL_CHAT_MESSAGE} from 'utils/firebase/analytics.config';

import {translate} from 'i18n/context/I18nContext';

const CHAT_NOTIFICATION_NUMBER_OF_LINES = 2;

const notifyMessage = (message, isMessagePanelOpen, dispatch) => {
  if (!isMessagePanelOpen) {
    dispatch(setIsMessageNotification(true));
    showMessage({
      message: message?.user?.name,
      description: message?.translatedText
        ? message?.translatedText
        : message?.text,
      duration: 5500,
      hideStatusBar: Platform.OS === 'android' ? true : false,
      type: 'success',
      textProps: {
        numberOfLines: CHAT_NOTIFICATION_NUMBER_OF_LINES,
      },
      icon: {icon: 'success', position: 'left'},
      renderFlashMessageIcon: () => {
        return <NotificationIcon />;
      },
      onPress: () => dispatch(setIsMessagePanelOpen(true)),
    });
  }
};

export const receiveMessage = (message) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    const {liveTranslation, targetLanguage} = state.meeting.settings;
    let translatedMessage = await roomClient.fetchMessageTranslation(
      liveTranslation,
      targetLanguage,
      message,
    );
    const isMessagePanelOpen = state.world.isMessagePanelOpen;
    notifyMessage(translatedMessage, isMessagePanelOpen, dispatch);
    dispatch({type: RECEIVE_MESSAGE, payload: translatedMessage});
  };
};

export const sendMessage = (message) => {
  const systemMuteChatMessage = {
    _id: Math.random().toString(36).substr(2, 9),
    text: translate('messages.chatDisabled'),
    createdAt: new Date(),
    system: true,
    user: {
      _id: 0,
      name: '3Dmeet',
      avatar:
        'https://www.shareicon.net/data/128x128/2016/11/09/851555_miscellaneous_512x512.png',
    },
  };
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;

    if (roomClient._chatDataProducer) {
      await roomClient.sendChatMessage(message);
      await logEvent(SEND_GLOBAL_CHAT_MESSAGE, {
        meeting_id: roomClient.meetingId,
        message_length: message.text.length,
      });
      return dispatch({type: SEND_MESSAGE, payload: message});
    } else {
      return dispatch({type: SEND_MESSAGE, payload: systemMuteChatMessage});
    }
  };
};

export const deleteMessage = (message) => {
  return {type: DELETE_MESSAGE, payload: message};
};

export const clearMessages = () => {
  return {type: CLEAR_MESSAGES};
};

export const refreshMessages = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {liveTranslation, targetLanguage} = state.meeting.settings;
    const {roomClient} = state.communication.control;
    const currentUserId = state.communication.me.id;
    let translatedMessages = await Promise.all(
      state.communication.chat.map((item) => {
        if (item.user._id !== currentUserId) {
          return roomClient.fetchMessageTranslation(
            liveTranslation,
            targetLanguage,
            item,
          );
        } else {
          return item;
        }
      }),
    );
    return dispatch({
      type: REFRESH_MESSAGE_TRANSLATIONS,
      payload: translatedMessages,
    });
  };
};

export const clearMessageTranslations = () => {
  return async (dispatch, getState) => {
    const state = getState();
    let untranslatedMessages = state.communication.chat.map((message) => {
      return {...message, translatedText: undefined};
    });

    return dispatch({
      type: CLEAR_MESSAGE_TRANSLATIONS,
      payload: untranslatedMessages,
    });
  };
};
