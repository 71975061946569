import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {View, Platform, PixelRatio, StyleSheet} from 'react-native';

import {COMMAND_ROUTE, USER_ROUTE, VIEWBOARD_SYNC} from 'utils/World/config';
import PlatformWorld from 'components/World/PlatformWorld';
import Loading from 'components/World/Loading';
import TextOverlayManager from 'components/World/TextOverlayManager';
import LabelDisplay from 'components/World/LabelDisplay';

class World extends Component {
  static instance = undefined;
  constructor(props) {
    super(props);
    this.state = {loading: true, lowPower: false};
  }

  componentDidMount() {
    if (World.instance) {
      throw new Error('Cannot have multiple 3d world components mounted');
    }
    World.instance = this;
  }

  componentWillUnmount() {
    World.instance = undefined;
  }

  shouldComponentUpdate(nextProps) {
    const {location} = this.props;
    if (nextProps.location !== location) {
      this.setState({loading: true});
    }

    if (nextProps.lowPower !== this.state.lowPower) {
      this.setPowerMode(nextProps.lowPower);
    }

    return true;
  }

  static runCommand = (command) => {
    console.log('COMMAND ROUTE: ', COMMAND_ROUTE);
    console.log('COMMAND: ', command);

    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: command,
    });
  };

  static onReceivePeerMessage = (message) => {
    if (message.route === 'j' || message.route === USER_ROUTE) {
      message.payload = `${message.peer}|${message.payload}`;
      PlatformWorld.sendMessage(message);
    } else if (message.action === 'teleport') {
      World.runCommand(`player_leave ${message.peer}`);
    } else if (message.route === VIEWBOARD_SYNC) {
      PlatformWorld.sendMessage(message);
    }
  };

  static onReceivePeerState = (peerName, state) => {
    if (
      state &&
      state.appearance &&
      typeof state.appearance !== 'undefined' &&
      Object.keys(state.appearance).length !== 0
    ) {
      PlatformWorld.sendMessage({
        route: 'd',
        payload: `${peerName}|${state.appearance}`,
      });
    }
  };

  static onUpdatePeerFloatingName = (peerId, peerDisplayName) => {
    if (peerId && peerDisplayName) {
      PlatformWorld.sendMessage({
        route: USER_ROUTE,
        payload: `${peerId}|nickname|${peerDisplayName}`,
      });
    }
  };

  filterTransformMessages = (message) => {
    const {onTransformFromWorld} = this.props;
    const t = message.value;
    const values = `${t.x} ${t.y} ${t.z} ${t.rx} ${t.ry} ${t.rz} ${t.time}`;
    const payload = `transform|${values}|${t.filter}`;

    onTransformFromWorld({route: USER_ROUTE, payload});
  };

  filterLegacyMessages = (message) => {
    const {onLegacyFromWorld} = this.props;
    const {route, payload} = message;

    if (route === 'x') {
      this.onLevelLoadComplete();
    } else if (route === 'p') {
      // ignore the old/erroneous command that sends a number with the p. In that case the payload will be undefined.
      if (!payload) {
        return;
      }
      const player_variables = payload.split('|');
      if (player_variables[0] === 'updatedAppearance') {
        // player var for knowing when unity has finished updating an avatar.
        if (player_variables[1] === 'System.Object') {
          // This particular variable is a false variable that Unity sends on initialization. We need to ignore it here.
          return;
        }
        // this is a completed avatar appearance. Update local state and send the message through to be processed.
        this.setState({loading: false});
        onLegacyFromWorld(message);
      }
    } else {
      onLegacyFromWorld(message);
    }
  };

  appearanceComplete = (message) => {
    const {onAppearanceCompleteFromWorld} = this.props;
    onAppearanceCompleteFromWorld(message);
  };

  setPowerMode(lowPower) {
    if (lowPower) {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_quality LOW',
      });
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_fps_limit 24',
      });
    } else {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_quality HIGH',
      });
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'set_fps_limit 30',
      });
    }

    this.setState({lowPower: lowPower});
  }

  onLevelLoadComplete = () => {
    const {
      locationFilter,
      onLoadComplete,
      lowPower,
      showFloatingNames,
    } = this.props;

    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: `set_location_filter ${locationFilter}`,
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'finish_loading',
    });
    PlatformWorld.sendMessage({route: COMMAND_ROUTE, payload: 'after_join'});
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: `show_names ${showFloatingNames}`,
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'show_chat false',
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'enable_movement true',
    });
    PlatformWorld.sendMessage({
      route: COMMAND_ROUTE,
      payload: 'use_player_camera',
    });

    this.setPowerMode(lowPower);

    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: 'show_thumbstick true',
      });

      const thumbSize = PixelRatio.getPixelSizeForLayoutSize(56);
      const thumbTravel = PixelRatio.getPixelSizeForLayoutSize(112);
      const thumbX = PixelRatio.getPixelSizeForLayoutSize(84);
      const thumbY = PixelRatio.getPixelSizeForLayoutSize(160);
      PlatformWorld.sendMessage({
        route: COMMAND_ROUTE,
        payload: `set_thumbstick ${thumbX} ${thumbY} ${thumbSize} ${thumbTravel}`,
      });
    }
    onLoadComplete();
  };

  render() {
    const {location, style} = this.props;
    const {loading} = this.state;

    return (
      <View pointerEvents="box-none" style={[styles.background, style]}>
        {location && (
          <PlatformWorld
            location={location}
            onTransform={(message) => this.filterTransformMessages(message)}
            onLegacyMessage={(message) => this.filterLegacyMessages(message)}
          />
        )}
        <Loading loading={loading} />
        <TextOverlayManager loading={loading} />
        <LabelDisplay loading={loading} />
      </View>
    );
  }
}

World.defaultProps = {
  style: {},
  onTransformFromWorld: () => {},
  onLegacyFromWorld: () => {},
  onLoadComplete: () => {},
  lowPower: false,
  showFloatingNames: false,
};

World.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  location: PropTypes.string.isRequired,
  locationFilter: PropTypes.string.isRequired,
  onTransformFromWorld: PropTypes.func,
  onLegacyFromWorld: PropTypes.func,
  onLoadComplete: PropTypes.func,
  lowPower: PropTypes.bool,
  showFloatingNames: PropTypes.bool,
};

const styles = StyleSheet.create({
  background: {
    backgroundColor: '#000',
  },
});

export default World;
