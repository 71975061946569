import airport from './WorldPreviews/airport.jpeg';
import hotel_lobby from './WorldPreviews/hotel_lobby.jpeg';
import house from './WorldPreviews/house.jpeg';
import movie_theater from './WorldPreviews/movie_theater.jpeg';
import sandbox from './WorldPreviews/sandbox.jpeg';
import tech_store from './WorldPreviews/tech_store.jpeg';
import vegas from './WorldPreviews/vegas.jpeg';
import logo_3dmeet_full from './logo_3dmeet_full.png';

const images = {
  airport,
  hotel_lobby,
  house,
  movie_theater,
  sandbox,
  tech_store,
  vegas,
  logo_3dmeet_full,
};

module.exports = images;
