import {combineReducers} from 'redux';

import app from './app';
import appearance from './appearance';
import meeting from './meeting';
import communication from './communication';
import world from './world';
import media from './media';
import viewboard from './viewboard';

export default combineReducers({
  app,
  appearance,
  meeting,
  communication,
  world,
  media,
  viewboard,
});
