import React, {useRef, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import {
  View,
  Text,
  Modal,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  StyleSheet,
} from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import isURL from 'validator/lib/isURL';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import {logEvent} from 'utils/firebase/analytics';
import {
  ADD_INSTANCE_CONTENT,
  ADD_INSTANCE_CONTENT_ERROR,
} from 'utils/firebase/analytics.config';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import shareMeetingModalStyle from 'style/ShareMeetingModalStyle';

const ALLOWED_URL_PROTOCOLS = ['https'];

const ViewboardAddInstanceContentModal = ({
  isViewboardAddInstanceContentModalVisible,
  setIsViewboardAddInstanceContentModalVisible,
}) => {
  const {translate} = useContext(I18nContext);

  const roomClient = useSelector(
    (state) => state.communication.control.roomClient,
  );

  const [linkText, setLinkText] = useState('');
  const [linkInputValidationError, setLinkInputValidationError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const instanceContentInput = useRef(null);

  const ADD_INSTANCE_CONTENT_SUCCESS_MESSAGE = translate(
    'viewboardAddInstanceContentModal.videoAdded',
  );
  const LINK_INPUT_VALIDATION_ERROR = translate(
    'viewboardAddInstanceContentModal.invalidLink',
  );
  const SUBMISSION_ERROR = translate('viewboardAddInstanceContentModal.error');
  const ADD_INSTANCE_CONTENT_ERROR_LOGGING_MESSAGE = translate(
    'viewboardAddInstanceContentModal.errorContent',
  );

  const validateLinkText = () => {
    return isURL(linkText, {protocols: ALLOWED_URL_PROTOCOLS});
  };

  const clearLinkText = () => {
    setLinkText('');
    setLinkInputValidationError('');
    instanceContentInput.current?.focus();
  };

  const onChangeText = (text) => {
    setLinkInputValidationError('');
    setLinkText(text);
  };

  const logError = () => {
    logEvent(ADD_INSTANCE_CONTENT_ERROR, {
      meeting_id: roomClient.meetingId,
      error: ADD_INSTANCE_CONTENT_ERROR_LOGGING_MESSAGE,
      url: linkText,
    });
  };

  const submitLinkText = async () => {
    if (!validateLinkText()) {
      setLinkInputValidationError(LINK_INPUT_VALIDATION_ERROR);
      instanceContentInput.current?.focus();
      return;
    } else {
      setLinkInputValidationError('');
    }

    try {
      setIsSubmitting(true);

      const isAddInstanceContentRequestSuccessful = await roomClient.requestAddInstanceContent(
        linkText,
      );

      if (isAddInstanceContentRequestSuccessful) {
        clearLinkText();
        setIsViewboardAddInstanceContentModalVisible(false);

        showMessage({
          message: ADD_INSTANCE_CONTENT_SUCCESS_MESSAGE,
          description: linkText,
          type: 'success',
          icon: 'auto',
          hideStatusBar: Platform.OS === 'android',
          duration: 3000,
        });

        logEvent(ADD_INSTANCE_CONTENT, {
          meeting_id: roomClient.meetingId,
          url: linkText,
        });
      } else {
        logError();
        setLinkInputValidationError(LINK_INPUT_VALIDATION_ERROR);
      }
    } catch (error) {
      logError();
      setLinkInputValidationError(SUBMISSION_ERROR);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isClearButtonDisabled =
    (linkText === '' || isSubmitting) && linkInputValidationError === '';
  const isSubmitButtonDisabled =
    linkText === '' || linkInputValidationError !== '';

  const VIEWBOARD_SHARE_INSTANCE_CONTENT_WEB_MODAL_MARGIN_TOP = getRemoteConfigValue(
    'viewboard_share_instance_content_web_modal_margin_top',
  ).asNumber();
  const webModalViewStyle = {
    flex: 1,
    alignItems: 'flex-end',
    marginTop: VIEWBOARD_SHARE_INSTANCE_CONTENT_WEB_MODAL_MARGIN_TOP,
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isViewboardAddInstanceContentModalVisible}
      supportedOrientations={[
        'portrait',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}>
      <TouchableWithoutFeedback
        onPress={() => {
          setIsViewboardAddInstanceContentModalVisible(false);
        }}>
        <View
          style={
            Platform.OS === 'web'
              ? webModalViewStyle
              : shareMeetingModalStyle.centeredView
          }>
          <TouchableWithoutFeedback>
            <KeyboardAvoidingView behavior="padding">
              <View style={shareMeetingModalStyle.modalView}>
                <View style={shareMeetingModalStyle.headerContainerTwo}>
                  <Text style={shareMeetingModalStyle.headerTextTwo}>
                    {translate('viewboardAddInstanceContentModal.enterYTLink')}
                  </Text>
                </View>
                <Icon
                  type="MaterialCommunityIcons"
                  style={shareMeetingModalStyle.closeIcon}
                  name="close"
                  onPress={() => {
                    setIsViewboardAddInstanceContentModalVisible(
                      !isViewboardAddInstanceContentModalVisible,
                    );
                  }}
                />
                <View style={shareMeetingModalStyle.divider} />
                <View style={styles.linkTextInputContainer}>
                  <TextInput
                    ref={instanceContentInput}
                    autoFocus={true}
                    onSubmitEditing={submitLinkText}
                    style={styles.linkInput}
                    onChangeText={onChangeText}
                    value={linkText}
                    placeholder={translate(
                      'viewboardAddInstanceContentModal.enterLink',
                    )}
                  />
                  <Text style={styles.linkValidationErrorText}>
                    {linkInputValidationError}
                  </Text>
                </View>
                <View style={styles.linkInputButtonContainer}>
                  <TouchableOpacity
                    onPress={clearLinkText}
                    disabled={isClearButtonDisabled}
                    style={styles.linkInputButton}>
                    <Text
                      style={
                        isClearButtonDisabled
                          ? [
                              styles.linkInputButtonText,
                              styles.linkInputButtonTextDisabled,
                            ]
                          : styles.linkInputButtonText
                      }>
                      {translate('viewboardAddInstanceContentModal.clear')}
                    </Text>
                  </TouchableOpacity>
                  {isSubmitting ? (
                    <ActivityIndicator
                      color="black"
                      style={styles.submitActivityIndicator}
                    />
                  ) : (
                    <TouchableOpacity
                      onPress={submitLinkText}
                      disabled={isSubmitButtonDisabled}
                      style={styles.linkInputButton}>
                      <Text
                        style={
                          isSubmitButtonDisabled
                            ? [
                                styles.linkInputButtonText,
                                styles.linkInputButtonTextDisabled,
                              ]
                            : styles.linkInputButtonText
                        }>
                        {translate('viewboardAddInstanceContentModal.submit')}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </KeyboardAvoidingView>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

ViewboardAddInstanceContentModal.propTypes = {
  isViewboardAddInstanceContentModalVisible: PropTypes.bool.isRequired,
  setIsViewboardAddInstanceContentModalVisible: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  linkInput: {
    height: 40,
    width: 238,
    borderWidth: 1,
    borderRadius: 25,
    padding: 5,
    textAlign: 'center',
    color: 'black',
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  linkInputButtonContainer: {
    width: 275,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  linkInputButton: {
    padding: 30,
  },
  linkInputButtonText: {
    fontSize: 20,
  },
  linkInputButtonTextDisabled: {
    color: 'lightgrey',
  },
  linkValidationErrorText: {
    color: 'red',
    textAlign: 'center',
  },
  linkTextInputContainer: {
    marginTop: 40,
    marginLeft: 40,
    marginRight: 40,
  },
  submitActivityIndicator: {
    paddingRight: 48,
  },
});

export default ViewboardAddInstanceContentModal;
