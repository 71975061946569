import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, View, Platform} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {isTablet, hasNotch} from 'react-native-device-info';
import {showMessage} from 'react-native-flash-message';
import Orientation from 'react-native-orientation-locker';

import IconButton from 'components/IconButton';
import Emotes from 'screens/Meeting/components/SideBar/Emotes';

import {
  disableMic,
  enableMic,
  disableCamera,
  enableCamera,
  switchCamera,
  disableScreenShare,
  enableScreenShare,
} from 'store/media';
import {DISABLED_MEETING_CONTROL_COLOR} from 'style/theme';
import {I18nContext} from 'i18n/context/I18nContext';
import {
  checkPermission,
  VOICE_UNMUTE_SELF,
  VIDEO_UNMUTE_SELF,
} from 'utils/permissions';

const CONTROL_ON_ICON_COLOR = '#5f6368';
const CONTROL_OFF_ICON_COLOR = '#ffffff';
const CONTROL_OFF_BUTTON_COLOR = DISABLED_MEETING_CONTROL_COLOR;
const WEBCAM_SWITCH_ON_BUTTON_COLOR = '#ffffff';
const END_CALL_BUTTON_COLOR = '#ffffff';
const END_CALL_ICON_COLOR = DISABLED_MEETING_CONTROL_COLOR;
const SCREEN_SHARE_ON_ICON_COLOR = '#008000';
const SCREEN_SHARE_BUTTON_COLOR = '#ffffff';

const Controls = ({onClose}) => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const micEnabled = useSelector((state) => state.media.micEnabled);
  const cameraEnabled = useSelector((state) => state.media.cameraEnabled);
  const canSwitchCamera = useSelector((state) => state.media.canSwitchCamera);
  const screenShareEnabled = useSelector(
    (state) => state.media.screenShareEnabled,
  );
  const muted = useSelector((state) => state.communication.me.muted);
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );

  const isViewboardContentFullScreen = useSelector(
    (state) => state.viewboard.isViewboardContentFullScreen,
  );

  const MUTED_MIC_MESSAGE = translate('controls.micMuted');
  const DISABLED_VIDEO_MESSAGE = translate('controls.videoDisabled');

  const micIcon = micEnabled ? 'microphone-outline' : 'microphone-off';
  const micIconColor = micEnabled
    ? CONTROL_ON_ICON_COLOR
    : CONTROL_OFF_ICON_COLOR;
  const micButtonColor = micEnabled
    ? CONTROL_OFF_ICON_COLOR
    : CONTROL_OFF_BUTTON_COLOR;
  const webcamIcon = cameraEnabled ? 'video-outline' : 'video-off-outline';
  const webcamIconColor = cameraEnabled
    ? CONTROL_ON_ICON_COLOR
    : CONTROL_OFF_ICON_COLOR;
  const webcamButtonColor = cameraEnabled
    ? CONTROL_OFF_ICON_COLOR
    : CONTROL_OFF_BUTTON_COLOR;
  const screenShareIconColor = screenShareEnabled
    ? SCREEN_SHARE_ON_ICON_COLOR
    : CONTROL_ON_ICON_COLOR;

  const allowChangeWebcam = cameraEnabled && canSwitchCamera;
  const webcamSwitchIconColor = allowChangeWebcam
    ? CONTROL_ON_ICON_COLOR
    : CONTROL_OFF_ICON_COLOR;
  const webcamSwitchButtonColor = allowChangeWebcam
    ? WEBCAM_SWITCH_ON_BUTTON_COLOR
    : CONTROL_OFF_BUTTON_COLOR;

  const toggleMic = () => {
    if (muted.audio && !checkPermission(VOICE_UNMUTE_SELF, permissions)) {
      showMessage({
        message: MUTED_MIC_MESSAGE,
        hideStatusBar: Platform.OS === 'android' ? true : false,
        type: 'danger',
        icon: 'auto',
      });
    } else {
      if (micEnabled) {
        dispatch(disableMic());
      } else {
        dispatch(enableMic());
      }
    }
  };

  const toggleWebcam = () => {
    if (muted.video && !checkPermission(VIDEO_UNMUTE_SELF, permissions)) {
      showMessage({
        message: DISABLED_VIDEO_MESSAGE,
        hideStatusBar: Platform.OS === 'android' ? true : false,
        type: 'danger',
        icon: 'auto',
      });
    } else {
      if (cameraEnabled) {
        dispatch(disableCamera());
      } else {
        dispatch(enableCamera());
      }
    }
  };

  const changeWebcam = () => {
    if (allowChangeWebcam) {
      dispatch(switchCamera());
    }
  };

  const handleScreenShare = () => {
    if (muted.video) {
      showMessage({
        message: DISABLED_VIDEO_MESSAGE,
        type: 'danger',
        icon: 'auto',
      });
    } else {
      if (screenShareEnabled) {
        dispatch(disableScreenShare());
      } else {
        dispatch(enableScreenShare());
      }
    }
  };

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  const MOBILE_BOTTOM_POSITION = 5;
  const WEB_BOTTOM_POSITION = 25;
  const CONTROLS_BOTTOM_POSITION =
    Platform.OS === 'web' || isTablet()
      ? WEB_BOTTOM_POSITION
      : MOBILE_BOTTOM_POSITION;

  // TODO: Fix orientation specific design for Android (only registering portrait for some reason)
  const EMOTES_PORTRAIT_BOTTOM_POSITION = 225;
  const EMOTES_LANDSCAPE_BOTTOM_POSITION = 24;
  const EMOTES_PORTRAIT_LEFT_POSITION = 0;
  const EMOTES_LANDSCAPE_LEFT_POSITION = 0;
  const EMOTES_IOS_NOTCH_LANDSCAPE_LEFT_POSITION = 24;
  const EMOTES_BOTTOM_POSITION =
    currentOrientation === 'PORTRAIT'
      ? EMOTES_PORTRAIT_BOTTOM_POSITION
      : EMOTES_LANDSCAPE_BOTTOM_POSITION;
  const EMOTES_LEFT_POSITION =
    currentOrientation === 'PORTRAIT'
      ? EMOTES_PORTRAIT_LEFT_POSITION
      : currentOrientation === 'LANDSCAPE-LEFT' &&
        Platform.OS === 'ios' &&
        hasNotch()
      ? EMOTES_IOS_NOTCH_LANDSCAPE_LEFT_POSITION
      : EMOTES_LANDSCAPE_LEFT_POSITION;

  return (
    <View style={styles.container} pointerEvents="box-none">
      <View
        style={[styles.tray, {bottom: CONTROLS_BOTTOM_POSITION}]}
        pointerEvents="box-none">
        <IconButton
          name={micIcon}
          onPress={() => toggleMic()}
          color={micButtonColor}
          iconColor={micIconColor}
        />
        {Platform.OS === 'web' ? (
          <IconButton
            name="phone-hangup"
            onPress={() => onClose()}
            color={END_CALL_BUTTON_COLOR}
            iconColor={END_CALL_ICON_COLOR}
          />
        ) : null}
        <IconButton
          name={webcamIcon}
          onPress={() => toggleWebcam()}
          color={webcamButtonColor}
          iconColor={webcamIconColor}
        />
        {Platform.OS === 'web' ? null : (
          <IconButton
            name="video-switch"
            onPress={() => changeWebcam()}
            color={webcamSwitchButtonColor}
            iconColor={webcamSwitchIconColor}
          />
        )}
        {Platform.OS === 'web' ? null : (
          <IconButton
            name="phone-hangup"
            onPress={() => onClose()}
            color={END_CALL_BUTTON_COLOR}
            iconColor={END_CALL_ICON_COLOR}
          />
        )}
      </View>
      {Platform.OS === 'web' ? (
        <View style={styles.screenShareButtonContainer}>
          <IconButton
            name="monitor-share"
            onPress={() => handleScreenShare()}
            color={SCREEN_SHARE_BUTTON_COLOR}
            iconColor={screenShareIconColor}
          />
        </View>
      ) : null}
      {Platform.OS !== 'web' && !isViewboardContentFullScreen && (
        <View
          style={[
            styles.emotesContainer,
            {bottom: EMOTES_BOTTOM_POSITION, left: EMOTES_LEFT_POSITION},
          ]}>
          <Emotes icon="walk" />
        </View>
      )}
    </View>
  );
};

Controls.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    flex: 1,
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  tray: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    position: 'absolute',
    width: '100%',
    maxWidth: 336,
  },
  screenShareButtonContainer: {
    position: 'absolute',
    bottom: 24,
    right: 24,
  },
  emotesContainer: {
    position: 'absolute',
  },
});

export default Controls;
