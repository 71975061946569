export function convertMessageToTransform(message) {
  const [, rawTransform] = message.split('|');
  const [x, y, z, rX, rY, rZ, t] = rawTransform.split(' ');
  return {
    x: parseFloat(x),
    y: parseFloat(y),
    z: parseFloat(z),
    rX: parseFloat(rX),
    rY: parseFloat(rY),
    rZ: parseFloat(rZ),
    t: parseFloat(t),
  };
}

export function calculateDistanceBetween(transformA, transformB) {
  if (
    !transformA ||
    typeof transformA === 'undefined' ||
    !transformB ||
    typeof transformB === 'undefined'
  ) {
    return Number.MAX_VALUE; // If something isn't available, treat the points as infinitely far away
  }
  const a = transformB.x - transformA.x;
  const b = transformB.y - transformA.y;
  const c = transformB.z - transformA.z;
  const a2 = a * a;
  const b2 = b * b;
  const c2 = c * c;
  const dist = Math.sqrt(a2 + b2 + c2);
  return dist;
}

// Amount of time to wait to update based on transform time (in ms)
// Without this other players do not load until other player moves
const TIME_DIFFERENCE_THRESHOLD = 5000;

const compareTransformData = (
  oldData,
  newData,
  COMPARISON_DIFFERENCE_THRESHOLD = 1,
) => {
  return Math.abs(oldData - newData) > COMPARISON_DIFFERENCE_THRESHOLD;
};

export const shouldUpdateTransformReduxState = ({
  oldTransformData,
  newTransformData,
}) => {
  if (!oldTransformData || !newTransformData) {
    return true;
  }

  return (
    compareTransformData(oldTransformData.x, newTransformData.x) ||
    compareTransformData(oldTransformData.y, newTransformData.y) ||
    compareTransformData(oldTransformData.z, newTransformData.z) ||
    compareTransformData(
      oldTransformData.t,
      newTransformData.t,
      TIME_DIFFERENCE_THRESHOLD,
    )
  );
};

export const shouldUpdateTransformUnity = ({
  oldTransformData,
  newTransformData,
}) => {
  if (!oldTransformData || !newTransformData) {
    return true;
  }

  const POSITION_DIFFERENCE_THRESHOLD = 0.01;

  return (
    compareTransformData(
      oldTransformData.x,
      newTransformData.x,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.y,
      newTransformData.y,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.z,
      newTransformData.z,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.rX,
      newTransformData.rX,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.rY,
      newTransformData.rY,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.rZ,
      newTransformData.rZ,
      POSITION_DIFFERENCE_THRESHOLD,
    ) ||
    compareTransformData(
      oldTransformData.t,
      newTransformData.t,
      TIME_DIFFERENCE_THRESHOLD,
    )
  );
};
