import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, TouchableOpacity} from 'react-native';
import AllIcon from './AllIcon';

const styles = StyleSheet.create({
  button: {
    height: 42,
    backgroundColor: '#2196F3',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 8,
  },
  text: {
    fontSize: 14,
    color: '#FFFFFF',
  },
});

const AllButton = (props) => {
  const {icon, text, showCancel, onPress} = props;
  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <AllIcon icon={icon} showCancel={showCancel} />
      <Text style={styles.text}>{text}</Text>
    </TouchableOpacity>
  );
};

AllButton.defaultProps = {
  showCancel: false,
};

AllButton.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showCancel: PropTypes.bool,
  onPress: PropTypes.func,
};

export default AllButton;
