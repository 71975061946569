import React, {useContext} from 'react';
import {TouchableOpacity, Text, StyleSheet} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';

const StartMeetingButton = () => {
  const {translate} = useContext(I18nContext);
  const marcusSiteUrl = getRemoteConfigValue('marcus_site_url').asString();

  return (
    <TouchableOpacity style={styles.button}>
      <Text href={`${marcusSiteUrl}/account/`} style={styles.text}>
        {translate('startMeetingButtonWeb.buttonText')}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  text: {
    color: 'white',
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '8px',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  button: {
    backgroundColor: 'orangered',
  },
});

export default StartMeetingButton;
