import React, {useState, useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  Switch,
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {Picker} from '@react-native-community/picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {
  setPositionalMode,
  showBubbleDistance,
  enablePositionalVoiceFallOff,
  disablePositionalVoiceFallOff,
} from 'store/meeting/actions/settings';
import {I18nContext} from 'i18n/context/I18nContext';
import {toTitleCase} from 'utils/helpers';

const POSITIONAL_MODE = ['bubble', 'world', 'global'];

const POSITIONAL_VOICE_BUBBLE_ICON_COLOR = '#2196f3';
const POSITIONAL_VOICE_WORLD_ICON_COLOR = '#5f8eb3';
const POSITIONAL_VOICE_GLOBAL_ICON_COLOR = '#888888';
const FALLOFF_VOICE_ON_ICON_COLOR = '#2196f3';
const FALLOFF_VOICE_OFF_ICON_COLOR = '#888888';

const PositionalModeSettings = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const positionalVoiceFallOff = useSelector(
    (state) => state.meeting.settings.positionalVoiceFallOff,
  );
  const {voice: voiceState} = useSelector(
    (state) => state.communication.room.positionalMode,
  );
  const [voice, setVoice] = useState(voiceState);

  useEffect(() => {
    setVoice(voiceState);
  }, [voiceState]);

  const togglePositionalVoiceFallOff = async () => {
    if (Platform.OS === 'web') {
      if (positionalVoiceFallOff) {
        dispatch(disablePositionalVoiceFallOff());
      } else {
        dispatch(enablePositionalVoiceFallOff());
      }
    }
  };

  const changePositionalMode = async (mode) => {
    if (POSITIONAL_MODE.includes(mode)) {
      setVoice(mode);
      dispatch(setPositionalMode(mode));
    }
  };

  const positionalModeIconColor = {
    color:
      voice === 'bubble'
        ? POSITIONAL_VOICE_BUBBLE_ICON_COLOR
        : voice === 'world'
        ? POSITIONAL_VOICE_WORLD_ICON_COLOR
        : POSITIONAL_VOICE_GLOBAL_ICON_COLOR,
  };

  const fallOffVoiceIconColor = {
    color: positionalVoiceFallOff
      ? FALLOFF_VOICE_ON_ICON_COLOR
      : FALLOFF_VOICE_OFF_ICON_COLOR,
  };

  const positionalModePickerItems = POSITIONAL_MODE.map((value, index) => {
    return (
      <Picker.Item
        label={toTitleCase(translate(`positionalModeSettings.${value}`))}
        value={value}
        key={`posMode-${index}`}
      />
    );
  });

  return (
    <View style={styles.container}>
      <View style={styles.pickerContainer}>
        <View style={styles.pickerHeader}>
          <Icon
            type="MaterialCommunityIcons"
            style={[styles.icon, positionalModeIconColor]}
            name="surround-sound"
          />
          <Text style={styles.settingsText}>
            {translate('positionalModeSettings.positional')}
          </Text>
        </View>
        <Picker
          style={[styles.picker, styles.title]}
          selectedValue={voice}
          onValueChange={(value) => changePositionalMode(value)}
          mode={'dropdown'}>
          {positionalModePickerItems}
        </Picker>
      </View>
      {voice === 'bubble' ? (
        <View style={styles.bubbleSettingsContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              dispatch(showBubbleDistance());
            }}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.icon, styles.buttonElements]}
              name="map-marker-distance"
            />
            <Text style={[styles.text, styles.buttonElements]}>
              {translate('positionalModeSettings.bubbleArea')}
            </Text>
          </TouchableOpacity>
          {Platform.OS === 'web' ? (
            <View style={[styles.fallOffVoiceContainer]}>
              <Icon
                type="MaterialCommunityIcons"
                style={[styles.icon, fallOffVoiceIconColor]}
                name="signal-distance-variant"
              />
              <Text style={[styles.text, styles.fallOffVoiceText]}>
                {translate('positionalModeSettings.fallOff')}
              </Text>
              <Switch
                onValueChange={() => togglePositionalVoiceFallOff()}
                value={positionalVoiceFallOff}
              />
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  pickerContainer: {
    flexDirection: 'column',
  },
  pickerHeader: {
    flexDirection: 'row',
  },
  picker: {
    marginTop: Platform.OS === 'ios' ? -40 : 0,
  },
  settingsText: {
    fontSize: 18,
    marginTop: 5,
  },
  text: {
    fontSize: 16,
  },
  icon: {
    fontSize: 28,
    margin: 5,
  },
  bubbleSettingsContainer: {
    alignItems: 'center',
    marginTop: Platform.OS === 'web' ? 10 : 0,
    marginBottom: 5,
  },
  fallOffVoiceContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fallOffVoiceText: {
    marginRight: 5,
  },
  title: {
    textTransform: 'capitalize',
  },
  button: {
    height: 42,
    backgroundColor: '#2196F3',
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  buttonElements: {
    color: 'white',
  },
});

export default PositionalModeSettings;
