import React from 'react';
import {Image, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  thumbnail: {
    height: 56,
    width: 56,
    paddingLeft: 8,
    paddingRight: 9,
    paddingBottom: 3,
    borderRadius: 56,
  },
});

const Thumbnail = (props) => {
  const {source, style, alt} = props;
  return (
    <Image
      source={source}
      style={[styles.thumbnail, style]}
      accessibilityLabel={alt}
    />
  );
};

Thumbnail.defaultProps = {
  source: {},
  alt: '',
};

Thumbnail.propTypes = {
  imageSource: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  alt: PropTypes.string,
};

export default Thumbnail;
