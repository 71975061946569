export const UNAVAILABLE = 'UNAVAILABLE';
export const UNKNOWN = 'UNKNOWN';
export const GRANTED = 'GRANTED';
export const BLOCKED = 'BLOCKED';

class Permissions {
  constructor() {
    this.videoStatus = UNKNOWN;
    this.audioStatus = UNKNOWN;
  }
  async checkPermissions() {
    const devices = await navigator.mediaDevices.enumerateDevices();

    let hasVideo = false;
    let hasVideoPermissions = false;
    let hasAudio = false;
    let hasAudioPermissions = false;

    for (const device of devices) {
      if (device.kind === 'videoinput') {
        hasVideo = true;
        if (device.label) {
          hasVideoPermissions = true;
        }
      }
      if (device.kind === 'audioinput') {
        hasAudio = true;
        if (device.label) {
          hasAudioPermissions = true;
        }
      }
    }

    if (!hasVideo) {
      this.videoStatus = UNAVAILABLE;
    } else if (hasVideoPermissions) {
      this.videoStatus = GRANTED;
    }

    if (!hasAudio) {
      this.audioStatus = UNAVAILABLE;
    } else if (hasAudioPermissions) {
      this.audioStatus = GRANTED;
    }
  }

  getAudioStatus() {
    return this.audioStatus;
  }

  getVideoStatus() {
    return this.videoStatus;
  }

  async requestMicPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      stream.getAudioTracks()[0].stop();
    } catch {
      this.audioStatus = BLOCKED;
    }
  }

  async requestCameraPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      stream.getVideoTracks()[0].stop();
    } catch {
      this.videoStatus = BLOCKED;
    }
  }
}

const permissions = new Permissions();

export default permissions;
