import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {View, ScrollView} from 'react-native';

import ViewboardButton from './ViewboardButton';
import {
  switchToDashboard,
  shareViewboardContent,
  addActiveViewboard,
} from 'store/viewboard';
import {I18nContext} from 'i18n/context/I18nContext';
import ViewboardMap from '../../ViewboardMap';
import ViewboardShareFlowTitle from '../ViewboardShareFlowTitle';
import {
  VIEWBOARD_MAP_IMAGE_CONTAINER,
  VIEWBOARD_SCROLL_ITEMS_CONTAINER,
  VIEWBOARD_SCROLL_VIEW_ITEM,
  VIEWBOARD_SCROLL_VIEW_CONTENT_CONTAINER,
} from 'style/theme';

const ViewboardSelect = ({setCurrentStep}) => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);

  const viewboards = useSelector((state) => state.viewboard.viewboards);
  const activeViewboards = useSelector(
    (state) => state.viewboard.activeViewboards,
  );
  const selectedContentId = useSelector(
    (state) => state.viewboard.selectedContentId,
  );

  const location = useSelector((state) => state.world.location);

  const onViewboardSelect = (viewboardId) => {
    dispatch(addActiveViewboard({viewboardId, contentId: selectedContentId}));
    dispatch(shareViewboardContent(location, viewboardId, selectedContentId));
    dispatch(switchToDashboard());
  };

  const onCancel = () => {
    dispatch(switchToDashboard());
  };

  return (
    <>
      <ViewboardShareFlowTitle
        sectionTitle={translate('viewboardSelect.selectViewboard')}
        onPressCancel={onCancel}
      />
      <ScrollView
        contentContainerStyle={VIEWBOARD_SCROLL_VIEW_CONTENT_CONTAINER}>
        <View style={VIEWBOARD_SCROLL_ITEMS_CONTAINER}>
          {viewboards.length > 0 &&
            viewboards?.map((viewboard) => {
              const foundActiveViewboard = activeViewboards.find(
                (activeViewboard) => activeViewboard?.id === viewboard?.id,
              );
              const isViewboardButtonDisabled = foundActiveViewboard
                ? true
                : false;

              return (
                <View key={viewboard?.id} style={VIEWBOARD_SCROLL_VIEW_ITEM}>
                  <ViewboardButton
                    onPress={() => onViewboardSelect(viewboard?.id)}
                    displayName={translate('viewboardSelect.viewboardDisplay', {
                      num: viewboard.mapLegendNumber,
                    })}
                    mapLegendNumber={viewboard?.mapLegendNumber}
                    isViewboardButtonDisabled={isViewboardButtonDisabled}
                  />
                </View>
              );
            })}
        </View>
        <View style={VIEWBOARD_MAP_IMAGE_CONTAINER}>
          <ViewboardMap />
        </View>
      </ScrollView>
    </>
  );
};

ViewboardSelect.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};

export default ViewboardSelect;
