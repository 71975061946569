import React, {useState, useEffect, useContext} from 'react';
import {useWindowDimensions, Platform} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import SplashScreen from 'react-native-splash-screen';
import base64 from 'react-native-base64';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import AvatarPreviewPortrait from 'screens/AvatarPreview/AvatarPreviewPortrait';
import AvatarPreviewLandscape from 'screens/AvatarPreview/AvatarPreviewLandscape';
import {
  setAppearanceInStorage,
  setCategory,
  setAvatarLoading,
  setAppearance,
  setAsdkId,
} from 'store/appearance';
import Appearances from 'utils/Appearances';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import {goToDeviceConfig} from 'store/app';
import {deepEqual} from 'utils/helpers';

const AvatarPreview = () => {
  const dispatch = useDispatch();
  const {height, width} = useWindowDimensions();
  const ratio = height / width;
  const isPortrait = height > width;
  const isInGame = false;

  const [loaded, setLoaded] = useState(false);

  const customizeLocation = useSelector(state => state.world.customizeLocation);
  const body = useSelector(state => state.appearance.body);
  const looks = useSelector(state => state.appearance.looks);
  const avatarLoading = useSelector(state => state.appearance.avatarLoading);
  const asdkId = useSelector(state => state.appearance.asdkId);
  const isFullBody = useSelector(state => state.appearance.isFullBody);
  const {translate} = useContext(I18nContext);

  const resetSelection = () => {
    dispatch(setCategory({}));
    Appearances.restoreCameraPosition(ratio, isInGame);
  };

  const onLoadComplete = async () => {
    Appearances.initializeAvatarSystem(asdkId);
    const looksToSend = {...looks};
    const bodyToSend = body;
    Appearances.sendFocusCameraParametersToUnity(body, ratio, isInGame);
    // set the loading views for avatar appearance
    dispatch(setAvatarLoading(true));
    Appearances.applyLook(looksToSend[bodyToSend], body, isFullBody);
    Appearances.UseCustomizeCamera(ratio, isInGame);
  };

  const onContinue = () => {
    dispatch(setAppearanceInStorage(asdkId, body, looks));
    onFinishedCustomizing();
    // reset users selections to starting point
    dispatch(setCategory({}));
    // set avatar loading to true until the next world loads
    dispatch(setAvatarLoading(true));
    dispatch(goToDeviceConfig());
  };

  const onFinishedCustomizing = () => {
    Appearances.saveAppearanceToAnalytics(body, looks);
    // log events for all the selected categories
    if (logCharacterAppearance.asBoolean()) {
      console.log(JSON.stringify(looks[body]));
    }
  };

  const onLegacyFromWorld = message => {
    // for the avatar preview customization screen, we are only concerned with the 'p' route - referring to local player variables.
    const {route, payload} = message;

    if (route === 'p') {
      const player_variables = payload.split('|');
      switch (player_variables[0]) {
        case 'updatedAppearance':
          // player var for knowing when unity has finished updating an avatar.
          // process the updated appearance
          const saveToLocalStorage = false;
          const appearance = player_variables[1];
          processAppearanceFromUnity(appearance, saveToLocalStorage);
          // update the players camera
          if (!loaded) {
            Appearances.UseCustomizeCamera(ratio, isInGame);
          }
          setLoaded(true);
          break;
        case 'asdkPlayerId':
          // player var for knowing the users asdkId from unity when they first start customizing.
          const newAsdkId = player_variables[1];
          saveUnityAsdkId(newAsdkId);
          // process the asdkId
          break;
        default:
          console.warn(
            'Unrecognized local player variable: ',
            player_variables[0],
          );
      }
    }
    // ignore all other routes/variables
  };

  /*
   *Compares the unity-given appearance data to the user's last selected appearance data.
   *
   *If they are not the same then the function shows a message to the user letting them know there was an issue *with Unity, and that they have been given a default appearance (that's what Unity does when it runs into an *issue with setting the avatar appearance).
   *
   *If they are the same then all is well and no appearance data is saved to local state.
   *
   *Either way at the end the function will set the loading state to false and will save appearance data to local *storage if the parameter setLocal is true.
   */
  const processAppearanceFromUnity = (encodedAppearance, setLocal = false) => {
    // 1 - decode the json
    const decoded = JSON.parse(base64.decode(encodedAppearance));
    // 2 - take a snapshot of the user's selection to be used for comparison / validation
    const localAppearanceSnapshot = JSON.parse(JSON.stringify(looks[body]));
    // 3 - create a newLooks object to be used in case any changes to redux are necessary. this is a copy of the existing looks object in redux (including all 3 body types).
    const newLooks = {...looks};

    // 4 -convert decoded appearance from unity to same format as local state appearance
    // create a base object to start from, that mirrors the format we need. Put the head values in right off.
    let convertedUnityAppearance = JSON.parse(
      JSON.stringify(localAppearanceSnapshot),
    );
    convertedUnityAppearance.categories.head = {
      color: '',
      generateHead: false,
      selection: decoded.headAddress,
      texture: '',
    };

    // loop over all the categories in the Unity appearance to save them to the new format
    // This only includes bottom, shoes, top, (no head data)
    const unityCategories = decoded.clothingData;

    for (const category in unityCategories) {
      convertedUnityAppearance.categories[category].selection = unityCategories[
        category
      ].clothingAddress
        ? unityCategories[category].clothingAddress
        : '';
      convertedUnityAppearance.categories[category].texture = unityCategories[
        category
      ].textureAddress
        ? unityCategories[category].textureAddress
        : '';
      convertedUnityAppearance.categories[category].color = unityCategories[
        category
      ].color
        ? unityCategories[category].color
        : '';
    }

    // 5 - Now check the unity received data against the users recent selections in redux
    // the texture/generateHead piece of the head category in the local storage seems to missing often for some reason.  Add it back in to be sure it matches (head texture doesn't actually matter for this validation).
    localAppearanceSnapshot.categories.head.texture = '';
    localAppearanceSnapshot.categories.head.generateHead = false;

    if (!deepEqual(convertedUnityAppearance, localAppearanceSnapshot)) {
      // if the bodies do not match, then there was a problem. Let the user know.
      showMessage({
        message: translate('avatarPreview.avatarUpdateProblem'),
        icon: 'auto',
        autoHide: false,
        type: 'danger',
        floating: true,
        style: {width: 250},
        titleStyle: {alignSelf: 'center'},
        hideOnPress: true,
        position: 'center',
      });
      // save the new appearance data to redux
      newLooks[body] = convertedUnityAppearance;
      dispatch(setAppearance(newLooks));
    }

    // 6 - save to local storage if indicated
    if (setLocal) {
      dispatch(setAppearanceInStorage(asdkId, body, newLooks));
    }

    // 7 - update the store loading back to false to show the UI again
    dispatch(setAvatarLoading(false));
  };

  const saveUnityAsdkId = encodedAsdkId => {
    // get the user ID from the message
    const userAsdkId = base64.decode(encodedAsdkId);
    // save the user ID to the redux store appearance
    dispatch(setAsdkId(userAsdkId));
  };

  const logCharacterAppearance = getRemoteConfigValue(
    'log_character_appearance',
  );

  // Avoid flashing white background on app load
  useEffect(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      SplashScreen.hide();
    }
  }, []);

  const display = isPortrait ? (
    <AvatarPreviewPortrait
      resetSelection={resetSelection}
      customizeLocation={customizeLocation}
      onLoadComplete={onLoadComplete}
      onContinue={onContinue}
      loaded={loaded}
      avatarLoading={avatarLoading}
      onLegacyFromWorld={onLegacyFromWorld}
    />
  ) : (
    <AvatarPreviewLandscape
      resetSelection={resetSelection}
      customizeLocation={customizeLocation}
      onLoadComplete={onLoadComplete}
      onContinue={onContinue}
      loaded={loaded}
      avatarLoading={avatarLoading}
      onLegacyFromWorld={onLegacyFromWorld}
    />
  );

  return display;
};

export default AvatarPreview;
