export const SWITCH_TO_DASHBOARD = 'SWITCH_TO_DASHBOARD';
export const SWITCH_TO_SHARE_ON_VIEWBOARD = 'SWITCH_TO_SHARE_ON_VIEWBOARD';
export const SELECT_VIEWBOARD_CONTENT = 'SELECT_VIEWBOARD_CONTENT';
export const SELECT_VIEWBOARD = 'SELECT_VIEWBOARD';
export const REGISTER_VIEWBOARDS = 'REGISTER_VIEWBOARDS';
export const ADD_ACTIVE_VIEWBOARD = 'ADD_ACTIVE_VIEWBOARD';
export const EDIT_ACTIVE_VIEWBOARD = 'EDIT_ACTIVE_VIEWBOARD';
export const REMOVE_ACTIVE_VIEWBOARD = 'REMOVE_ACTIVE_VIEWBOARD';
export const CLEAR_VIEWBOARDS = 'CLEAR_VIEWBOARDS';
export const SET_IS_VIEWBOARD_CONTENT_FULLSCREEN =
  'SET_IS_VIEWBOARD_CONTENT_FULLSCREEN';
export const SET_VIEWBOARD_CONTENT = 'SET_VIEWBOARD_CONTENT';
