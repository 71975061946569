import {
  RECEIVE_MESSAGE,
  SEND_MESSAGE,
  DELETE_MESSAGE,
  CLEAR_MESSAGES,
  REFRESH_MESSAGE_TRANSLATIONS,
  CLEAR_MESSAGE_TRANSLATIONS,
} from 'store/communication/actions/actionTypes';
export const initialState = [];

const chat = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MESSAGE: {
      const message = action.payload;

      return [message, ...state];
    }

    case SEND_MESSAGE: {
      const message = action.payload;

      return [message, ...state];
    }

    case DELETE_MESSAGE: {
      const messageToBeDeleted = action.payload;

      return [
        ...state.filter((message) => message._id !== messageToBeDeleted._id),
      ];
    }

    case CLEAR_MESSAGES: {
      return [];
    }

    case CLEAR_MESSAGE_TRANSLATIONS: {
      return [...action.payload];
    }

    case REFRESH_MESSAGE_TRANSLATIONS: {
      return [...action.payload];
    }
    // TODO: Update previous messages on display name/photo url change

    default:
      return state;
  }
};

export default chat;
