import React, {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Text, View, StyleSheet, Platform} from 'react-native';
import {Picker} from '@react-native-community/picker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {setPerspectiveMode} from 'store/meeting/actions/settings';
import {PERSPECTIVE_MODES} from 'store/meeting/reducers/settings';
import World from 'components/World';
import {I18nContext} from 'i18n/context/I18nContext';
import {logEvent} from 'utils/firebase/analytics';
import {SELECT_AVATAR_PERSPECTIVE} from 'utils/firebase/analytics.config';

const PerspectiveSwitcher = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const perspectiveMode = useSelector(
    (state) => state.meeting.settings.perspectiveMode,
  );

  const perspectiveIconColor = {color: '#3457D5'};

  const updatePerspectiveMode = (perspective) => {
    dispatch(setPerspectiveMode(perspective));
    World.runCommand(`player_camera ${perspective}`);

    logEvent(SELECT_AVATAR_PERSPECTIVE, {
      perspective: perspective,
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.pickerContainer}>
        <View style={styles.pickerHeader}>
          <Icon
            type="MaterialCommunityIcons"
            style={[styles.icon, perspectiveIconColor]}
            name="eye"
          />
          <Text style={styles.settingsText}>
            {translate('perspectiveSwitcher.changePerspective')}
          </Text>
        </View>
        <Picker
          selectedValue={perspectiveMode}
          style={styles.picker}
          onValueChange={(itemValue) => updatePerspectiveMode(itemValue)}>
          <Picker.Item
            label={translate('perspectiveSwitcher.third')}
            value={PERSPECTIVE_MODES.third}
          />
          <Picker.Item
            label={translate('perspectiveSwitcher.first')}
            value={PERSPECTIVE_MODES.first}
          />
          <Picker.Item
            label={translate('perspectiveSwitcher.overShoulder')}
            value={PERSPECTIVE_MODES.shoulder}
          />
        </Picker>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  pickerContainer: {
    flexDirection: 'column',
  },
  pickerHeader: {
    flexDirection: 'row',
  },
  picker: {
    marginTop: Platform.OS === 'ios' ? -40 : 0,
  },
  settingsText: {
    fontSize: 18,
    marginTop: 5,
  },
  icon: {
    fontSize: 28,
    margin: 5,
    marginBottom: 0,
  },
});

export default PerspectiveSwitcher;
