import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const LottieAnimation = ({
  loop,
  autoPlay,
  source,
  webHeight,
  webWidth,
  webAutoResize,
}) => {
  if (webAutoResize && webHeight !== webWidth) {
    webHeight >= webWidth ? (webWidth = webHeight) : (webHeight = webWidth);
  }

  return (
    <Lottie
      options={{
        loop: loop,
        autoplay: autoPlay,
        animationData: source,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      height={webHeight}
      width={webWidth}
    />
  );
};

LottieAnimation.defaultProps = {
  loop: true,
  autoPlay: true,
  webHeight: 100,
  webWidth: 100,
  webAutoResize: true,
};

LottieAnimation.propTypes = {
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  source: PropTypes.shape({}).isRequired,
  webHeight: PropTypes.number,
  webWidth: PropTypes.number,
  webAutoResize: PropTypes.bool,
};

export default LottieAnimation;
