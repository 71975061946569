import React, {useState, useContext} from 'react';
import Webcam from 'react-webcam';
import {TouchableOpacity, Text, View} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import Loading from 'components/World/Loading';

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

const WebcamCapture = ({takePicture, styles}) => {
  const {translate} = useContext(I18nContext);
  const [isLoading, setIsLoading] = useState(true);
  return (
    <View style={styles.webcamImageContainer}>
      <Loading loading={isLoading} color={'transparent'} />
      <Webcam
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={() => setIsLoading(false)}
        /* eslint-disable react-native/no-inline-styles */
        style={{
          flex: 1,
          height: '95%',
        }}>
        {({getScreenshot}) => (
          <View style={styles.webcamButtonContainer}>
            <TouchableOpacity
              onPress={() => {
                const imageSrc = getScreenshot();
                takePicture(imageSrc);
              }}
              style={styles.webcamButton}>
              <Text>{translate('avatarPreview.headCapture')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </Webcam>
    </View>
  );
};

export default WebcamCapture;
