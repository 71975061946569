import {combineReducers} from 'redux';
import settings from './settings';
import attendance from './attendance';
import properties from './properties';
import recording from './recording';

const reducers = combineReducers({
  settings,
  attendance,
  properties,
  recording,
});

export default reducers;
