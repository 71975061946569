const firebaseConfig = {
  apiKey: 'AIzaSyC0vU7Bu0NWIpNbRzgoEhX1-0tSQPfXAtA',
  authDomain: 'marcus-prod-env.firebaseapp.com',
  databaseURL: 'https://marcus-prod-env.firebaseio.com',
  projectId: 'marcus-prod-env',
  storageBucket: 'marcus-prod-env.appspot.com',
  messagingSenderId: '116705416677',
  appId: '1:116705416677:web:185be8004a3d3a72f5cb0c',
  measurementId: 'G-BHFJYK8NBK',
};

export default firebaseConfig;
