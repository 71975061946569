import React from 'react';
import {Image, StyleSheet} from 'react-native';
import {useSelector} from 'react-redux';

import {getResourceIDFromLocation} from 'utils/helpers';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';

const getViewboardMapImageHeight = (location) => {
  switch (location) {
    case 'vegas':
      return 155;
    case 'airport':
      return 114;
    case 'house':
      return 345;
    case 'movie_theater':
      return 268;
    case 'tech_store':
      return 226;
    default:
      return 200;
  }
};

const ViewboardMap = () => {
  const viewboardMapImageUri = getRemoteConfigValue('viewboard_map_image_uri');
  const viewboardMapImagePath = getRemoteConfigValue(
    'viewboard_map_image_path',
  );

  const location = useSelector((state) => state.world.location);
  const locations = useSelector((state) => state.meeting.properties.locations);

  const locationResourceId = getResourceIDFromLocation(location, locations);
  const viewboardMapImageHeight = getViewboardMapImageHeight(
    locationResourceId,
  );

  return (
    <Image
      style={[styles.viewboardMapImage, {height: viewboardMapImageHeight}]}
      resizeMode="contain"
      source={{
        uri: `${viewboardMapImageUri.asString()}${locationResourceId}${viewboardMapImagePath.asString()}`,
      }}
    />
  );
};

const styles = StyleSheet.create({
  viewboardMapImage: {width: 300},
});

export default ViewboardMap;
