import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  ScrollView,
  Platform,
} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {clearLabelDisplay} from 'store/world/actions';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const LabelDisplay = (props) => {
  const {loading} = props;
  const labelInfo = useSelector((state) => state.world.labelInfo);
  const dispatch = useDispatch();
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [InfoElement, setInfoElement] = useState(null);

  useEffect(() => {
    setShouldDisplay(!(loading || labelInfo === null));
  }, [labelInfo, loading]);

  const closeLabelDisplay = useCallback(() => {
    dispatch(clearLabelDisplay());
  }, [dispatch]);

  const GenerateAltText = useCallback(() => {
    if (!labelInfo || !labelInfo.alt) {
      return null;
    }

    return (
      <ScrollView style={styles.altContainer}>
        <Text style={styles.alt}>{labelInfo.alt}</Text>
      </ScrollView>
    );
  }, [labelInfo]);

  useEffect(() => {
    if (!labelInfo) {
      setInfoElement(<></>);
      return;
    }

    const altText = GenerateAltText();
    const renderedInfo = (
      <View style={styles.body}>
        {labelInfo.img ? (
          <Image
            source={{uri: labelInfo?.img}}
            style={altText === null ? styles.imageFull : styles.image}
            resizeMode="contain"
            resizeMethod="resize"
            accessibilityLabel={labelInfo?.alt}
            onError={() =>
              setInfoElement(
                <View style={styles.body}>{GenerateAltText()}</View>,
              )
            }
          />
        ) : (
          <></>
        )}
        {GenerateAltText()}
      </View>
    );
    setInfoElement(renderedInfo);
  }, [labelInfo, GenerateAltText]);

  return shouldDisplay ? (
    <View style={styles.container}>
      <Icon
        type="MaterialCommunityIcons"
        style={styles.close}
        name="close"
        onPress={closeLabelDisplay}
      />
      <Text style={styles.title}>{labelInfo?.title}</Text>
      {InfoElement}
    </View>
  ) : (
    <></>
  );
};

LabelDisplay.propTypes = {
  loading: PropTypes.bool.isRequired,
};

const webContainer = {
  top: 15,
  maxWidth: windowWidth * 0.4,
  minWidth: windowWidth * 0.15,
  maxHeight: windowHeight * 0.3,
};

const mobileContainer = {
  top: 25,
  left: 60,
  maxHeight: windowHeight * 0.45,
};

const styles = StyleSheet.create({
  container: {
    ...(Platform.OS === 'web' ? webContainer : mobileContainer),
    elevation: 10,
    zIndex: 10,
    position: 'absolute',
    right: 60,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    color: '#202124',
    padding: 8,
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  title: {
    textAlign: 'center',
    width: '95%',
    margin: 'auto',
    fontSize: Platform.OS === 'web' ? 20 : 16,
    color: '#202124',
    fontWeight: '600',
    marginBottom: 4,
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  image: {
    height: Platform.OS === 'web' ? windowHeight * 0.15 : '50%',
    width: '100%',
    marginBottom: 6,
  },
  imageFull: {
    height: windowHeight * 0.35,
    width: '100%',
  },
  altContainer: {
    maxHeight: windowHeight * 0.35,
    overflow: 'scroll',
    padding: 5,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    borderRadius: 10,
  },
  alt: {
    textAlign: 'center',
    textAlignVertical: 'center',
    width: '100%',
    height: '100%',
    color: '#202124',
    margin: 'auto',
    paddingBottom: 10,
  },
  close: {
    fontSize: 15,
    color: '#3c4043',
    top: 6,
    right: 6,
    position: 'absolute',
  },
});

export default LabelDisplay;
