import React from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {
  nextElementInCollection,
  previousElementInCollection,
  setIsViewboardContentFullScreen,
  setFullScreen,
} from 'store/viewboard';
import {checkPermission, VIEWBOARD_CONTROL} from 'utils/permissions';
import {logEvent} from 'utils/firebase/analytics';
import {
  FULLSCREEN_VIEWBOARD,
  NEXT_ELEMENT_IN_VIEWBOARD_COLLECTION,
  PREVIOUS_ELEMENT_IN_VIEWBOARD_COLLECTION,
} from 'utils/firebase/analytics.config';

const ActiveViewboardControls = ({viewboardId, contentType}) => {
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );

  const isViewboardContentFullScreen = useSelector(
    (state) => state.viewboard.isViewboardContentFullScreen,
  );
  const onSetFullScreen = () => {
    setFullScreen(viewboardId, !isViewboardContentFullScreen);
    dispatch(setIsViewboardContentFullScreen(!isViewboardContentFullScreen));

    logEvent(FULLSCREEN_VIEWBOARD, {
      viewboard_id: viewboardId,
      is_full_screen: !isViewboardContentFullScreen,
    });
  };

  const onNextElementInCollection = () => {
    nextElementInCollection(viewboardId);

    logEvent(NEXT_ELEMENT_IN_VIEWBOARD_COLLECTION, {
      viewboard_id: viewboardId,
    });
  };

  const onPreviousElementInCollection = () => {
    previousElementInCollection(viewboardId);

    logEvent(PREVIOUS_ELEMENT_IN_VIEWBOARD_COLLECTION, {
      viewboard_id: viewboardId,
    });
  };

  const shouldDisplayCollectionControls =
    contentType === 'collection' &&
    checkPermission(VIEWBOARD_CONTROL, permissions);

  return (
    <View style={styles.controlsContainer}>
      <TouchableOpacity onPress={onSetFullScreen}>
        <Icon
          type="MaterialCommunityIcons"
          name={isViewboardContentFullScreen ? 'fullscreen-exit' : 'fullscreen'}
          style={[styles.buttonIcon, styles.fullscreenButton]}
        />
      </TouchableOpacity>
      {shouldDisplayCollectionControls && (
        <>
          <TouchableOpacity onPress={() => onPreviousElementInCollection()}>
            <Icon
              type="MaterialCommunityIcons"
              name="arrow-left"
              style={styles.buttonIcon}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => onNextElementInCollection()}>
            <Icon
              type="MaterialCommunityIcons"
              name="arrow-right"
              style={styles.buttonIcon}
            />
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  controlsContainer: {flexDirection: 'row'},
  buttonIcon: {fontSize: 30},
  fullscreenButton: {marginRight: 6},
});

ActiveViewboardControls.propTypes = {
  viewboardId: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
};

export default ActiveViewboardControls;
