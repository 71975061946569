import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {View, Text, ScrollView, StyleSheet} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import {checkPermission, VIEWBOARD_CONTROL} from 'utils/permissions';
import ViewboardMap from '../ViewboardMap';
import ViewboardShareButton from './ViewboardShareButton';
import ActiveViewboard from './ActiveViewboard';
import {
  VIEWBOARDS_HEADING_STYLE,
  VIEWBOARD_MAP_IMAGE_CONTAINER,
  VIEWBOARD_SCROLL_VIEW_CONTENT_CONTAINER,
  VIEWBOARD_SCROLL_ITEMS_CONTAINER,
  VIEWBOARD_SCROLL_VIEW_ITEM,
} from 'style/theme';

const ViewboardDashboard = () => {
  const {translate} = useContext(I18nContext);

  const activeViewboards = useSelector(
    (state) => state.viewboard.activeViewboards,
  );
  const availableViewboards = useSelector(
    (state) => state.viewboard.viewboards,
  );
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );

  const getViewboardDashboardHeadingText = (activeViewboardLength) => {
    if (activeViewboardLength === 0) {
      return translate('viewboardDashboard.noActiveViewboard');
    } else if (activeViewboardLength === 1) {
      return translate('viewboardDashboard.activeViewboard');
    } else if (activeViewboardLength >= 2) {
      return translate('viewboardDashboard.activeViewboards');
    }
  };

  return (
    <>
      {checkPermission(VIEWBOARD_CONTROL, permissions) &&
      activeViewboards?.length < availableViewboards?.length ? (
        <View style={styles.viewboardShareButtonContainer}>
          <ViewboardShareButton />
        </View>
      ) : null}
      <Text style={VIEWBOARDS_HEADING_STYLE}>
        {getViewboardDashboardHeadingText(activeViewboards.length)}
      </Text>
      <ScrollView
        contentContainerStyle={VIEWBOARD_SCROLL_VIEW_CONTENT_CONTAINER}>
        <View style={VIEWBOARD_SCROLL_ITEMS_CONTAINER}>
          {activeViewboards?.map((viewboard) => {
            return (
              <View key={viewboard.id} style={VIEWBOARD_SCROLL_VIEW_ITEM}>
                <ActiveViewboard viewboard={viewboard} />
              </View>
            );
          })}
        </View>
        <View style={VIEWBOARD_MAP_IMAGE_CONTAINER}>
          <ViewboardMap />
        </View>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  viewboardShareButtonContainer: {
    marginTop: 15,
    alignSelf: 'center',
  },
});

export default ViewboardDashboard;
