// This disable is necessary to prevent issues with `new MediaRecorder()` and such.
/* eslint-disable no-undef */
import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {View, Button, ActivityIndicator, StyleSheet} from 'react-native';

import {
  notifyEndRecording,
  requestRecording,
  setRecording,
  setRecordingElements,
} from 'store/meeting/actions/recording';
import {I18nContext} from 'i18n/context/I18nContext';
import DeviceInfo from 'utils/communication/DeviceInfo';

// TODO: Allow recording just audio, just video, or both (sans viewboard audio, current implementation)

const RECORDING_MIME_TYPES = [
  'video/mp4',
  'video/webm;codecs=vp8,opus',
  'video/webm;codecs=h264',
  'video/webm;codecs=daala',
  'video/webm',
  'video/mpeg',
];
const RECORDING_OPTIONS = {
  // audioBitsPerSecond: 128000,
  // videoBitsPerSecond: 2500000,
  // bitsPerSecond: 100000,
};
const SCREEN_RECORDING_OPTIONS = {
  video: {
    cursor: 'motion',
    logicalSurface: true,
    displaySurface: 'browser',
  },
  audio: false,
};

const Recorder = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);

  const recording = useSelector(
    (state) => state.meeting.recording.recordingLocally,
  );
  const recorder = useSelector((state) => state.meeting.recording.recorder);
  const microphoneStream = useSelector((state) => state.media.micStream);
  const microphoneEnabled = useSelector((state) => state.media.micEnabled);
  const audioContext = useSelector(
    (state) => state.meeting.recording.audioContext,
  );
  const audioDestination = useSelector(
    (state) => state.meeting.recording.audioDestination,
  );
  const screenStream = useSelector(
    (state) => state.meeting.recording.screenCaptureStream,
  );
  const whiteNoiseSource = useSelector(
    (state) => state.meeting.recording.whiteNoiseSource,
  );

  const [initializing, setInitializing] = useState(false);

  const download = (name, blob) => {
    const link = document.createElement('a');
    const recordedVideoUrl = window.URL.createObjectURL(blob);
    link.setAttribute('href', recordedVideoUrl);
    link.setAttribute('download', name);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(recordedVideoUrl);
    }, 100);
  };

  useEffect(() => {
    if (recorder && microphoneStream) {
      audioContext
        .createMediaStreamSource(microphoneStream)
        .connect(audioDestination);
    }
  }, [microphoneStream, audioContext, audioDestination, recorder]);

  const generateFilename = (extension) => {
    const now = new Date();
    const formattedYear = now.getFullYear();
    // Pad with leading zeros (0) to length 2 for other number elements
    // Months are zero-based, so add one
    const formattedMonth = (now.getMonth() + 1).toString().padStart(2, '0');
    const formattedDay = now.getDate().toString().padStart(2, '0');
    const formattedHour = now.getHours().toString().padStart(2, '0');
    const formattedMinutes = now.getMinutes().toString().padStart(2, '0');
    const meetingDate = `${formattedYear}-${formattedMonth}-${formattedDay}_${formattedHour}-${formattedMinutes}`;
    return `3Dmeet_${meetingDate}.${extension}`;
  };

  const startRecording = async () => {
    try {
      setInitializing(true);
      await dispatch(requestRecording(DeviceInfo.platform));
      try {
        // Start screen capture (no audio to make for a consistent experience for all browsers, we can change this in the future if needed)
        const recordingScreenStream = await navigator.mediaDevices.getDisplayMedia(
          SCREEN_RECORDING_OPTIONS,
        );

        // Setup for recording/mixing
        const combinedRecordingStream = new MediaStream();
        const audioCtx = new AudioContext();
        const screenVideoTrack = recordingScreenStream.getVideoTracks()[0];

        const audioSources = [];
        if (microphoneEnabled) {
          audioSources.push(audioCtx.createMediaStreamSource(microphoneStream));
        }

        // Just in case screen capture audio is enabled, we might as well record it, right?
        if (recordingScreenStream.getAudioTracks().length > 0) {
          audioSources.push(
            audioCtx.createMediaStreamSource(recordingScreenStream),
          );
        }

        const destination = audioCtx.createMediaStreamDestination();
        audioSources.forEach((audioSrc) => {
          audioSrc.connect(destination);
        });

        // The following issues occur with Chrome:
        //   1) If no audio is present, the resulting file doesn't work
        //   2) The file syncs the beginning of audio to the start of the video
        //     a) if a recording starts and no one talks for 30 seconds, then audio starts at the beginning with the video (or a few frames earlier)
        // To resolve this, we can generate a silent audio stream that starts at the beginning and attach it
        const bufferSize = 2 * audioCtx.sampleRate;
        const noiseBuffer = audioCtx.createBuffer(
          1,
          bufferSize,
          audioCtx.sampleRate,
        );
        const output = noiseBuffer.getChannelData(0);
        for (let i = 0; i < bufferSize; i++) {
          output[i] = 0;
        }
        const whiteNoise = audioCtx.createBufferSource();
        whiteNoise.buffer = noiseBuffer;
        whiteNoise.loop = true;
        whiteNoise.start();
        whiteNoise.connect(destination);

        // Combine streams for recording
        combinedRecordingStream.addTrack(screenVideoTrack);
        combinedRecordingStream.addTrack(
          destination.stream.getAudioTracks()[0],
        );

        const recordOptions = RECORDING_OPTIONS;
        for (let i = 0; i < RECORDING_MIME_TYPES.length; i++) {
          if (MediaRecorder.isTypeSupported(RECORDING_MIME_TYPES[i])) {
            recordOptions.mimeType = RECORDING_MIME_TYPES[i];
            break;
          }
        }
        const mediaRecorder = new MediaRecorder(
          combinedRecordingStream,
          recordOptions,
        );
        // Called when recording stops
        //   There might be a way to do more chunk based data available but that would require more research
        mediaRecorder.ondataavailable = (e) => {
          // TODO: Get extension based off mediaRecorder.mimeType
          download(generateFilename('webm'), e.data);
        };

        // If user clicks the browser's stop sharing button, we should end the recording
        screenVideoTrack.onended = () => {
          finishRecording(
            mediaRecorder,
            recordingScreenStream,
            whiteNoise,
            destination,
            audioCtx,
          );
        };

        // TODO: Add a system that can show a message to the user before leaving that can maintain references to prevent accidental overwrites
        window.onbeforeunload = handleUnload;
        mediaRecorder.start();

        // Handle redux changes
        dispatch(setRecording(true));
        dispatch(
          setRecordingElements(
            audioCtx,
            destination,
            whiteNoise,
            recordingScreenStream,
            mediaRecorder,
          ),
        );
      } catch (err) {
        // User cancels the screenshare option or setup failed for other reason
        dispatch(notifyEndRecording(DeviceInfo.platform, 'capture_denied'));
        console.error(err);
      }
    } catch (requestErr) {
      // Recording is not allowed (integration or user/permission setting)
      dispatch(notifyEndRecording(DeviceInfo.platform, 'not_allowed'));
      console.error(requestErr);
    } finally {
      setInitializing(false);
    }
  };

  const handleUnload = (event) => {
    // We can't really show a custom message or trigger the download from here
    //   but we can make the user aware that they'll lose the recording if they continue the navigation
    const e = event || window.event;
    if (e) {
      e.returnValue = translate('recorderWeb.keepRecordingPrompt');
    }
    return translate('recorderWeb.keepRecordingPrompt');
  };

  const stopRecording = () => {
    finishRecording(
      recorder,
      screenStream,
      whiteNoiseSource,
      audioDestination,
      audioContext,
    );
  };

  const finishRecording = (
    recorderToEnd,
    screenCaptureToEnd,
    whiteNoiseToEnd,
    audioDest,
    audioCtx,
  ) => {
    dispatch(notifyEndRecording(DeviceInfo.platform, 'user_ended'));
    window.onbeforeunload = null;

    // End the recording (invokes callback) and stop screen capture
    if (recorderToEnd.state !== 'inactive') {
      recorderToEnd.stop();
    }
    if (
      typeof screenCaptureToEnd !== 'undefined' &&
      screenCaptureToEnd !== null
    ) {
      screenCaptureToEnd.getTracks().forEach((track) => track.stop());
    }
    whiteNoiseToEnd.stop();
    whiteNoiseToEnd.disconnect(audioDest);
    audioDest.disconnect();
    audioCtx.close();

    // Handle redux changes
    dispatch(setRecording(false));
    dispatch(setRecordingElements(null, null, null, null, null));
  };

  // RN Button should have a loading prop but that doesn't seem to work on web, thus the ActivityIndicator.
  return (
    <View>
      {!recording ? (
        initializing ? (
          <View>
            <ActivityIndicator
              style={styles.initializing}
              size="small"
              color="#fff"
            />
          </View>
        ) : (
          <Button
            onPress={startRecording}
            title={translate('recorderWeb.record')}
            accessibilityLabel={translate('recorderWeb.startRecording')}
          />
        )
      ) : (
        <Button
          onPress={stopRecording}
          title={translate('recorderWeb.stop')}
          accessibilityLabel={translate('recorderWeb.stopRecording')}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  initializing: {
    backgroundColor: '#2196f3',
    borderRadius: 3,
    padding: 5,
  },
});

export default Recorder;
