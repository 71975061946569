import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

const styles = StyleSheet.create({
  iconArea: {
    width: 42,
    height: 42,
  },
  primaryIcon: {
    left: 7,
    bottom: 9,
    fontSize: 28,
    position: 'absolute',
    color: '#FFFFFF',
  },
  secondaryIcon: {
    right: 0,
    bottom: 5,
    fontSize: 22,
    position: 'absolute',
    color: '#FFFFFF80',
  },
  tertiaryIcon: {
    left: 0,
    bottom: 5,
    fontSize: 22,
    position: 'absolute',
    color: '#FFFFFF80',
  },
  cancelOverlay: {
    fontSize: 42,
    color: 'red',
    position: 'absolute',
  },
});

const AllIcon = (props) => {
  const {icon, showCancel} = props;
  return (
    <View style={styles.iconArea}>
      <Icon
        type="MaterialCommunityIcons"
        style={[styles.tertiaryIcon]}
        name={icon}
      />
      <Icon
        type="MaterialCommunityIcons"
        style={[styles.secondaryIcon]}
        name={icon}
      />
      <Icon
        type="MaterialCommunityIcons"
        style={[styles.primaryIcon]}
        name={icon}
      />
      {showCancel ? (
        <Icon
          type="MaterialCommunityIcons"
          style={styles.cancelOverlay}
          name="cancel"
        />
      ) : null}
    </View>
  );
};

AllIcon.defaultProps = {
  showCancel: false,
};

AllIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  showCancel: PropTypes.bool,
};

export default AllIcon;
