import React from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';

const DEFAULT_BORDER_RADIUS = 20;
const DEFAULT_BORDER_WIDTH = 1;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  containerCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderWidth: DEFAULT_BORDER_WIDTH,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  foreground: {
    width: '100%',
    height: '100%',
    backgroundColor: '#4BE',
    borderColor: '#0000',
    borderWidth: DEFAULT_BORDER_WIDTH,
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  disabled: {
    borderColor: '#CCC',
  },
});

const VolumeIndicator = (props) => {
  const {volume, style, disable, vertical, center, backgroundColor} = props;

  let volumeActual = typeof volume !== 'number' ? 0 : volume;

  volumeActual = Math.round(volumeActual * 100);
  volumeActual = Math.min(Math.max(volumeActual, 0), 100); // Clamp between 0-100

  const foregroundWidth = volumeActual + '%';

  const foreground = {
    ...(vertical ? {height: foregroundWidth} : {width: foregroundWidth}),
  };

  return (
    <View
      style={[styles.container, center ? styles.containerCenter : null, style]}
      pointerEvents="box-none">
      {!disable && volumeActual >= 0.05 ? (
        <View style={[styles.foreground, foreground]} />
      ) : null}
      <View
        style={[
          styles.background,
          {borderColor: backgroundColor},
          disable ? styles.disabled : null,
        ]}
      />
    </View>
  );
};

VolumeIndicator.defaultProps = {
  style: null,
  disable: false,
  vertical: false,
  center: false,
  backgroundColor: '#000000',
};

VolumeIndicator.propTypes = {
  volume: PropTypes.number.isRequired,
  disable: PropTypes.bool,
  vertical: PropTypes.bool,
  center: PropTypes.bool,
  backgroundColor: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default VolumeIndicator;
