import React, {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {View, TouchableHighlight, Text, StyleSheet} from 'react-native';
import {showMessage} from 'react-native-flash-message';

import {I18nContext} from 'i18n/context/I18nContext';
import {SIDE_PANEL_SETTINGS_COLOR} from 'style/theme';
import {logEvent} from 'utils/firebase/analytics';
import {MANUAL_APPEARANCE_BROADCAST} from 'utils/firebase/analytics.config';
import {forceBroadcast} from 'store/appearance';

const BroadcastAppearanceButton = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const roomClient = useSelector(
    (state) => state.communication.control.roomClient,
  );
  const avatarLoading = useSelector((state) => state.appearance.avatarLoading);

  const forceAppearanceBroadcast = () => {
    if (avatarLoading) {
      // prompt the user with a message
      showFlashMessage();
    } else {
      dispatch(forceBroadcast(true));
      logEvent(MANUAL_APPEARANCE_BROADCAST, {meeting_id: roomClient.meetingId});
    }
  };

  const showFlashMessage = () => {
    showMessage({
      message: translate('broadcastAppearanceButton.flashMessage'),
      icon: 'auto',
      autoHide: true,
      type: 'info',
      floating: true,
      style: {width: '50%'},
      titleStyle: {alignSelf: 'center'},
      hideOnPress: true,
      position: 'top',
    });
  };

  return (
    <View style={styles.centeredView}>
      <TouchableHighlight
        style={styles.refreshButton}
        onPress={forceAppearanceBroadcast}>
        <Text style={styles.textStyle}>
          {translate('broadcastAppearanceButton.buttonText')}
        </Text>
      </TouchableHighlight>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  refreshButton: {
    backgroundColor: SIDE_PANEL_SETTINGS_COLOR,
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    maxWidth: '96%',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default BroadcastAppearanceButton;
