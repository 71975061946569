import * as types from 'store/communication/actions/actionTypes';
import {ROOM_STATE} from 'utils/communication/utils';

export const initialState = {
  url: null,
  state: 'new', // new/connecting/connected/disconnected/closed,
  activeSpeakerId: null,
  activeSpeakerVolume: 0,
  statsPeerId: null,
  faceDetection: false,
  failure: {
    source: undefined,
    reason: undefined,
    message: undefined,
  },
  muted: {
    audio: false,
    video: false,
    chat: false,
  },
  positionalMode: {
    voice: 'global',
    video: 'global',
  },
};

const room = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROOM_URL': {
      const {url} = action.payload;

      return {...state, url};
    }

    case 'SET_ROOM_STATE': {
      const roomState = action.payload.state;

      if (roomState === ROOM_STATE.connected) {
        return {...state, state: roomState};
      } else {
        return {
          ...state,
          state: roomState,
          activeSpeakerId: null,
          activeSpeakerVolume: 0,
          statsPeerId: null,
        };
      }
    }

    case 'SET_ROOM_ACTIVE_SPEAKER': {
      const {peerId, volume} = action.payload;

      return {...state, activeSpeakerId: peerId, activeSpeakerVolume: volume};
    }

    case 'SET_ROOM_STATS_PEER_ID': {
      const {peerId} = action.payload;

      if (state.statsPeerId === peerId) {
        return {...state, statsPeerId: null};
      }

      return {...state, statsPeerId: peerId};
    }

    case 'SET_FACE_DETECTION': {
      const flag = action.payload;

      return {...state, faceDetection: flag};
    }

    case types.SET_ROOM_AUDIO_MUTED_STATE: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, audio: muted}};
    }

    case types.SET_ROOM_VIDEO_MUTED_STATE: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, video: muted}};
    }

    case types.SET_ROOM_CHAT_MUTED_STATE: {
      const {muted} = action.payload;

      return {...state, muted: {...state.muted, chat: muted}};
    }

    case types.SET_ROOM_VOICE_POSITIONAL_MODE_STATE: {
      const {mode} = action.payload;

      return {...state, positionalMode: {...state.positionalMode, voice: mode}};
    }

    case types.SET_ROOM_VIDEO_POSITIONAL_MODE_STATE: {
      const {mode} = action.payload;

      return {...state, positionalMode: {...state.positionalMode, video: mode}};
    }

    case 'REMOVE_PEER': {
      const {peerId} = action.payload;
      const newState = {...state};

      if (peerId && peerId === state.activeSpeakerId) {
        newState.activeSpeakerId = null;
      }

      if (peerId && peerId === state.statsPeerId) {
        newState.statsPeerId = null;
      }

      return newState;
    }

    case 'SET_CONNECTION_FAILURE': {
      const {source, reason, message} = action.payload;

      return {...state, failure: {source, reason, message}};
    }

    case 'CLEAR_CONNECTION_FAILURE': {
      return {
        ...state,
        failure: {source: undefined, reason: undefined, message: undefined},
      };
    }

    default:
      return state;
  }
};

export default room;
