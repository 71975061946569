export default class Logger {
  // eslint-disable-next-line no-useless-constructor
  constructor(prefix) {}

  get debug() {
    return console.log;
  }

  get warn() {
    return console.warn;
  }

  get error() {
    return console.error;
  }
}
