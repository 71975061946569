import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {View, Switch, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useDispatch, useSelector} from 'react-redux';

import PeerNavButton from './PeerNavButton';
import PeerChaseButton from './PeerChaseButton';
import {
  recallPeer,
  enablePeerAudio,
  disablePeerAudio,
  enablePeerVideo,
  disablePeerVideo,
  enablePeerChat,
  disablePeerChat,
} from 'store/meeting/actions/attendance';
import {requestRecall} from 'store/world/actions';
import {logEvent} from 'utils/firebase/analytics';
import {
  MUTE_TARGETED_AUDIO,
  UNMUTE_TARGETED_AUDIO,
  MUTE_TARGETED_VIDEO,
  UNMUTE_TARGETED_VIDEO,
  MUTE_TARGETED_CHAT,
  UNMUTE_TARGETED_CHAT,
  RECALL_TARGETED,
  TELEPORT_TO_PARTICIPANT,
} from 'utils/firebase/analytics.config';
import {
  checkPermission,
  VOICE_MUTE_OTHER,
  VIDEO_MUTE_OTHER,
  CHAT_MUTE_OTHER,
  LOCATION_RECALL,
} from 'utils/permissions';
import {I18nContext} from 'i18n/context/I18nContext';

const AttendanceContent = (props) => {
  const {peerId} = props;
  const dispatch = useDispatch();
  const peer = useSelector((state) => state.communication.peers[peerId]);
  const locations = useSelector((state) => state.meeting.properties.locations);
  const {permissions} = useSelector((state) => state.communication.me);
  const {translate} = useContext(I18nContext);

  const [location, setLocation] = useState(peer.location);
  const {device} = peer;
  const {type, platform} = device;

  const deviceIcon = type ? type : 'cellphone-off';
  const platformIcon = platform
    ? platform === 'ios'
      ? 'apple'
      : platform
    : 'help-circle';

  // TODO: Make sure to sync redux state if it becomes different
  const isAudioBlocked = peer && peer.muted && peer.muted.audio;
  const isVideoBlocked = peer && peer.muted && peer.muted.video;
  const isChatBlocked = peer && peer.muted && peer.muted.chat;

  const toggleBlockAudio = async () => {
    if (isAudioBlocked) {
      dispatch(enablePeerAudio(peerId));

      await logEvent(UNMUTE_TARGETED_AUDIO);
    } else {
      dispatch(disablePeerAudio(peerId));

      await logEvent(MUTE_TARGETED_AUDIO);
    }
  };

  const toggleBlockVideo = async () => {
    if (isVideoBlocked) {
      dispatch(enablePeerVideo(peerId));

      await logEvent(UNMUTE_TARGETED_VIDEO);
    } else {
      dispatch(disablePeerVideo(peerId));

      await logEvent(MUTE_TARGETED_VIDEO);
    }
  };

  const toggleBlockChat = async () => {
    if (isChatBlocked) {
      dispatch(enablePeerChat(peerId));

      await logEvent(UNMUTE_TARGETED_CHAT);
    } else {
      dispatch(disablePeerChat(peerId));

      await logEvent(MUTE_TARGETED_CHAT);
    }
  };

  const recall = async () => {
    dispatch(recallPeer(peerId));

    await logEvent(RECALL_TARGETED);
  };

  const chase = async () => {
    dispatch(requestRecall(peerId, location.id));

    await logEvent(TELEPORT_TO_PARTICIPANT, {location: location.id});
  };

  useEffect(() => {
    if (peer.location) {
      const locationData = locations?.find((loc) => loc.id === peer.location);
      setLocation(locationData);
    }
  }, [peer, locations]);

  return (
    <View style={styles.background}>
      {checkPermission(VOICE_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          <Switch onValueChange={toggleBlockAudio} value={!isAudioBlocked} />
          <Icon
            type="MaterialCommunityIcons"
            style={styles.controlIcon}
            name={isAudioBlocked ? 'volume-off' : 'volume-high'}
          />
        </View>
      ) : null}
      {checkPermission(VIDEO_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          <Switch onValueChange={toggleBlockVideo} value={!isVideoBlocked} />
          <Icon
            type="MaterialCommunityIcons"
            style={styles.controlIcon}
            name={isVideoBlocked ? 'video-off' : 'video'}
          />
        </View>
      ) : null}
      {checkPermission(CHAT_MUTE_OTHER, permissions) ? (
        <View style={styles.controlRow}>
          <Switch onValueChange={toggleBlockChat} value={!isChatBlocked} />
          <Icon
            type="MaterialCommunityIcons"
            style={styles.controlIcon}
            name={isChatBlocked ? 'chat-remove' : 'chat'}
          />
        </View>
      ) : null}
      <View style={styles.controlRow}>
        {checkPermission(LOCATION_RECALL, permissions) ? (
          <PeerNavButton
            icon="arrow-down-circle"
            onPress={recall}
            text={translate('attendanceContent.recall')}
          />
        ) : null}
        <PeerChaseButton
          location={location?.preview}
          alt={location?.name}
          onPress={chase}
          text={translate('attendanceContent.teleport')}
        />
      </View>
      <View style={styles.platformIcons}>
        <Icon
          type="MaterialCommunityIcons"
          style={styles.platformIcon}
          name={deviceIcon}
        />
        <Icon
          type="MaterialCommunityIcons"
          style={styles.platformIcon}
          name={platformIcon}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  platformIcons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  platformIcon: {
    fontSize: 18,
  },
  controlRow: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
  },
  controlIcon: {
    fontSize: 28,
  },
  background: {
    backgroundColor: '#e3f1f1',
    padding: 10,
  },
});

AttendanceContent.propTypes = {
  peerId: PropTypes.string.isRequired,
};

export default AttendanceContent;
