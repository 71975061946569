import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';

const VideoStream = ({stream, mirror, style}) => {
  const videoRef = useRef();

  useEffect(() => {
    const {current} = videoRef;

    if (stream) {
      current.srcObject = stream;
    }
  }, [stream]);

  // This is for a very special video element, and stylesheets don't work well with it
  const squareVideoFitStyle = {
    minWidth: '100%',
    minHeight: '100%',
    objectFit: 'cover',
  };

  return (
    <View style={[style, styles.container, mirror ? styles.mirror : null]}>
      <video ref={videoRef} autoPlay style={squareVideoFitStyle} muted />
    </View>
  );
};

const styles = StyleSheet.create({
  mirror: {
    transform: [{rotateY: '180deg'}],
  },
  container: {
    overflow: 'hidden',
  },
});

VideoStream.defaultProps = {
  style: null,
  stream: null,
  mirror: false,
};

VideoStream.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  stream: PropTypes.shape({}.isRequired),
  mirror: PropTypes.bool,
};

export default VideoStream;
