export const disablePeerAudio = (peerId) => {
  return mutePeerAudio(peerId, true);
};

export const enablePeerAudio = (peerId) => {
  return mutePeerAudio(peerId, false);
};

export const disablePeerVideo = (peerId) => {
  return mutePeerVideo(peerId, true);
};

export const enablePeerVideo = (peerId) => {
  return mutePeerVideo(peerId, false);
};

export const disablePeerChat = (peerId) => {
  return mutePeerChat(peerId, true);
};

export const enablePeerChat = (peerId) => {
  return mutePeerChat(peerId, false);
};

export const recallAllPeers = (peerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.recallAllPeers();
  };
};

export const recallPeer = (peerId) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.recallPeer(peerId);
  };
};

export const muteRoomAudio = (mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.muteRoomAudio(mute);
  };
};

export const mutePeerAudio = (peerId, mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.mutePeerAudio(peerId, mute);
  };
};

export const muteRoomVideo = (mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.muteRoomVideo(mute);
  };
};

export const mutePeerVideo = (peerId, mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.mutePeerVideo(peerId, mute);
  };
};

export const muteRoomChat = (mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.muteRoomChat(mute);
  };
};

export const mutePeerChat = (peerId, mute) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.mutePeerChat(peerId, mute);
  };
};
