import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Platform} from 'react-native';
import {Picker} from '@react-native-community/picker';

const DeviceSelectionPicker = ({
  selectedValue,
  onValueChange,
  pickerItemObjects,
}) => {
  const iOS_HEIGHT = 150;
  const deviceSelectionPickerItems = pickerItemObjects.map((value, index) => {
    return (
      <Picker.Item
        label={value?.label}
        value={value?.deviceId}
        key={value?.deviceId}
      />
    );
  });

  return (
    <Picker
      style={[
        styles.picker,
        Platform.OS === 'ios' ? {height: iOS_HEIGHT} : null,
      ]}
      selectedValue={selectedValue}
      onValueChange={(value) => onValueChange(value)}
      mode={'dropdown'}>
      {deviceSelectionPickerItems}
    </Picker>
  );
};

DeviceSelectionPicker.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  pickerItemObjects: PropTypes.array.isRequired,
};

const styles = StyleSheet.create({
  picker: {
    marginTop: Platform.OS === 'ios' ? -80 : 0,
    width: 250,
  },
});

export default DeviceSelectionPicker;
