export const MEETING_START = 'meetingStart';
export const MEETING_END = 'meetingEnd';
export const ROOM_CONFIGS_REFRESH = 'roomConfigsRefresh';
export const VOICE_MUTE_OTHER = 'voiceMuteOther';
export const VOICE_UNMUTE_SELF = 'voiceUnmuteNewProducer';
export const VIDEO_MUTE_OTHER = 'videoMuteOther';
export const VIDEO_UNMUTE_SELF = 'videoUnmuteNewProducer';
export const CHAT_MUTE_OTHER = 'chatMuteOther';
export const LOCATION_RECALL = 'locationRecall';
export const VIEWBOARD_CONTROL = 'viewboardControl';
export const FILESHARE_UPLOAD = 'fileshareUpload';
export const FILESHARE_DELETE = 'fileshareDelete';
export const INSTANCE_CONTENT_ADD = 'instanceContentAdd';
export const INSTANCE_CONTENT_REMOVE = 'instanceContentRemove';
export const VOICE_CHANGE_POSITIONAL_MODE = 'voiceChangePositionalMode';
export const VIDEO_CHANGE_POSITIONAL_MODE = 'videoChangePositionalMode';
export const MEETING_RECORD = 'meetingRecord';

export const checkPermission = (permissionToBeChecked, permissions) => {
  return permissions.includes(permissionToBeChecked);
};
