import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

const ViewboardMapLegendIcon = ({mapLegendNumber}) => {
  return (
    <View style={styles.viewboardMapLegendIconContainer}>
      <Text style={styles.viewboardMapLegendIconText}>{mapLegendNumber}</Text>
    </View>
  );
};

ViewboardMapLegendIcon.propTypes = {
  mapLegendNumber: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  viewboardMapLegendIconContainer: {
    alignItems: 'center',
    backgroundColor: '#06ACE9',
    height: 30,
    width: 30,
    borderRadius: 30,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.8,
    shadowRadius: 1,
  },
  viewboardMapLegendIconText: {
    fontWeight: 'bold',
    fontSize: 24,
    color: 'white',
  },
});

export default ViewboardMapLegendIcon;
