import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/remote-config';

import firebaseConfig from 'config/firebase.config.js';
import remoteConfigDefault from 'config/remoteConfigDefault.config.js';

let instance = null;

class FirebaseService {
  constructor() {
    if (!instance) {
      this.app = firebase.initializeApp(firebaseConfig);
      // eslint-disable-next-line consistent-this
      instance = this;
    }
    return instance;
  }
}

const firebaseService = new FirebaseService().app;
firebaseService.analytics();
firebaseService.performance();

const remoteConfig = firebaseService.remoteConfig();
remoteConfig.defaultConfig = remoteConfigDefault;

firebaseService.remoteConfig = remoteConfig;

export default firebaseService;
