import React from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, Text, View, Image, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {useSelector} from 'react-redux';
import VolumeIndicator from 'components/Configs/VolumeIndicator';

const EMOTE_ICONS = {
  raiseHand: 'human-greeting',
  point: 'hand-pointing-up',
  fly: 'airplane',
  sitOnGround: 'seat',
  sitOnChair: 'seat',
};

const styles = StyleSheet.create({
  statusContainer: {
    width: 32,
    height: 32,
  },
  remoteStatus: {
    left: 4,
    top: 4,
    fontSize: 24,
    position: 'absolute',
  },
  cancelOverlay: {
    fontSize: 32,
    color: '#ff0000',
    position: 'absolute',
  },
  activeColor: {
    color: '#00ff00',
  },
  mutedColor: {
    color: '#808080',
  },
  unknownColor: {
    color: '#ffa500',
  },
  header: {
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#a9dad6',
  },
  identityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarStyle: {marginRight: 10},
  displayName: {fontWeight: '600'},
  status: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandIcon: {fontSize: 18},
  activeEmote: {
    color: '#808080',
    fontSize: 18,
  },
  emotesContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const AttendanceHeader = (props) => {
  const {peerId, expanded, expand, volume} = props;
  const peer = useSelector((state) => state.communication.peers[peerId]);
  const consumerSources = useSelector((state) => state.communication.consumers);
  const {displayName, consumers} = peer;
  let formattedName = displayName;
  if (formattedName.length > 19) {
    formattedName = formattedName.substring(0, 16) + '...';
  }
  let hasAudio = false;
  let playingAudio = false;
  let pausedAudio = true;
  let hasVideo = false;
  let playingVideo = false;
  let pausedVideo = true;
  for (let i = 0; i < consumers.length; i++) {
    const consumer = consumerSources[consumers[i]];
    if (consumer && consumer.track && consumer.track.kind === 'audio') {
      hasAudio = true;
      if (!consumer.remotelyPaused) {
        playingAudio = true;
      }
      if (!consumer.locallyPaused) {
        pausedAudio = false;
      }
    }
    if (consumer && consumer.track && consumer.track.kind === 'video') {
      hasVideo = true;
      if (!consumer.remotelyPaused) {
        playingVideo = true;
      }
      if (!consumer.locallyPaused) {
        pausedVideo = false;
      }
    }
  }
  const photoURL = '';
  const audioBlocked = hasAudio && pausedAudio;
  const videoBlocked = hasVideo && pausedVideo;

  let audioState = 'muted';
  if (playingAudio) {
    audioState = 'playing';
  } else if (hasAudio) {
    audioState = 'muted';
  }

  let audioColor = styles.unknownColor;
  if (audioState === 'playing') {
    audioColor = styles.activeColor;
  } else if (audioState === 'muted') {
    audioColor = styles.mutedColor;
  }

  let videoState = 'missing';
  if (playingVideo) {
    videoState = 'playing';
  }

  return (
    <TouchableOpacity style={styles.header} onPress={expand}>
      <View style={styles.identityContainer}>
        <Image
          user={{name: displayName, avatar: photoURL}}
          avatarStyle={styles.avatarStyle}
        />
        <Text style={styles.displayName}>{`${formattedName}`}</Text>
      </View>
      <View style={styles.emotesContainer}>
        {peer?.activeEmotes?.map((emote) => {
          return (
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.activeEmote]}
              name={EMOTE_ICONS[emote]}
            />
          );
        })}
      </View>
      <View style={styles.status}>
        {videoState === 'playing' ? (
          <View style={styles.statusContainer}>
            <Icon
              type="MaterialCommunityIcons"
              style={[styles.remoteStatus, styles.activeColor]}
              name="video"
            />
            {videoBlocked ? (
              <Icon
                type="MaterialCommunityIcons"
                style={styles.cancelOverlay}
                name="cancel"
              />
            ) : null}
          </View>
        ) : null}
        <View style={styles.statusContainer}>
          {volume === false ? null : (
            <VolumeIndicator
              volume={volume}
              disable={audioState !== 'playing'}
              vertical
              center
              backgroundColor={'transparent'}
            />
          )}
          <Icon
            type="MaterialCommunityIcons"
            style={[audioColor, styles.remoteStatus]}
            name={audioState === 'playing' ? 'microphone' : 'microphone-off'}
          />
          {audioBlocked ? (
            <Icon
              type="MaterialCommunityIcons"
              style={styles.cancelOverlay}
              name="cancel"
            />
          ) : null}
        </View>
        {expanded ? (
          <Icon
            type="MaterialCommunityIcons"
            style={styles.expandIcon}
            name="minus"
          />
        ) : (
          <Icon
            type="MaterialCommunityIcons"
            style={styles.expandIcon}
            name="plus"
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

AttendanceHeader.propTypes = {
  peerId: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  expand: PropTypes.func.isRequired,
};

export default AttendanceHeader;
