import React from 'react';
import {ScrollView, StyleSheet} from 'react-native';

import WorldSettingsListItems from './WorldSettingsListItems';

const Settings = () => {
  return (
    <ScrollView style={styles.container}>
      <WorldSettingsListItems />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {flex: 1},
});

export default Settings;
