import World from 'components/World';
import * as types from './actionTypes';
import {logEvent} from 'utils/firebase/analytics';
import {
  SET_POSITIONAL_MODE,
  ENABLE_LIVE_TRANSLATION,
  DISABLE_LIVE_TRANSLATION,
  SET_LIVE_TRANSLATION_LANGUAGE,
} from 'utils/firebase/analytics.config';

export const enableLowPowerMode = () => {
  return {
    type: types.ENABLE_LOW_POWER_MODE,
  };
};

export const disableLowPowerMode = () => {
  return {
    type: types.DISABLE_LOW_POWER_MODE,
  };
};

export const enableLiveTranslation = () => {
  logEvent(ENABLE_LIVE_TRANSLATION);
  return {
    type: types.ENABLE_LIVE_TRANSLATION,
  };
};

export const disableLiveTranslation = () => {
  logEvent(DISABLE_LIVE_TRANSLATION);
  return {
    type: types.DISABLE_LIVE_TRANSLATION,
  };
};

export const setLiveTranslation = (language) => {
  logEvent(SET_LIVE_TRANSLATION_LANGUAGE, {
    targetLanguage: language,
  });
  return {
    type: types.SET_LIVE_TRANSLATION_LANGUAGE,
    payload: language,
  };
};

export const setIncomingVideoEnabled = (enabled) => {
  return {
    type: types.SET_INCOMING_VIDEO_ENABLED,
    payload: {enabled},
  };
};

const changeIncomingVideo = async (dispatch, getState, enable) => {
  await dispatch(setIncomingVideoEnabled(enable));
  const state = getState();
  const {roomClient} = state.communication.control;
  return await roomClient.syncAllConsumers();
};

export const disableAllIncomingVideo = () => {
  return async (dispatch, getState) => {
    return changeIncomingVideo(dispatch, getState, false);
  };
};

export const enableAllIncomingVideo = () => {
  return async (dispatch, getState) => {
    return changeIncomingVideo(dispatch, getState, true);
  };
};

export const setPositionalMode = (mode) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {roomClient} = state.communication.control;
    await roomClient.setRoomVoicePositionalMode(mode);
    await roomClient.setRoomVideoPositionalMode(mode);
    await logEvent(SET_POSITIONAL_MODE, {
      meeting_id: roomClient.meetingId,
      mode: mode,
    });
  };
};

export const enableBubbleMode = (type, mode) => {
  return async (dispatch, getState) => {
    dispatch(showPositionalModeMessage(type, mode));
    dispatch(showBubbleDistance());
  };
};

export const showPositionalModeMessage = (type, mode) => {
  return async (dispatch, getState) => {
    // TODO: Show user a message showing that the mode has changed
    /* eslint-disable-next-line no-console */
    console.log(`${type} positional mode changed to ${mode}`);
  };
};

export const showBubbleDistance = () => {
  return async (dispatch, getState) => {
    const range = 10;
    World.runCommand(`show_voice_distance ${range} 1 1`);
  };
};

export const enablePositionalVoiceFallOff = () => {
  return {
    type: types.ENABLE_POSITIONAL_VOICE_FALL_OFF,
  };
};

export const disablePositionalVoiceFallOff = () => {
  return {
    type: types.DISABLE_POSITIONAL_VOICE_FALL_OFF,
  };
};

export const setPerspectiveMode = (perspectiveMode) => {
  return {
    type: types.SET_PERSPECTIVE_MODE,
    payload: perspectiveMode,
  };
};
