import React, {useContext, useState} from 'react';
import {View, Text, TouchableOpacity, Image, StyleSheet} from 'react-native';
import {showMessage} from 'react-native-flash-message';

import WebcamCapture from './WebcamCaptures';
import {I18nContext} from 'i18n/context/I18nContext';

// asset imports
import selfieIcon from 'images/CustomizationIcons/selfie.png';
import presetHeadIcon from 'images/CustomizationIcons/premadeheads.png';
import choosePhotoIcon from 'images/CustomizationIcons/photo.png';

const HeadOptions = ({styles, setShowPresetHeads, savePicture}) => {
  const {translate} = useContext(I18nContext);
  // selfie state: button, webcam, pic
  const [selfieState, setSelfieState] = useState('button');
  const [webcamPic, setWebcamPic] = useState('');
  // selectPicState: button, pic
  const [selectPicState, setSelectPicState] = useState('button');
  const [selectedPic, setSelectedPic] = useState('');

  const processImage = (dataURL) => {
    // reset all local state variables
    setSelfieState('button');
    setSelectPicState('button');
    setWebcamPic('');
    setSelectedPic('');
    // send pic data to unity
    savePicture(dataURL, true);
  };

  const displaySelectedPic = (blobEvent) => {
    // Get a reference to the file
    const file = blobEvent.target.files[0];

    // Encode the file using the FileReader API
    const reader = new window.FileReader();
    reader.onloadend = () => {
      setSelectedPic(reader.result);
    };
    reader.readAsDataURL(file);
    setSelectPicState('pic');
  };

  const showFlashMessage = (cbFunc) => {
    showMessage({
      message: translate('avatarPreview.imageFlashMessageTitle'),
      description: translate('avatarPreview.imageFlashMessageDescription'),
      icon: 'auto',
      autoHide: false,
      type: 'info',
      floating: true,
      style: {width: '95%'},
      titleStyle: {alignSelf: 'center'},
      hideOnPress: true,
      position: 'center',
      onPress: () => {
        cbFunc();
      },
    });
  };

  const selfieButtonDisplay = (
    <TouchableOpacity
      onPress={() => {
        setSelectPicState('button');
        showFlashMessage(() => {
          setSelfieState('webcam');
        });
      }}
      style={style.headButtons}>
      <Image source={selfieIcon} style={style.headButtonIcons} />
      <Text style={style.headButtonText}>
        {translate('avatarPreview.headSelfie')}
      </Text>
    </TouchableOpacity>
  );

  const selfieWebcamDisplay = (
    <View style={style.headButtons}>
      <WebcamCapture
        takePicture={(data) => {
          setWebcamPic(data);
          setSelfieState('pic');
        }}
        styles={style}
      />
    </View>
  );

  const selfieTakenDisplay = (
    <View style={style.headButtons}>
      <View style={style.webPicContainer}>
        <Image source={webcamPic} style={style.webPicImage} />
        <View style={style.webcamButtonContainer}>
          <TouchableOpacity
            onPress={() => processImage(webcamPic)}
            style={style.webcamButton}>
            <Text>{translate('avatarPreview.headSave')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );

  let selfieDisplay;
  switch (selfieState) {
    case 'button':
      selfieDisplay = selfieButtonDisplay;
      break;
    case 'webcam':
      selfieDisplay = selfieWebcamDisplay;
      break;
    case 'pic':
      selfieDisplay = selfieTakenDisplay;
      break;
    default:
      console.error(
        'Local state for selfie display incorrectly set - current state: ',
        selfieState,
      );
  }

  const selectPicButtonDisplay = (
    <TouchableOpacity
      onPress={() => {
        setSelfieState('button');
        showFlashMessage(() => {
          document.getElementById('fileUpload').click();
        });
      }}
      style={style.headButtons}>
      <Image source={choosePhotoIcon} style={style.headButtonIcons} />
      <Text style={style.headButtonText}>
        {translate('avatarPreview.headPicture')}
      </Text>
      <View style={style.selectFile}>
        <input
          type="file"
          id="fileUpload"
          onChange={displaySelectedPic}
          accept="image/*"
        />
      </View>
    </TouchableOpacity>
  );

  const selectPicSelectedDisplay = (
    <View style={style.headButtons}>
      <View style={style.webPicContainer}>
        <Image source={selectedPic} style={style.webPicImage} />
        <View style={style.webcamButtonContainer}>
          <TouchableOpacity
            onPress={() => processImage(selectedPic)}
            style={style.webcamButton}>
            <Text>{translate('avatarPreview.headSave')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );

  let selectPicDisplay;
  switch (selectPicState) {
    case 'button':
      selectPicDisplay = selectPicButtonDisplay;
      break;
    case 'pic':
      selectPicDisplay = selectPicSelectedDisplay;
      break;
    default:
      console.error(
        'Local state for selectPicDisplay incorrectly set. Current state: ',
        selectPicState,
      );
  }

  return (
    <View style={styles.tierSpacer}>
      {selfieDisplay}
      {selectPicDisplay}
      <TouchableOpacity
        onPress={() => setShowPresetHeads(true)}
        style={style.headButtons}>
        <Image source={presetHeadIcon} style={style.headButtonIcons} />
        <Text style={style.headButtonText}>
          {translate('avatarPreview.headPreset')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const style = StyleSheet.create({
  webPicContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  webPicImage: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: 'contain',
  },
  webcamImageContainer: {
    width: undefined,
    height: undefined,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  webcamImage: {
    flex: 1,
    height: '100%',
    width: 'auto',
  },
  webcamButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '65%',
  },
  webcamButton: {
    padding: 5,
    borderRadius: 5,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: '#fff',
    borderWidth: 1,
    color: '#fff',
  },
  selectFile: {
    display: 'none',
  },
  headButtons: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: '#fe6507',
    padding: 10,
    margin: 10,
  },
  headButtonIcons: {
    width: undefined,
    height: undefined,
    flex: 1,
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  headButtonText: {
    textAlign: 'center',
    color: '#fff',
  },
});

export default HeadOptions;
