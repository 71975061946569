import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {View} from 'react-native';
import hark from 'hark';

import VolumeIndicator from './VolumeIndicator';

const AudioMonitor = (props) => {
  const {stream, style} = props;

  const [volume, setVolume] = useState(0);

  useEffect(() => {
    let newHark;
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];

      if (audioTrack) {
        // eslint-disable-next-line no-undef
        const newStream = new MediaStream();

        newStream.addTrack(audioTrack);

        newHark = hark(newStream, {play: false});

        newHark.on('volume_change', (dBs, threshold) => {
          // The exact formula to convert from dBs (-100..0) to linear (0..1) is:
          //   Math.pow(10, dBs / 20)
          // However it does not produce a visually useful output, so let exagerate
          // it a bit. Also, let convert it from 0..1 to 0..10 and avoid value 1 to
          // minimize component renderings.
          const steps = 30;
          let audioVolume = Math.round(Math.pow(10, dBs / 85) * steps) / steps;

          if (audioVolume === 1) {
            audioVolume = 0;
          }

          setVolume(audioVolume);
        });
      }
    }
    return function cleanup() {
      if (newHark) {
        newHark.stop();
      }
    };
  }, [stream]);

  return (
    <View style={style}>
      <VolumeIndicator volume={volume} disable={!stream} />
    </View>
  );
};

AudioMonitor.defaultProps = {
  stream: null,
  style: null,
};

AudioMonitor.propTypes = {
  stream: PropTypes.shape({}),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default AudioMonitor;
