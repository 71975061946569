import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import {setBody, setAvatarLoading} from 'store/appearance';
import Appearances from 'utils/Appearances';
import UniversalButton from 'components/UniversalButton';
import {I18nContext} from 'i18n/context/I18nContext';
import {CHANGE_CUSTOMIZE_AVATAR_BODY} from 'utils/firebase/analytics.config';
import {logEvent} from 'utils/firebase/analytics';

//asset imports
import prevarrowIcon from 'images/CustomizationIcons/prevarrow.png';
import nextarrowIcon from 'images/CustomizationIcons/nextarrow.png';

// TODO: move this to a separate file so it isn't duplicated. currently exists in this file AND in the Avatar Preview
const styles = StyleSheet.create({
  button: {
    margin: 4,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  thumbnail: {
    flex: 1,
    width: null,
    height: null,
    resizeMode: 'contain',
    borderRadius: null,
  },
});

const BodySelection = (props) => {
  const {style, onSelect} = props;
  const userSelectedBody = useSelector((state) => state.appearance.body);
  const looks = useSelector((state) => state.appearance.looks);
  const avatarLoading = useSelector((state) => state.appearance.avatarLoading);
  const isFullBody = useSelector((state) => state.appearance.isFullBody);
  const appearanceData = useSelector(
    (state) => state.appearance.appearanceData,
  );
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);

  const bodyTypes = Appearances.getBodyTypes(appearanceData);

  const nextBody = () => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_BODY);
    // find the body at the NEXT index from the currently selected body
    const currentIndex = bodyTypes.findIndex((body) => {
      return body === userSelectedBody;
    });

    const nextBodyType =
      currentIndex === bodyTypes.length - 1
        ? bodyTypes[0]
        : bodyTypes[currentIndex + 1];

    // update local state
    dispatch(setBody(nextBodyType));
    // set the loading views
    dispatch(setAvatarLoading(true));
    // update the avatar with the new body and the saved looks for that body.
    Appearances.applyLook(looks[nextBodyType], nextBodyType, isFullBody);
    // run the parent component's function
    onSelect(nextBodyType);
  };

  const previousBody = () => {
    logEvent(CHANGE_CUSTOMIZE_AVATAR_BODY);
    // find the body at the PREVIOUS index from the currently selected body
    const currentIndex = bodyTypes.findIndex((body) => {
      return body === userSelectedBody;
    });

    const prevBody =
      currentIndex === 0
        ? bodyTypes[bodyTypes.length - 1]
        : bodyTypes[currentIndex - 1];

    // update the store
    dispatch(setBody(prevBody));
    // set the loading views
    dispatch(setAvatarLoading(true));
    // update the appearance to the users saved selections for that body
    Appearances.applyLook(looks[prevBody], prevBody, isFullBody);
    // run the parent component's function
    onSelect(prevBody);
  };

  return (
    <View style={style} pointerEvents="box-none">
      <UniversalButton
        imageSource={prevarrowIcon}
        onPress={previousBody}
        text={translate('avatarPreview.previousBody')}
        style={styles.button}
        color={'transparent'}
        textSize={12}
        thumbnailStyles={styles.thumbnail}
        disabled={avatarLoading}
      />
      <UniversalButton
        imageSource={nextarrowIcon}
        onPress={nextBody}
        text={translate('avatarPreview.nextBody')}
        style={styles.button}
        color={'transparent'}
        textSize={12}
        thumbnailStyles={styles.thumbnail}
        disabled={avatarLoading}
      />
    </View>
  );
};

BodySelection.defaultProps = {
  style: undefined,
  onSelect: () => {},
};

BodySelection.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  onSelect: PropTypes.func,
};

export default BodySelection;
