import React, {useState, useEffect, useContext} from 'react';
import {
  Platform,
  Share,
  View,
  Text,
  Modal,
  Clipboard,
  TouchableOpacity,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from 'react-native';
import PropTypes from 'prop-types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {I18nContext} from 'i18n/context/I18nContext';
import {logEvent} from 'utils/firebase/analytics';
import {SHARE_MEETING_INVITE} from 'utils/firebase/analytics.config';
import shareMeetingModalStyle from 'style/ShareMeetingModalStyle';

const ShareMeetingModal = ({
  inviteURL,
  peerCount,
  meetingMaxNumberParticipants,
  isRoomConnected,
  shouldDisplay,
}) => {
  const {translate} = useContext(I18nContext);

  const [isShareMeetingModalVisible, setIsShareMeetingModalVisible] = useState(
    false,
  );

  useEffect(() => {
    if (isRoomConnected) {
      // A little bit of a hack to allow time for peers to load in
      setTimeout(() => setIsShareMeetingModalVisible(true), 2000);
    }
  }, [isRoomConnected]);

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: translate('shareMeetingModal.joinMeeting', {inviteURL}),
      });
      if (result.action === Share.sharedAction) {
        setIsShareMeetingModalVisible(false);
        if (result.activityType) {
          // shared with activity type of result.activityType
          await logEvent(SHARE_MEETING_INVITE, {
            activity_type: result.activityType,
          });
        } else {
          // shared
          await logEvent(SHARE_MEETING_INVITE);
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      setIsShareMeetingModalVisible(false);
      Clipboard.setString(inviteURL);
      await logEvent(SHARE_MEETING_INVITE, {
        activity_type: 'copied to clipboard',
      });
    }
  };

  const copyToClipboardAndClose = () => {
    Clipboard.setString(inviteURL);
    setIsShareMeetingModalVisible(false);
  };

  const isModalVisible =
    isShareMeetingModalVisible &&
    peerCount === 0 &&
    meetingMaxNumberParticipants === 1 &&
    shouldDisplay;

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isModalVisible}
      supportedOrientations={[
        'portrait',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}>
      <TouchableWithoutFeedback
        onPress={() => {
          setIsShareMeetingModalVisible(false);
        }}>
        <View style={shareMeetingModalStyle.centeredView}>
          <TouchableWithoutFeedback>
            <View style={shareMeetingModalStyle.modalView}>
              <View style={shareMeetingModalStyle.headerContainer}>
                <Text style={shareMeetingModalStyle.headerText}>
                  {translate('shareMeetingModal.firstOneHere')}
                </Text>
              </View>
              <Icon
                type="MaterialCommunityIcons"
                style={shareMeetingModalStyle.closeIcon}
                name="close"
                onPress={() => {
                  setIsShareMeetingModalVisible(!isShareMeetingModalVisible);
                }}
              />
              <View style={shareMeetingModalStyle.divider} />
              <Text style={shareMeetingModalStyle.shareText}>
                {translate('shareMeetingModal.shareWithOthers')}
              </Text>
              {inviteURL ? (
                <Text style={shareMeetingModalStyle.linkText}>{inviteURL}</Text>
              ) : (
                <ActivityIndicator
                  size="small"
                  color="#0000ff"
                  style={shareMeetingModalStyle.linkText}
                />
              )}
              {Platform.OS === 'web' && (
                <TouchableOpacity
                  onPress={copyToClipboardAndClose}
                  disabled={!inviteURL}>
                  <View style={shareMeetingModalStyle.copyInfoContainer}>
                    <Icon
                      type="MaterialCommunityIcons"
                      style={shareMeetingModalStyle.copyIcon}
                      name="content-copy"
                      onPress={copyToClipboardAndClose}
                    />
                    <Text style={shareMeetingModalStyle.copyInfoText}>
                      {translate('shareMeetingModal.copyJoinInfo')}
                    </Text>
                  </View>
                </TouchableOpacity>
              )}
              {Platform.OS !== 'web' && (
                <View style={shareMeetingModalStyle.shareButtonWrapper}>
                  <TouchableOpacity onPress={onShare} disabled={!inviteURL}>
                    <View style={[shareMeetingModalStyle.shareButtonContainer]}>
                      <Icon
                        type="MaterialCommunityIcons"
                        name="export-variant"
                        style={[shareMeetingModalStyle.shareButtonIcon]}
                        color="white"
                      />
                      <Text style={[shareMeetingModalStyle.shareButtonText]}>
                        {translate('shareMeetingModal.shareInvite')}
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

ShareMeetingModal.defaultProps = {
  inviteURL: null,
  peerCount: 0,
};

ShareMeetingModal.propTypes = {
  inviteURL: PropTypes.string,
  peerCount: PropTypes.number,
  meetingMaxNumberParticipants: PropTypes.number.isRequired,
  isRoomConnected: PropTypes.bool.isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
};

export default ShareMeetingModal;
