const WorkingAnimation = {
  v: '5.2.1',
  fr: 30,
  ip: 0,
  op: 180,
  w: 1920,
  h: 1080,
  nm: 'Guy and girl working together black',
  ddd: 0,
  assets: [
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 6',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [84.912, 153.778, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-13.06, 8.63],
                        [-2.4, 7.69],
                        [6.12, -0.24],
                        [8.62, -1.69],
                      ],
                      o: [
                        [0, 0],
                        [12.77, 3.48],
                        [3.86, -2.54],
                        [2.13, -6.81],
                        [-15.98, 0.62],
                        [-13.15, 7.99],
                      ],
                      v: [
                        [-26.422, -10.175],
                        [-31.102, 13.005],
                        [10.788, 13.005],
                        [30.648, -3.885],
                        [20.898, -11.685],
                        [-12.532, -17.505],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 4',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [38.737, 218.823, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.66, -0.06],
                        [0, 0],
                        [-0.08, 1.65],
                        [0, 0],
                        [-1.04, 0.45],
                        [0, 0],
                        [-0.85, -0.59],
                        [0, 0],
                        [0.17, -1.15],
                        [0, 0],
                        [0.97, -0.4],
                        [0, 0],
                        [-0.68, -1.55],
                        [0, 0],
                        [-1.49, 0.57],
                        [0, 0],
                        [-0.11, 1.12],
                        [0, 0],
                        [0.92, 0.62],
                        [0, 0],
                        [1, -0.56],
                        [0, 0],
                        [0.04, -1.03],
                      ],
                      o: [
                        [-0.07, 1.66],
                        [0, 0],
                        [1.65, 0.06],
                        [0, 0],
                        [0.06, -1.14],
                        [0, 0],
                        [0.95, -0.42],
                        [0, 0],
                        [0.96, 0.65],
                        [0, 0],
                        [-0.15, 1.04],
                        [0, 0],
                        [-1.56, 0.65],
                        [0, 0],
                        [0.65, 1.46],
                        [0, 0],
                        [1.06, -0.4],
                        [0, 0],
                        [0.12, -1.1],
                        [0, 0],
                        [-0.95, -0.64],
                        [0, 0],
                        [-0.9, 0.51],
                        [0, 0],
                      ],
                      v: [
                        [-37.478, 11.213],
                        [-34.598, 14.343],
                        [-22.828, 14.823],
                        [-19.708, 11.963],
                        [-18.888, -5.357],
                        [-17.098, -7.957],
                        [-0.338, -15.327],
                        [2.552, -15.057],
                        [16.642, -5.487],
                        [17.922, -2.547],
                        [15.372, 14.173],
                        [13.552, 16.493],
                        [-2.988, 23.293],
                        [-4.588, 27.293],
                        [0.252, 38.163],
                        [4.072, 39.743],
                        [31.052, 29.433],
                        [32.962, 26.953],
                        [37.462, -15.497],
                        [36.162, -18.287],
                        [5.082, -39.427],
                        [1.912, -39.557],
                        [-34.688, -18.897],
                        [-36.208, -16.417],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Rectangle 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [99.588, 131.269, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [10.671, 129.74], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 5.336, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.105881996453, 0.337255001068, 0.607842981815, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 34.216, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [100.302, 130.217, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-55.016, 59.592],
                        [36.264, -72.548],
                        [55.014, -59.587],
                        [-36.266, 72.542],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [161.412, 41.213, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-0.68, -1.52],
                        [0, 0],
                        [1.5, -0.68],
                        [0, 0],
                        [0.05, -1.13],
                        [0, 0],
                        [-0.86, -0.58],
                        [0, 0],
                        [-1, 0.58],
                        [0, 0],
                        [-0.02, 1.06],
                        [0, 0],
                        [-1.69, -0.07],
                        [0, 0],
                        [-0.01, -1.6],
                        [0, 0],
                        [1, -0.52],
                        [0, 0],
                        [0.91, 0.62],
                        [0, 0],
                        [-0.16, 1.13],
                        [0, 0],
                        [-0.94, 0.42],
                      ],
                      o: [
                        [1.52, -0.68],
                        [0, 0],
                        [0.67, 1.51],
                        [0, 0],
                        [-1.04, 0.47],
                        [0, 0],
                        [-0.03, 1.03],
                        [0, 0],
                        [0.96, 0.65],
                        [0, 0],
                        [0.91, -0.53],
                        [0, 0],
                        [0.02, -1.69],
                        [0, 0],
                        [1.59, 0.06],
                        [0, 0],
                        [0.01, 1.13],
                        [0, 0],
                        [-0.98, 0.51],
                        [0, 0],
                        [-0.94, -0.64],
                        [0, 0],
                        [0.14, -1.02],
                        [0, 0],
                      ],
                      v: [
                        [-4.693, -39.796],
                        [-0.714, -38.266],
                        [4.047, -27.496],
                        [2.547, -23.546],
                        [-13.263, -16.416],
                        [-15.033, -13.796],
                        [-15.723, 4.504],
                        [-14.403, 7.094],
                        [-0.323, 16.674],
                        [2.877, 16.784],
                        [17.497, 8.274],
                        [18.987, 5.714],
                        [19.227, -12.156],
                        [22.357, -15.116],
                        [34.247, -14.606],
                        [37.117, -11.646],
                        [37.457, 17.234],
                        [35.847, 19.934],
                        [-1.973, 39.714],
                        [-5.053, 39.534],
                        [-36.143, 18.394],
                        [-37.423, 15.504],
                        [-31.663, -26.136],
                        [-29.923, -28.456],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.164706006646, 0.44313699007, 0.768626987934, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path 5',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [104.55, 143.309, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.05, 1.76],
                        [4.13, 3.31],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1.05, -1.75],
                        [0, 0],
                        [0, 0],
                        [6.67, -1.27],
                      ],
                      v: [
                        [10.619, 3.062],
                        [-0.481, -9.058],
                        [-6.911, -4.528],
                        [-10.711, 9.062],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Path',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [83.287, 136.507, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.45, -0.19],
                        [3.96, -2.83],
                        [0, 0],
                        [-1.97, 2.5],
                        [0, 0],
                        [-4.08, 6.21],
                        [1.09, -0.21],
                      ],
                      o: [
                        [-4.2, 0.18],
                        [0, 0],
                        [0, 0],
                        [1.63, -2.06],
                        [0, 0],
                        [0, 0],
                        [-4.27, 0.79],
                      ],
                      v: [
                        [-1.669, -9.132],
                        [-22.909, 7.728],
                        [1.701, 11.308],
                        [4.661, 1.298],
                        [10.931, -4.282],
                        [22.911, -6.022],
                        [12.151, -11.302],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_1',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Shape 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [33.07, 52.056, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [12.17, -3.21],
                        [-3.21, -12.17],
                      ],
                      o: [
                        [-3.2, -12.17],
                        [-12.18, 3.2],
                        [0, 0],
                      ],
                      v: [
                        [12.661, -34.379],
                        [-15.179, -50.619],
                        [-31.419, -22.779],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ind: 1,
                  ty: 'sh',
                  ix: 2,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-31.423, -22.779],
                        [-11.913, 51.381],
                        [32.177, 39.781],
                        [12.667, -34.379],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 2',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'mm',
                  mm: 1,
                  nm: 'Merge Paths 1',
                  mn: 'ADBE Vector Filter - Merge',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.952941000462, 0.949020028114, 0.949020028114, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Shape',
              np: 4,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [78.416, 75.126, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [8.726, -10.43],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.616, 9.103],
                        [0, 0],
                      ],
                      v: [
                        [-73.656, -51.737],
                        [-47.276, -61.587],
                        [-13.386, 29.193],
                        [-14.551, 46.522],
                        [-33.576, 55.633],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_2',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Rectangle 9',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [65.552, 10.829, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Rectangle 8',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [104.484, 26.957, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 45, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Rectangle 7',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [120.612, 65.894, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 90, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Rectangle 6',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [104.49, 104.831, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 135, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Rectangle 5',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [65.552, 120.959, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Rectangle 4',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [26.613, 104.831, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 45, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: 'Rectangle 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [10.479, 65.894, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 90, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: 'Rectangle 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [26.619, 26.957, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [18.861, 18.861], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 0, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 135, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: 'Oval 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [65.553, 65.894, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: {a: 0, k: [83.355, 83.355], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  nm: 'Ellipse Path 1',
                  mn: 'ADBE Vector Shape - Ellipse',
                  hd: false,
                },
                {
                  ty: 'st',
                  c: {
                    a: 0,
                    k: [0.639216005802, 0.639216005802, 0.639216005802, 1],
                    ix: 3,
                  },
                  o: {a: 0, k: 100, ix: 4},
                  w: {a: 0, k: 14.749, ix: 5},
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  ml2: {a: 0, k: 4, ix: 8},
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Oval',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_3',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: 'hmr',
          refId: 'comp_4',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {
              a: 1,
              k: [
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 0,
                  s: [-35],
                  e: [48],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 30,
                  s: [48],
                  e: [-35],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 60,
                  s: [-35],
                  e: [48],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 90,
                  s: [48],
                  e: [-35],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 120,
                  s: [-35],
                  e: [48],
                },
                {
                  i: {x: [0.833], y: [0.833]},
                  o: {x: [0.167], y: [0.167]},
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 150,
                  s: [48],
                  e: [-35],
                },
                {t: 180},
              ],
              ix: 10,
            },
            p: {
              a: 1,
              k: [
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 0,
                  s: [35.5, 110.5, 0],
                  e: [60.5, 107.5, 0],
                  to: [4.16666650772095, -0.5, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 30,
                  s: [60.5, 107.5, 0],
                  e: [35.5, 110.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 60,
                  s: [35.5, 110.5, 0],
                  e: [60.5, 107.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 90,
                  s: [60.5, 107.5, 0],
                  e: [35.5, 110.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 120,
                  s: [35.5, 110.5, 0],
                  e: [60.5, 107.5, 0],
                  to: [0, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: {x: 0.833, y: 0.833},
                  o: {x: 0.167, y: 0.167},
                  n: '0p833_0p833_0p167_0p167',
                  t: 150,
                  s: [60.5, 107.5, 0],
                  e: [35.5, 110.5, 0],
                  to: [0, 0, 0],
                  ti: [4.16666650772095, -0.5, 0],
                },
                {t: 180},
              ],
              ix: 2,
            },
            a: {a: 0, k: [44, 103.5, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          w: 118,
          h: 136,
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [165.168, 208.378, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-37.74, -29.906],
                        [-48.42, 29.904],
                        [32.76, 24.214],
                        [48.42, -12.106],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.278430998325, 0.274509996176, 0.274509996176, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [99.378, 165.089, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.33, 8.41],
                        [8.41, 1.33],
                        [0, 0],
                        [0, 0],
                        [-7.62, -18.37],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1.34, -8.41],
                        [0, 0],
                        [0, 0],
                        [-7.62, -18.37],
                        [0, 0],
                        [0, 0],
                        [8.41, 1.33],
                      ],
                      v: [
                        [58.472, 46.61],
                        [45.662, 28.97],
                        [-3.728, 21.14],
                        [-35.548, -51.58],
                        [-57.058, -35.19],
                        [-25.608, 48.9],
                        [40.832, 59.43],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: 'comp_4',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: 'Path 6',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [55.266, 87.41, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [6.11, -3.17],
                        [-3.01, 5.05],
                        [6.71, 6.5],
                        [-1.27, 1.55],
                      ],
                      o: [
                        [0, 0],
                        [-4.79, -3.11],
                        [3.75, -6.32],
                        [-5.37, -5.2],
                        [2.37, -0.34],
                      ],
                      v: [
                        [10.583, -4.37],
                        [4.853, 22.12],
                        [-5.777, 6.49],
                        [-6.727, -9.23],
                        [-9.417, -22.12],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: 'Path 5',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [34.614, 92.309, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.66, -9.34],
                        [-7.58, -20.69],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [15.52, 15],
                        [2.5, 6.8],
                        [-18.26, -1.73],
                        [0, 0],
                      ],
                      v: [
                        [6.709, -22.922],
                        [22.669, 3.318],
                        [2.089, 24.258],
                        [-22.861, 10.238],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: 'Path 4',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [52.857, 97.804, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1, -3.37],
                        [1.07, -5.98],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [1, 3.36],
                        [0, 0],
                        [0, 0],
                        [4.25, 0.08],
                      ],
                      v: [
                        [11.003, -10.465],
                        [10.813, 14.904],
                        [-11.687, 6.314],
                        [-2.997, -14.906],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: 'Path 3',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [88.442, 28.399, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-1.3, -1.18],
                        [0, 0],
                        [-5.16, 5.68],
                        [0, 0],
                        [5.68, 5.17],
                        [0, 0],
                        [1.18, -1.3],
                      ],
                      o: [
                        [-1.17, 1.3],
                        [0, 0],
                        [5.68, 5.16],
                        [0, 0],
                        [5.16, -5.68],
                        [0, 0],
                        [-1.3, -1.18],
                        [0, 0],
                      ],
                      v: [
                        [-27.453, -10.234],
                        [-27.233, -5.744],
                        [5.027, 23.546],
                        [24.667, 22.596],
                        [24.667, 22.596],
                        [23.717, 2.956],
                        [-8.543, -26.324],
                        [-13.033, -26.114],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.278430998325, 0.274509996176, 0.274509996176, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: 'Rectangle 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [55.42, 73.354, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ty: 'rc',
                  d: 1,
                  s: {a: 0, k: [14.118, 157.913], ix: 2},
                  p: {a: 0, k: [0, 0], ix: 3},
                  r: {a: 0, k: 7.059, ix: 4},
                  nm: 'Rectangle Path 1',
                  mn: 'ADBE Vector Shape - Rect',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.278430998325, 0.274509996176, 0.274509996176, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 40.877, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Rectangle',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: 'Path 2',
          sr: 1,
          ks: {
            o: {a: 0, k: 100, ix: 11},
            r: {a: 0, k: 0, ix: 10},
            p: {a: 0, k: [33.616, 113.499, 0], ix: 2},
            a: {a: 0, k: [0, 0, 0], ix: 1},
            s: {a: 0, k: [100, 100, 100], ix: 6},
          },
          ao: 0,
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.47, -6.49],
                        [-8.75, -3.89],
                        [0, 0],
                        [-9.377, 4.705],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-2.48, 6.5],
                        [0, 0],
                        [0, 0],
                        [3.258, -1.634],
                        [0, 0],
                        [-9.07, 1.46],
                      ],
                      v: [
                        [-15.783, -3.657],
                        [7.377, 16.143],
                        [14.967, 14.533],
                        [26.751, 11.76],
                        [29.162, -1.622],
                        [14.517, -16.147],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false,
                },
                {
                  ty: 'fl',
                  c: {
                    a: 0,
                    k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                    ix: 4,
                  },
                  o: {a: 0, k: 100, ix: 5},
                  r: 1,
                  nm: 'Fill 1',
                  mn: 'ADBE Vector Graphic - Fill',
                  hd: false,
                },
                {
                  ty: 'tr',
                  p: {a: 0, k: [0, 0], ix: 2},
                  a: {a: 0, k: [0, 0], ix: 1},
                  s: {a: 0, k: [100, 100], ix: 3},
                  r: {a: 0, k: 0, ix: 6},
                  o: {a: 0, k: 100, ix: 7},
                  sk: {a: 0, k: 0, ix: 4},
                  sa: {a: 0, k: 0, ix: 5},
                  nm: 'Transform',
                },
              ],
              nm: 'Path',
              np: 2,
              cix: 2,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false,
            },
          ],
          ip: 0,
          op: 180,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: 'wrnch',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 10,
              s: [0],
              e: [-15],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 15,
              s: [-15],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [0],
              e: [-15],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 50,
              s: [-15],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 80,
              s: [0],
              e: [-15],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 85,
              s: [-15],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 115,
              s: [0],
              e: [-15],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [-15],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [0],
              e: [-15],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 155,
              s: [-15],
              e: [0],
            },
            {t: 180},
          ],
          ix: 10,
        },
        p: {a: 0, k: [875, 360, 0], ix: 2},
        a: {a: 0, k: [161.5, 41, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      w: 201,
      h: 260,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: 'arm2',
      refId: 'comp_1',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 10,
              s: [0],
              e: [-12],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 15,
              s: [-12],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [0],
              e: [-12],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 50,
              s: [-12],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 80,
              s: [0],
              e: [-12],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 85,
              s: [-12],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 115,
              s: [0],
              e: [-12],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [-12],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [0],
              e: [-12],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 155,
              s: [-12],
              e: [0],
            },
            {t: 180},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [658.5, 369.5, 0],
              e: [663.5, 382, 0],
              to: [0.83333331346512, 2.08333325386047, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 15,
              s: [663.5, 382, 0],
              e: [658.5, 369.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [658.5, 369.5, 0],
              e: [663.5, 382, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [663.5, 382, 0],
              e: [658.5, 369.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [658.5, 369.5, 0],
              e: [663.5, 382, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 85,
              s: [663.5, 382, 0],
              e: [658.5, 369.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 115,
              s: [658.5, 369.5, 0],
              e: [663.5, 382, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [663.5, 382, 0],
              e: [658.5, 369.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 150,
              s: [658.5, 369.5, 0],
              e: [663.5, 382, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 155,
              s: [663.5, 382, 0],
              e: [658.5, 369.5, 0],
              to: [0, 0, 0],
              ti: [0.83333331346512, 2.08333325386047, 0],
            },
            {t: 180},
          ],
          ix: 2,
        },
        a: {a: 0, k: [21.5, 19.5, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      w: 69,
      h: 132,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path 2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 10,
              s: [0],
              e: [10],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 15,
              s: [10],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [0],
              e: [10],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 50,
              s: [10],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 80,
              s: [0],
              e: [10],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 85,
              s: [10],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 115,
              s: [0],
              e: [10],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [10],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [0],
              e: [10],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 155,
              s: [10],
              e: [0],
            },
            {t: 180},
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [690.916, 470.126, 0],
              e: [720.916, 477.626, 0],
              to: [5, 1.25, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 15,
              s: [720.916, 477.626, 0],
              e: [690.916, 470.126, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 45,
              s: [690.916, 470.126, 0],
              e: [720.916, 477.626, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 50,
              s: [720.916, 477.626, 0],
              e: [690.916, 470.126, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 80,
              s: [690.916, 470.126, 0],
              e: [720.916, 477.626, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 85,
              s: [720.916, 477.626, 0],
              e: [690.916, 470.126, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 115,
              s: [690.916, 470.126, 0],
              e: [720.916, 477.626, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [720.916, 477.626, 0],
              e: [690.916, 470.126, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 150,
              s: [690.916, 470.126, 0],
              e: [720.916, 477.626, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 155,
              s: [720.916, 477.626, 0],
              e: [690.916, 470.126, 0],
              to: [0, 0, 0],
              ti: [5, 1.25, 0],
            },
            {t: 180},
          ],
          ix: 2,
        },
        a: {a: 0, k: [-24.5, 45, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [14.01, 1.28],
                    [0, 0],
                    [-5.181, 6.303],
                  ],
                  o: [
                    [0, 0],
                    [15.5, 1.41],
                    [0, 0],
                    [0, 0],
                    [5.181, -6.303],
                  ],
                  v: [
                    [-13.386, 29.193],
                    [65.464, 37.893],
                    [58.164, 61.533],
                    [-33.576, 55.633],
                    [-31.847, 34.927],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [664.532, 428.907, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -0.01],
                    [0, 0],
                    [1.2, -22.56],
                    [-0.19, -21.34],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-10.71, -6.92],
                    [0, 0],
                    [-2.43, 45.66],
                    [0, 0],
                    [0, 0],
                    [-3.21, -43.1],
                  ],
                  v: [
                    [32.93, -79.156],
                    [-21.86, -91.536],
                    [-42.56, -54.876],
                    [-50.78, 91.534],
                    [50.78, 87.314],
                    [46.8, -13.786],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [670.771, 345.761, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [7.61, 8.7],
                    [15.86, 4.98],
                    [-5.19, 1.73],
                    [0, 2.38],
                  ],
                  o: [
                    [-8.01, -9.14],
                    [-2.68, -0.84],
                    [5.19, -1.73],
                    [0, -2.38],
                  ],
                  v: [
                    [24.025, 2.314],
                    [-20.515, -13.976],
                    [-25.275, -4.676],
                    [22.695, 13.844],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.45098039215686275, 0.45098039215686275, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [670.504, 344.905, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.16, 8.19],
                    [16.15, 3.95],
                    [-5.07, 2.06],
                    [0.15, 2.38],
                  ],
                  o: [
                    [-8.57, -8.61],
                    [-2.73, -0.68],
                    [5.07, -2.06],
                    [-0.15, -2.37],
                  ],
                  v: [
                    [24.075, -0.762],
                    [-21.415, -14.172],
                    [-25.565, -4.592],
                    [23.145, 14.028],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.45098039215686275, 0.45098039215686275, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [669.074, 541.05, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-30.42, 0],
                    [0, 0],
                    [17.89, 3.43],
                    [1.71, 23.77],
                  ],
                  o: [
                    [0, 0],
                    [3.45, 0],
                    [0, 0],
                    [-19.78, -3.79],
                    [0, 0],
                  ],
                  v: [
                    [-44.459, -24.304],
                    [37.471, -27.744],
                    [44.461, 19.326],
                    [0.461, 27.086],
                    [-43.219, -7.014],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [680.699, 265.266, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.67, -20.87],
                    [0, 0],
                    [6.3, 1.68],
                    [5, -2.26],
                    [9.3, -8.9],
                    [7.89, 3.36],
                    [-17.85, 0.21],
                    [-1.18, 0.1],
                    [3, 13.5],
                    [-3.75, 3.75],
                    [-2.03, -0.49],
                    [0, 0],
                    [-11.91, 1.5],
                  ],
                  o: [
                    [0, 0],
                    [0.02, 2.09],
                    [-7.86, -2.11],
                    [-4.99, 2.25],
                    [-9.31, 8.89],
                    [-7.88, -3.36],
                    [1.36, -0.01],
                    [0, 0],
                    [-1.33, -5.97],
                    [12, -11.25],
                    [32.25, 6.75],
                    [5.48, -1.75],
                    [35.09, -4.43],
                  ],
                  v: [
                    [50.736, 25.599],
                    [13.016, 19.769],
                    [5.216, 23.939],
                    [-15.894, 16.339],
                    [-18.014, 48.999],
                    [-44.224, 35.179],
                    [-42.234, -9.711],
                    [-38.424, -9.891],
                    [-51.924, -25.411],
                    [-45.924, -41.911],
                    [-26.424, -50.911],
                    [-4.674, -26.481],
                    [20.886, -31.471],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [706.541, 308.945, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.19, -0.58],
                  ],
                  o: [
                    [0.2, 1.67],
                    [0, 0],
                  ],
                  v: [
                    [-3.703, -3.058],
                    [3.707, 3.062],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 2.376, ix: 5},
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [680.603, 327.77, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.79, 5.68],
                    [-2.9, -1.18],
                    [-0.62, -3.27],
                  ],
                  o: [
                    [0, 0],
                    [-0.78, 2.24],
                    [0, 0],
                  ],
                  v: [
                    [-6.658, -11.369],
                    [4.292, -7.039],
                    [6.662, 11.371],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [666.475, 329.502, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-5.87, 11.26],
                    [2.33, 30.32],
                    [0, 0],
                    [3.07, -2.83],
                    [-1.59, -4.35],
                    [0, 0],
                  ],
                  o: [
                    [5.87, -11.27],
                    [0, 0],
                    [4.29, 3.41],
                    [-3.06, 2.84],
                    [0, 0],
                    [-8.14, 1.76],
                  ],
                  v: [
                    [-25.797, 20.925],
                    [-5.167, -27.285],
                    [11.943, -17.485],
                    [18.063, -8.435],
                    [26.483, 26.555],
                    [9.803, 23.665],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [713.872, 295.354, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [1.12, -1.56],
                    [0, 0],
                  ],
                  o: [
                    [-0.26, 6.61],
                    [-1.12, 1.55],
                    [0, 0],
                  ],
                  v: [
                    [-1.11, -7.168],
                    [3.09, 4.952],
                    [-3.24, 7.172],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 2.376, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [668.25, 837.375, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-134.25, 90.375],
                    [134.25, 90.375],
                    [134.25, -90.375],
                    [48.75, -90.375],
                    [48.75, -44.625],
                    [-42, -44.625],
                    [-42, 0.375],
                    [-134.25, 0.375],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [1, 0.45098039215686275, 0.45098039215686275, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [687.07, 293.593, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0.72, -26.53],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-27.42, 10.04],
                    [0, 0],
                  ],
                  v: [
                    [-28.214, -31.336],
                    [33.066, -28.606],
                    [22.376, 29.764],
                    [-33.064, -1.496],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [711.421, 610.558, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -36.23],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 36.23],
                    [0, 0],
                  ],
                  v: [
                    [-43.933, -70.128],
                    [43.937, -31.798],
                    [30.497, 70.132],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 49.611, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [660.298, 633.917, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -36.23],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 36.23],
                    [0, 0],
                  ],
                  v: [
                    [-1.476, -99.055],
                    [5.874, 5.005],
                    [-5.876, 99.055],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 46.93, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [766.955, 728.804, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.46, 6.46],
                    [6.46, -6.46],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 4.023, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [754.035, 727.135, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.46, 6.46],
                    [6.46, -6.46],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 4.023, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [758.566, 733.483, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-7.28, 9.99],
                    [-0.15, -6.41],
                    [14.63, 1.22],
                  ],
                  o: [
                    [0, 0],
                    [23.28, 10.85],
                    [0.15, 6.42],
                    [-6.12, -6.67],
                  ],
                  v: [
                    [-38.13, -13.728],
                    [-19.06, -13.728],
                    [39.96, 8.862],
                    [-36.87, 12.702],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [734.964, 707.653, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.991, -17.462],
                    [-4.989, 17.458],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3},
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 17.431, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [681.706, 774.005, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.46, 6.46],
                    [6.46, -6.46],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 4.023, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [668.787, 772.339, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-6.46, 6.46],
                    [6.46, -6.46],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 4.023, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [673.317, 778.686, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-7.28, 9.99],
                    [-0.15, -6.41],
                    [14.63, 1.22],
                  ],
                  o: [
                    [0, 0],
                    [23.27, 10.85],
                    [0.15, 6.42],
                    [-6.12, -6.68],
                  ],
                  v: [
                    [-38.13, -13.728],
                    [-19.06, -13.728],
                    [39.96, 8.862],
                    [-36.87, 12.702],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 24,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [649, 753.825, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [4.187, -18.084],
                    [-4.183, 18.086],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 17.431, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 25,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1312.372, 736.947, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-11.87, -14.94],
                    [1.19, -18.16],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-40.26, 45.12],
                  ],
                  o: [
                    [49.55, 62.37],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [38.21, -10.08],
                  ],
                  v: [
                    [30.367, -91.921],
                    [73.417, 101.389],
                    [-35.033, 81.939],
                    [-31.263, 10.669],
                    [-49.363, 24.749],
                    [-74.173, -28.891],
                    [-26.563, -91.921],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.094117999077, 0.094117999077, 0.094117999077, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 26,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1263.115, 880.957, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [48.08, 15.15],
                  ],
                  o: [
                    [-55.28, 0],
                    [0, 0],
                  ],
                  v: [
                    [70.488, 8.322],
                    [-70.492, -8.318],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.278430998325, 0.274509996176, 0.274509996176, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 1.341, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 27,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1330.912, 853.618, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [28.14, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 28.14],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-54.008, -35.657],
                    [54.003, -32.287],
                    [54.003, -15.297],
                    [3.053, 35.653],
                    [-54.008, 35.653],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 28,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [824.557, 274.73, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [13.22, 7.16],
                    [14.15, -4.58],
                    [-2.99, -8.64],
                    [0, 0],
                  ],
                  o: [
                    [-13.22, -7.16],
                    [-9.46, 3.07],
                    [0, 0],
                    [0.16, -2.14],
                  ],
                  v: [
                    [15.703, -3.905],
                    [-26.577, -9.205],
                    [-27.177, 11.455],
                    [31.733, 11.455],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 29,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1135.763, 681.811, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-7.7, -17.63],
                    [5.12, -18.77],
                    [9.41, 4.04],
                    [0, 0],
                  ],
                  o: [
                    [7.7, 17.63],
                    [-3.43, 12.57],
                    [0, 0],
                    [2.34, -0.21],
                  ],
                  v: [
                    [4.555, -20.818],
                    [9.985, 35.422],
                    [-12.565, 36.082],
                    [-12.075, -42.238],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 30,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1220.281, 719.526, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [76.248, -29.574],
                    [5.468, 29.576],
                    [-76.252, -8.304],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3},
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 32.18, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 31,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1323.63, 563.217, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.34, -2.1],
                    [0, 0],
                    [4.09, -5.85],
                    [0, 0],
                    [-3.88, -7.36],
                    [4.37, -7.67],
                    [-3.91, -1.84],
                    [-1.73, 13.06],
                    [-6.26, 16.13],
                    [5.79, -0.79],
                    [0.42, 1.42],
                    [9.07, 3.54],
                    [3.61, -5.36],
                    [13.86, -11.01],
                    [1.38, -2.76],
                    [2.68, -1.54],
                    [-4.18, -11.98],
                  ],
                  o: [
                    [0, 0],
                    [2.33, 4.18],
                    [0, 0],
                    [2.91, -6.56],
                    [3.88, 7.35],
                    [-4.36, 7.67],
                    [3.91, 1.84],
                    [1.73, -13.07],
                    [5.01, -12.91],
                    [0.32, -0.21],
                    [0.09, -18.23],
                    [-4.53, -1.77],
                    [-9.79, -11.19],
                    [-1.93, 1.52],
                    [-2.31, -0.88],
                    [-12.17, 6.98],
                    [0, 0],
                  ],
                  v: [
                    [-17.356, 3.037],
                    [-1.566, 4.597],
                    [-7.806, 23.677],
                    [2.904, 27.637],
                    [17.584, 23.377],
                    [0.684, 46.897],
                    [9.154, 60.967],
                    [32.924, 59.977],
                    [44.704, 7.437],
                    [33.614, -6.343],
                    [33.404, -8.903],
                    [20.924, -61.593],
                    [8.494, -54.483],
                    [-27.146, -60.683],
                    [-32.076, -54.133],
                    [-39.576, -53.363],
                    [-39.566, -5.333],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 32,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1278.661, 604.44, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [5.66, 1.13],
                  ],
                  o: [
                    [-0.77, 1.67],
                    [0, 0],
                  ],
                  v: [
                    [4.936, -2.059],
                    [-4.934, 1.831],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 2.682, ix: 5},
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 33,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1301.704, 634.384, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.41, 5.62],
                    [3.49, -0.63],
                    [1.44, -3.47],
                  ],
                  o: [
                    [0, 0],
                    [0.35, 2.65],
                    [0, 0],
                  ],
                  v: [
                    [10.612, -11.078],
                    [-2.978, -9.038],
                    [-10.608, 11.082],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 34,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1311.688, 634.694, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.84, 13.81],
                    [-4.1, 9.92],
                    [0, 0],
                    [-2.72, -3.85],
                    [2.78, -4.43],
                    [0, 0],
                  ],
                  o: [
                    [-3.84, -13.82],
                    [0, 0],
                    [-5.53, 2.76],
                    [2.73, 3.85],
                    [0, 0],
                    [8.58, 3.85],
                  ],
                  v: [
                    [25.887, 24.906],
                    [21.307, -31.064],
                    [0.117, -24.254],
                    [-13.083, -11.164],
                    [-26.243, 20.976],
                    [-7.143, 21.686],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 35,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1265.587, 872.331, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [14.17, -35.42],
                    [0, 0],
                  ],
                  o: [
                    [-42.51, 0],
                    [-14.17, 35.43],
                    [0, 0],
                  ],
                  v: [
                    [79.367, -23.506],
                    [-78.303, -0.206],
                    [39.997, 23.724],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 57.656, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 36,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1275.241, 585.43, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-0.41, -2.12],
                    [0, 0],
                  ],
                  o: [
                    [-2.88, 6.89],
                    [0.41, 2.12],
                    [0, 0],
                  ],
                  v: [
                    [5.041, -7.843],
                    [-5.019, 2.557],
                    [0.401, 7.847],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.090195998549, 0.294117987156, 0.533333003521, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 2.682, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 37,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1309.67, 589.069, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [5.42, -29.46],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [27.93, 17.51],
                    [0, 0],
                  ],
                  v: [
                    [38.691, -28.71],
                    [-24.529, -36.95],
                    [-38.689, 31.66],
                    [33.861, 17.58],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 38,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1049.045, 357.122, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.001, -20.924],
                    [18.119, -10.464],
                    [18.119, 10.466],
                    [-0.001, 20.926],
                    [-18.121, 10.466],
                    [-18.121, -10.464],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 39,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.549, 357.122, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.001, -20.924],
                    [18.119, -10.464],
                    [18.119, 10.466],
                    [-0.001, 20.926],
                    [-18.121, 10.466],
                    [-18.121, -10.464],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 40,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.553, 606.894, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {a: 0, k: [52.802, 52.802], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.447059005499, 0.447059005499, 0.447059005499, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 41,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1072.544, 680.807, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [61.33, 34.795], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.909803986549,
                  0.909803986549,
                  0.909803986549,
                  0.090000003576,
                ],
                ix: 4,
              },
              o: {a: 0, k: 9, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 42,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1049.892, 517.092, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Fill Mask',
              mn: 'ADBE Fill-0001',
              ix: 1,
              v: {a: 0, k: 0, ix: 1},
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Fill-0007',
              ix: 2,
              v: {a: 0, k: 0, ix: 2},
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Fill-0002',
              ix: 3,
              v: {
                a: 1,
                k: [
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 10,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 15,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 45,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 50,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 80,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 85,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 115,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {t: 120},
                ],
                ix: 3,
              },
            },
            {
              ty: 7,
              nm: 'Invert',
              mn: 'ADBE Fill-0006',
              ix: 4,
              v: {a: 0, k: 0, ix: 4},
            },
            {
              ty: 0,
              nm: 'Horizontal Feather',
              mn: 'ADBE Fill-0003',
              ix: 5,
              v: {a: 0, k: 0, ix: 5},
            },
            {
              ty: 0,
              nm: 'Vertical Feather',
              mn: 'ADBE Fill-0004',
              ix: 6,
              v: {a: 0, k: 0, ix: 6},
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Fill-0005',
              ix: 7,
              v: {a: 0, k: 1, ix: 7},
            },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {a: 0, k: [12.746, 12.746], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 43,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1073.365, 517.092, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Fill Mask',
              mn: 'ADBE Fill-0001',
              ix: 1,
              v: {a: 0, k: 0, ix: 1},
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Fill-0007',
              ix: 2,
              v: {a: 0, k: 0, ix: 2},
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Fill-0002',
              ix: 3,
              v: {
                a: 1,
                k: [
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 10,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 15,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 45,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 50,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 80,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 85,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 115,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {t: 120},
                ],
                ix: 3,
              },
            },
            {
              ty: 7,
              nm: 'Invert',
              mn: 'ADBE Fill-0006',
              ix: 4,
              v: {a: 0, k: 0, ix: 4},
            },
            {
              ty: 0,
              nm: 'Horizontal Feather',
              mn: 'ADBE Fill-0003',
              ix: 5,
              v: {a: 0, k: 0, ix: 5},
            },
            {
              ty: 0,
              nm: 'Vertical Feather',
              mn: 'ADBE Fill-0004',
              ix: 6,
              v: {a: 0, k: 0, ix: 6},
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Fill-0005',
              ix: 7,
              v: {a: 0, k: 1, ix: 7},
            },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {a: 0, k: [12.746, 12.746], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 44,
      ty: 4,
      nm: 'Oval',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1096.838, 517.092, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: 'Fill',
          np: 9,
          mn: 'ADBE Fill',
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: 'Fill Mask',
              mn: 'ADBE Fill-0001',
              ix: 1,
              v: {a: 0, k: 0, ix: 1},
            },
            {
              ty: 7,
              nm: 'All Masks',
              mn: 'ADBE Fill-0007',
              ix: 2,
              v: {a: 0, k: 0, ix: 2},
            },
            {
              ty: 2,
              nm: 'Color',
              mn: 'ADBE Fill-0002',
              ix: 3,
              v: {
                a: 1,
                k: [
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 10,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 15,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 45,
                    s: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 50,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 80,
                    s: [0.870588243008, 0.870588243008, 0.870588243008, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 85,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                  },
                  {
                    i: {x: [0.833], y: [0.833]},
                    o: {x: [0.167], y: [0.167]},
                    n: ['0p833_0p833_0p167_0p167'],
                    t: 115,
                    s: [0.945098042488, 0.839215695858, 0.482352942228, 1],
                    e: [0.478431373835, 0.741176486015, 0.929411768913, 1],
                  },
                  {t: 120},
                ],
                ix: 3,
              },
            },
            {
              ty: 7,
              nm: 'Invert',
              mn: 'ADBE Fill-0006',
              ix: 4,
              v: {a: 0, k: 0, ix: 4},
            },
            {
              ty: 0,
              nm: 'Horizontal Feather',
              mn: 'ADBE Fill-0003',
              ix: 5,
              v: {a: 0, k: 0, ix: 5},
            },
            {
              ty: 0,
              nm: 'Vertical Feather',
              mn: 'ADBE Fill-0004',
              ix: 6,
              v: {a: 0, k: 0, ix: 6},
            },
            {
              ty: 0,
              nm: 'Opacity',
              mn: 'ADBE Fill-0005',
              ix: 7,
              v: {a: 0, k: 1, ix: 7},
            },
          ],
        },
      ],
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 1,
              ty: 'el',
              s: {a: 0, k: [12.746, 12.746], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Oval',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 45,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [821.476, 692.842, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.560783982277, 0.737254977226, 0.996078014374, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 46,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [821.476, 671.389, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 47,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [837.98, 589.537, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 48,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [837.98, 558.194, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 49,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [837.98, 526.849, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 50,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [816.529, 589.537, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 51,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [816.529, 558.194, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 52,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [816.529, 526.849, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 20.618], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.870588004589, 0.870588004589, 0.870588004589, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 53,
      ty: 0,
      nm: 'g1',
      refId: 'comp_2',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [360],
            },
            {t: 180},
          ],
          ix: 10,
        },
        p: {a: 0, k: [962.5, 607, 0], ix: 2},
        a: {a: 0, k: [65.5, 66, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      w: 131,
      h: 132,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 54,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.55, 606.896, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [324.225, 213.807], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {a: 0, k: [1, 1, 1, 1], ix: 4},
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 55,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 0,
              s: [0],
              e: [0],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 10,
              s: [0],
              e: [-60],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 15,
              s: [-60],
              e: [-60],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 45,
              s: [-60],
              e: [-120],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 50,
              s: [-120],
              e: [-120],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 80,
              s: [-120],
              e: [-180],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 85,
              s: [-180],
              e: [-180],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 115,
              s: [-180],
              e: [-240],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 120,
              s: [-240],
              e: [-240],
            },
            {
              i: {x: [0.833], y: [0.833]},
              o: {x: [0.167], y: [0.167]},
              n: ['0p833_0p833_0p167_0p167'],
              t: 150,
              s: [-240],
              e: [-300],
            },
            {t: 155},
          ],
          ix: 10,
        },
        p: {a: 0, k: [876.059, 357.122, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-0.001, -20.924],
                    [18.119, -10.464],
                    [18.119, 10.466],
                    [-0.001, 20.926],
                    [-18.121, 10.466],
                    [-18.121, -10.464],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 56,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 473.176, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 57,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 451.721, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 58,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1384.741, 900.791, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.2, -11.95],
                    [-2.45, 5.94],
                    [14.08, 4.13],
                  ],
                  o: [
                    [0, 0],
                    [25.62, -1.76],
                    [2.45, -5.93],
                    [-8.11, 4.03],
                  ],
                  v: [
                    [-40.756, 0.592],
                    [-27.106, 17.712],
                    [40.684, 13.503],
                    [-29.626, -17.708],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 59,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1340.354, 910.419, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-28.451, 1.04],
                    [28.449, -1.04],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {a: 0, k: [1, 0.721569001675, 0.466666996479, 1], ix: 3},
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 17.431, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 60,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 430.268, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.972549021244, 0.972549021244, 0.972549021244, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 61,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 852.883, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 62,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 831.428, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 63,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 809.975, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 64,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.551, 788.521, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [10.727, 234.572], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 90, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 65,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.55, 820.701, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [324.225, 213.807], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.278430998325, 0.274509996176, 0.274509996176, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 66,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [962.55, 393.089, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [324.225, 213.807], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.176470994949, 0.176470994949, 0.176470994949, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 67,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1376.22, 887.651, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.21, -11.94],
                    [-2.46, 5.94],
                    [14.08, 4.13],
                  ],
                  o: [
                    [0, 0],
                    [25.62, -1.75],
                    [2.45, -5.93],
                    [-8.11, 4.03],
                  ],
                  v: [
                    [-40.145, -0.694],
                    [-28.965, 17.196],
                    [40.075, 14.006],
                    [-30.235, -17.204],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.30980399251, 0.466666996479, 0.576470971107, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 68,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1337.154, 896.128, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-25.404, 0.888],
                    [25.406, -0.892],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 3,
              },
              o: {a: 0, k: 100, ix: 4},
              w: {a: 0, k: 17.431, ix: 5},
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: {a: 0, k: 4, ix: 8},
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 69,
      ty: 0,
      nm: 'arm1',
      refId: 'comp_3',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {
          a: 1,
          k: [
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 0,
              s: [0],
              e: [42],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 30,
              s: [42],
              e: [0],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 60,
              s: [0],
              e: [42],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 90,
              s: [42],
              e: [0],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 120,
              s: [0],
              e: [42],
            },
            {
              i: {x: [0.667], y: [1]},
              o: {x: [0.333], y: [0]},
              n: ['0p667_1_0p333_0'],
              t: 150,
              s: [42],
              e: [0],
            },
            {t: 180},
          ],
          ix: 10,
        },
        p: {a: 0, k: [1290.5, 665, 0], ix: 2},
        a: {a: 0, k: [163.082, 187.059, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      w: 215,
      h: 240,
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 70,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [702.388, 369.106, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [6.99, -6.21],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-22.38, 3.26],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [36.71, 0.582],
                    [12.24, -32.048],
                    [-36.71, -9.328],
                    [-13.98, 32.052],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.890196025372, 0.878431022167, 0.878431022167, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 71,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [743.664, 323.515, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.4, 7.65],
                    [-7.65, 1.39],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-1.39, -7.65],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-7.65, 1.4],
                  ],
                  v: [
                    [-60.143, 43.664],
                    [-48.813, 27.284],
                    [-5.673, 4.564],
                    [50.337, -55.216],
                    [60.377, -35.496],
                    [13.737, 19.724],
                    [-43.763, 54.984],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.839215993881, 0.600000023842, 0.384314000607, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 72,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {
          a: 1,
          k: [
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 0,
              s: [1107.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [4, 0, 0],
              ti: [-4, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 10,
              s: [1131.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 56,
              s: [1131.004, 491.572, 0],
              e: [1107.004, 491.572, 0],
              to: [-4, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 60,
              s: [1107.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [0, 0, 0],
              ti: [-4, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 70,
              s: [1131.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 116,
              s: [1131.004, 491.572, 0],
              e: [1107.004, 491.572, 0],
              to: [-4, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 120,
              s: [1107.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [0, 0, 0],
              ti: [-4, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 130,
              s: [1131.004, 491.572, 0],
              e: [1131.004, 491.572, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {x: 0.833, y: 0.833},
              o: {x: 0.167, y: 0.167},
              n: '0p833_0p833_0p167_0p167',
              t: 176,
              s: [1131.004, 491.572, 0],
              e: [1107.004, 491.572, 0],
              to: [-4, 0, 0],
              ti: [4, 0, 0],
            },
            {t: 180},
          ],
          ix: 2,
        },
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [40.907, 24.006], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.478430986404, 0.741176009178, 0.929412007332, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 73,
      ty: 4,
      nm: 'Rectangle',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [1069.114, 280.191, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: {a: 0, k: [68.193, 19.489], ix: 2},
              p: {a: 0, k: [0, 0], ix: 3},
              r: {a: 0, k: 0, ix: 4},
              nm: 'Rectangle Path 1',
              mn: 'ADBE Vector Shape - Rect',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.945097982883, 0.839215993881, 0.482353001833, 1],
                ix: 4,
              },
              o: {a: 0, k: 100, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Rectangle',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 74,
      ty: 4,
      nm: 'Path',
      sr: 1,
      ks: {
        o: {a: 0, k: 100, ix: 11},
        r: {a: 0, k: 0, ix: 10},
        p: {a: 0, k: [960.001, 925.554, 0], ix: 2},
        a: {a: 0, k: [0, 0, 0], ix: 1},
        s: {a: 0, k: [100, 100, 100], ix: 6},
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-69.9, 3.13],
                    [0, 3.06],
                    [111.66, 0],
                    [33.66, -2.72],
                    [55.22, 0],
                    [0, -4.68],
                    [-263, 0],
                  ],
                  o: [
                    [57.9, -1.49],
                    [0, -4.68],
                    [-78.12, 0],
                    [-49.32, -0.31],
                    [-263, 0],
                    [0, 4.68],
                    [200.99, 0],
                  ],
                  v: [
                    [411.243, 5.178],
                    [507.753, -2.053],
                    [305.583, -10.523],
                    [126.023, -5.943],
                    [-31.547, -6.423],
                    [-507.757, 2.048],
                    [-31.547, 10.527],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [
                  0.094117999077,
                  0.094117999077,
                  0.094117999077,
                  0.20000000298,
                ],
                ix: 4,
              },
              o: {a: 0, k: 20, ix: 5},
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: {a: 0, k: [0, 0], ix: 2},
              a: {a: 0, k: [0, 0], ix: 1},
              s: {a: 0, k: [100, 100], ix: 3},
              r: {a: 0, k: 0, ix: 6},
              o: {a: 0, k: 100, ix: 7},
              sk: {a: 0, k: 0, ix: 4},
              sa: {a: 0, k: 0, ix: 5},
              nm: 'Transform',
            },
          ],
          nm: 'Path',
          np: 2,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export default WorkingAnimation;
