import firebaseService from 'utils/firebase/firebase';
import {setRemoteConfigLoaded} from './actions';

// Web remote config default values are initialized in `utils/firebase/firebase.web.js`
export const initRemoteConfig = () => {
  return async (dispatch, getState) => {
    firebaseService.remoteConfig.fetchAndActivate().then(() => {
      dispatch(setRemoteConfigLoaded());
    });
  };
};
