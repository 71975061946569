import * as types from '../actions/actionTypes';

export const initialState = {
  recordingLocally: false,
  recorder: null,
  audioContext: null,
  audioDestination: null,
  screenCaptureStream: null,
  whiteNoiseSource: null,
  isBeingRecorded: false,
};

const recording = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_RECORDING: {
      const recordingLocally = action.payload;
      return {...state, recordingLocally};
    }

    case types.SET_RECORDING_ELEMENTS: {
      const audioContext = action.payload.audioContext;
      const audioDestination = action.payload.audioDestination;
      const recorder = action.payload.recorder;
      const screenCaptureStream = action.payload.screenCaptureStream;
      const whiteNoiseSource = action.payload.whiteNoiseSource;

      return {
        ...state,
        audioContext,
        audioDestination,
        recorder,
        screenCaptureStream,
        whiteNoiseSource,
      };
    }

    case types.SET_IS_BEING_RECORDED: {
      const {recording: beingRecorded} = action.payload;

      return {...state, isBeingRecorded: beingRecorded};
    }

    default:
      return state;
  }
};

export default recording;
