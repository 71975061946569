import React, {useContext} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {View, StyleSheet, Button, Text} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import recordError from './recordError';
import LottieAnimation from 'components/animations/LottieAnimation';
import WorkingAnimation from 'components/animations/scenes/WorkingAnimation';

const ANIMATION_HEIGHT = 300;

const errorHandler = (error) => {
  recordError(error);
};

const ErrorFallback = ({resetErrorBoundary}) => {
  const {translate} = useContext(I18nContext);

  return (
    <View style={[styles.container]}>
      <LottieAnimation
        source={WorkingAnimation}
        style={[styles.animationView]}
        webHeight={ANIMATION_HEIGHT}
        autoPlay
      />

      <View>
        <Text style={styles.errorMessageText}>
          {translate('errorHandler.wrong')}
        </Text>
        <Button
          title={translate('errorHandler.tryAgain')}
          onPress={resetErrorBoundary}
        />
      </View>
    </View>
  );
};

export const ErrorHandler = ({children}) => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={errorHandler}>
    {children}
  </ErrorBoundary>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  animationView: {
    height: ANIMATION_HEIGHT,
  },
  errorMessageText: {
    fontSize: 24,
    marginBottom: 10,
  },
});
