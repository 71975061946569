const initialState = {
  roomClient: undefined,
};

const control = (state = initialState, action) => {
  switch (action.type) {
    case 'JOIN_ROOM': {
      const {roomClient} = action.payload;

      return {...state, roomClient};
    }

    case 'CLEAR_ROOM': {
      return {...state, roomClient: undefined};
    }
    default: {
      return state;
    }
  }
};

export default control;
