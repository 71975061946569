import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  Clipboard,
  StyleSheet,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {MessageText, MessageImage, Time, utils} from 'react-native-gifted-chat';
import {isTablet} from 'react-native-device-info';

import {deleteMessage} from 'store/communication/actions/chatActions';
import {translate} from 'i18n/context/I18nContext';

const {isSameUser, isSameDay} = utils;

class MessageBubble extends React.Component {
  constructor(props) {
    super(props);
    this.onLongPress = this.onLongPress.bind(this);
    this.state = {
      showOriginalText: false,
    };
  }

  onLongPress() {
    if (this.props.onLongPress) {
      this.props.onLongPress(this.context, this.props.currentMessage);
    } else {
      if (this.props.currentMessage.text) {
        const options = [
          translate('messageBubble.copyText'),
          translate('messageBubble.deleteMsg'),
          translate('shared.cancel'),
        ];
        const cancelButtonIndex = options.length - 1;
        this.context.actionSheet().showActionSheetWithOptions(
          {
            options,
            cancelButtonIndex,
          },
          (buttonIndex) => {
            switch (buttonIndex) {
              case 0:
                Clipboard.setString(this.props.currentMessage.text);
                break;
              case 1:
                this.props.deleteMessage(this.props.currentMessage);
                break;
            }
          },
        );
      }
    }
  }

  renderMessageText() {
    const {
      containerStyle,
      wrapperStyle,
      messageTextStyle,
      currentMessage,
      renderMessageText,
      ...messageTextProps
    } = this.props;
    if (renderMessageText) {
      return this.props.renderMessageText(messageTextProps);
    } else {
      return (
        <MessageText
          {...messageTextProps}
          currentMessage={
            typeof currentMessage?.translatedText !== 'undefined' &&
            this.props?.liveTranslation
              ? {...currentMessage, text: currentMessage.translatedText}
              : currentMessage
          }
          textStyle={{
            left: [
              styles.standardFont,
              styles.messageText,
              messageTextProps.textStyle,
              messageTextStyle,
            ],
          }}
        />
      );
    }
  }

  renderMessageImage() {
    if (this.props.currentMessage.image) {
      const {containerStyle, wrapperStyle, ...messageImageProps} = this.props;
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps);
      }
      return (
        <MessageImage
          {...messageImageProps}
          imageStyle={[styles.image, messageImageProps.imageStyle]}
        />
      );
    }
    return null;
  }

  renderTicks() {
    const {currentMessage} = this.props;
    if (this.props.renderTicks) {
      return this.props.renderTicks(currentMessage);
    }
    if (currentMessage.user._id !== this.props.user._id) {
      return null;
    }
    if (currentMessage.sent || currentMessage.received) {
      return (
        <View style={[styles.headerItem, styles.tickView]}>
          {currentMessage.sent && (
            <Text
              style={[styles.standardFont, styles.tick, this.props.tickStyle]}>
              ✓
            </Text>
          )}
          {currentMessage.received && (
            <Text
              style={[styles.standardFont, styles.tick, this.props.tickStyle]}>
              ✓
            </Text>
          )}
        </View>
      );
    }
    return null;
  }

  renderUsername() {
    const username = this.props.currentMessage.user.name;
    if (username) {
      const {containerStyle, wrapperStyle, ...usernameProps} = this.props;
      if (this.props.renderUsername) {
        return this.props.renderUsername(usernameProps);
      }
      return (
        <Text
          style={[
            styles.standardFont,
            styles.headerItem,
            styles.username,
            this.props.usernameStyle,
          ]}>
          {username}
        </Text>
      );
    }
    return null;
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      const {containerStyle, wrapperStyle, ...timeProps} = this.props;
      if (this.props.renderTime) {
        return this.props.renderTime(timeProps);
      }
      return (
        <Time
          {...timeProps}
          containerStyle={{left: [styles.timeContainer]}}
          textStyle={{
            left: [
              styles.standardFont,
              styles.headerItem,
              styles.time,
              timeProps.textStyle,
            ],
          }}
        />
      );
    }
    return null;
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props);
    }
    return null;
  }

  renderTranslationLink() {
    const {currentMessage, liveTranslation} = this.props;
    const {showOriginalText} = this.state;
    if (
      typeof currentMessage?.translatedText !== 'undefined' &&
      liveTranslation
    ) {
      return (
        <View>
          {showOriginalText ? (
            <Text style={styles.orginalText}>{currentMessage?.text}</Text>
          ) : null}
          <Text
            style={styles.linkText}
            onPress={() => {
              this.toggleTranslationLink();
              this.forceUpdate();
            }}>
            {!showOriginalText
              ? translate('liveTranslation.showOriginal')
              : translate('liveTranslation.hideOriginal')}
          </Text>
        </View>
      );
    }
  }

  toggleTranslationLink() {
    const {showOriginalText} = this.state;
    this.setState({
      showOriginalText: !showOriginalText,
    });
  }

  render() {
    const isSameThread =
      isSameUser(this.props.currentMessage, this.props.previousMessage) &&
      isSameDay(this.props.currentMessage, this.props.previousMessage);

    const messageHeader = isSameThread ? null : (
      <View style={styles.headerView}>
        {this.renderUsername()}
        {this.renderTime()}
        {this.renderTicks()}
      </View>
    );

    return (
      <View style={[styles.container, this.props.containerStyle]}>
        <TouchableOpacity
          onLongPress={this.onLongPress}
          accessibilityTraits="text"
          {...this.props.touchableProps}>
          <View style={[styles.wrapper, this.props.wrapperStyle]}>
            <View>
              {this.renderCustomView()}
              {messageHeader}
              {this.renderMessageImage()}
              {this.renderMessageText()}
              {this.renderTranslationLink()}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

// Note: Everything is forced to be "left" positioned with this component.
// The "right" position is only used in the default Bubble.
const styles = StyleSheet.create({
  standardFont: {
    fontSize: 15,
  },
  messageText: {
    width: Platform.OS === 'web' || isTablet() ? 248 : 180,
    marginLeft: 0,
    marginRight: 0,
  },
  orginalText: {
    width: Platform.OS === 'web' || isTablet() ? 200 : 160,
    marginLeft: 0,
    marginRight: 0,
    color: '#88aaaa',
  },
  container: {
    alignItems: 'flex-start',
  },
  wrapper: {
    minHeight: 20,
    justifyContent: 'flex-end',
  },
  username: {
    fontWeight: 'bold',
  },
  time: {
    textAlign: 'left',
    fontSize: 12,
  },
  timeContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
  },
  headerItem: {
    marginRight: 10,
  },
  headerView: {
    // Try to align it better with the avatar on Android.
    marginTop: Platform.OS === 'android' ? -2 : 0,
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  tick: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  tickView: {
    flexDirection: 'row',
  },
  linkText: {
    fontSize: 12,
    paddingRight: 10,
    textAlign: 'right',
    textDecorationLine: 'underline',
  },
  image: {
    borderRadius: 3,
    marginLeft: 0,
    marginRight: 0,
  },
});

MessageBubble.contextTypes = {
  actionSheet: PropTypes.func,
};

MessageBubble.defaultProps = {
  touchableProps: {},
  onLongPress: null,
  renderMessageImage: null,
  renderMessageText: null,
  renderCustomView: null,
  renderTime: null,
  currentMessage: {
    text: null,
    createdAt: null,
    image: null,
  },
  nextMessage: {},
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  tickStyle: {},
  containerToNextStyle: {},
  containerToPreviousStyle: {},
  liveTranslation: false,
};

MessageBubble.propTypes = {
  touchableProps: PropTypes.object,
  onLongPress: PropTypes.func,
  renderMessageImage: PropTypes.func,
  renderMessageText: PropTypes.func,
  renderCustomView: PropTypes.func,
  renderUsername: PropTypes.func,
  renderTime: PropTypes.func,
  renderTicks: PropTypes.func,
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  user: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
  wrapperStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
  messageTextStyle: PropTypes.object,
  usernameStyle: PropTypes.object,
  tickStyle: PropTypes.object,
  containerToNextStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
  containerToPreviousStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object,
  }),
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteMessage: (message) => dispatch(deleteMessage(message)),
  };
};

const mapStateToProps = (state) => ({
  liveTranslation: state.meeting.settings.liveTranslation,
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageBubble);
