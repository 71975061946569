import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {View, Text, ScrollView, StyleSheet} from 'react-native';

import {
  selectViewboardContent,
  editActiveViewboard,
  shareViewboardContent,
  switchToDashboard,
} from 'store/viewboard';
import {I18nContext} from 'i18n/context/I18nContext';
import {stepOptions} from '../ViewboardShareFlow';
import ViewboardShareFlowTitle from '../ViewboardShareFlowTitle';
import ViewboardContentButton from './ViewboardContentButton';
import ViewboardUploadContentButton from './ViewboardUploadContentButton';
import ViewboardAddInstanceContentButton from './ViewboardAddInstanceContentButton';
import {VIEWBOARD_SCROLL_VIEW_ITEM} from 'style/theme';
import {
  checkPermission,
  FILESHARE_UPLOAD,
  INSTANCE_CONTENT_ADD,
} from 'utils/permissions';

/* TODO: Look into adding search / filter functionality */
const ViewboardSelectContent = ({setCurrentStep}) => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const content = useSelector((state) => state.viewboard.content);
  const location = useSelector((state) => state.world.location);
  const selectedViewboardId = useSelector(
    (state) => state.viewboard.selectedViewboardId,
  );
  const isEditMode = useSelector((state) => state.viewboard.isEditMode);
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );

  const onContentSelect = (contentId) => {
    if (isEditMode) {
      dispatch(
        editActiveViewboard({viewboardId: selectedViewboardId, contentId}),
      );
      dispatch(shareViewboardContent(location, selectedViewboardId, contentId));
      dispatch(switchToDashboard());
    } else {
      dispatch(selectViewboardContent(contentId));
      setCurrentStep(stepOptions.selectViewboard);
    }
  };

  const onCancel = () => {
    dispatch(switchToDashboard());
  };

  return (
    <>
      {checkPermission(FILESHARE_UPLOAD, permissions) && (
        <View style={styles.viewboardAddContentButtonContainer}>
          <ViewboardUploadContentButton />
        </View>
      )}
      {checkPermission(INSTANCE_CONTENT_ADD, permissions) && (
        <View style={styles.viewboardAddContentButtonContainer}>
          <ViewboardAddInstanceContentButton />
        </View>
      )}
      <ViewboardShareFlowTitle
        sectionTitle={translate('viewboardSelectContent.selectContent')}
        onPressCancel={onCancel}
      />
      {content && content?.length === 0 && (
        <Text style={styles.text}>
          {translate('viewboardSelectContent.noContent')}
        </Text>
      )}
      <ScrollView>
        {content?.map((contentObject) => {
          return (
            <View key={contentObject.id} style={VIEWBOARD_SCROLL_VIEW_ITEM}>
              <ViewboardContentButton
                onPress={() => onContentSelect(contentObject.id)}
                name={contentObject.name}
                type={contentObject.type}
              />
            </View>
          );
        })}
      </ScrollView>
    </>
  );
};

ViewboardSelectContent.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  text: {
    marginLeft: 15,
  },
  viewboardAddContentButtonContainer: {
    marginTop: 15,
    alignSelf: 'center',
  },
});

export default ViewboardSelectContent;
