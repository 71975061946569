import React, {useEffect} from 'react';
import {Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';

import {initRemoteConfig} from 'store/app/remoteConfigActions';
import {initDynamicLinkHandler} from 'store/app/linkActions';
import {initCache} from 'store/app/cacheActions';

const RemoteConfigHandler = () => {
  const dispatch = useDispatch();
  const isRemoteConfigLoaded = useSelector(
    (state) => state.app.isRemoteConfigLoaded,
  );

  useEffect(() => {
    if (!isRemoteConfigLoaded) {
      dispatch(initRemoteConfig());
    } else {
      Platform.OS !== 'web' && dispatch(initDynamicLinkHandler());
      Platform.OS !== 'web' && dispatch(initCache());
    }
  }, [dispatch, isRemoteConfigLoaded]);

  // Web breaks for some reason using useEffect to call this
  Platform.OS === 'web' && dispatch(initDynamicLinkHandler());

  return <></>;
};

export default RemoteConfigHandler;
