import React, {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import {switchToShareOnViewboard} from 'store/viewboard';
import {I18nContext} from 'i18n/context/I18nContext';
import {SIDE_PANEL_VIEWBOARDS_COLOR} from 'style/theme';

const ViewboardShareButton = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const viewboards = useSelector((state) => state.viewboard.viewboards);

  const isSharingButtonDisabled = !viewboards || viewboards.length === 0;

  const onViewboardShare = () => {
    dispatch(switchToShareOnViewboard());
  };

  return (
    <TouchableOpacity
      onPress={onViewboardShare}
      disabled={isSharingButtonDisabled}>
      <View style={styles.buttonContainer}>
        <Icon
          type="MaterialCommunityIcons"
          name="plus"
          style={styles.buttonIcon}
          color="white"
        />
        <Text style={styles.buttonText}>
          {translate('viewboardShareButton.shareViewboard')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    backgroundColor: SIDE_PANEL_VIEWBOARDS_COLOR,
    color: 'white',
    borderRadius: 50,
  },
  buttonIcon: {fontSize: 30, paddingRight: 6, paddingLeft: 8},
  buttonText: {
    fontSize: 18,
    marginRight: 10,
    paddingRight: 8,
    color: 'white',
    fontWeight: 'bold',
  },
});

export default ViewboardShareButton;
