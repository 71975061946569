import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {View, Text, TouchableOpacity, StyleSheet} from 'react-native';

import {I18nContext} from 'i18n/context/I18nContext';
import {VIEWBOARDS_HEADING_STYLE} from 'style/theme';

const ViewboardShareFlowTitle = ({sectionTitle, onPressCancel}) => {
  const {translate} = useContext(I18nContext);

  return (
    <View style={styles.titleContainer}>
      <Text style={VIEWBOARDS_HEADING_STYLE}>{sectionTitle}</Text>
      <TouchableOpacity onPress={onPressCancel}>
        <Text style={styles.cancelText}>{translate('shared.cancel')}</Text>
      </TouchableOpacity>
    </View>
  );
};

ViewboardShareFlowTitle.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  onPressCancel: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  cancelText: {marginTop: 2.5, marginRight: 15, marginLeft: 5, color: 'blue'},
});

export default ViewboardShareFlowTitle;
