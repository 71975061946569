let mediaQueryDetectorElem;

export const ROOM_STATE = Object.freeze({
  connecting: 'connecting',
  connected: 'connected',
  disconnecting: 'disconnecting',
  disconnected: 'disconnected',
  closed: 'closed',
});

export function initialize() {
  // Media query detector stuff.
  mediaQueryDetectorElem = document.getElementById(
    'mediasoup-demo-app-media-query-detector',
  );

  return Promise.resolve();
}

export function isDesktop() {
  return Boolean(mediaQueryDetectorElem.offsetParent);
}

export function isMobile() {
  return !mediaQueryDetectorElem.offsetParent;
}
