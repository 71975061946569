import React, {useEffect, useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {calculateDistanceBetween} from 'utils/World/Transform';
import DeviceInfo from 'utils/communication/DeviceInfo';

const DEFAULT_AUDIO_VOLUME = 1.0;
const RANGE = 10;

const RemoteAudio = ({consumerId}) => {
  const micConsumer = useSelector(
    (state) => state.communication.consumers[consumerId],
  );
  const audioContext = useSelector(
    (state) => state.meeting.recording.audioContext,
  );
  const audioDestination = useSelector(
    (state) => state.meeting.recording.audioDestination,
  );

  const audioElement = useRef(null);

  useEffect(() => {
    let audioTrack = null;

    if (micConsumer) {
      audioTrack = micConsumer.track;
    }

    if (audioTrack && audioTrack.readyState !== 'ended') {
      // eslint-disable-next-line no-undef
      const stream = new MediaStream();
      stream.addTrack(audioTrack);
      if (!stream.active) {
        audioElement.current.onended = null;
        audioElement.current.srcObject = null;
        return;
      }
      audioElement.current.srcObject = stream;
    } else {
      audioElement.current.onended = null;
      audioElement.current.srcObject = null;
    }
  }, [micConsumer]);

  const peers = useSelector((state) => state.communication.peers);

  const [peerId, setPeerId] = useState(null);

  useEffect(() => {
    Object.values(peers).forEach((peer) => {
      if (peer.consumers.includes(consumerId)) {
        if (peer.id !== peerId) {
          setPeerId(peer.id);
        } else {
          // HACK: This seems to allow the stream to recover if there's a slight delay in initialization
          setupRecording();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId, peers]);

  const positionalMode = useSelector(
    (state) => state.communication.room.positionalMode,
  );
  const positionalVoiceFallOff = useSelector(
    (state) => state.meeting.settings.positionalVoiceFallOff,
  );
  const {transform: myTransform} = useSelector(
    (state) => state.communication.me || {transform: undefined},
  );

  useEffect(() => {
    if (audioElement.current === null) {
      return;
    }

    let volume = DEFAULT_AUDIO_VOLUME;

    if (
      positionalVoiceFallOff &&
      positionalMode.voice === 'bubble' &&
      micConsumer &&
      !micConsumer.paused
    ) {
      const peer = peers[peerId];
      const {transform: peerTransform} = peer || {transform: undefined};
      const distance = calculateDistanceBetween(myTransform, peerTransform);
      volume =
        DEFAULT_AUDIO_VOLUME - Math.min(distance / RANGE, DEFAULT_AUDIO_VOLUME);
      volume = volume * volume;
    }

    audioElement.current.volume = volume;
  }, [
    micConsumer,
    myTransform,
    peerId,
    peers,
    positionalMode.voice,
    positionalVoiceFallOff,
  ]);

  const setupRecording = useCallback(() => {
    const audioObj = audioElement.current;
    if (!audioDestination || !audioContext || !audioObj) {
      return;
    }

    try {
      const stream =
        DeviceInfo.platform === 'firefox'
          ? audioObj.mozCaptureStream()
          : audioObj.captureStream();
      if (stream.active && stream.getAudioTracks().length >= 1) {
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(audioDestination); // We have to connect to the recording destination
        source.connect(audioContext.destination); // We have to reconnect to the system output (otherwise audio can't be heard)
        audioObj.onended = () => {
          source.disconnect();
        };
      }
    } catch (err) {
      console.error(err);
    }
  }, [audioElement, audioDestination, audioContext]);

  useEffect(() => {
    setTimeout(() => {
      setupRecording();
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioDestination, audioContext]);

  return <audio ref={audioElement} autoPlay />;
};

RemoteAudio.defaultProps = {
  consumerId: null,
};

RemoteAudio.propTypes = {
  consumerId: PropTypes.string,
};

export default RemoteAudio;
