import {useQueryParam, StringParam} from 'use-query-params';
import {showMessage} from 'react-native-flash-message';
import {Linking} from 'react-native';

import getRemoteConfigValue from 'utils/firebase/remoteConfig';
import {setDynamicLink} from './actions';
import {setToken} from 'store/meeting/actions/properties';
import {setMe} from 'store/communication/actions/stateActions';
import {translate} from 'i18n/context/I18nContext';
import {VerifyAndParseToken} from 'utils/communication/Token';
import {logEvent} from 'utils/firebase/analytics';
import {truncateFirebaseAnalyticParameterValue} from 'utils/firebase/utils';
import {
  SET_USER_PERMISSIONS,
  INVALID_TOKEN,
} from 'utils/firebase/analytics.config';

export const initDynamicLinkHandler = () => {
  const marcusSiteUrl = getRemoteConfigValue('marcus_site_url');
  const articlePath = '/blog/issues-joining-a-3dmeet-meeting/';
  return async (dispatch, getState) => {
    const [token] = useQueryParam('token', StringParam);

    // Verify token (reject/return to previous state if invalid)
    const verifiedToken = VerifyAndParseToken(token);
    const {error} = verifiedToken;
    if (error) {
      await dispatch(setDynamicLink(undefined));

      if (token) {
        showMessage({
          type: 'danger',
          message: translate('linkActionsWeb.clickForSupport'),
          autoHide: false,
          onPress: () => {
            Linking.openURL(`${marcusSiteUrl.asString()}${articlePath}`);
          },
        });

        await logEvent(INVALID_TOKEN, {
          error: error,
        });

        console.error(error);
      }
    } else {
      await dispatch(setDynamicLink(token));
      await dispatch(setToken(token));

      const {peerId, permissions, roomId, integration} = verifiedToken;
      const displayName = translate('linkActionsWeb.userNum', {
        num: peerId.substring(0, 4),
      });
      const displayNameSet = false;
      const device = {};
      await dispatch(
        setMe({peerId, displayName, displayNameSet, device, permissions}),
      );

      const permissionsString = permissions.join(' ');
      await logEvent(SET_USER_PERMISSIONS, {
        meeting_id: roomId,
        integration: integration,
        permissions: truncateFirebaseAnalyticParameterValue(permissionsString),
      });
    }
  };
};
