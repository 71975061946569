import React, {useContext} from 'react';
import {
  SafeAreaView,
  View,
  StatusBar,
  TouchableOpacity,
  Text,
  Image,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';

import World from 'components/World';
import CustomizeTray from 'components/Customization/CustomizeTray';
import BodySelection from 'components/Customization/BodySelection';
import worldStyle from 'style/3d';
import containerStyle from 'style/screen';
import {I18nContext} from 'i18n/context/I18nContext';

// asset imports
import rotateIcon from 'images/CustomizationIcons/360.png';

const AvatarPreviewPortrait = ({
  resetSelection,
  customizeLocation,
  onLoadComplete,
  onContinue,
  loaded,
  avatarLoading,
  onLegacyFromWorld,
}) => {
  const {translate} = useContext(I18nContext);
  const {width} = useWindowDimensions();
  const tierTwoWidth = 0.35 * width;

  // stylesheets included in function so they can use the useWindowDimensions hook variables
  const styles = StyleSheet.create({
    worldStyle: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      width: '100%',
    },
    container: {
      flex: 1,
      alignItems: 'center',
      padding: 15,
    },
    // flexed under container
    textContainer: {
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
    },
    mainCustomize: {
      flex: 7,
      flexDirection: 'row',
      width: '100%',
    },
    joinContainer: {
      flex: 1,
      justifyContent: 'center',
      width: 150,
    },
    // end container flex
    // flexed under textContainer
    text: {
      color: '#fff',
      textAlign: 'center',
      fontSize: 24,
      paddingLeft: 20,
      paddingRight: 20,
    },
    // end flex for textContainer
    // flexed under joinContainer
    joinButton: {
      backgroundColor: '#fe6507',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
    },
    joinButtonLoading: {
      backgroundColor: 'rgba(254, 101, 7, .5)',
      padding: 10,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 10,
    },
    // end flex for joinContainer
    //flexed under joinButton
    joinButtonText: {
      color: '#fff',
    },
    // end flex joinButton
    // flexed under mainCustomize
    bodyContainer: {
      height: '100%',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    trayContainer: {
      flex: -1,
      height: '100%',
    },
    //end mainCustomize flex
    // flexed under joinContainer is only a Button - no other views
    // flexed under bodyContainer
    bodySpacer: {
      flex: 8,
    },
    rotateIconContainer: {
      flex: 1,
      width: 75,
    },
    genders: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    //end bodyContainer flex
    rotateIcon: {
      height: undefined,
      width: undefined,
      flex: 1,
      resizeMode: 'contain',
    },
    // flexed under trayContainer
    tray: {
      flex: 6,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    traySpacer: {
      flex: 2,
    },
    // end trayContainer flex
    // flexed under tray
    tierOne: {
      flex: -1,
      height: '100%',
      flexDirection: 'column',
      marginLeft: 10,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
    },
    tierTwo: {
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      width: tierTwoWidth,
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
    },
    tierSpacer: {
      width: tierTwoWidth,
      justifyContent: 'center',
      alignContent: 'center',
      padding: 5,
      textAlign: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 10,
    },
    // end tray flex
    // flex under tierSpacer
    tierSpacerText: {
      textAlign: 'center',
    },
    //end tierSpacer flex
    // flexed under TierOne
    tierOneScrollContainer: {
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },
    // end tier flex
    // flexed under tierScrollContainer
    tierOneScrollContents: {
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'flex-start',
    },
    // end tierScrollContainer
    // flexed under TierTwo
    tierTwoScrollContainer: {
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      minWidth: 200,
    },
    colorWheel: {
      width: '100%',
      height: 125,
      marginBottom: 10,
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    // end tier flex
    // flexed under tierScrollContainer
    tierTwoScrollContents: {
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'flex-start',
    },
    // end tierScrollContainer
  });

  return (
    <View style={containerStyle.full} pointerEvents="box-none">
      <StatusBar
        backgroundColor="rgba(0, 0, 0, 0.25)"
        barStyle="light-content"
        translucent
      />
      <World
        location={customizeLocation}
        locationFilter={customizeLocation}
        onLoadComplete={onLoadComplete}
        style={worldStyle.world}
        onLegacyFromWorld={onLegacyFromWorld}
      />
      <SafeAreaView style={worldStyle.safeUI} pointerEvents="box-none">
        {!loaded ? null : (
          <View style={styles.container} pointerEvents="box-none">
            <View style={styles.textContainer}>
              <Text style={styles.text}>
                {translate('avatarPreview.joinMeeting')}
              </Text>
            </View>
            <View style={styles.mainCustomize} pointerEvents="box-none">
              <View style={styles.bodyContainer} pointerEvents="box-none">
                <View style={styles.bodySpacer} pointerEvents="none" />
                <View style={styles.rotateIconContainer} pointerEvents="none">
                  <Image source={rotateIcon} style={styles.rotateIcon} />
                </View>
                <BodySelection
                  style={styles.genders}
                  onSelect={(nextGender) => resetSelection(nextGender)}
                />
              </View>
              <View style={styles.trayContainer}>
                <CustomizeTray styles={styles} isInGame={false} />
                <View style={styles.traySpacer} />
              </View>
            </View>
            <View style={styles.joinContainer}>
              <TouchableOpacity
                disabled={avatarLoading}
                style={
                  avatarLoading ? styles.joinButtonLoading : styles.joinButton
                }
                onPress={() => onContinue()}>
                <Text style={styles.joinButtonText}>
                  {translate('avatarPreview.join')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </SafeAreaView>
    </View>
  );
};

export default AvatarPreviewPortrait;
