import React from 'react';
import PropTypes from 'prop-types';

import VideoContainer from 'components/Communication/VideoContainer';

const RemoteVideo = ({remoteVideoConsumer, videoContainerStyle}) => {
  let stream = null;
  if (
    remoteVideoConsumer &&
    remoteVideoConsumer.track &&
    remoteVideoConsumer.track.readyState === 'live'
  ) {
    // eslint-disable-next-line no-undef
    stream = new MediaStream();
    stream.addTrack(remoteVideoConsumer.track);
  }

  const waiting =
    remoteVideoConsumer &&
    (remoteVideoConsumer.locallyPaused || remoteVideoConsumer.remotelyPaused);

  const hide = !remoteVideoConsumer || waiting;

  return (
    <VideoContainer
      stream={stream}
      style={videoContainerStyle}
      waiting={waiting}
      hide={hide}
    />
  );
};

RemoteVideo.defaultProps = {
  videoContainerStyle: {width: 200, height: 200},
};

RemoteVideo.propTypes = {
  remoteVideoConsumer: PropTypes.shape({
    locallyPaused: PropTypes.bool,
    remotelyPaused: PropTypes.bool,
    track: PropTypes.object,
  }).isRequired,
  videoContainerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default React.memo(RemoteVideo);
