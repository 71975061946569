import firebaseService from 'utils/firebase/firebase';

export const logScreenView = async (screen) => {
  await firebaseService.analytics().setCurrentScreen(screen);
};

export const logEvent = async (name, params = {}) => {
  await firebaseService.analytics().logEvent(name, params);
};

export const setUserProperties = async (properties) => {
  await firebaseService.analytics().setUserProperties(properties);
};
