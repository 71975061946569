import React, {useState, useEffect, useRef} from 'react';
import {View, Animated, StyleSheet, Platform} from 'react-native';
import PropTypes from 'prop-types';
import {isTablet, hasNotch} from 'react-native-device-info';
import {useSelector} from 'react-redux';
import Orientation from 'react-native-orientation-locker';

import VideoContainer from 'components/Communication/VideoContainer';

const LocalVideo = ({style}) => {
  const [showing, setShowing] = useState(false);
  const [fadeAnimation] = useState(new Animated.Value(0));
  const [position] = useState(new Animated.Value(-20));
  const [stream, setStream] = useState(undefined);
  const {mirrorCamera, cameraEnabled, screenShareEnabled} = useSelector(
    (state) => state.media,
  );

  const producers = useSelector((state) => state.communication.producers);
  let track;
  let paused = true;
  for (let producerId in producers) {
    if (Object.prototype.hasOwnProperty.call(producers, producerId)) {
      const producer = producers[producerId];
      if (producer.track.kind === 'video') {
        track = producer.track;
        paused = producer.paused;
      }
    }
  }

  const isInitialMount = useRef(true);
  const waiting = false;

  useEffect(() => {
    if (!stream && track && !paused) {
      // eslint-disable-next-line no-undef
      let newStream = new MediaStream();
      newStream.addTrack(track);
      setStream(newStream);
    } else if (stream && (!track || paused)) {
      setStream(null);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (cameraEnabled !== showing || screenShareEnabled !== showing) {
        if (cameraEnabled || screenShareEnabled) {
          Animated.parallel([
            Animated.timing(fadeAnimation, {
              toValue: 1,
              duration: 300,
              useNativeDriver: false,
            }),
            Animated.spring(position, {
              toValue: 8,
              duration: 300,
              overshootClamping: true,
              useNativeDriver: false,
            }),
          ]).start();
        } else {
          Animated.parallel([
            Animated.timing(fadeAnimation, {
              toValue: 0,
              duration: 300,
              useNativeDriver: false,
            }),
            Animated.spring(position, {
              toValue: -20,
              duration: 300,
              overshootClamping: true,
              useNativeDriver: false,
            }),
          ]).start();
        }
        setShowing(cameraEnabled || screenShareEnabled);
      }
    }
  }, [
    showing,
    fadeAnimation,
    position,
    stream,
    track,
    cameraEnabled,
    paused,
    screenShareEnabled,
  ]);

  const [currentOrientation, setCurrentOrientation] = useState(
    Orientation.getInitialOrientation(),
  );

  useEffect(() => {
    const onOrientationDidChange = (orientation) => {
      if (currentOrientation !== orientation) {
        setCurrentOrientation(orientation);
      }
    };
    Orientation.addOrientationListener(onOrientationDidChange);

    return () => {
      Orientation.removeOrientationListener(onOrientationDidChange);
    };
  }, [currentOrientation]);

  const localVideoContainerStyleOverride =
    Platform.OS === 'ios' &&
    hasNotch() &&
    (currentOrientation === 'LANDSCAPE-LEFT' ||
      currentOrientation === 'LANDSCAPE-RIGHT')
      ? {bottom: 20, right: 90}
      : {};

  const videoContainerStyle =
    isTablet() || Platform.OS === 'web'
      ? {
          width: 200,
          height: 200,
        }
      : {width: 100, height: 100};

  return (
    <View
      style={[styles.container, style, localVideoContainerStyleOverride]}
      pointerEvents="box-none">
      <Animated.View
        style={[
          styles.animatedContainer,
          {right: position, opacity: fadeAnimation},
        ]}
        pointerEvents="box-none">
        <VideoContainer
          style={videoContainerStyle}
          stream={stream}
          pointerEvents="box-none"
          waiting={waiting}
          mirror={mirrorCamera}
        />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 110,
    right: 0,
    overflow: 'hidden',
  },
  animatedContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
  },
});

LocalVideo.propTypes = {
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

LocalVideo.defaultProps = {
  style: {},
};

export default LocalVideo;
