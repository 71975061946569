import base64 from 'react-native-base64';
import {Platform} from 'react-native';

import World from 'components/World';
import fallbackData from './appearance.json';
import images from './images';
import {
  HEAD_CATEGORY,
  TOP_CATEGORY,
  BOTTOM_CATEGORY,
  SHOES_CATEGORY,
  DRESS_CATEGORY,
} from 'utils/Appearances/appearanceConsts';
import {
  SAVE_CUSTOMIZE_AVATAR_SUBCATEGORY,
  SAVE_CUSTOMIZE_AVATAR_TEXTURE,
  SAVE_CUSTOMIZE_AVATAR_COLOR,
  SAVE_CUSTOMIZE_AVATAR_BODY,
  CUSTOMIZE_AVATAR_SUBCATEGORY,
  CUSTOMIZE_AVATAR_TEXTURE,
  CUSTOMIZE_AVATAR_COLOR,
  CUSTOMIZE_AVATAR_BODY,
} from 'utils/firebase/analytics.config';
import {logEvent, setUserProperties} from 'utils/firebase/analytics';
import getRemoteConfigValue from 'utils/firebase/remoteConfig';

export default class Appearances {
  static appearanceData = null;
  static bodyTypes = null;

  static getBodyTypes(appearanceDataParameter) {
    // use the fallback appearanceData if it's not been set yet
    this.appearanceData =
      !appearanceDataParameter || appearanceDataParameter === ''
        ? fallbackData
        : appearanceDataParameter;

    this.bodyTypes = [];

    for (const x in this.appearanceData.bodyTypes) {
      this.bodyTypes.push(x);
    }

    return this.bodyTypes;
  }

  static getCategories(appearanceDataParameter, body) {
    // use the fallback appearanceData if it's not been set yet
    this.appearanceData =
      !appearanceDataParameter || appearanceDataParameter === ''
        ? fallbackData
        : appearanceDataParameter;

    if (!body) {
      return [];
    }

    // get only the category options for the provided gender
    const categories = [];
    for (const category in this.appearanceData.bodyTypes[body].categories) {
      categories.push(category);
    }

    return categories;
  }

  static getCategoryImage(body, category) {
    return images[body][category];
  }

  static getSubcategories(appearanceDataParameter, body, category) {
    // use the fallback appearanceData if it's not been set yet
    this.appearanceData =
      !appearanceDataParameter || appearanceDataParameter === ''
        ? fallbackData
        : appearanceDataParameter;

    if (!body || !category) {
      return [];
    }

    const categoryData = this.appearanceData.bodyTypes[body].categories[
      category
    ].types;

    const categoryOptions = categoryData.map(option => {
      const {id, imgUrl} = option;
      return {id, imgUrl};
    });

    return categoryOptions;
  }

  static getTextures(appearanceDataParameter, body, category, subCategory) {
    // use the fallback appearanceData if it's not been set yet
    this.appearanceData =
      !appearanceDataParameter || appearanceDataParameter === ''
        ? fallbackData
        : appearanceDataParameter;

    if (!body || !category || !subCategory || category === HEAD_CATEGORY) {
      return [];
    }

    const subCategoryObject = this.appearanceData.bodyTypes[body].categories[
      category
    ].types.find(type => {
      return type.id === subCategory;
    });

    const textureData = subCategoryObject.textures;

    const textureOptions = textureData.map(texture => {
      const {id, imgUrl} = texture;
      return {id, imgUrl};
    });

    return textureOptions;
  }

  static applyLook(look, body) {
    console.log('LOOK: ', look);
    const unityAppearanceObject = {
      headType: '',
      headAddress: '',
      body,
      clothingData: {},
    };

    let appearanceLook = look;

    // default to male if we weren't given a look
    // get a random male appearance if no look was given
    if (typeof look === 'undefined' || look === undefined) {
      unityAppearanceObject.body = 'male';
      appearanceLook = {
        body: 'male',
        categories: this.getRandomAppearance(null, 'male'),
      };
    }

    const categories = appearanceLook.categories;
    for (const category in categories) {
      // if it's the head category, determine the headType
      if (category === HEAD_CATEGORY) {
        let headType = categories[category].generateHead
          ? 'GENERATE_ASDK'
          : 'DOWNLOAD_ASDK';

        unityAppearanceObject.headType = headType;
        unityAppearanceObject.headAddress = categories[category].selection;
      }
      // if its any of the non-head categories...
      else {
        // create the clothing object
        let clothingObject = {
          clothingAddress: categories[category].selection,
          color: categories[category].color,
          textureAddress: categories[category].texture,
        };
        // add to the unityObject
        unityAppearanceObject.clothingData[category] = clothingObject;
      }
    }

    console.log('UNITY APPEARANCE OBJECT: ', unityAppearanceObject);

    const unityEncodedObject = base64.encode(
      JSON.stringify(unityAppearanceObject),
    );

    World.runCommand(`set_avatar runtime.user.local ${unityEncodedObject}`);
  }

  static getRandomAppearance(appearanceDataParameter, body) {
    // use the fallback appearanceData if it's not been set yet
    this.appearanceData =
      !appearanceDataParameter || appearanceDataParameter === ''
        ? fallbackData
        : appearanceDataParameter;

    // create a randomized looks object using the JSON file data and return it.
    const bodySpecificCategories = this.appearanceData.bodyTypes[body]
      .categories;
    let newLook = {};

    for (const category in bodySpecificCategories) {
      // get all possible type values then select one randomly
      const typeOptions = bodySpecificCategories[category].types;
      const randomType =
        Array.isArray(typeOptions) && typeOptions.length > 0
          ? typeOptions[Math.floor(Math.random() * typeOptions.length)]
          : '';

      newLook[category] = {};
      newLook[category].selection = randomType.id;
      if (category === HEAD_CATEGORY) {
        newLook[category].generateHead = false;
      }

      // get all possible texture values for the selected type, then select one randomly
      const textureOptions = randomType.textures;
      const randomTexture =
        Array.isArray(textureOptions) && textureOptions.length > 0
          ? textureOptions[Math.floor(Math.random() * textureOptions.length)]
          : '';

      newLook[category].texture = randomTexture.id;

      // generate a random color hex code only if it's not the head category.
      let randomColor = '';
      if (category !== HEAD_CATEGORY) {
        randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
      }

      newLook[category].color = randomColor;
    }

    // if the new look has a "dress" in it then randomly pick either the dress or the top/bottom to use
    if ('dress' in newLook) {
      // generate random number of either 0 or 1
      const randomCheck = Math.floor(Math.random() * 2);
      // remove the dress
      if (randomCheck === 0) {
        delete newLook.dress;
      }
      // remove the top/bottom
      else {
        delete newLook.top;
        delete newLook.bottom;
      }
    }

    return newLook;
  }

  static restoreCameraPosition(ratio, isInGame) {
    console.log('RESTORING CAMERA POSITION');
    console.log('RATIO H/W: ', ratio);
    const radius = isInGame ? 2.25 : 5;
    // radius value refers to the radius of the circle on which the camera rotates and is measured in in-game meters. The higher the number the farther the camera is zoomed out from the avatar.

    const angle = isInGame ? 0 : 10;
    // angle refers to the camera's rotation around the avatar and is measured in degrees. 0 being right in front of the avatar and positive values moving the camera to the left around the avatar.

    const height = 1.5;
    // height refers to the cameras position vertically in front of the avatar and is measured in in-game meters. 0 means the avatars feet are directly in center of camera, positive numbers move the camera up from there.

    const pitch = isInGame ? 0 : 10;
    // pitch refers to the cameras vertical rotational orientation. 0 means its pointing straight ahead, postive values tilt it down, negative values tilt it up.

    // the camera offset needs to change based on the ratio. Here's the best formula I could get with the data I had
    // offset = -0.735 + 0.747 ln x (where x is the ratio)
    const horizontal_offset = this.horizontalOffsetCalc(
      ratio,
      radius,
      isInGame,
    );
    // horizontal_offset refers to the cameras position horizontally in front of the avatar and is measured in in-game meters. 0 means the avatar is centered in front of the camera. Positive numbers move the camera to the left, negative to the right - apparently.

    const cameraOrtho = false;
    // TODO: remove the cameraOrtho variable and all it's uses from both Unity and ReactNative (no longer used)
    // determines whether the camera users Orthographic or Perspective type (NOTE: this is no longer used by Unity, but is still in the command unity receives.)

    const command = `focus_camera user ${radius} ${angle} ${height} ${pitch} ${horizontal_offset} ${cameraOrtho}`;

    World.runCommand(command);
  }

  // Currently, to put avatar in customizer scene at start, we need to call focus_camera
  // method after the user has actually created because the corresponding method in unity
  // looks for player and if that's not created ever, the method is ignored.
  // But this idea is not working for now. So for now, we are just sending camera parameters
  // to unity and calling that focus camera method at end through unity at end of avatar creation.

  static sendFocusCameraParametersToUnity(body, ratio, isInGame) {
    console.log('SENDING CAMERA PARAMETERS TO UNITY');
    World.runCommand('set_logger true 2');
    console.log('RATIO H/W: ', ratio);

    var category = this.appearanceData.bodyTypes[body].categories[0];

    console.log('category : ' + category);
    console.log('body : ' + body);

    const radius = isInGame ? 2.25 : 5;
    // radius value refers to the radius of the circle on which the camera rotates and is measured in in-game meters. The higher the number the farther the camera is zoomed out from the avatar.

    const angle = isInGame ? 0 : 10;
    // angle refers to the camera's rotation around the avatar and is measured in degrees. 0 being right in front of the avatar and positive values moving the camera to the left around the avatar.

    const height = 1.5;
    // height refers to the cameras position vertically in front of the avatar and is measured in in-game meters. 0 means the avatars feet are directly in center of camera, positive numbers move the camera up from there.

    const pitch = isInGame ? 0 : 10;
    // pitch refers to the cameras vertical rotational orientation. 0 means its pointing straight ahead, postive values tilt it down, negative values tilt it up.

    // the camera offset needs to change based on the ratio. Here's the best formula I could get with the data I had
    // offset = -0.735 + 0.747 ln x (where x is the ratio)
    const horizontal_offset = this.horizontalOffsetCalc(
      ratio,
      radius,
      isInGame,
    );
    // horizontal_offset refers to the cameras position horizontally in front of the avatar and is measured in in-game meters. 0 means the avatar is centered in front of the camera. Positive numbers move the camera to the left, negative to the right - apparently.

    const cameraOrtho = false;

    var radiusCategory;
    var angleCategory;
    var heightCategory;
    var pitchCategory;
    var horizontal_offsetCategory;
    const cameraOrthoCategory = false;

    switch (category) {
      case HEAD_CATEGORY:
        radiusCategory = 1.25;
        angleCategory = 0;
        heightCategory = body === 'boy' || body === 'girl' ? 1.25 : 1.55;
        pitchCategory = 0;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
        break;
      case TOP_CATEGORY:
        radiusCategory = 2.25;
        angleCategory = 0;
        heightCategory = body === 'boy' || body === 'girl' ? 1.0 : 1.1;
        pitchCategory = 0;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
        break;
      case BOTTOM_CATEGORY:
        radiusCategory = 2.5;
        angleCategory = 0;
        heightCategory = body === 'boy' || body === 'girl' ? 0.5 : 0.5;
        pitchCategory = 0;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
        break;
      case SHOES_CATEGORY:
        radiusCategory = 2;
        angleCategory = 10;
        heightCategory = body === 'boy' || body === 'girl' ? 0.75 : 0.75;
        pitchCategory = 20;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
        break;
      case DRESS_CATEGORY:
        radiusCategory = 2.75;
        angleCategory = 0;
        heightCategory = body === 'boy' || body === 'girl' ? 1 : 1;
        pitchCategory = 0;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
        break;
      default:
        radiusCategory = 1.5;
        angleCategory = 0;
        heightCategory = body === 'boy' || body === 'girl' ? 1 : 1;
        pitchCategory = 0;
        horizontal_offsetCategory = this.horizontalOffsetCalc(
          ratio,
          radiusCategory,
          isInGame,
        );
    }

    // TODO: remove the cameraOrtho variable and all it's uses from both Unity and ReactNative (no longer used)
    // determines whether the camera users Orthographic or Perspective type (NOTE: this is no longer used by Unity, but is still in the command unity receives.)

    const command = `set_focus_camera_parameters ${radius} ${angle} ${height} ${pitch} ${horizontal_offset} ${cameraOrtho} ${radiusCategory} ${angleCategory} ${heightCategory} ${pitchCategory} ${horizontal_offsetCategory} ${cameraOrthoCategory}`;

    World.runCommand(command);
  }

  static focusCategory(category, ratio, isInGame, body) {
    let radius;
    let angle;
    let height;
    let pitch;
    let horizontal_offset;
    const cameraOrtho = false;

    switch (category) {
      case HEAD_CATEGORY:
        radius = 1.25;
        angle = 0;
        height = body === 'boy' || body === 'girl' ? 1.25 : 1.55;
        pitch = 0;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
        break;
      case TOP_CATEGORY:
        radius = 2.25;
        angle = 0;
        height = body === 'boy' || body === 'girl' ? 1.0 : 1.1;
        pitch = 0;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
        break;
      case BOTTOM_CATEGORY:
        radius = 2.5;
        angle = 0;
        height = body === 'boy' || body === 'girl' ? 0.5 : 0.5;
        pitch = 0;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
        break;
      case SHOES_CATEGORY:
        radius = 2;
        angle = 10;
        height = body === 'boy' || body === 'girl' ? 0.75 : 0.75;
        pitch = 20;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
        break;
      case DRESS_CATEGORY:
        radius = 2.75;
        angle = 0;
        height = body === 'boy' || body === 'girl' ? 1 : 1;
        pitch = 0;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
        break;
      default:
        radius = 1.5;
        angle = 0;
        height = body === 'boy' || body === 'girl' ? 1 : 1;
        pitch = 0;
        horizontal_offset = this.horizontalOffsetCalc(ratio, radius, isInGame);
    }

    World.runCommand(
      `focus_camera user ${radius} ${angle} ${height} ${pitch} ${horizontal_offset} ${cameraOrtho}`,
    );
  }

  static horizontalOffsetCalc(ratio, radius, isInGame) {
    // offset = -0.735 + 0.747 ln x (where x is the ratio)
    // this equation applies to the base case (camera is not focused on any specific body part)
    // Base radius is 4 (this was the radius I used to get the data for the equation)
    const radiusDiff = radius / 4;

    let horizontalOffset = radiusDiff * (-0.735 + 0.747 * Math.log(ratio));

    // because the equation was not a perfect fit... let's make some minor adjustments at certain points
    // the line was off above ratios of 1.5 - the actual data was a log line approaching ~.35 offset. So just set it to -.35.
    // (for all exceptional cases be sure to adjust based on the radius)
    if (ratio > 1.5) {
      horizontalOffset = radiusDiff * -0.4;
    }
    // the line is off between ratios of .33 and 1.25 by about +.1
    else if (ratio > 0.33 && ratio < 1.25) {
      horizontalOffset += 0.1 * radiusDiff;
    }
    // the line is off below ratios of .33 - the actual data indicates an offset that approaches infinity as the ratio approaches 0...
    // so we will pick -.3 as a good adjustor to catch most practical cases
    else if (ratio <= 0.33) {
      horizontalOffset += -0.3 * radiusDiff;
    }

    // lastly - if the user is modifying their character IN GAME then they need to be adjusted a bit further.
    // the adjustment only really needs to happen at .75 ratio and above (below that it all looks ok)
    if (isInGame) {
      if (ratio >= 0.75 && ratio < 0.9) {
        horizontalOffset += -0.3 * radiusDiff;
      } else if (ratio >= 0.9) {
        horizontalOffset += -0.5 * radiusDiff;
      }
    }

    return horizontalOffset;
  }

  static getAddressablesPlatformName() {
    switch (Platform.OS) {
      case 'android':
        return 'Android';
      case 'ios':
        return 'iOS';
      case 'web':
        return 'WebGL';
      default:
        console.warn('Unexpected OS found: ', Platform.OS);
        return '';
    }
  }

  static initializeAvatarSystem(uid) {
    // TODO: move these two const variables to configurable environment variables
    const avatarSDKClientId = getRemoteConfigValue(
      'avatar_sdk_client_id',
    ).asString();
    const avatarSDKSecretKey = getRemoteConfigValue(
      'avatar_sdk_secret_key',
    ).asString();

    const unityCredentialsObject = {
      playerUID: uid,
      ClientId: avatarSDKClientId,
      SecretKey: avatarSDKSecretKey,
    };
    const encodedCredentials = base64.encode(
      JSON.stringify(unityCredentialsObject),
    );
    const addressablesBaseUrl = getRemoteConfigValue(
      'addressables_catalog_base_url',
    ).asString();
    const addressablesCatalog = `${addressablesBaseUrl}${this.getAddressablesPlatformName()}/catalog_view.json`;
    World.runCommand('set_logger true 2');
    World.runCommand(
      `initialize_avatar_system ${encodedCredentials} ${addressablesCatalog}`,
    );
  }

  static UseCustomizeCamera(ratio, isInGame) {
    World.runCommand('show_thumbstick false');
    World.runCommand('enable_movement false');
    Appearances.restoreCameraPosition(ratio, isInGame);
  }

  static UsePlayerCamera() {
    World.runCommand('show_thumbstick true');
    World.runCommand('enable_movement true');
    World.runCommand('use_player_camera');
  }

  static broadcastAppearance(userSelectedBody, looks) {
    World.runCommand('save_appearance');
    this.saveAppearanceToAnalytics(userSelectedBody, looks);
  }

  static saveAppearanceToAnalytics(userSelectedBody, looks) {
    // save selected body to analytics
    logEvent(SAVE_CUSTOMIZE_AVATAR_BODY, userSelectedBody);
    setUserProperties({[CUSTOMIZE_AVATAR_BODY]: userSelectedBody});

    const categories = looks[userSelectedBody].categories;
    for (const category in categories) {
      // save the selected subcategory to analytics
      const subcategoryStringEvent = `${SAVE_CUSTOMIZE_AVATAR_SUBCATEGORY}_${category}`;
      const subcategoryStringUser = `${CUSTOMIZE_AVATAR_SUBCATEGORY}_${category}`;
      logEvent(subcategoryStringEvent, categories[category].selection);
      setUserProperties({
        [subcategoryStringUser]: categories[category].selection,
      });

      // save the selected texture to analytics
      const textureStringEvent = `${SAVE_CUSTOMIZE_AVATAR_TEXTURE}_${category}`;
      const textureStringUser = `${CUSTOMIZE_AVATAR_TEXTURE}_${category}`;
      logEvent(textureStringEvent, categories[category].texture);
      setUserProperties({[textureStringUser]: categories[category].texture});

      // save the selected color to analytics
      const colorStringEvent = `${SAVE_CUSTOMIZE_AVATAR_COLOR}_${category}`;
      const colorStringUser = `${CUSTOMIZE_AVATAR_COLOR}_${category}`;
      logEvent(colorStringEvent, categories[category].color);
      setUserProperties({[colorStringUser]: categories[category].color});
    }
  }
}
