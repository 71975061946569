import React, {useState, useContext} from 'react';
import {View, SafeAreaView, StatusBar, StyleSheet} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';

import {goToLanding} from 'store/app';
import {leaveRoom} from 'store/communication/actions/controlActions';
import {checkPermission, MEETING_END} from 'utils/permissions';
import {I18nContext} from 'i18n/context/I18nContext';

import MeetingWorld from 'screens/Meeting/components/MeetingWorld';
import Controls from 'screens/Meeting/components/Controls';
import LocalVideo from 'screens/Meeting/components/LocalVideo';
import RemoteVideos from 'screens/Meeting/components/peers/RemoteVideos';
import RemoteAudios from 'screens/Meeting/components/peers/RemoteAudios';
import SidePanel from 'screens/Meeting/components/SidePanel';
import Settings from 'screens/Meeting/components/SideBar/Settings';
import Viewboards from 'screens/Meeting/components/SideBar/Viewboards';
import Navigation from 'screens/Meeting/components/SideBar/Navigation';
import Attendance from 'screens/Meeting/components/SideBar/Attendance';
import Customize from './components/SideBar/Customize/Customize';
import Messages from 'screens/Meeting/components/SideBar/Messages';
import ConnectionFailureOverlay from 'components/Communication/ConnectionFailureOverlay';
import SettingsDialogueOverlay from 'components/Communication/SettingsDialogueOverlay';
import LeaveMeetingOverlay from 'components/Communication/LeaveMeetingOverlay';
import ShareMeetingModal from 'screens/Meeting/components/ShareMeetingModal';

import worldStyle from 'style/3d';
import styles from 'style/screen';
import {
  MEETING_STATUS_BAR_BACKGROUND_COLOR,
  SIDE_PANEL_ATTENDANCE_COLOR,
  SIDE_PANEL_MESSAGES_COLOR,
  SIDE_PANEL_NAVIGATION_COLOR,
  SIDE_PANEL_VIEWBOARDS_COLOR,
  SIDE_PANEL_SETTINGS_COLOR,
} from 'style/theme';
import RecordingStatus from 'components/Communication/RecordingStatus';
import {ROOM_STATE} from 'utils/communication/utils';

const Meeting = () => {
  const dispatch = useDispatch();
  const {translate} = useContext(I18nContext);
  const permissions = useSelector(
    (state) => state.communication.me.permissions,
  );
  const inviteURL = useSelector((state) => state.meeting.properties.inviteURL);
  const isMessageNotification = useSelector(
    (state) => state.world.isMessageNotification,
  );
  const peers = useSelector((state) => state.communication.peers);
  const peerCount = Object.keys(peers).length;

  const activeViewboards = useSelector(
    (state) => state.viewboard.activeViewboards,
  );

  const meetingMaxNumberParticipants = useSelector(
    (state) => state.meeting.properties.meetingMaxNumberParticipants,
  );

  const roomState = useSelector((state) => state.communication.room.state);
  const isRoomConnected = roomState === ROOM_STATE.connected;

  const [isLeaveMeetingModalVisible, setIsLeaveMeetingModalVisible] = useState(
    false,
  );

  const onClose = () => {
    if (checkPermission(MEETING_END, permissions) && peerCount > 0) {
      setIsLeaveMeetingModalVisible(true);
    } else {
      dispatch(leaveRoom());
      dispatch(goToLanding());
    }
  };

  return (
    <View style={styles.full} pointerEvents="box-none">
      <ShareMeetingModal
        inviteURL={inviteURL}
        peerCount={peerCount}
        meetingMaxNumberParticipants={meetingMaxNumberParticipants}
        isRoomConnected={isRoomConnected}
        shouldDisplay={
          checkPermission(MEETING_END, permissions) && inviteURL !== undefined
        }
      />
      <StatusBar
        backgroundColor={MEETING_STATUS_BAR_BACKGROUND_COLOR}
        barStyle="light-content"
        translucent
      />
      <MeetingWorld />
      <RemoteAudios />
      <SafeAreaView style={worldStyle.safeUI} pointerEvents="box-none">
        <LocalVideo />
        <RemoteVideos />
        <Controls onClose={onClose} />
        <SidePanel>
          <Customize
            color={'#fe6507'}
            icon="account-edit"
            title={translate('meeting.avatar')}
          />
          <Attendance
            color={SIDE_PANEL_ATTENDANCE_COLOR}
            icon="account-group"
            title={translate('meeting.people')}
            textBadge
            badgeContent={peerCount > 0 ? peerCount : '+'}
          />
          <Messages
            color={SIDE_PANEL_MESSAGES_COLOR}
            icon="message-text-outline"
            title={translate('meeting.messages')}
            textBadge={isMessageNotification}
            badgeStyle={meetingStyles.badgeStyle}
          />
          <Navigation
            color={SIDE_PANEL_NAVIGATION_COLOR}
            icon="earth"
            title={translate('meeting.navigation')}
          />
          <Viewboards
            color={SIDE_PANEL_VIEWBOARDS_COLOR}
            icon="monitor-clean"
            title={translate('meeting.viewboards')}
            textBadge={activeViewboards?.length > 0}
            badgeContent={
              activeViewboards?.length > 0 ? activeViewboards.length : ''
            }
          />
          <Settings
            color={SIDE_PANEL_SETTINGS_COLOR}
            icon="cog-outline"
            title={translate('meeting.settings')}
          />
        </SidePanel>
      </SafeAreaView>

      <RecordingStatus />

      <ConnectionFailureOverlay />

      <SettingsDialogueOverlay />
      <LeaveMeetingOverlay
        isLeaveMeetingModalVisible={isLeaveMeetingModalVisible}
        setIsLeaveMeetingModalVisible={setIsLeaveMeetingModalVisible}
      />
    </View>
  );
};

const meetingStyles = StyleSheet.create({
  badgeStyle: {
    height: 15,
    width: 15,
  },
});

export default Meeting;
